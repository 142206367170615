





























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import { Driver } from '@/shared/models/driver.model';

@Component({})
export default class DriversSearchModal extends Vue {
  @Prop({ default: '' }) search: string;
  @Prop(String) modalName;

  searchModel = null;

  drivers: Driver[] = [];
  items: Driver[] = [];

  perPage = 25;
  currentPage = 1;

  activeIndex = null;

  constructor() {
    super();
  }

  paginate(pageSize: number, pageNumber: number) {
    this.drivers = this.items.slice(
      pageNumber * pageSize,
      (pageNumber + 1) * pageSize
    );
  }

  onPageChanged(page: number) {
    this.paginate(this.perPage, page - 1);
    this.activeIndex = null;
  }

  created() {
    this.searchModel = this.search;
    this.getDrivers();
  }

  async getDrivers() {
    this.items = await MasterDataService.getDrivers(
      this.searchModel,
      'firstName'
    );

    this.paginate(this.perPage, 0);
  }

  selectDriver(index: number) {
    this.activeIndex = index === this.activeIndex ? null : index;
  }

  assign() {
    this.$emit('select', this.drivers[this.activeIndex].id);
    this.hide();
  }

  hide() {
    this.$bvModal.hide(this.modalName);
  }
}

import { AccountManagementService } from '@/shared/services/account-management/account-management.service';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { ToastHelper } from '../../../utils/toast.util';
import { Account } from './account-management.model';

@Module({
  namespaced: true,
  name: 'accountManagement'
})
export default class AccountManagementModule extends VuexModule {

  loading = false;
  errorMessage: any = null;

  filters: string = null;
  page = 1;
  meta: any = null;

  accounts: Account[] | any = [];
  account: Account = null;

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setAccounts({ data, meta }) {
    this.accounts = data;
    this.meta = meta;
  }

  @Mutation
  public getAccounts() {
    return this.accounts;
  }

  @Mutation
  public setAccount(data) {
    this.account = data;
  }

  @Mutation
  public getAccount() {
    return this.account;
  }

  @Mutation
  public setMetaInformation(meta: any) {
    this.meta = meta;
  }

  @Mutation
  public setFilters(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public setPage(page: number) {
    this.page = page;
  }

  @Action
  async load() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await AccountManagementService.getAccounts(null);
      this.context.commit('setAccounts', res);
    } catch (err) {
      console.log('accountsImport Error', err);
      ToastHelper.show('Account', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async search(limit?) {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await AccountManagementService.getAccounts(
        this.filters,
        this.page,
        limit
      );
      this.context.commit('setAccounts', res);
    } catch (err) {
      console.log('accountsImport Error', err);
      ToastHelper.show('Account', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async getAccountById(id: string) {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await AccountManagementService.getAccountById(
        id
      );
      this.context.commit('setAccount', res);
    } catch (err) {
      ToastHelper.show('Account', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async changeAccountActive(account: any) {
    this.context.commit('setLoadingStatus', true);
    try {
      await AccountManagementService.changeAccountActive(
        account
      );
    } catch (err) {
      ToastHelper.show('Account', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async createOrEditAccount(account: any) {
    this.context.commit('setLoadingStatus', true);
    try {
      if (account.acctId)
        await AccountManagementService.updateAccount(
          account
        );
      else
        await AccountManagementService.createAccount(
          account
        );
      this.load();
      this.context.commit('setLoadingStatus', false);
      ToastHelper.show('Account', 'Data saved successfully', 5000, 'success');
    } catch (err) {
      ToastHelper.show('Account', err.response.data?.error);
      this.context.commit('setLoadingStatus', false);
      throw err;
    }
  }
}

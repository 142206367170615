import { BaseClass } from '../base/base.service';

export class AccountManagementProvider extends BaseClass {
  constructor() {
    super('');
  }

  limit = 10000;

  async getAccounts(filter, page = this.page, limit = this.limit) {
    const res = await this.get('accounts/', {
      filter,
      limit,
      page
    });

    return res.data;
  }

  async getAccountById(id: string) {
    const res = await this.get(`accounts/${id}`);
    return res.data;
  }

  async createAccount(data) {
    const res = await this.post('accounts/', data);
    return res.data;
  }

  async updateAccount(data) {
    return await this.put(`accounts/${data.acctId}`, data);
  }

  async deleteAccountById(id: string) {
    return await this.delete(`accounts/${id}`);
  }

  async changeAccountActive(account: any) {
    const res = await this.patch(`accounts/${account.acctId}?active=${account.active}`, null);
    return res.data;
  }

  async passwordReset(uuid: string) {
    const res = await this.post(`accounts/${uuid}/password/reset`, null);
    return res.data;
  }

  async getSupportEmails() {
    const res = await this.get(`support-info/emails`);
    return res.data;
  }

  async getGroupInfo() {
    const res = await this.get(`configs/group-info`);
    return res.data?.map(i=>({value:i.groupId,text:i.groupName}));
  }

}
export const AccountManagementService = new AccountManagementProvider();

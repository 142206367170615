import Home from '@/pages/Home.vue';
import { permissions } from '@/utils/permissions/permission.model';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import AuthGuard from './guards/auth.guard';
import DivisionGuard from './guards/division.guard';
import GuestGuard from './guards/guest.guard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: "/map/map",
    meta: { requiresAuth: true }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/pages/Auth/Login.vue'),
    meta: { requiresNotLogin: true }
  },
  {
    path: '/user/create',
    name: 'user/create',
    component: () => import('@/pages/Auth/CreateUser.vue'),
    meta: { permission: permissions.user.create }
  },
  {
    path: '/wo/import/:type',
    name: 'workOrder',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Import/ImportCreate/ImportCreate.vue')
  },
  {
    path: '/main/:type',
    name: 'main',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Main/MainPage.vue')
  },
  {
    path: '/reports/:type',
    name: 'reports',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Reports/Reports.vue')
  },
  {
    path: '/master',
    component: () => import('@/pages/Master/MasterPage.vue'),
    children: [
      {
        path: '/master/driver',
        name: 'master-driver',
        meta: { subPage: 'main' },
        component: () => import('@/pages/Master/Driver/Driver.vue')
      },
      {
        path: '/master/company',
        name: 'company',
        meta: { subPage: 'main' },
        component: () => import('@/pages/Master/Company/Company.vue')
      },
      {
        path: '/master/account-code',
        name: 'account-code',
        meta: { subPage: 'main' },
        component: () => import('@/pages/Master/Account/Account.vue')
      }
    ]
  },
  {
    path: '/dispatch/export/booking',
    name: 'export-booking',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Dispatch/Export/Booking/Booking.vue')
  },
  {
    path: '/dispatch/export/return',
    name: 'export-return',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Dispatch/Export/Return/Return.vue')
  },
  {
    path: '/dispatch/export/delivery',
    name: 'export-delivery',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Dispatch/Export/Delivery/Delivery.vue')
  },
  {
    path: '/dispatch/export/unavailable',
    name: 'export-unavailable',
    meta: { subPage: 'main' },
    component: () =>
      import('@/pages/Dispatch/Export/Unavailable/Unavailable.vue')
  },
  {
    path: '/dispatch/export/available',
    name: 'export-available',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Dispatch/Export/Available/Available.vue')
  },
  {
    path: '/dispatch/van/:type',
    name: 'van',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Dispatch/Van/Van.vue')
  },
  {
    path: '/dispatch/import/:type',
    name: 'import',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Dispatch/Import/Import.vue')
  },
  {
    path: '/acct/:type',
    name: 'accounting',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Accounting/Accounting.vue')
  },
  {
    path: '/yms/inventory',
    name: 'inventory',
    meta: { subPage: 'yms' },
    component: () => import('@/pages/YMS/Inventory/Inventory.vue')
  },
  {
    path: '/yms/daily-in-out',
    name: 'daily-in-out',
    meta: { subPage: 'yms' },
    component: () => import('@/pages/YMS/DailyInOut/DailyInOut.vue')
  },
  {
    path: '/yms/damage-report',
    name: 'damage',
    meta: { subPage: 'yms' },
    component: () => import('@/pages/YMS/DamageReport/DamageReport.vue')
  },
  {
    path: '/yms/nav-trac',
    name: 'yms/nav-trac',
    meta: { subPage: 'yms' },
    component: () => import('@/pages/YMS/NavTrac/NavTrac.vue')
  },
  {
    path: '/driver/:type',
    name: 'driver',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Driver/Driver.vue')
  },
  {
    path: '/edi/hd',
    name: 'edi/hd',
    meta: { subPage: 'main' },
    component: () => import('@/pages/EDI/HD/HD.vue')
  },
  {
    path: '/edi/eg',
    name: 'edi/eg',
    meta: { subPage: 'main' },
    component: () => import('@/pages/EDI/EG/EG.vue')
  },
  {
    path: '/admin/early-warning',
    name: 'admin/early-warning',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Admin/EarlyWarning/EarlyWarning.vue')
  },
  {
    path: '/admin/roles',
    name: 'admin/roles',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Admin/UserRoles/UserRoles.vue')
  },
  {
    path: '/admin/config',
    name: 'admin/config',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Admin/Configuration/Configuration.vue')
  },
  {
    path: '/admin/early-warning-modal',
    name: 'early-warning-modal',
    component: () => import('@/shared/components/modal/EarlyWarningModal.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/admin/user-managment',
    name: 'admin/user-management',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Admin/UserManagement/UserManagement.vue')
  },
  {
    path: '/account-managment',
    name: 'account-management',
    meta: { subPage: 'accmgmt' },
    component: () => import('@/pages/Admin/AccountManagement/AccountManagement.vue')
  }, {
    path: '/admin/basic',
    name: 'admin/basic',
    meta: { subPage: 'main' },
    component: () => import('@/pages/Admin/Basic/Basic.vue')
  },
  {
    path: '/map',
    component: () => import('@/pages/Map/MapPage.vue'),
    children: [
      {
        path: 'map',
        name: 'Map',
        meta: { subPage: 'main' },
        component: () => import('@/pages/Map/Components/ClassicMap.vue')
      },
      {
        path: 'geofence',
        name: 'Geofence',
        meta: { subPage: 'main' },
        component: () => import('@/pages/Map/Components/Geofence.vue')
      }
    ]
  },
  {
    path: '*',
    meta: { subPage: 'main' },
    component: () => import('@/pages/NotFound/NotFoundPage.vue')
  }
];

const router = new VueRouter({
  routes
});

router.beforeResolve(GuestGuard);
router.beforeResolve(AuthGuard);
router.beforeResolve(DivisionGuard);

export default router;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_DtatP33mX',
  aws_user_pools_web_client_id: '5ck1eas8tn2ckc3s6f5s76fv4p',
  oauth: {}
};

export default awsmobile;

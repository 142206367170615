














































































































import { Component, Prop } from 'vue-property-decorator';
import { WoModalBaseClass } from './WoModalBase.class';
import { DATE_API_FORMAT } from '@/utils/date.util';
import EmailModal from '@/shared/components/modal/EmailModal.vue';
import { WorkOrderTrackingService } from '@/shared/services/wo/tracking.service';
import Loading from '@/shared/components/Loading.vue';
import TrackingModal from './DetailInfo/TrackingModal.vue';
import moment from 'moment-timezone';

@Component({
  components: { EmailModal, TrackingModal, Loading }
})
export default class TrackingTabModal extends WoModalBaseClass {
  //#region variables
  // props
  @Prop() wo: any;

  // variables
  deliveryStatus = false;
  loading = false;

  // email
  template = null;
  subject = null;
  clietEmails = null;
  fromEmail = null;
  trackingEmailModal = 'trackingEmailModal';

  trackingData = [];
  mapData: any;
  trackingModalSize = 'xl';

  constructor() {
    super();
  }

  created() {
    this.loadTrackingTable();
  }

  async loadTrackingTable() {
    this.loading = true;
    this.trackingData =
      await WorkOrderTrackingService.getWoTrackingTableDetails(
        this.wo.category,
        this.wo.orderNumber
      );
    this.loading = false;
  }

  getTime(timestamp: any) {
    return moment(timestamp, DATE_API_FORMAT).format('HH:mm');
  }

  hide() {
    this.$bvModal.hide('wo-base-modal');
  }

  showTripReplay() {
    this.$bvModal.show('TrackingTripReplayModal');
  }
}

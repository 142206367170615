import moment from 'moment-timezone';

export const DATE_VIEW_FORMAT = 'MM/DD/YY';
export const BS_DATEPICKER_FORMAT = 'YYYY-MM-DD';
export const DATE_API_FORMAT = 'YYYYMMDDHHmmss';
export const DATE_API_FORMAT_WITHOUT_TIME = 'YYYYMMDD';
export const DATE_EMAIL_FORMAT = 'MM/DD/YY';

export const DATE_VIEW_FULL_FORMAT = 'MM/DD/YY';

export const DATE_VIEW_FULL_FORMAT_WITHTIME = 'MM/DD/YY HH:mm:ss';
export const DATE_TIME_FORMAT = 'HH:mm';
export const DATE_VALIDATION_FORMAT = 'DD/MM/YY';
export const DATE_TIME_EMAIL_FORMAT = 'MM/DD/YY HH:mm';
export const BS_DATEPICKER_WITH_TIME = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';

export const setTimeZone = (timzone: string) => {
  moment.tz.setDefault(timzone);
};

export const convertDateToAPIFormat = (data: any, fields: string[]) => {
  fields.forEach((filed: string) => {
    if (data[filed]) {
      data[filed] = moment(data[filed])
        .utc()
        .format(DATE_API_FORMAT);
    }
  });
};

export const convertAPIToDateFormat = (date: any) => {
  return moment(date, DATE_API_FORMAT).format(BS_DATEPICKER_FORMAT);
};

export const convertAPIToViewFullFormat = (date: any) => {
  return moment(date, DATE_API_FORMAT).format(DATE_VIEW_FULL_FORMAT);
};

export const convertDateFieldToAPIFormat = (date: string, format?: string) => {
  return moment(date, format)
    .utc()
    .format(DATE_API_FORMAT);
};

export const convertDateFieldToTime = (date: string, format?: string) => {
  return moment(date, format).format(DATE_TIME_FORMAT_WITH_SECONDS);
};

export const convertDateFieldToAPIFormatWithoutTime = (date: string) => {
  return moment(date).format(DATE_API_FORMAT_WITHOUT_TIME);
};

export const convertAPIToFormat = (date: string, format?: string) => {
  return moment(date, DATE_API_FORMAT).format(format);
};

export const convertDateArrayFormatFieldToAPIFormat = (
  date: string,
  format: any[]
) => {
  return moment(date, format).format(DATE_API_FORMAT);
};

export const endOfDay = (date: string) => {
  return moment(date)
    .endOf('day')
    .format(DATE_API_FORMAT);
};

export const combineDateAndTime = (date, time) => {
  return moment(
    `${date} ${time || '00:00:00'}`,
    BS_DATEPICKER_WITH_TIME
  ).format(DATE_API_FORMAT);
};

export const lessThenCompare = (lessDate, compareDate) => {
  return moment(lessDate, DATE_API_FORMAT).diff(
    moment(compareDate, DATE_API_FORMAT),
    'days'
  );
};

export const lessThenTimeCompare = (lessDate, compareDate) => {
  return moment(lessDate, DATE_API_FORMAT).diff(
    moment(compareDate, DATE_API_FORMAT),
    'minutes'
  );
};

export function convertStrignDateToDate(date: string, format?: string): Date {
  return moment(date, format).toDate();
}

export function convertDateToFormat(date: Date, format: string) {
  return moment(date).format(format);
}

export function addDaysToDate(date: Date, numberCalendarDays: number): Date {
  return moment(date)
    .add(numberCalendarDays, 'days')
    .toDate();
}

export function isWeekend(date: Date) {
  return date.getDay() === 6 || date.getDay() === 0;
}


























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EmailOrderService } from '@/shared/services/email/email.service';
import Loading from '../Loading.vue';
import { VueEditor } from 'vue2-editor';
import { validationMixin } from 'vuelidate';
import { emailValid } from './@types/email.modal';

Component.registerHooks(['validations']);
@Component({
  components: { Loading, VueEditor },
  mixins: [validationMixin]
})
export default class EmailModal extends Vue {
  @Prop() template: string;
  @Prop() subject: string;
  @Prop() clietEmails: string;
  @Prop() cc: string;
  @Prop() id: string;
  form = null;
  errorModal = false;

  customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' }
    ],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: [] }, { background: [] }] // dropdown with defaults from theme
  ];

  emailOrderService = EmailOrderService;

  emailTo = null;
  emailCc = null;
  emailSubject = null;
  emailBody = null;

  loading = false;

  constructor() {
    super();
  }

  created() {
    this.form = {
      emailTo: this.clietEmails,
      emailCc: this.cc,
      emailSubject: this.subject,
      emailBody: this.template
    };
  }

  async submitEmail() {
    if (this.isFormInValid()) return;
    const emailForm = {
      to: this.form.emailTo,
      cc: this.form.emailCc,
      subject: this.form.emailSubject,
      body: btoa(this.form.emailBody?.replace(/<p>/g, '').replace(/<\/p>/g, ''))
    };
    this.loading = true;
    await this.emailOrderService.sendEmail(emailForm);
    this.hide();
    this.loading = false;
  }

  hide() {
    this.$bvModal.hide(this.id ? this.id : 'EmailModal');
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  get requiredFields(): Set<string> {
    return new Set(
      Object.keys(emailValid).filter(
        fieldName => emailValid[fieldName].required
      )
    );
  }

  isFormInValid() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }
    return this.$v.form.$anyError;
  }

  validations() {
    return {
      form: emailValid
    };
  }
}

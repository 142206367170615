




























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Loading from '@/shared/components/Loading.vue';
import {
  JobPayments,
  JobPlanning,
  responseJobPayment
} from '@/shared/models/driverIdModal.model';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import {
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { WorkOrder } from '@/store/modules/workOrder/work-order.model';
import {
  BS_DATEPICKER_WITH_TIME,
  convertDateFieldToAPIFormat
} from '@/utils/date.util';
import { deepClone } from '@/utils/utils';

@Component({ components: { Loading } })
export default class DriverIdModal extends Vue {
  @Prop() data: JobPayments;
  @Prop() wo: WorkOrder;

  masterDataService = MasterDataService;

  saveClicked = false;
  tableKeys = { ACCT: 'ACCT', LOCA: 'LOCA' };
  loading = true;
  jobPlanning: JobPlanning = null;
  jobPaymentIndex = 0;
  jobPayments: responseJobPayment[] = null;
  fieldsToValidate = ['accountingCodeName', 'amount'];

  created() {
    this.getPlaning();
  }

  addPayment() {
    this.jobPayments[this.jobPaymentIndex].payments.push({
      accountingCode: null,
      accountingCodeName: null,
      amount: null
    });
  }

  removePayment(index) {
    this.jobPayments[this.jobPaymentIndex].payments.splice(index, 1);
  }

  back() {
    if (this.jobPayments.length > 1 && this.jobPaymentIndex !== 0) {
      this.jobPaymentIndex--;
    } else {
      this.hide(true);
    }
  }

  next() {
    this.saveClicked = true;
    if (this.validateFields()) {
      this.saveClicked = false;
      this.jobPaymentIndex++;
    }
  }

  save() {
    this.saveClicked = true;
    if (this.validateFields()) {
      this.jobPlanning.jobPayments.forEach(resJobPayment => {
        this.jobPayments.forEach(jobPayment => {
          if (resJobPayment.jobUuid === jobPayment.jobUuid) {
            resJobPayment.shouldSkip = true;
            resJobPayment.payments = jobPayment.payments;
          }
        });
      });
      this.$emit('saveModal', this.jobPlanning);
      this.$bvModal.hide('DriverIdModal');
    }
  }

  async getPlaning() {
    const currentWo = deepClone({
      orderNumber: this.wo.orderNumber,
      category: this.wo.category,
      ...this.data
    });
    currentWo.currentSchedules.forEach(item => {
      item.scheduleIn = convertDateFieldToAPIFormat(
        `${item.scheduleIn} ${item.scheduleInTime}`,
        BS_DATEPICKER_WITH_TIME
      );
    });
    const { jobPlanning, jobPayments } = await JobsService.planning(currentWo);

    this.jobPayments = jobPayments;
    this.jobPlanning = jobPlanning;
    this.loading = false;
  }

  validateFields() {
    let status = true;
    this.jobPayments[this.jobPaymentIndex].payments.forEach(payment => {
      this.fieldsToValidate.forEach(field => {
        if (!payment[field]) {
          status = false;
        }
      });
    });
    return status;
  }

  validateString(string: string) {
    if (!string || string.toString().trim() === '') {
      return false;
    }
    return true;
  }

  onChange(data, index) {
    this.jobPayments[this.jobPaymentIndex].payments[index].accountingCode = data
      ? data.id
      : null;
    this.jobPayments[this.jobPaymentIndex].payments[index].accountingCodeName =
      data ? data.name : null;
  }

  onInput(input, index) {
    if (input?.length === 0) {
      this.jobPayments[this.jobPaymentIndex].payments[index].accountingCode =
        '';
      this.jobPayments[this.jobPaymentIndex].payments[
        index
      ].accountingCodeName = '';
    }
  }

  async getGLCodes(query: string) {
    const filter = TransformFiltersToJson([
      {
        search: query,
        field: 'name',
        condition: FilterConditions.BeginsWith
      },
      {
        search: '49999',
        field: 'id',
        condition: FilterConditions.Greater
      },
      {
        search: '60000',
        field: 'id',
        condition: FilterConditions.LessThenOrEqual
      }
    ]);
    const glCodes = await this.masterDataService.getGLCodes(filter);
    return glCodes.data;
  }

  hide(openDriverModal = false) {
    const activeDrivers = this.jobPayments.filter(
      jobPayment => !jobPayment.shouldSkip
    );
    const activeUiid = this.jobPlanning.schedules.filter(payment =>
      activeDrivers.find(driver => driver.driverId === payment.driverId)
    );

    this.$emit('showDriverModal', {
      activeUiid,
      openDriverModal: openDriverModal
    });
    this.$bvModal.hide('DriverIdModalAccCode');
    this.$bvModal.hide('driverAmaunModal');
  }
}

























import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TimePicker extends Vue {
  @Prop(String) value: string;
  @Prop({ type: [Boolean], default: null }) state: boolean;
  @Prop(Boolean) required: boolean;
  @Prop(Boolean) isDirty: boolean;
  @Prop(String) size: string;
  @Prop(Boolean) disabled: boolean;

  timeInputModel: string = null;

  handlerChecked = true;
  handlerModal = false;
  incorrectDateModel = false;
  incorrectDateModelFormat = false;
  handlerTitle = 'Error message';

  timeRangeMask = (value: string) => {
    const timeMask = (value: string) => {
      const hours = [/[0-2]/, value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ':', ...minutes] : hours;
    };

    const numbers = value.replace(/[^0-9]/g, '');
    if (numbers.length > 4) {
      return [...timeMask(numbers.substring(0, 4))];
    }
    return [...timeMask(numbers)];
  };

  constructor() {
    super();
  }

  change() {
    if (this.timeInputModel && this.timeInputModel.length === 5) {
      this.$emit('input', `${this.timeInputModel}:00`);
    }
  }

  created() {
    this.timeInputModel =
      this.value && this.value.length > 8
        ? moment(this.value, DATE_API_FORMAT).format('HH:mm')
        : this.value;
  }
}

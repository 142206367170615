import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

import Breadcrumbs from './Breadcrumbs.vue';
import Vue from 'vue';

import Multiselect from 'vue-multiselect';
import ArTable from './form/ArTable.vue';
import AutoSuggest from './form/AutoSuggest.vue';
import DateTimePicker from './form/DateTimePicker.vue';
import DateYearPicker from './form/DateYearPicker.vue';
import DateMonthPicker from './form/DateMonthPicker.vue';
import DatePicker from './form/DatePicker.vue';
import TimePicker from './form/TimePicker.vue';
import FileUpload from './form/FileUpload.vue';
import Header from './header/Header.vue';
import WorkOrderList from './wo/WorkOrderList/WorkOrderList.vue';
import VueTypeaheadBootstrap from './typeahead/VueTypeaheadBootstrap.vue';
import VueSimpleContextMenu from 'vue-simple-context-menu';
import TraceReplay from './traceReplay/TraceReplay.vue';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

// @ts-ignore
Vue.component('vueSimpleContextMenu', VueSimpleContextMenu);

Vue.component('WorkOrderList', WorkOrderList);
Vue.component('TmsHeader', Header);
Vue.component('TmsFileUpload', FileUpload);
Vue.component('TmsArTable', ArTable);
Vue.component('TmsAutoSuggest', AutoSuggest);
Vue.component('TmsDateTimePicker', DateTimePicker);
Vue.component('TmsDatePicker', DatePicker);
Vue.component('TmsBreadcrumbs', Breadcrumbs);
Vue.component('TmsDateYearPicker', DateYearPicker);
Vue.component('TmsDateMonthPicker', DateMonthPicker);
Vue.component('Multiselect', Multiselect);
Vue.component('TimePicker', TimePicker);
Vue.component('TraceReplay', TraceReplay);
Vue.component('VueTypeaheadBootstrap', VueTypeaheadBootstrap);






































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import { GoogleMapLoader } from '../../../utils/gmap';
import { GPSTrackingService } from '../../services/wo/gpsTracking.service';
import { TraceMock } from './mock/TraceMock';
import { mapColors } from './@model/mapColors';
import { TraceReplayStatus } from './@model/TraceReplay.model';

import TraceReplaySpeed from './TraceReplaySpeed.vue';
import TraceReplayDateFilter from './TraceReplayDateFilter.vue';

import Loading from '../Loading.vue';
import {
  convertAPIToFormat,
  DATE_VIEW_FULL_FORMAT_WITHTIME
} from '@/utils/date.util';
import { ToastHelper } from '@/utils/toast.util';

import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';

import moment from 'moment-timezone';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';

@Component({
  components: {
    Loading,
    TraceReplaySpeed,
    TraceReplayDateFilter
  }
})
export default class TraceReplay extends Vue {
  @Prop() jobId: string;
  @Prop() orderNumber: string;
  @Prop() driverId: string;
  @Prop() carrierId: string;
  @Prop() withTimepicker: boolean;

  range = {
    from: null,
    to: null
  };

  showTimepicker = false;

  data = TraceMock;
  // traceData = TraceMock.traceData;

  loading = false;

  google = null;
  map = null;
  line;
  carMarker;
  carImages = [];
  infoWindow;
  alarmMarkerUrl = '/img/marker.png';

  dataPoints = [];
  dataPointsList = [];

  mapColors = mapColors;

  routeDetailMarkers = [];
  trips = [];
  activeTrip = null;
  activeTripIndex = 0;

  tripStartMarker = null;
  tripEndMarker = null;

  startLocation = null;
  endLocation = null;
  timeSpan = '';
  step = 0;
  stepInterval = 1;
  speedInterval = 1;
  steps = [1, 2, 4, 8, 16];

  noData = false;

  mapOptions;

  TraceReplayStatus = TraceReplayStatus;

  status: TraceReplayStatus = TraceReplayStatus.Paused;
  speed = 0;

  carAnimation;

  sliderValue = 0;

  //   TRACE_DATA = jsonObj.traceData;
  //   ALARM_DATA = jsonObj.alarmData;
  //   startTimeText = jsonObj.startTimeText;
  //   endTimeText = jsonObj.endTimeText;
  //   startLocation = jsonObj.startLocation;
  //   endLocation = jsonObj.endLocation;

  currentPage = this.$route.query.page || 1;

  constructor() {
    super();
  }

  mounted() {
    if (!this.withTimepicker) {
      this.loadData();
    } else {
      this.showTimepicker = true;
    }
  }

  async loadData() {
    this.loading = true;
    this.showTimepicker = false;
    const { gpsDataPoints } = await GPSTrackingService.getGpsData(
      this.withTimepicker
        ? {
            driverId: this.driverId,
            carrierId: this.carrierId,
            startTime: this.range.from,
            endTime: this.range.to
          }
        : {
            jobId: this.jobId,
            orderNumber: this.orderNumber
          }
    );

    if (!gpsDataPoints || !gpsDataPoints.length) {
      this.loading = false;
      this.noData = true;
      return;
    }

    this.trips = gpsDataPoints
      .sort(function (a, b) {
        return b.startTime - a.startTime;
      })
      .map(trip => {
        return {
          ...trip,
          endTime: convertAPIToFormat(
            trip.endTime,
            DATE_VIEW_FULL_FORMAT_WITHTIME
          ),
          startTime: convertAPIToFormat(
            trip.startTime,
            DATE_VIEW_FULL_FORMAT_WITHTIME
          )
        };
      });

    // gpsDataPoints.forEach(({ gpsData }) => {
    //   this.dataPoints.push(gpsData);
    //   this.dataPointsList.push(...gpsData);
    // });

    // TODO: Fix this when API will be ready
    // this.setStartEndLocations(this.data);

    this.google = await GoogleMapLoader.load();
    this.initializeMap();
    this.initCarIcons();
    this.setTrip(this.trips[0], 0);

    this.loading = false;
    this.$forceUpdate();
  }

  async setTrip(trip, index) {
    this.reset(false);

    if (!trip?.startLocation && !trip?.endLocation) {
      const { startLocation, endLocation } = await this.getStartEndLocations(
        trip
      );
      trip.startLocation = startLocation;
      trip.endLocation = endLocation;
    }

    this.activeTripIndex = index;
    this.activeTrip = trip;

    this.drowTripOnMap();
  }

  initializeMap() {
    const mapContainer = this.$refs.googleMap;

    const mapOptions = {
      zoom: 6,
      minZoom: 10,
      draggable: true,
      panControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_CENTER
      },
      mapTypeControl: true,
      scaleControl: false,
      overviewMapControl: false,
      scrollwheel: true,
      rotateControl: false,
      streetViewControl: false,
      mapTypeId: this.google.maps.MapTypeId.ROADMAP
    };

    this.map = new this.google.maps.Map(mapContainer, mapOptions);
  }

  drowTripOnMap() {
    if (!this.activeTrip?.gpsData) {
      this.loading = false;
      return;
    }

    this.cleanMap();

    const lineCoordinates = [];

    this.activeTrip.gpsData.forEach(i => {
      lineCoordinates.push(new this.google.maps.LatLng(i.lat, i.lng));
    });

    // this.tripStartMarker = new this.google.maps.Marker({
    //   position: lineCoordinates[0],
    //   map: this.map
    // });

    // this.tripEndMarker = new this.google.maps.Marker({
    //   position: lineCoordinates[this.activeTrip.gpsData.length - 1],
    //   map: this.map
    // });

    this.map.setCenter(lineCoordinates[0]);

    this.carMarker = new this.google.maps.Marker({
      position: lineCoordinates[0],
      map: this.map,
      icon: this.carImages[0]
    });

    this.line = new this.google.maps.Polyline({
      path: lineCoordinates,
      strokeColor: mapColors[this.activeTripIndex],
      icons: [
        {
          offset: '100%'
        }
      ],
      map: this.map
    });

    this.activeTrip?.routeDetails.forEach(i => {
      const routeDetailsMarker = new this.google.maps.Marker({
        position: new this.google.maps.LatLng(i.lat, i.lng),
        map: this.map,
        // icon: this.alarmMarkerUrl,
        label: {
          color: '#ea4335',
          text: i.type,
          className: 'map-label-text'
        }
      });

      this.routeDetailMarkers.push(routeDetailsMarker);

      //create infoWindow
      this.google.maps.event.addListener(routeDetailsMarker, 'hover', () => {
        var contentString =
          '<div id="content">' +
          '<div id="bodyContent">' +
          '<p><b>' +
          i.address;
        '</b></p>' + '</div></div>';

        if (!this.infoWindow) {
          this.infoWindow = new this.google.maps.InfoWindow({
            maxWidth: 350
          });
        }

        this.infoWindow.setContent(contentString);
        this.infoWindow.open(this.map, routeDetailsMarker);
      });
    });
  }

  play() {
    this.status = TraceReplayStatus.PlayInProgress;
    this.carTracing();
  }

  reset(dontResetPosition?: boolean) {
    clearInterval(this.carAnimation);
    // resetSpeedValue(0);
    // resetRpmValue(0);
    this.step = 0;
    this.speed = 0;
    this.status = TraceReplayStatus.Paused;

    if (!dontResetPosition && this.activeTrip?.gpsData.length > 0) {
      var dpoint = this.activeTrip.gpsData[0];
      var newPostion = new google.maps.LatLng(dpoint.lat, dpoint.lng);
      this.carMarker.setPosition(newPostion);
      this.map.panTo(newPostion);
      this.sliderValue = 0;
    } else if (dontResetPosition) {
      this.sliderValue = 100;
    }
  }

  pause() {
    clearTimeout(this.carAnimation);
    this.speed = 0;
    this.status = TraceReplayStatus.Paused;
  }

  resetSlider() {
    this.sliderValue = Math.round(
      (this.step / this.activeTrip.gpsData.length) * 100
    );
  }

  sliderChange(value: number) {
    if (this.step <= this.activeTrip.gpsData.length) {
      this.step = Math.round(value * 0.01 * this.activeTrip.gpsData.length);
    }
  }

  carTracing() {
    this.carAnimation = setInterval(() => {
      const dp = this.activeTrip.gpsData[this.step];
      this.speed = dp.speed;
      const newPostion = new this.google.maps.LatLng(dp.lat, dp.lng);
      this.carMarker.setPosition(newPostion);
      this.map.panTo(newPostion);

      this.timeSpan = convertAPIToFormat(
        dp.tmTime,
        DATE_VIEW_FULL_FORMAT_WITHTIME
      );

      // Speed data
      // if (dp.dataPoint || this.step % 25 == 0) {
      // resetSpeedValue(Number(dp.speed));
      // resetRpmValue(Number(dp.rpmDivd));
      // }

      this.resetCarDirection(dp.direction);
      this.resetSlider();

      this.step = this.step + this.steps[this.stepInterval - 1];
      if (this.step >= this.activeTrip.gpsData.length) {
        this.reset(true);
      }
    }, 30);
  }

  accelerate() {
    if (this.stepInterval < 5) {
      this.stepInterval = this.stepInterval + 1;
      this.speedInterval = this.speedInterval * 2;
    }
  }

  decelerate() {
    if (this.stepInterval > 1) {
      this.stepInterval = this.stepInterval - 1;
      this.speedInterval = this.speedInterval / 2;
    }
  }

  initCarIcons(type: 'car' | 'container' = 'car') {
    const car = [
      0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225,
      240, 255, 270, 285, 300, 315, 330, 345
    ];
    car.forEach(i => {
      var carMarker = {
        url:
          type === 'car'
            ? `/img/carDirec/car${i}.png`
            : `/img/assetDeviceDirec/container${i}.png`,
        anchor: new this.google.maps.Point(15, 15)
      };
      this.carImages.push(carMarker);
    });
  }

  resetCarDirection(direction) {
    if (!direction) {
      this.carMarker.setIcon(this.carImages[0]);
    }

    var index = Math.round(direction / 15);
    if (index == 24) {
      index = 0;
    }
    if (index >= 0 && index < 24) {
      this.carMarker.setIcon(this.carImages[index]);
    }
  }

  async getStartEndLocations(trip) {
    try {
      const startAddress = await this.getAddressFromLatLng(trip.gpsData[0]);
      const endAddress = await this.getAddressFromLatLng(
        trip.gpsData[trip.gpsData.length - 1]
      );

      return {
        startLocation: `Start: [${trip.startTime}] ${startAddress?.results[0]?.formatted_address}`,
        endLocation: `End: [${trip.endTime}] ${endAddress?.results[0]?.formatted_address}`
      };
    } catch (e) {
      console.log(e);
      ToastHelper.show('Error', 'Error while loading GPS data', 5000, 'danger');
      return {};
    }
  }

  async getAddressFromLatLng({ lat, lng }) {
    const location = new google.maps.LatLng(lat, lng);
    const geocoder = new google.maps.Geocoder();

    return geocoder.geocode({ location });
  }

  cleanMap() {
    this.line?.setMap(null);
    this.carMarker?.setMap(null);
    this.routeDetailMarkers.forEach(i => i.setMap(null));
  }
}

import { ConfigType } from './../../../pages/Admin/Configuration/model/Config.model';
import { BaseClass } from '../base/base.service';
import { ConfigModule } from '@/store';

export class AdminConfProvider extends BaseClass {
  containerType: any[];
  containerSize: any[];
  chassisPool: any[];
  shippingLine: any[];

  sortBy = 'active';
  sortDescDirection = true;

  constructor() {
    super('admin/');
    this.getSelectData();
  }

  async loadConfig() {
    const data = {
      containerType: [],
      containerSize: [],
      chassisPool: [],
      shippingLine: []
    };

    await Promise.all([
      this.getData(ConfigType.TYPE).then(i => {
        ConfigModule.getConfigType();
        return (data.containerType = this.convertAndSortValues(i));
      }),
      this.getData(ConfigType.SIZE).then(i => {
        ConfigModule.getConfigSize();
        return (data.containerSize = this.convertAndSortValues(i));
      }),
      this.getData(ConfigType.POOLS).then(
        i => (data.chassisPool = this.convertAndSortValues(i))
      ),
      this.getData(ConfigType.SSL).then(
        i => (data.shippingLine = this.convertAndSortValues(i))
      )
    ]);

    this.containerType = data.containerType;
    this.containerSize = data.containerSize;
    this.chassisPool = data.chassisPool;
    this.shippingLine = data.shippingLine;
  }

  async getSelectData() {
    return {
      containerType: this.containerType,
      containerSize: this.containerSize,
      chassisPool: this.chassisPool,
      shippingLine: this.shippingLine
    };
  }

  async getPools() {
    const res = await this.get('pools');
    return res.data.data;
  }

  async getData(chunk) {
    const res = await this.get(chunk);
    return res.data.data;
  }

  async getContainers() {
    const res = await this.get('container-sizes');
    return res.data.data;
  }

  async createItem(type: string, data: object) {
    const res = await this.post(`${type}`, data);
    return res.data;
  }

  async saveItem(type: string, id: string, data: object) {
    const res = await this.put(`${type}/${id}`, data);
    return res.data;
  }

  async updateActive(type: string, id: string, params: object) {
    const res = await this.patch(`${type}/${id}`, null, { params });
    return res.data;
  }

  async updateTimeZone(type: string, id: string, params: object) {
    const res = await this.patch(`${type}/${id}`, null, { params });
    return res.data;
  }

  async addItem(type: string, category: string, data: object) {
    const res = await this.post(`${type}/${category}`, data);
    return res.data;
  }

  private convertAndSortValues(data) {
    return this.sortAfabeticalyByKey(
      this.convertDataToSelectValues(
        data.filter(({ active }) => active),
        'id',
        'name'
      ),
      'text'
    );
  }
}

export const AdminConfService = new AdminConfProvider();

export const en = {
  action: {},
  searchBtn: 'Search',
  excelBtn: 'excel',
  emailBtn: 'email',
  cancelBtn: 'Cancel',
  requiredFieldMessage: 'Required fields are missing',
  navigation: {
    order: {
      title: 'order'
    },
    dispatch: {
      title: 'dispatch',
      book: 'booking',
      unavailable: 'una',
      available: 'ava',
      delivery: 'dlv',
      return: 'rtn'
    },
    main: {
      main: 'MAIN',
      import: 'IMPORT',
      export: 'EXPORT',
      van: 'VAN',
      cytocy: 'CY to CY',
      bareChassis: 'BARE CHASSIS'
    },
    reports: {
      report: 'Report',
      pullout: 'PULLOUT',
      delivery: 'DELIVERY',
      return: 'RETURN',
      demurrage: 'DEMURRAGE',
      perdiem: 'PERDIEM',
      statistics: 'STATISTICS',
      'early-warning': 'EARLY WARNING LIST'
    },
    driver: {
      title: 'DRIVER',
      pod: 'POD LIST',
      bill: 'BILL CONFIRM',
      manifest: 'MANIFEST'
    },
    map: {
      title: 'map',
      map: 'map',
      geofence: 'geofence'
    },
    master: {
      title: 'master',
      driver: 'driver',
      company: 'company',
      account: 'account code'
    },
    user: {
      user: 'User',
      profile: 'Profile',
      createUser: 'Create User',
      signOut: 'Sign Out'
    },
    import: {
      import: 'Import',
      order: 'ORDER',
      main: 'Main',
      dispatch: 'Dispatch'
    },
    export: {
      booking: 'Booking',
      dataInput: 'Data input',
      main: 'Main',
      dispatch: 'Dispatch',
      export: 'Export'
    },
    admin: {
      title: 'admin',
      basic: 'Basic',
      adminAuth: 'Admin Auth',
      conf: 'Conf',
      early: 'Early warning',
      earlyModal: 'Early warning modal',
      userManagment: 'User management',
      accountManagement: 'Account Management'
    },
    language: 'English'
  },
  order: {
    billTo: 'Bill To',
    mbl: 'MBL #',
    houseBL: 'House BL #',
    referenceNumber: 'REF #',
    poNumber: 'PO #',
    deliveryNumber: 'Delivery #',
    cont: 'Cont #',
    trailNumber: 'Trailer #',
    containerSize: 'Size',
    containerType: 'Type',
    vessel: 'VESSEL',
    chassisNumber: 'Chassis #',
    pulloutChassisNumber: 'P/OUT Chassis #',
    chassisPool: 'POOL',
    vesselEta: 'ETA',
    ava: 'AVA',
    dsc: 'DSC',
    obl: 'OBL',
    cus: 'CUS',
    oth: 'OTH',
    lfd: 'LFD',
    freeDays: 'Free Days',
    rfd: 'RFD',
    seal: 'SEAL',
    commodity: 'COMMODITY',
    weight: 'WGT',
    quantity: 'QTY',
    unit: 'Unit',
    sendTo: 'Send To',
    remarks: 'Remark',
    pullOut: 'PULLOUT',
    puSchedule: 'P/OUT Schedule',
    delivery: 'Delivery',
    deliverySchedule: 'DILV Schedule',
    return: 'Return',
    returnSchedule: 'RTN Schedule',
    oh: 'O/H',
    ow: 'O/W',
    haz: 'HAZ',
    hot: 'HOT',
    qty: 'Quantity',
    amt: 'Amount',
    rate: 'Rate',
    arName: 'A/R Name',
    arCode: 'A/R Code',
    ssl: 'SSL',
    dropLive: 'DROP/LIVE',
    bondEntry: 'Bond Entry #',
    do: 'DO',
    cf: 'CF',
    other: 'Other',
    categories: 'Categories',
    bookingNumber: 'Booking #',
    wms: 'WMS',
    erd: 'ERD',
    cutOff: 'CUT OFF',
    bk: 'BK#',
    avaDsc: 'AVAILABILITY DSC',
    avaDate: 'AVAILABILITY DATE',
    tare: 'TARE(lb)',
    st: 'S/T',
    mblBk: 'MBL / BK #',
    loadCFM: 'Load CFM',
    emptyCFM: 'Empty CFM'
  },
  common: {
    email: 'Email',
    emailAddress: 'Email address',
    close: 'Close',
    back: 'Back',
    date: 'Date',
    time: 'Time',
    document: 'Document',
    type: 'Type',
    updatedBy: 'Updated by',
    update: 'Update',
    ck: 'CK',
    actions: 'Actions',
    browse: 'Browse',
    upload: 'Upload',
    delete: 'Delete',
    on: 'On',
    off: 'Off',
    driver: 'Driver',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    from: 'From',
    To: 'To',
    next: 'Next',
    save: 'Save',
    saveReset: 'Save & Reset',
    saveCopy: 'Save & Copy',
    warning: 'Warning',
    link: 'Link',
    confirm: 'Confirm',
    send: 'Send',
    submit: 'Submit',
    reset: 'Reset',
    search: 'Search',
    searchBy: 'Search by',
    excel: 'Excel',
    assign: 'Assign',
    add: 'Add',
    remove: 'Remove',
    sync: 'Sync',
    approve: 'Approve',
    complete: 'Complete',
    incomplete: 'Incomplete',
    show: 'Show',
    requiredFieldMessage: 'Required fields are missing',
    selectToday: 'Select Today',
    role: 'Role',
    divisions: 'Divisions'
  },
  validations: {
    required: 'This is a required field.',
    max: 'Maximum count of characters {number}',
    maxIntegers: 'Maximum count of characters {number} and only integers',
    maxWithDecimal:
      'Maximum count of characters {number} and only 2 digits after the dot',
    reqMax: 'This is a required field and maximum count of characters {number}',
    lettersAndDigitsReq:
      'This field is a required and must have {letters} letters and {digits} digits',
    lettersAndDigitsOpt:
      'Field must have {letters} letters and {digits} digits',
    isValidPoandDlv: 'P/OUT Schedule and DILV Schedule fields are required.',
    invalidPO: 'P/OUT Schedule field are required.',
    invalidDLV: 'DILV Schedule field are required.',
    invalidReturn:
      'The RTN Schedule date and time must be later than P/OUT Schedule',
    laterThenMsg: 'The {current} date and time must be later than {related}',
    bcScheduleInvalidLocation:
      'The LOCA ID must be same to {locationField} field in row {row}',
    bcScheduleInvalidDelivery:
      'The LOCA ID must be same to {locationField} field in schedule',
    bcScheduleRequiredInRow: 'are required in row',
    bcScheduleFields: 'The fields',
    notEmptyFile: 'The file cannot be empty',
    rowsExpected: 'At least 2 rows in schedule are expected.',
    phoneFormat: 'Phone format: (###) ###-####',
    invalidEmail: 'Invalid email',
    invalidEmailMax: 'Enter valid emails separated by comma, a max length is {number}',
    invalidEmailReqMax: 'This is a required field and should be valid email and also maximum count of characters {number}',
    shouldNotBeEarlier: '{current} should not be earlier than {related}',
    conf: {
      nameDuplicated:
        'This {name} is duplicated. Please insert the different POOL for this one.',
      abbrDuplicated:
        'This {abbr} is duplicated. Please insert the different ABBR for this one.'
    },
    requiredNotSpecialChars:
      'This field is a required and must not have special characters',
    requiredPasswordMinChars: 'This is a required field and minimum count of characters {number}',
    requiredMismatch: 'This is a required field and should match with password field'
  },
  contextMenu: {
    detailInfo: 'Detail info',
    billingInfo: 'Billing info',
    document: 'Document',
    tracking: 'Tracking',
    basicInfo: 'Basic info',
    location: 'Location',
    availability: 'Availability',
    schedule: 'Schedule',
    memo: 'Memo'
  },
  mainTable: {
    headers: {
      equipment: 'Equipment',
      info: 'Info',
      location: 'Location',
      geofence: 'Geofence',
      schedule: 'Schedule',
      pOutD: 'P/OUT Dispatching',
      dilvD: 'DILV Dispatching',
      puD: 'P/U Dispatching',
      rtnD: 'RTN Dispatching',
      ar: 'AR',
      ap: 'AP',
      doc: 'Document'
    },
    wo: 'WO #',
    no: 'NO',
    sz: 'SZ',
    bond: 'BOND',
    ref: 'REF',
    po: 'PO',
    mbl: 'M B/L',
    pOut: 'P/OUT',
    dilv: 'DILV',
    rtn: 'RTN',
    orig: 'Orig',
    curr: 'Curr',
    dest: 'Dest',
    pOutSDate: 'P/OUT S Date',
    time: 'Time',
    driverId: 'DRV ID',
    dilvSDate: 'DILV S Date',
    dropLive: 'LIVE/DRP',
    rfdDay: 'RFD DAY',
    lfdDay: 'LFD DAY',
    pUSDate: 'P/U S Date',
    rtnSDate: 'RTN S Date',
    booking: 'Booking',
    co: 'C/O',
    drop: 'Drop',
    avd: 'Avd',
    scheIn: 'S IN',
    actualIn: 'A IN',
    actualOut: 'A OUT',
    drv: 'DRV',
    ar: 'A/R',
    trucking: 'Trucking',
    fscR: 'FSC/R',
    chR: 'CH/R',
    peR: 'PE/R',
    deR: 'DE/R',
    drR: 'DR/R',
    ap: 'A/P',
    drvP: 'DRV/P',
    chP: 'CH/P',
    peP: 'PE/P',
    deP: 'DE/P',
    drP: 'DR/P',
    prft: 'Prft',
    inv: 'INV',
    pod: 'POD',
    bl: 'B/L',
    do: 'DO',
    it: 'IT',
    tmf: 'TMF',
    pOutLoc: 'P/Out LOC',
    pOutSche: 'P/Out SCHE',
    pOutInActual: 'P/Out IN Actual',
    state: 'STATE',
    dilvSche: 'DILV SCHE',
    dilvLoc: 'DILV LOC',
    dilvOutActual: 'DILV OUT Actual',
    drvDilv: 'DRV(DELIVERY)',
    drvCurr: 'DRV(Current)',
    rtnLoc: 'RTN LOC',
    rtnSche: 'RTN SCHE',
    ngl: 'NGL',
    cus: 'CUS',
    reason: 'REASON',
    level: 'LEVEL',
    category: 'CATEGORY',
    warningIssue: 'WARNING ISSUE',
    updDate: 'UPDATED DATE',
    user: 'USER',
    status: 'Status',
    notice: 'Notice',
    origin: 'Origin',
    destination: 'Destination',
    acctCode: 'ACCT Code',
    acctCodeName: 'ACCT Code Name',
    desc: 'Desc',
    reject: 'Reject',
    approval: 'Approval',
    company: 'Company',
    owner: 'Owner',
    carrier: 'Carrier',
    active: 'Active',
    inactive: 'Inactive',
    loads: 'Loads',
    totalBillAmount: 'Total Bill Amount',
    tripReplay: 'Trip Replay',
    position: 'Position',
    tabletId: 'Tablet ID',
    name: 'Name',
    cell: 'CELL #',
    dl: 'DL#',
    dlLicense: 'DL EXP',
    twicExpiration: 'TWIC EXP',
    medicalExpiration: 'Medical EXP',
    drugTest: 'Drug Test',
    mc: 'MC #',
    dot: 'DOT #',
    hire: 'Hire',
    terminate: 'Terminate',
    zip: 'Zip',
    action: 'Action',
    subdrivers: 'Subdriver(s)',
    title: 'Title',
    country: 'Country',
    contactName: 'Contact Name',
    fax: 'Fax',
    trackingId: 'Tracking ID',
    office: 'Office',
    dispatchBooking: {
      contSize: '{container} CONT SIZE',
      contType: '{container} CONT TYPE',
      contTotal: '{container} CONT TOTAL',
      contUsed: '{container} CONT USED',
      contBalance: '{container} CONT BALANCE',
      cntr: '{container} CNTR'
    }
  },
  mainFilters: {
    createdDate: 'Created Date',
    pullOut: 'P/OUT',
    delivery: 'Delivery',
    return: 'Return',
    pickUp: 'P/U',
    containerNumber: 'Container#',
    containerHash: 'CONT#',
    wo: 'WO#',
    ref: 'REF#',
    po: 'PO#',
    mbl: 'MBL#',
    billTo: 'Bill To',
    driverId: 'DRV ID',
    trailer: 'Trailer#',
    bk: 'BK#'
  },
  userCreate: {
    enter: 'Enter',
    fullNamePlaceholder: 'Enter your full name'
  },
  scheduleTable: {
    tripReplay: 'Trip replay',
    locationId: 'LOCA ID',
    locationInfo: 'LOCATION INFO',
    city: 'City',
    state: 'ST',
    in: 'IN',
    out: 'OUT',
    scheduleDate: 'SCHE DATE',
    time: 'Time',
    actualDate: 'ACTUAL DATE',
    memo: 'MEMO',
    driverId: 'DRV ID',
    status: 'STATUS',
    updatedBy: 'Updated by',
    notActualInDateButTime: 'ACTUAL IN DATE if TIME is named',
    notActualInTimeButDate: 'ACTUAL IN TIME if DATE is named',
    notActualOutDateButTime: 'ACTUAL OUT DATE if TIME is named',
    notActualOutTimeButDate: 'ACTUAL OUT TIME if DATE is named'
  },
  wo: {
    notDelivered: 'Not delivered yet',
    createSuccessfully: 'order successfully created.',
    savedSuccessfully: 'successfully saved.',
    memoSavedSuccessfully: 'memo successfully saved.',
    workOrder: 'Work Order',
    email: {
      puSchedule: {
        subject:
          '[{billTo}]Terminal Schedule, {containerNumber}, {pulloutLocation}, {pulloutSchedule}',
        body:
          'Dear {billTo}, <br/>{containerNumber} is scheduled to pick up from {pulloutLocation} at {pulloutSchedule} <br/> NGL will update the schedule as soon as possible <br/> Thanks,'
      },
      deliverySchedule: {
        subject:
          'DILV APPT, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
        body: `Dear {billTo}, <br/>{containerNumber} has been scheduled to deliver to {deliveryLocation} at {deliverySchedule} <br/><br/>NGL will update the schedule as soon as possible <br/><br/>Thanks,`
      },
      podPol: {
        subject:
          '{type}, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
        body:
          'Dear {billTo}, <br/>{containerNumber} has been delivered <br/>Please find the attachment!<br/>NGL will update the schedule as soon as possible <br/>Thanks,'
      },
      demurrage: {
        subject:
          'Warning-Dmrgs {containerNumber}, {pulloutLocation}, {lastFreeDay}',
        body: `Dear {billTo},<br/>To avoid Demurrage charge, {containerNumber} must be pulled out of a terminal by {lastFreeDay}.<br/>Please assist us with getting the hold cleared as soon as possible so that we can retrieve the container.<br/>If you have questions, please contact us to have them answered promptly, thank you!<br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber}<br/>Customer: {customer}<br/>Last Free Day: {lastFreeDay}<br/>`
      },
      waiting: {
        subject:
          'Warning-DrvWaitings {containerNumber}, {deliveryLocation}, {deliverySchedule}',
        body: `Dear {billTo}, <br/>Please be advised that the driver has been on site since {deliverySchedule} and waiting time will start soon.<br/>If you have questions, please contact us to have them answered promptly, thank you!<br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber}<br/>Customer: {customer}<br/>Appointment Time:  {deliverySchedule}`
      },
      perdiem: {
        subject:
          'Warning-PrDms {containerNumber}, {returnLocation}, {returnFreeDay}',
        body: `Dear {billTo}, <br/>To avoid Per Diem charge, {containerNumber} must be returned by {returnFreeDay}. <br/>Please assist us with returning the subject container as soon as possible. <br/>If you have questions, please contact us to have them answered promptly, thank you!<br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber}<br/>Customer: {customer}<br/>Delivery date: {deliverySchedule} <br/>Return Free Day: {returnFreeDay}`
      },
      trackingId: {
        subject: 'Tracking ID for CONT# {containerNumber},{deliveryLocation}',
        body: `
        Dear {billTo},<br/>CONT# {containerNumber} has been departed to {deliveryLocation} at {previousDeliverySchedule}<br/>Delivery To: {deliveryAddress}<br/>Container #:{containerNumber} <br/>PO # or REF #: {customer}  <br/>Delivery Schedule: {deliverySchedule} <br/><h1 style="text-align: center;"><a title="LIVE ETA" href="{trackingUrl}" target="_blank" rel="noopener">Track Container</a></h1>
        Thanks,`
      }
    },
    schedule: {
      dateMoreMonth:
        'Schedule date is more than one month than the previous schedule date',
      pullOutLaterLFD: 'P/OUT Schedule date is later than LFD',
      scheduleInLaterRFD: 'Schedule date is later than RFD'
    }
  },
  reports: {
    categories: 'category',
    div: 'div',
    search: 'search date',
    searchFrom: 'from',
    searchTo: 'to',
    billTo: 'bill to',
    searchYear: 'Year',
    searchMonth: 'Month'
  },
  datePicker: {
    '90DaysDiff': `You've set a 90-day difference from the current one. Are you sure?`,
    incorrectDate: 'Please enter correct date in MM/DD/YY format',
    maxDate: 'From date must be early than To date',
    minDate: 'To date must be later than From date'
  },
  userManagement: {
    deleteConfirmMessage: `Deleting a user will remove that user's access to system.\n
    Are you sure you want to delete this user?`,
    resetConfirmMessage: `Are you sure want to reset the password for {name} \n\n
    Login credentials will be sent to {email}`,
    userManagement: 'User Management',
    sendResetEmail: 'Send Reset Email',
    deleteUser: 'Delete User',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phone: 'Phone',
    firstPage: 'First Page',
    defaultDivision: 'Default Division',
    update: 'Update',
    close: 'Close',
    requiredFieldMessage: 'Required fields are missing',
    yes: 'Yes',
    status: 'Status',
    filterBy: 'Search By',
    userName: 'User Name',
    reset: 'Reset',
    create: 'Create'
  },
  accountManagement: {
    defaultLanguage: 'Default Language',
    systemMeasurement: 'System Measurement',
    country: 'Country',
    timeZone: 'Time Zone',
    businessType: 'Business Type',
    company: 'Company',
    firstName: 'First Name',
    lastName: 'Last Name',
    userId: 'User Id',
    phone: 'Phone',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    email: 'Email',
    supportEmail: 'Support Email',
    alertsEmail: 'Alert Emails',
    reportsEmail: 'Report Emails',
    address: 'Address',
    modality: 'Modality',
    accountType: 'Account Type',
    group: 'Group',
    emailContent: 'Email Content'
  },
  tracking: {
    GATE_IN: 'Gate In',
    GATE_OUT: 'Gate Out',
    READY_TO_START: 'Ready to start',
    READY_TO_PICK_UP: 'Ready to pick up',
    PICKED_UP: 'Picked up',
    READY_TO_DROP_OFF: 'Ready to drop off',
    LOADING_UNLOADING: 'Loading unloading',
    LOADED_UNLOADED: 'Loading unloaded',
    RETURN: 'Return',
    DROPPED_OFF: 'Dropped Off'
  },
  trackingStatus: {
    COMPLETED: 'Completed',
    IN_TRANSIT: 'In transit',
    ALL: 'All'
  },
  fileDownload: {
    upload: 'Upload file'
  },
  driverModal: {
    driverId: 'DriverId'
  },
  earlyWarningModal: {
    title: 'Early warning'
  },
  emailModal: {
    title: 'Send email',
    cc: 'CC',
    to: 'to',
    subject: 'Subject',
    required: 'Required fields are missing'
  },
  woModalSearch: {
    chassisNumber: 'Chassis#',
    containerNumbe: 'Container#',
    trailerNumber: 'Trailer#',
    containerNumber: 'Container#'
  },
  driver: {
    subdrivertitle: 'Sub Driver detail',
    nodriverfound: 'No Sub driver(s) found'
  }
};

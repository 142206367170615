import { BaseClass } from '../base/base.service';
import { FilterConditions } from './../filter/filter.service';
import {
  DATE_API_FORMAT,
  BS_DATEPICKER_FORMAT
} from '../../../utils/date.util';
import moment from 'moment-timezone';

export class MasterDataProvider extends BaseClass {
  constructor() {
    super('master-data');
  }

  async getGLCodes(filter?) {
    // limit: -1 means get ALL
    const res = await this.get('/gl-codes', {
      filter
    });

    res.data.data.filter(i => !i.dummy).map(i => ({ ...i, code: `${i.code}` }));

    return res.data;
  }

  async updateGLCode(id: string, data) {
    const res = await this.put(`/gl-codes/${id}`, data);

    return res.data;
  }

  async createGLCode(data) {
    const res = await this.post('/gl-codes', data);

    return res.data;
  }

  async getGLCodeById(id: string) {
    const res = await this.get(`/gl-codes/${id}`);

    return res.data;
  }

  async getDrivers(search?: string, field = 'firstName') {
    // limit: -1 means get ALL
    const res = await this.get('/drivers/identifiers', {
      filter: this.transformFiltersToJson([
        {
          search,
          field,
          condition: FilterConditions.BeginsWith
        }
      ]),
      limit: -1
    });
    return res.data.data;
  }

  async getEmployees(filter?) {
    // limit: -1 means get ALL
    const res = await this.get('/employees', {
      filter,
      limit: -1
    });
    return res.data;
  }

  async createEmployee(data) {
    const res = await this.post('/employees', data);
    return res.data;
  }

  async autocompleteEmployee() {
    // limit: -1 means get ALL
    const res = await this.get('/employees', {
      limit: -1
    });
    return res.data;
  }

  async getEmployeeById(id: string) {
    const res = await this.get(`/employees/${id}`);

    // const dateKeys = [
    //   'hireDate',
    //   'terminateDate',
    //   'legalExpirationDate',
    //   'driverLicenseExpirationDate',
    //   'birthday',
    //   'rehireDate'
    // ];

    // dateKeys.forEach(key => {
    //   if (res.data[key]) {
    //     res.data[key] = moment(res.data[key], DATE_API_FORMAT).format(
    //       BS_DATEPICKER_FORMAT
    //     );
    //   }
    // });
    // console.log(res.data.hireDate);
    return res.data;
  }

  async updateEmployee(id: string, data) {
    const res = await this.put(`/employees/${id}`, data);
    return res;
  }

  async getEmployeeFileLink(id: string, category: string) {
    const res = await this.get(`/employees/${id}/files/${category}`);
    return res.data;
  }

  async deleteEmployeeFileById(id: string, category: string) {
    const res = await this.delete(`/employees/${id}/files/${category}`);
  }

  async changeEmployeeActive(id: string, data) {
    const res = await this.patch(`/employees/${id}`, data);
    return res.data;
  }
}

export const MasterDataService = new MasterDataProvider();






































































































































































































































































































import moment from 'moment-timezone';
import { BIconPlusCircle, BIconDashCircle } from 'bootstrap-vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AccountReceivable } from '../../../../shared/components/wo/models/wo.model';
import { DATE_API_FORMAT } from '@/utils/date.util';
import { MasterDataService } from '../../../../shared/services/mater-data/master-data.service';
import { WorkOrderService } from '../../../../shared/services/wo/wo-import.service';
import { CompanyService } from '../../../../shared/services/mater-data/company.service';
import { ToastHelper } from '@/utils/toast.util';
import {
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { WoType } from '@/shared/components/wo/models';
import { accReceivablesValidations } from './models/bilingInfo-validation';
import { validationMixin } from 'vuelidate';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CurrencyFilter, formatCurrency } from '@/utils/currency';
import { ApArService } from '@/shared/services/wo/apar.service';
import { DivisionModule } from '@/store';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 5
});

Component.registerHooks(['validations']);

@Component({
  mixins: [validationMixin],
  components: {
    BIconPlusCircle,
    BIconDashCircle
  }
})
export default class AccountReceivables extends Vue {
  @Prop({ default: [] }) accountReceivables: AccountReceivable[];
  @Prop({ default: false }) editMode: boolean;
  @Prop() orderNumber: string;
  @Prop() successMsg: string;
  @Prop() canDeleteAPAR: boolean;
  @Prop() woLabel: string;
  @Prop() woType!: WoType;
  @Prop({ default: true }) showQuickBooks: boolean;

  masterDataService = MasterDataService;
  companyService = CompanyService;
  saveClicked = false;

  currencyMask = currencyMask;

  arData: AccountReceivable[] = [];

  total = 0;

  constructor() {
    super();
  }

  created() {
    this.arData = JSON.parse(
      JSON.stringify(this.accountReceivables)
    ).sort((a, b) => (a.qbId > b.qbId ? 1 : -1));

    this.arData.forEach(i => {
      i.rate = CurrencyFilter.format(i.rate as number);
    });
    this.calculateTotal();
    this.checkDisabledInoceNumbers();
  }

  add() {
    this.arData.push({
      date: moment().format(DATE_API_FORMAT),
      name: '',
      billToName: '',
      amount: 0,
      rate: 0,
      quantity: 1,
      remark: '',
      division: DivisionModule.division
    });
  }

  checkboxChange(item) {
    if (item.ck) {
      if (this.arData.filter(i => i.ck).length === 1) {
        this.arData.forEach(i => (i.ckDisabled = false));
      }

      this.$forceUpdate();
      return;
    }

    if (
      this.arData.filter(
        i => i.id !== item.id && i.billTo === item.billTo && !i.qbId
      ).length
    ) {
      this.arData.forEach(i => {
        if (i.id !== item.id) {
          if (i.billTo === item.billTo && !i.qbId) {
            i.ck = true;
          } else {
            i.ck = false;
            i.ckDisabled = true;
          }
        }
      });

      this.$forceUpdate();
      return;
    }

    if (item.invoiceNumber) {
      this.arData.forEach(i => {
        if (i.invoiceNumber === item.invoiceNumber) {
          i.ck = true;
        }
      });

      this.$forceUpdate();
      return;
    }

    this.arData.forEach(i => {
      if (i.id !== item.id && i.billTo !== item.billTo) {
        i.ck = false;
        i.ckDisabled = true;
      }
    });
    this.$forceUpdate();
  }

  async getGLCodes(query: string) {
    const filter = TransformFiltersToJson([
      {
        search: query,
        field: 'name',
        condition: FilterConditions.BeginsWith
      },
      {
        search: '40000',
        field: 'id',
        condition: FilterConditions.GreaterOrEqual
      },
      {
        search: '49999',
        field: 'id',
        condition: FilterConditions.LessThenOrEqual
      },
      {
        field: 'type1Name',
        search: 'Income',
        condition: FilterConditions.Equal
      }
    ]);
    const glCodes = await this.masterDataService.getGLCodes(filter);
    return glCodes.data;
  }

  validateState(item) {
    const { $dirty, $error } = item;
    return $dirty ? !$error : null;
  }

  validations() {
    return {
      arData: accReceivablesValidations
    };
  }

  isFormInValid() {
    this.$v.arData.$touch();

    return this.$v.arData.$anyError;
  }

  checkDisabledInoceNumbers() {
    this.arData.forEach(i => {
      i.invoiceNumberDisabled = this.arData.some(
        j =>
          j.invoiceNumber &&
          j.invoiceNumber === i.invoiceNumber &&
          j.qbId === i.qbId
      );
    });
  }

  async save() {
    if (this.isFormInValid()) {
      return;
    }

    const requestArData = this.arData.map(i => {
      const item = {
        ...i,
        rate: formatCurrency(i.rate)
      };

      delete item.ck;
      delete item.ckDisabled;
      delete item.invoiceNumberDisabled;

      return item;
    });

    WorkOrderService.updateAccountReceivables(
      this.woType,
      this.orderNumber,
      requestArData,
      this.woLabel,
      this.successMsg
    )
      .then(() => {
        ToastHelper.show(
          this.woLabel,
          `${this.woLabel} ${this.orderNumber} ${this.successMsg}`,
          8000,
          'success'
        );
        this.$emit('update');
        this.$forceUpdate();
      })
      .catch(e => {
        ToastHelper.show(this.woLabel, e.message, 8000, 'danger');
      });
  }

  async remove(index) {
    if (!this.arData[index].id) {
      this.arData.splice(index, 1);
      return;
    }

    const res = await ApArService.deleteAR(
      this.woType,
      this.orderNumber,
      this.arData[index].id
    );

    if (res) {
      return;
    }

    this.arData.splice(index, 1);
  }

  selectBillTo(ar, item) {
    ar.billTo = item ? item.id : '';
    ar.billToName = item ? item.name : '';
    this.$forceUpdate();
  }

  selectGLCode(ar, item) {
    ar.dummyCode = item ? item.dummy : '';
    ar.code = item ? item.id : '';
    ar.name = item ? item.name : '';
    this.$forceUpdate();
  }

  sendToQB() {
    const rowIds = [];
    this.arData.forEach(row => {
      if (row.ck) {
        rowIds.push(row.id);
      }
    });
    if (rowIds && rowIds.length) {
      WorkOrderService.sendQB(
        this.woType,
        this.orderNumber,
        'account-receivables',
        rowIds
      ).then(() => this.$emit('update'));
    } else {
      ToastHelper.show(
        'Warning',
        'Please select Account Payables rows by checkbox before send to QB',
        8000,
        'warning'
      );
    }
  }

  updateAmount(ar) {
    ar.amount = parseInt(ar.quantity) * formatCurrency(ar.rate);
  }

  isDisabledRemoveBtn(row) {
    if (
      !this.canDeleteAPAR ||
      !row.qbId ||
      row.receivedAmount == 0 ||
      row.receivedAmount === null
    ) {
      return false;
    }
    return true;
  }

  calculateTotal() {
    if (this.arData?.length) {
      return this.arData
        .map(ar => ar.amount)
        .reduce(
          (value, currentValue) =>
            parseFloat(value as any) + parseFloat(currentValue as any),
          0
        );
    } else {
      return 0;
    }
  }
}

export const es = {
  action: {},
  searchBtn: 'Búsqueda',
  excelBtn: 'excel',
  emailBtn: 'Email',
  cancelBtn: 'Cancelar',
  requiredFieldMessage: 'Faltan campos obligatorios',
  navigation: {
    order: {
      title: 'pedido'
    },
    dispatch: {
      title: 'despacho',
      book: 'reserva',
      unavailable: 'una',
      available: 'ava',
      delivery: 'dlv',
      return: 'rtn'
    },
    main: {
      main: 'PRINCIPAL',
      import: 'IMPORTAR',
      export: 'EXPORTAR',
      van: 'CAMIONETA',
      cytocy: 'CY a CY',
      bareChassis: 'CHASIS DESNUDO'
    },
    reports: {
      report: 'TMS Reporte',
      pullout: 'EXTRAER',
      delivery: 'ENTREGA',
      return: 'DEVOLVER',
      demurrage: 'DEMORA',
      perdiem: 'POR DÍA',
      statistics: 'ESTADÍSTICAS',
      'early-warning': 'LISTA DE ALERTA TEMPRANA'
    },
    driver: {
      title: 'CONDUCTOR',
      pod: 'LISTA DE POD',
      bill: 'CONFIRMAR FACTURA',
      manifest: 'MANIFIESTO'
    },
    map: {
      title: 'mapa',
      map: 'mapa',
      geofence: 'geocerca'
    },
    master: {
      title: 'maestro',
      driver: 'conductor',
      company: 'empresa',
      account: 'código de cuenta'
    },
    user: {
      user: 'Usuaria',
      profile: 'Perfil',
      createUser: 'Crear usuario',
      signOut: 'Cerrar sesión'
    },
    import: {
      import: 'Importar',
      dataInput: 'Entrada de datos',
      main: 'Principal',
      dispatch: 'Envío',
      order: 'PEDIDO'
    },
    export: {
      booking: 'Reserva',
      dataInput: 'Entrada de datos',
      main: 'Principal',
      dispatch: 'Envío',
      export: 'Exportar'
    },
    admin: {
      title: 'administración',
      basic: 'BasBásicoic',
      adminAuth: 'Autenticación de administrador',
      conf: 'Conf',
      early: 'Advertencia temprana',
      earlyModal: 'Modo de alerta temprana',
      userManagment: 'Gestión de usuarios',
      accountManagement: 'Administración de cuentas'
    },

    language: 'Español'
  },
  order: {
    billTo: 'Facturar a',
    mbl: 'MBL #',
    houseBL: 'Casa BL #',
    referenceNumber: 'REF #',
    poNumber: 'PO #',
    deliveryNumber: 'Delivery #',
    cont: 'Cont #',
    trailNumber: 'Trailer #',
    containerSize: 'Tamaño',
    containerType: 'Tipo',
    vessel: 'VESSEL',
    chassisNumber: 'Chassis #',
    chassisPool: 'PISCINA',
    vesselEta: 'ETA',
    ava: 'AVA',
    dsc: 'DSC',
    obl: 'OBL',
    cus: 'CUS',
    oth: 'OTH',
    lfd: 'LFD',
    freeDays: 'Días libres',
    rfd: 'RFD',
    seal: 'SELLO',
    commodity: 'COMMODITY',
    weight: 'WGT',
    quantity: 'QTY',
    unit: 'Unidad',
    sendTo: 'Enviar a',
    remarks: 'Observaciones',
    pullOut: 'PULLOUT',
    puSchedule: 'Programación P / OUT',
    delivery: 'Entrega *',
    deliverySchedule: 'Horario DILV',
    return: 'Regresar',
    returnSchedule: 'Programación RTN',
    oh: 'O / H',
    ow: 'O / W',
    haz: 'HAZ',
    hot: 'CALIENTE',
    qty: 'Cantidad',
    amt: 'Cantidad',
    rate: 'Tasa',
    arName: 'Nombre de A / R',
    arCode: 'Código A / R',
    ssl: 'SSL *',
    dropLive: 'DROP / LIVE',
    bondEntry: 'Bond Entry #',
    hacer: 'HACER *',
    cf: 'CF',
    other: 'Otro',
    categories: 'Categorías',
    bookingNumber: 'Booking #',
    wms: 'WMS',
    erd: 'ERD',
    cutOff: 'CUT OFF',
    bk: 'BK #',
    avaDsc: 'DISPONIBILIDAD DSC',
    avaDate: 'FECHA DE DISPONIBILIDAD',
    tare: 'TARA (lb)',
    st: 'S / T',
    mblBk: 'MBL / BK #',
    loadCFM: 'Load CFM',
    emptyCFM: 'Empty CFM'
  },
  common: {
    email: 'Dirección',
    emailAddress: 'Dirección de correo electrónico',
    close: 'Cerca',
    back: 'Atrás',
    date: 'Fecha',
    time: 'Tiempo',
    document: 'Documento',
    type: 'Tipo',
    updatedBy: 'Actualizado por',
    update: 'Actualizar',
    ck: 'CK',
    actions: 'Acciones',
    browse: 'Navegar',
    upload: 'Subir',
    delete: 'Eliminar',
    on: 'Encendido',
    off: 'Apagado',
    driver: 'Conductor',
    cancel: 'Cancelar',
    yes: 'Sí',
    no: 'No',
    from: 'De',
    To: 'Para',
    next: 'Next',
    save: 'Guardar',
    saveReset: 'Guardar y restablecer',
    saveCopy: 'Guardar y copiar',
    warning: 'Advertencia',
    link: 'Enlace',
    confirm: 'Confirmar',
    send: 'Enviar',
    submit: 'Enviar',
    reset: 'Reiniciar',
    search: 'Búsqueda',
    searchBy: 'Búsqueda por',
    excel: 'Excel',
    assign: 'Asignar',
    add: 'Agregar',
    remove: 'Remover',
    sync: 'Sincronizar',
    approve: 'Aprobar',
    complete: 'Completo',
    incomplete: 'Incompleto',
    show: 'Show',
    requiredFieldMessage: 'Faltan campos obligatorios',
    selectToday: 'Seleccionar hoy',
    role: 'El role',
    divisions: 'Divisiones'
  },
  validations: {
    required: 'Este es un campo obligatorio.',
    max: 'Cantidad máxima de caracteres {number}',
    maxIntegers: 'Cantidad máxima de caracteres {number} y solo enteros',
    maxWithDecimal:
      'Cantidad máxima de caracteres {number} y solo 2 dígitos después del punto',
    reqMax:
      'Este es un campo obligatorio y cuenta máxima de caracteres {number}',
    lettersAndDigitsReq:
      'Este campo es obligatorio y debe tener {letters} letras y {digits} dígitos',
    lettersAndDigitsOpt:
      'El campo debe tener {letters} letras y {digits} dígitos',
    isValidPoandDlv: 'Los campos P/O Schedule y DLV Schedule son obligatorios.',
    invalidPO: 'El campo P/O Schedule es obligatorio.',
    invalidDLV: 'El campo de programación de DLV es obligatorio.',
    invalidReturn:
      'The RTN Schedule date and time must be later than P/OUT Schedule',
    laterThenMsg: 'The {current} date and time must be later than {related}',
    bcScheduleInvalidLocation:
      'The LOCA ID must be same to {locationField} field in row {row}',
    bcScheduleInvalidDelivery:
      'The LOCA ID must be same to {locationField} field in schedule',
    bcScheduleRequiredInRow: 'are required in row',
    bcScheduleFields: 'The fields',
    notEmptyFile: 'The file cannot be empty',
    rowsExpected: 'At least 2 rows in schedule are expected.',
    phoneFormat: 'Phone format: (###) ###-####',
    invalidEmail: 'Email inválido',
    invalidEmailMax: 'Ingrese correos electrónicos válidos separados por comas, una longitud máxima es {number}',
    invalidEmailReqMax: 'Este es un campo obligatorio y debe ser un correo electrónico válido y también un número máximo de caracteres {number}',
    shouldNotBeEarlier: '{current} should not be earlier than {related}',
    conf: {
      nameDuplicated:
        'Este {name} está duplicado. Por favor inserte el POOL diferente para este.',
      abbrDuplicated:
        'Este {abbr} está duplicado. Por favor inserte el ABBR diferente para este.'
    },
    requiredNotSpecialChars:
      'Este campo es obligatorio y no debe tener caracteres especiales.',
    requiredPasswordMinChars: 'Este es un campo obligatorio y cuenta mínima de caracteres {number}',
    requiredMismatch: 'Este es un campo obligatorio y debe coincidir con el campo de contraseña'
  },
  contextMenu: {
    detailInfo: 'Información detallada',
    billingInfo: 'Información de facturación',
    document: 'Documento',
    tracking: 'Seguimiento',
    basicInfo: 'Información básica',
    location: 'Localización',
    availability: 'Disponibilidad',
    schedule: 'Calendario',
    memo: 'Memorándum'
  },
  mainTable: {
    headers: {
      equipment: 'Equipment',
      info: 'Info',
      location: 'Location',
      geofence: 'Geofence',
      schedule: 'Schedule',
      pOutD: 'P/OUT Dispatching',
      dilvD: 'DILV Dispatching',
      puD: 'P/U Dispatching',
      rtnD: 'RTN Dispatching',
      ar: 'AR',
      ap: 'AP',
      doc: 'Document'
    },
    wo: 'WO #',
    no: 'NO',
    sz: 'SZ',
    bond: 'BOND',
    ref: 'REF',
    po: 'PO',
    mbl: 'M B/L',
    pOut: 'P/OUT',
    dilv: 'DILV',
    rtn: 'RTN',
    orig: 'Orig',
    curr: 'Curr',
    dest: 'Dest',
    pOutSDate: 'P/OUT S Date',
    time: 'Time',
    driverId: 'DRV ID',
    dilvSDate: 'DILV S Date',
    dropLive: 'LIVE/DRP',
    rfdDay: 'RFD DAY',
    lfdDay: 'LFD DAY',
    pUSDate: 'P/U S Date',
    rtnSDate: 'RTN S Date',
    booking: 'Booking',
    co: 'C/O',
    drop: 'Drop',
    avd: 'Avd',
    scheIn: 'S IN',
    actualIn: 'A IN',
    actualOut: 'A OUT',
    drv: 'DRV',
    ar: 'A/R',
    trucking: 'Trucking',
    fscR: 'FSC/R',
    chR: 'CH/R',
    peR: 'PE/R',
    deR: 'DE/R',
    drR: 'DR/R',
    ap: 'A/P',
    drvP: 'DRV/P',
    chP: 'CH/P',
    peP: 'PE/P',
    deP: 'DE/P',
    drP: 'DR/P',
    prft: 'Prft',
    inv: 'INV',
    pod: 'POD',
    bl: 'B/L',
    do: 'DO',
    it: 'IT',
    tmf: 'TMF',
    pOutLoc: 'P/Out LOC',
    pOutSche: 'P/Out SCHE',
    pOutInActual: 'P/Out IN Actual',
    state: 'STATE',
    dilvSche: 'DILV SCHE',
    dilvLoc: 'DILV LOC',
    dilvOutActual: 'DILV OUT Actual',
    drvDilv: 'DRV(DELIVERY)',
    drvCurr: 'DRV(Current)',
    rtnLoc: 'RTN LOC',
    rtnSche: 'RTN SCHE',
    ngl: 'NGL',
    cus: 'CUS',
    reason: 'REASON',
    level: 'LEVEL',
    category: 'CATEGORY',
    warningIssue: 'WARNING ISSUE',
    updDate: 'UPDATED DATE',
    user: 'USER',
    status: 'Status',
    notice: 'Notice',
    origin: 'Origin',
    destination: 'Destination',
    acctCode: 'ACCT Code',
    acctCodeName: 'ACCT Code Name',
    desc: 'Desc',
    reject: 'Reject',
    approval: 'Approval',
    company: 'Company',
    owner: 'Owner',
    carrier: 'Carrier',
    active: 'Active',
    inactive: 'Inactive',
    loads: 'Loads',
    totalBillAmount: 'Total Bill Amount',
    position: 'Position',
    tabletId: 'Tablet ID',
    name: 'Name',
    cell: 'CELL #',
    dl: 'DL#',
    dlLicense: 'DL EXP',
    twicExpiration: 'TWIC EXP',
    medicalExpiration: 'Medical EXP',
    drugTest: 'Drug Test',
    mc: 'MC #',
    dot: 'DOT #',
    hire: 'Hire',
    terminate: 'Terminate',
    zip: 'Zip',
    action: 'Action',
    subdrivers: 'Subdriver(s)',
    title: 'Title',
    country: 'Country',
    contactName: 'Contact Name',
    fax: 'Fax',
    trackingId: 'Tracking ID',
    office: 'Office',
    dispatchBooking: {
      contSize: '{container} CONT SIZE',
      contType: '{container} CONT TYPE',
      contTotal: '{container} CONT TOTAL',
      contUsed: '{container} CONT USED',
      contBalance: '{container} CONT BALANCE',
      cntr: '{container} CNTR'
    }
  },
  mainFilters: {
    createdDate: 'Created Date',
    pullOut: 'P/OUT',
    delivery: 'Delivery',
    return: 'Return',
    pickUp: 'P/U',
    containerNumber: 'Container#',
    containerHash: 'CONT#',
    wo: 'WO#',
    ref: 'REF#',
    po: 'PO#',
    mbl: 'MBL#',
    billTo: 'Bill To',
    driverId: 'DRV ID',
    trailer: 'Trailer#',
    bk: 'BK#'
  },
  userCreate: {
    enter: 'Enter',
    fullNamePlaceholder: 'Enter your full name'
  },
  scheduleTable: {
    locationId: 'ID LOCA',
    locationInfo: 'INFORMACIÓN DE UBICACIÓN',
    city: 'Ciudad',
    state: 'ST',
    in: 'EN',
    out: 'FUERA',
    scheduleDate: 'SCHE DATE',
    time: 'tiempo',
    actualDate: 'FECHA ACTUAL',
    memo: 'MEMO',
    driverId: 'ID de DRV',
    estado: 'ESTADO',
    updatedBy: 'Actualizado por',
    notActualInDateButTime: 'ACTUAL IN DATE si se nombra TIME',
    notActualInTimeButDate: 'ACTUAL IN TIME si se nombra FECHA',
    notActualOutDateButTime: 'FECHA DE SALIDA REAL si se nombra HORA',
    notActualOutTimeButDate: 'HORA DE SALIDA REAL si se nombra FECHA'
  },
  wo: {
    notDelivered: 'No entregado todavía',
    createSuccessfully: 'pedido creado correctamente.',
    SavedSuccessfully: 'guardado correctamente.',
    memoSavedSuccessfully: 'memo guardado correctamente.',
    workOrder: 'Orden de trabajo',
    email: {
      puSchedule: {
        subject:
          '[{billTo}]Horario de la terminal, {containerNumber}, {pulloutLocation}, {pulloutSchedule}',
        body:
          'Estimado {billTo}, <br/> {containerNumber} está programado para recoger de {pulloutLocation} en {pulloutSchedule} <br/>NGL actualizará el horario lo antes posible <br/>Gracias,'
      }
    },
    deliverySchedule: {
      subject:
        'DILV APPT, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
      body: `Dear {billTo}, <br/>{containerNumber} has been scheduled to deliver to {deliveryLocation} at {deliverySchedule} <br/>NGL will update the schedule as soon as possible <br/>Thanks,`
    },
    podPol: {
      subject:
        '{type}, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
      body:
        'Estimado {billTo}, <br/> {containerNumber} ha sido entregado <br/>¡Por favor busque el archivo adjunto! <br/> NNGL actualizará el horario lo antes posible <br/>Gracias,'
    },
    demurrage: {
      subject:
        'Advertencia-Dmrgs {containerNumber}, {pulloutLocation}, {lastFreeDay}',
      body: `Estimado {billTo}: <br/>Para evitar cargos por demora, {containerNumber} debe ser retirado de una terminal antes del {lastFreeDay}. <br/>Por favor ayúdenos a borrar la retención lo antes posible para que podamos recuperar el contenedor. <br/>Si tiene preguntas, comuníquese con nosotros para que las respondamos de inmediato, ¡gracias! <br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber} <br/>Cliente: {customer} <br/>Último día libre: {lastFreeDay } <br/>`
    },
    waiting: {
      subject:
        'Advertencia-DrvWaitings {containerNumber}, {deliveryLocation}, {deliverySchedule}',
      body: `Estimado {billTo}: <br/>Tenga en cuenta que el conductor ha estado en el lugar desde el {deliverySchedule} y el tiempo de espera comenzará pronto. <br/>Si tiene preguntas, comuníquese con nosotros para que se las respondan de inmediato, ¡gracias! <br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber} <br/>Customer: {customer} <br/>Appointment Time: {deliverySchedule} `
    },
    perdiem: {
      subject:
        'Advertencia-PrDms {containerNumber}, {returnLocation}, {returnFreeDay}',
      body: `Estimado {billTo}, <br/>Para evitar el cargo por día, {containerNumber} debe ser devuelto antes del {returnFreeDay}. <br/>Por favor, ayúdenos a devolver el contenedor en cuestión lo antes posible. <br/>Si tiene preguntas, comuníquese con nosotros para que las respondamos de inmediato, ¡gracias! <br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber} <br/>Cliente: {customer} <br/>Fecha de entrega: {deliverySchedule} <br/>Día de devolución: {returnFreeDay} `
    },
    trackingId: {
      subject:
        'ID de seguimiento para CONT# {containerNumber},{deliveryLocation}',
      body: `
      Estimado {billTo}:<br/>CONT# {containerNumber} ha salido a {deliveryLocation} a las {previousDeliverySchedule}<br/>Entrega a: {deliveryAddress}<br/>Container #:{containerNumber} <br/>PO # o REF #: {customer} <br/>Programa de entrega: {deliverySchedule} <br/><h1 style="text-align: center;"><a title="LIVE ETA" href="{trackingUrl}" target="_blank" rel="noopener">Contenedor de seguimiento</a></h1>
      Gracias,`
    }
  },
  schedule: {
    dateMoreMonth:
      'La fecha del cronograma es más de un mes que la fecha del cronograma anterior',
    pullOutLaterLFD: 'La fecha de programación de P/OUT es posterior a LFD',
    scheduleInLaterRFD: 'La fecha del cronograma es posterior a la RFD'
  },
  reports: {
    categories: 'categoría',
    div: 'div',
    search: 'fecha de búsqueda',
    searchFrom: 'desde',
    searchTo: 'to',
    billTo: 'facturar a',
    searchYear: 'Año',
    searchMonth: 'Mes'
  },
  datePicker: {
    '90DaysDiff': `Ha establecido una diferencia de 90 días con respecto a la actual. ¿Está seguro?`,
    incorrectDate: 'Ingrese la fecha correcta en formato MM/DD/AA',
    maxDate: 'Desde la fecha debe ser anterior a Hasta la fecha',
    minDate: 'La fecha hasta debe ser posterior a la fecha Desde'
  },
  userManagement: {
    deleteConfirmMessage: `Eliminar un usuario eliminará el acceso de ese usuario al sistema.\n\n
    ¿Está seguro de que desea eliminar este usuario?`,
    resetConfirmMessage: `¿Está seguro de que desea restablecer la contraseña de {name} \n\n    
    Las credenciales de inicio de sesión se enviarán a {email}`,
    userManagement: 'Gestión de usuarios',
    sendResetEmail: 'Enviar correo electrónico de restablecimiento',
    deleteUser: 'Borrar usuario',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    firstPage: 'Primera página',
    defaultDivision: 'División predeterminada',
    update: 'Actualizar',
    close: 'Cerrar',
    requiredFieldMessage: 'Faltan campos obligatorios',
    yes: 'Sí',
    status: 'Estado',
    filterBy: 'Búsqueda por',
    userName: 'Nombre de usuario',
    reset: 'Reiniciar',
    create: 'Crear'
  },
  accountManagement: {
    defaultLanguage: 'Idioma predeterminado',
    systemMeasurement: 'Medición del sistema',
    country: 'País',
    timeZone: 'Zona horaria',
    businessType: 'Tipo de negocio',
    company: 'Compañía',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    userId: 'Identificación de usuario',
    phone: 'Teléfono',
    password: 'Clave',
    confirmPassword: 'Confirmar contraseña',
    email: 'Correo electrónico',
    supportEmail: 'Correo electrónico de soporte',
    alertsEmail: 'Correos electrónicos de alerta',
    reportsEmail: 'Informe de correos electrónicos',
    address: 'Dirección',
    modality: 'Modalidad',
    accountType: 'Tipo de cuenta',
    group: 'Grupo',
    emailContent: 'Contenido del correo electrónico'
  },
  tracking: {
    GATE_IN: 'Puerta de entrada',
    GATE_OUT: 'Puerta de salida',
    READY_TO_START: 'Listo para comenzar',
    READY_TO_PICK_UP: 'Listo para recoger',
    PICKED_UP: 'Recogido',
    READY_TO_DROP_OFF: 'Listo para dejar',
    LOADING_UNLOADING: 'Cargando descargando',
    LOADED_UNLOADED: 'Cargando descargado',
    RETURN: 'Devolver',
    DROPPED_OFF:'Dejado'
  },
  trackingStatus: {
    COMPLETED: 'Terminado',
    IN_TRANSIT: 'En tránsito',
    ALL: 'Todos'
  },
  fileDownload: {
    upload: 'Subir archivo'
  },
  driverModal: {
    driverId: 'ID del conductor'
  },
  earlyWarningModal: {
    title: 'Advertencia temprana'
  },
  emailModal: {
    title: 'Enviar correo electrónico',
    cc: 'CC',
    to: 'para',
    subject: 'Sujeto',
    required: 'Faltan campos obligatorios'
  },
  woModalSearch: {
    chassisNumber: 'Chasis#',
    containerNumbe: 'Envase#',
    trailerNumber: 'Remolque#'
  },
  driver: {
    subdrivertitle: 'Detalle del controlador secundario',
    nodriverfound: 'No se encontraron subcontroladores'
  }
};
































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import { FilterInput } from '@/shared/services/filter/filter.service';
import {
  BS_DATEPICKER_FORMAT,
  convertAPIToDateFormat,
  convertDateFieldToAPIFormatWithoutTime,
  DATE_API_FORMAT
} from '@/utils/date.util';

import moment from 'moment-timezone';

@Component({
  components: { Fragment }
})
export default class DateFilter extends Vue {
  to = null;
  from = null;

  fromMaxDate = moment().format(BS_DATEPICKER_FORMAT);

  minDate = null;
  maxDate = null;

  created() {}

  onFromChange() {
    this.minDate = moment(this.from, DATE_API_FORMAT).format(
      BS_DATEPICKER_FORMAT
    );
    this.maxDate = moment(this.from, DATE_API_FORMAT)
      .add(4, 'days')
      .endOf('day')
      .format(BS_DATEPICKER_FORMAT);
    console.log(
      moment(this.from, DATE_API_FORMAT)
        .add(4, 'days')
        .endOf('day')
        .format(DATE_API_FORMAT)
    );
    this.$emit('rangeChange', {
      from: this.from,
      to: moment(this.from, DATE_API_FORMAT)
        .add(4, 'days')
        .endOf('day')
        .format(DATE_API_FORMAT)
    });
  }
}

import axios, { AxiosInstance } from 'axios';
import { Auth } from 'aws-amplify';
import { Role } from '@/utils/permissions/permission.model';

export class ApiService {
  http: AxiosInstance;
  URLAuthStopList = ['s3'];

  constructor(baseURL: string) {
    this.http = axios.create({
      baseURL: `${process.env.VUE_APP_BASE_API}/${baseURL}`,
      timeout: 30000
    });
    this.configureInterceptor();
  }

  get axios() {
    return axios;
  }

  get(url: string, params?: any) {
    return this.http.get(url, { params });
  }

  post(url: string, data: any, params?: any) {
    return this.http.post(url, data, params);
  }

  put(url: string, data: any, params?: any) {
    return this.http.put(url, data, params);
  }

  patch(url: string, data: any, params?: any) {
    return this.http.patch(url, data, params);
  }

  delete(url: string, params?: any) {
    return this.http.delete(url, params);
  }

  private async getJWTToken() {
    const user = await Auth.currentAuthenticatedUser();
    return user.getSignInUserSession().getIdToken().jwtToken;
  }

  async isOptestRole() {
    const user = await Auth.currentAuthenticatedUser();
    return user && user.signInUserSession.accessToken.payload['cognito:groups'] && user.signInUserSession.accessToken.payload['cognito:groups'].includes(Role.Optest);
  }

  private configureInterceptor() {
    this.http.interceptors.request.use(
      async config => {
        if (this.isURLInStopList(config.url)) {
          return config;
        }
        const token = await this.getJWTToken();
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['Content-Type'] = 'application/json';

        // set account id if the role is OPTEST and window is clientWebWin only.
        if (await this.isOptestRole() && window.name === 'clientWebWin') {
          try {
            let params = JSON.parse(localStorage['params']);
            if (params.acctId)
              config.headers['acct_id'] = params.acctId;
          } catch (error) {
            console.log(error);
          }
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  private isURLInStopList(baseURL: string) {
    let status = false;
    this.URLAuthStopList.forEach(url => {
      if (baseURL.indexOf(url) > -1) {
        status = true;
      }
    });
    return status;
  }
}

export const limit = 600;











































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import { WorkOrder } from '../../../store/modules/workOrder/work-order.model';
import WOMemo from '@/shared/components/wo/WoMemo.vue';
import ScheduleTableView from '@/pages/Main/components/DetailInfo/ScheduleTableView.vue';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import { ToastHelper } from './../../../utils/toast.util';
import { UserModule } from '@/store';
import moment from 'moment-timezone';
import { DATE_API_FORMAT } from '@/utils/date.util';

@Component({
  components: {
    WOMemo,
    ScheduleTableView
  }
})
export default class DetailMemo extends Vue {
  @Prop() wo: WorkOrder;
  userModule = UserModule;
  successMsg = this.$i18n.t('wo.savedSuccessfully');
  woLabel: any = this.$i18n.t('wo.workOrder');

  memo: string = null;

  newMemoIndex = null;
  showBKMemo = false;

  addMemo(index: number) {
    this.resetMemo();
    this.newMemoIndex = index;
  }

  addBKMemo() {
    this.resetMemo();
    this.showBKMemo = true;
  }

  saveMemo(schedule, index) {
    if (!this.newMemoIndex && this.newMemoIndex !== 0) {
      const data = {
        text: this.memo,
        rowId: null,
        fieldName: 'BOOKING_NUMBER'
      };

      WorkOrderService.saveMemo(this.wo.category, this.wo.orderNumber, data)
        .then(() => {
          ToastHelper.show(
            this.woLabel,
            `${this.woLabel} ${this.wo.orderNumber} ${this.successMsg}`,
            8000,
            'success'
          );
          this.resetMemo();
        })
        .catch(e =>
          ToastHelper.show('BookingNumberMemo', e.message, 8000, 'danger')
        );
    } else {
      const data = {
        text: this.memo,
        rowId: schedule.uuid,
        fieldName: 'SCHEDULE'
      };

      WorkOrderService.saveMemo(this.wo.category, this.wo.orderNumber, data)
        .then(() => {
          ToastHelper.show(
            this.woLabel,
            `${this.woLabel} ${this.wo.orderNumber} ${this.successMsg}`,
            8000,
            'success'
          );
          const newMemo = {
            acctId: this.wo.acctId,
            orderNumber: this.wo.orderNumber,
            createdBy: this.userModule?.currentUser?.email,
            createdDate: moment().format(DATE_API_FORMAT),
            ...data
          };
          this.wo.schedules[index].memos.push(newMemo);
          this.resetMemo();
          this.$emit('woUpdated');
        })
        .catch(e => {
          ToastHelper.show('ScheduleMemo', e.message, 8000, 'danger');
        });
    }
  }

  resetMemo() {
    this.showBKMemo = false;
    this.memo = null;
    this.newMemoIndex = null;
  }

  maxCharacters(val: string) {
    return String(val).substring(0, 300);
  }
}

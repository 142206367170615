




























































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BIconPlusCircle, BIconTrash } from 'bootstrap-vue';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import ScheduleAreaHeader from './ScheduleAreaHeader.vue';
import AssignDriversModal from './AssignDriversModal.vue';
import TrackingModal from './TrackingModal.vue';
import WoMemo from '@/shared/components/wo/WoMemo.vue';
import moment from 'moment-timezone';
import {
  BS_DATEPICKER_FORMAT,
  BS_DATEPICKER_WITH_TIME,
  convertAPIToDateFormat,
  convertDateFieldToAPIFormat,
  convertDateFieldToTime,
  DATE_API_FORMAT,
  DATE_API_FORMAT_WITHOUT_TIME,
  DATE_TIME_FORMAT_WITH_SECONDS
} from '@/utils/date.util';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import { JobPayments } from '@/shared/models/driverIdModal.model';
import DriverInputWrapper from '@/shared/components/form/DriverInputWrapper.vue';
import { WOFieldChangeEventBusInstance } from '../../../../shared/components/wo/wo-field-change-event-bus';
import { WoType } from '@/shared/components/wo/models/index';

import { locationMap, Location } from './models/location.model';
import {
  DispatchScheduleLocation,
  DropLiveType,
  LocationType
} from '@/shared/models/woSchedule.model';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import DriverIdModal from '@/shared/components/modal/DriverIdModal.vue';
import { errorByLocation } from '@/shared/services/wo/schedule-validation';
import {
  dpPipe,
  isLessOrMoreTodayBy
} from '@/shared/components/helpers/datePickerHandlers';
import { deepClone, generateUUID } from '@/utils/utils';

@Component({
  components: {
    BIconPlusCircle,
    BIconTrash,
    ScheduleAreaHeader,
    WoMemo,
    AssignDriversModal,
    TrackingModal,
    DriverInputWrapper,
    DriverIdModal
  }
})
export default class ScheduleArea extends Vue {
  @Prop() wo: any;
  @Prop() woForm: any;
  @Prop() putRequest: JobPayments;

  dpHandler: Function = isLessOrMoreTodayBy(90, 'datePicker.90DaysDiff');
  WoType = WoType;

  scheduleDBHandler;

  schedule: any = [{ memos: [{}] }];
  scheduleIndex: number = null;
  activeSchedule: any = null;
  companyService = CompanyService;
  masterDataService = MasterDataService;
  type = null;
  locationType = 'STOP';
  search = null;
  typeOptions = [
    { value: DropLiveType.DROP, text: 'Drop' },
    { value: DropLiveType.LIVE, text: 'Live' }
  ];
  locationTypeOptions = [
    { value: 'STOP', text: 'STOP' },
    { value: 'RETURN', text: 'RETURN', disabled: true }
  ];
  todayDate = moment().format(BS_DATEPICKER_FORMAT);
  todayTime = moment().format(DATE_TIME_FORMAT_WITH_SECONDS);
  jobPayments: JobPayments = null;
  isAddScheduleDisabled = false;
  trackingModalSize = 'm';

  jobId = null;
  isTripReplay = false;

  constructor() {
    super();
    if (this.wo) {
      this.wo.schedules.forEach(item => {
        item.scheduleInTime = convertDateFieldToTime(
          item.scheduleIn,
          DATE_API_FORMAT
        );

        if (item.actualIn) {
          item.actualInTime = convertDateFieldToTime(
            item.actualIn,
            DATE_API_FORMAT
          );
          item.actualOutTime = convertDateFieldToTime(
            item.actualOut,
            DATE_API_FORMAT
          );
        }

        const dateKeys = ['scheduleIn', 'actualIn', 'actualOut'];
        dateKeys
          .filter(key => item[key])
          .forEach(key => {
            item[key] = moment(item[key], DATE_API_FORMAT).format(
              BS_DATEPICKER_FORMAT
            );
          });
      });
    }
  }

  isInputDropLive(value: string) {
    this.dropLiveChanged(value);

    const pickUpIndex = this.wo.schedules.findIndex(
      row => row.locationType === LocationType.PICKUP
    );
    const deliveryIndex = this.wo.schedules.findIndex(
      row => row.locationType === LocationType.DELIVERY
    );
    this.wo = JobsService.updateSchedules(this.wo);

    if (this.type === DropLiveType.LIVE && this.wo.schedules[pickUpIndex]) {
      this.wo.schedules.splice(pickUpIndex, 1);
      this.$forceUpdate();
    } else this.addPickUpSchedule(deliveryIndex, pickUpIndex);
  }
  get deliveryDateTime(): string {
    return this.getScheduleDateTime(LocationType.DELIVERY);
  }

  get deliveryMinDate(): string {
    return this.pullOutDateTime
      ? convertAPIToDateFormat(this.pullOutDateTime)
      : undefined;
  }

  get pickupDateTime(): string {
    return this.getScheduleDateTime(LocationType.PICKUP);
  }

  get pickupMinDate(): string {
    return this.deliveryDateTime
      ? convertAPIToDateFormat(this.deliveryDateTime)
      : undefined;
  }

  get pullOutDateTime(): string {
    return this.getScheduleDateTime(LocationType.PULLOUT);
  }

  get preturnDateTime(): string {
    return this.getScheduleDateTime(LocationType.RETURN);
  }

  get returnMinDate(): string {
    return this.deliveryDateTime
      ? convertAPIToDateFormat(this.pickupDateTime)
      : undefined;
  }

  get minDates(): { [key in LocationType]: string } {
    return {
      [LocationType.PULLOUT]: undefined,
      [LocationType.DELIVERY]: this.deliveryMinDate,
      [LocationType.PICKUP]: this.pickupMinDate,
      [LocationType.RETURN]: this.returnMinDate
    };
  }

  getScheduleDatesOrderErrors() {
    return errorByLocation(this.wo.schedules);
  }

  @Watch('wo.schedules', { deep: true, immediate: true })
  onSchedulesChange() {
    const deliveryIndex = this.wo.schedules.findIndex(
      row => row.locationType === LocationType.DELIVERY
    );
    let pickUpIndex = this.wo.schedules.findIndex(
      row => row.locationType === LocationType.PICKUP
    );

    const locationTypeReturn = this.locationTypeOptions.find(
      item => item.value === 'RETURN'
    );

    const emptyScheduleRowIndex = this.wo.schedules.findIndex(
      row => !row.locationType
    );
    if (emptyScheduleRowIndex > 0) {
      this.isAddScheduleDisabled = true;
      if (this.wo.schedules.length - 1 === emptyScheduleRowIndex) {
        locationTypeReturn.disabled = false;
      }
    } else {
      this.isAddScheduleDisabled = false;
      locationTypeReturn.disabled = true;
    }
    if (this.type === DropLiveType.LIVE && this.wo.schedules[pickUpIndex]) {
      this.wo.schedules.splice(pickUpIndex, 1);
      pickUpIndex = this.wo.schedules.findIndex(
        row => row.locationType === LocationType.PICKUP
      );

      const apPickUpIndex = this.wo?.accountPayables?.findIndex(
        row => row.jobId === this.wo?.schedules[pickUpIndex].jobUuid
      );
      if (apPickUpIndex) {
        this.wo?.accountPayables?.splice(apPickUpIndex, 1);
        this.$emit('updateAP', this.wo.accountPayables);
      }

      this.wo.schedules.splice(pickUpIndex, 1);
      this.$forceUpdate();
    } else {
      this.addPickUpSchedule(deliveryIndex, pickUpIndex);
    }

    if (emptyScheduleRowIndex > 0) {
      this.isAddScheduleDisabled = true;
      if (this.wo.schedules.length - 1 === emptyScheduleRowIndex) {
        locationTypeReturn.disabled = false;
      }
    } else {
      this.isAddScheduleDisabled = false;
      locationTypeReturn.disabled = true;
    }
    if (deliveryIndex > 0) {
      this.updateDriverIdForDelivery(deliveryIndex);
    }
    this.updateRequiredField(this.wo);

    // trip replay button enabled when at least one job satisfies below contion.
    this.isTripReplay = this.wo.schedules.some(
      schedule =>
        schedule.driverId &&
        !(
          schedule.actualIn === 'Invalid date' ||
          schedule.actualIn === '' ||
          !schedule.actualIn
        )
    );
  }

  updateRequiredField(wo) {
    wo.schedules.forEach(schedule => {
      const currentScheduleIndex = wo.schedules.findIndex(
        a => a.uuid === schedule.uuid
      );
      if (schedule.driverId && currentScheduleIndex !== -1) {
        // update current schedule
        const currentSchedule = wo.schedules[currentScheduleIndex];
        currentSchedule.scheduleInRequired =
          currentSchedule.driverId &&
          (currentSchedule.scheduleIn === 'Invalid date' ||
            currentSchedule.scheduleIn === '' ||
            !currentSchedule.scheduleIn);

        // update next immediate schedule
        const nextScheduleIndex = currentScheduleIndex + 1;
        if (wo.schedules.length > nextScheduleIndex) {
          const nextSchedule = wo.schedules[nextScheduleIndex];
          const nextCondition =
            nextSchedule.scheduleIn === 'Invalid date' ||
            nextSchedule.scheduleIn === '' ||
            !nextSchedule.scheduleIn;
          if (nextSchedule.scheduleInRequired !== nextCondition)
            nextSchedule.scheduleInRequired = nextCondition;
        }
      } else if (
        currentScheduleIndex !== -1 &&
        !wo.schedules[currentScheduleIndex - 1]?.driverId
      ) {
        wo.schedules[currentScheduleIndex].scheduleInRequired = false;
      }
    });
  }

  created() {
    this.type = this.wo.dropLive;
  }

  addSchedule(index: number, locationType: string) {
    const locationTypeReturn = this.locationTypeOptions.find(
      item => item.value === 'RETURN'
    );

    this.wo.schedules.splice(index + 1, 0, { uuid: this.getUuid() });

    if (this.wo.schedules.length - 1 === index + 1) {
      locationTypeReturn.disabled = false;
    } else {
      locationTypeReturn.disabled = true;
    }
  }

  validateActualInTime(i) {
    if (i.actualInTime && i.actualIn) {
      return moment(
        `${moment(i.actualIn, DATE_API_FORMAT).format(
          DATE_API_FORMAT_WITHOUT_TIME
        )} ${i.actualInTime}`,
        BS_DATEPICKER_WITH_TIME
      ).isBefore(moment());
    }

    return true;
  }

  removeSchedule(index: number) {
    this.wo.schedules.splice(index, 1);
  }

  scheduleDateHandler(schedule, RFDField = null) {
    return date =>
      dpPipe(
        () => {
          if (date.diff(moment(), 'month') > 0) {
            return this.$i18n.t('wo.schedule.dateMoreMonth');
          }
        },
        () => {
          if (
            schedule.locationType === 'PULLOUT' &&
            this.woForm.lastFreeDay &&
            date.isAfter(moment(this.woForm.lastFreeDay, DATE_API_FORMAT))
          ) {
            return this.$i18n.t('wo.schedule.pullOutLaterLFD');
          }
        },
        () => {
          if (
            this.woForm.returnFreeDay &&
            date.isAfter(moment(this.woForm.returnFreeDay, DATE_API_FORMAT))
          ) {
            return this.$i18n.t('wo.schedule.scheduleInLaterRFD');
          }
        }
      )(date);
  }

  updateLocation(data, schedule) {
    if (!data) {
      schedule.location = '';
      schedule.locationName = '';
      schedule.city = '';
      schedule.state = '';
    } else {
      schedule.location = data.id;
      schedule.locationName = data.name;
      schedule.city = data.city;
      schedule.state = data.state;
      this.updateLocationForDrop(data, schedule);
    }

    if (
      [Location.PULLOUT, Location.DELIVERY, Location.RETURN].indexOf(
        schedule.locationType
      ) > -1
    ) {
      this.$emit('locationChanged', {
        key: locationMap[schedule.locationType],
        value: data ? data.id : ''
      });
    }

    this.$forceUpdate();
  }

  // update delivery & pickup locations if they are not same for Drop Schedule.
  updateLocationForDrop(data: any, schedule: any) {
    if (
      this.type === DropLiveType.DROP &&
      [LocationType.PICKUP, Location.DELIVERY].includes(schedule.locationType)
    ) {
      const pickupIndex = this.wo.schedules.findIndex(
        row => row.locationType === LocationType.PICKUP
      );
      const deliveryIndex = this.wo.schedules.findIndex(
        row => row.locationType === LocationType.DELIVERY
      );
      if (
        pickupIndex !== -1 &&
        deliveryIndex !== -1 &&
        this.wo.schedules[pickupIndex].location !=
          this.wo.schedules[deliveryIndex].location
      ) {
        const index =
          schedule.locationType == LocationType.PICKUP
            ? deliveryIndex
            : pickupIndex;
        this.wo.schedules[index].location = data.id;
        this.wo.schedules[index].locationName = data.name;
        this.wo.schedules[index].city = data.city;
        this.wo.schedules[index].state = data.state;
      }
    }
  }

  onScheduleInInput(newValue: string, scheduleItem) {
    WOFieldChangeEventBusInstance.notifyChanges({
      fieldKey: 'schedules.scheduleIn',
      newValue,
      contextData: scheduleItem
    });
  }

  assign(driverId) {
    this.wo.schedules[this.scheduleIndex].driverId = driverId;
    this.$forceUpdate();
  }

  showMemo(schedule) {
    this.activeSchedule = schedule;
    this.$bvModal.show('ScheduleAreaMemo');
  }

  showTripReplay({ jobUuid }) {
    this.jobId = jobUuid;
    this.$bvModal.show('TripReplayModal');
  }

  updateScheduleMemo(value) {
    this.activeSchedule.memo = value;
    this.$forceUpdate();
  }

  showSearchModal(index) {
    this.scheduleIndex = index;
    this.$bvModal.show('DriversSearchModal');
  }

  updateSchedule({ jobPayments, schedules }) {
    this.wo.schedules.forEach((i, index) => {
      schedules.forEach((e, scheduleIndex) => {
        if (scheduleIndex === index) {
          i.jobUuid = e.jobUuid;
        }
      });
    });

    this.wo.jobPayments = jobPayments;
    this.$bvModal.hide('DriverIdModalAccCode');
  }

  onSaveDrivers(schedules) {
    this.openAccCodeModal(schedules);
    this.wo.schedules = schedules;
  }

  openAccCodeModal(schedules) {
    const data: DispatchScheduleLocation[] = schedules.map(i => ({
      uuid: i.uuid,
      locationType: i.locationType,
      driverId: i.driverId,
      scheduleIn: i.scheduleIn
        ? convertDateFieldToAPIFormat(
            `${i.scheduleIn} ${i.scheduleInTime}`,
            BS_DATEPICKER_WITH_TIME
          )
        : null
    }));

    const mappedSchedules = deepClone(this.wo.schedules);
    mappedSchedules.forEach(item => {
      item.scheduleIn = convertDateFieldToAPIFormat(
        `${item.scheduleIn} ${item.scheduleInTime}`,
        BS_DATEPICKER_WITH_TIME
      );
    });

    this.jobPayments = JobsService.getDispatchJobPayments(
      data,
      mappedSchedules,
      this.wo.jobPayments
    );
    this.wo = JobsService.updateSchedules(
      this.wo,
      this.jobPayments.currentSchedules
    );
    if (this.jobPayments) {
      this.$bvModal.show('DriverIdModalAccCode');
    }
  }

  removeScheduleRow(row, dropLive) {
    let action;
    switch (row.locationType) {
      case LocationType.PULLOUT:
        action = false;
        break;
      case LocationType.DELIVERY:
        action = false;
        break;
      case dropLive === DropLiveType.DROP && LocationType.PICKUP:
        action = false;
        break;
      case dropLive === DropLiveType.LIVE && LocationType.PICKUP:
        action = true;
        break;
      default:
        action = true;
        break;
    }

    if (
      row.locationType === LocationType.PULLOUT &&
      (this.wo.category === WoType.ExportRegular ||
        this.wo.category === WoType.ExportShuttle)
    ) {
      action = true;
    }
    return action;
  }

  private getScheduleDateTime(type: LocationType): string {
    const scheduleItem: DispatchScheduleLocation = (
      this.wo.schedules || []
    ).find(
      (schedule: DispatchScheduleLocation) => schedule.locationType === type
    );

    return scheduleItem?.scheduleIn ? `${scheduleItem?.scheduleIn}` : undefined;
  }

  isDisabledDropLive() {
    let disabled = false;
    let deliveryLocationIndex = this.wo.schedules.findIndex(
      item => item.locationType === LocationType.DELIVERY
    );
    if (
      this.wo.schedules[deliveryLocationIndex].actualOut &&
      this.wo.schedules[deliveryLocationIndex++].actualIn
    ) {
      disabled = true;
    }
    return disabled;
  }

  dropLiveChanged(value) {
    this.wo.dropLive = value;
    this.type = value;
    this.$forceUpdate();
    this.$emit('dropLiveChanged', value);
  }

  isValidLocation(locationType) {
    const validationsByLocation = this.getScheduleDatesOrderErrors();

    if (locationType) {
      const locationError = validationsByLocation.find(
        item => item.locationType === locationType
      );
      return locationError;
    }
  }

  addPullOutSchedule(index, item) {
    this.wo.schedules.splice(index, 0, item);
  }

  addPickUpSchedule(deliveryIndex, pickUpIndex) {
    if (this.type === DropLiveType.DROP && !this.wo.schedules[pickUpIndex]) {
      this.wo.schedules.splice(deliveryIndex + 1, 0, {
        locationType: LocationType.PICKUP,
        location: this.wo.schedules[deliveryIndex].location,
        locationName: this.wo.schedules[deliveryIndex].locationName,
        scheduleIn: this.wo.schedules[deliveryIndex].scheduleIn,
        scheduleInTime: this.wo.schedules[deliveryIndex].scheduleInTime,
        city: this.wo.schedules[deliveryIndex].city,
        state: this.wo.schedules[deliveryIndex].state,
        driverId: null
      });
    }
  }

  // Restrict driver on certain location types
  isDriverAssignAllowed(schedule: any, index?: any): boolean {
    let isNotAllowed = false;
    if (
      schedule.actualInTime &&
      schedule.actualIn &&
      schedule.locationType !== 'STOP'
    ) {
      const data = moment(
        `${moment(schedule.actualIn, DATE_API_FORMAT).format(
          DATE_API_FORMAT_WITHOUT_TIME
        )} ${schedule.actualInTime}`,
        BS_DATEPICKER_WITH_TIME
      );
      if (data) {
        isNotAllowed = true;
      }
    } else if (
      [Location.DELIVERY, Location.RETURN].includes(schedule.locationType)
    ) {
      isNotAllowed = true;
    } else if (
      [LocationType.PICKUP, 'STOP'].includes(schedule.locationType) &&
      Object.keys(this.wo.schedules).length - 1 === index &&
      this.wo.schedules[this.wo.schedules?.length - 1].uuid === schedule.uuid
    ) {
      isNotAllowed = true;
    } else if (schedule.locationType === 'STOP') {
      const actualOutDate = moment(
        `${moment(schedule.actualOut, DATE_API_FORMAT).format(
          DATE_API_FORMAT_WITHOUT_TIME
        )} ${schedule.actualOutTime}`,
        BS_DATEPICKER_WITH_TIME
      );
      const actualInDate = moment(
        `${moment(schedule.actualIn, DATE_API_FORMAT).format(
          DATE_API_FORMAT_WITHOUT_TIME
        )} ${schedule.actualInTime}`,
        BS_DATEPICKER_WITH_TIME
      );
      if (actualOutDate.isValid() && actualInDate.isValid()) {
        isNotAllowed = true;
      } else if (actualOutDate.isValid()) {
        isNotAllowed = true;
      }
    }
    return isNotAllowed;
  }

  isActualInOutEditable(schedule: any, isActualOut = false) {
    if (schedule.locationType === LocationType.DELIVERY) {
      const deliveryLocationIndex = this.wo.schedules.findIndex(
        item => item.locationType === LocationType.DELIVERY
      );
      return (
        (deliveryLocationIndex > 0 &&
          this.wo.schedules[deliveryLocationIndex - 1].driverId === null) ||
        (deliveryLocationIndex == 0 &&
          this.wo.schedules[deliveryLocationIndex].driverId === null)
      );
    } else if (schedule.locationType === LocationType.RETURN) {
      const returnLocationIndex = this.wo.schedules.findIndex(
        item => item.locationType === LocationType.RETURN
      );
      return (
        returnLocationIndex > 0 &&
        this.wo.schedules[returnLocationIndex - 1].driverId === null
      );
    } else if (schedule.locationType === 'STOP') {
      const currentStopIndex = this.wo.schedules.findIndex(
        item => item.uuid === schedule.uuid
      );
      if (isActualOut) {
        return (
          (currentStopIndex > 0 &&
            this.wo.schedules[currentStopIndex - 1].driverId === null) ||
          !schedule.driverId
        );
      } else
        return (
          currentStopIndex > 0 &&
          this.wo.schedules[currentStopIndex - 1].driverId === null
        );
    } else {
      return !schedule.driverId;
    }
  }

  getActualMinDate(job: any, isIn = false) {
    let minDate: string;
    if (isIn) {
      minDate = this.getPreviousActualOutDate(job.locationType);
    } else {
      if (job.locationType != LocationType.PULLOUT && !job.actualIn) {
        minDate = this.getPreviousActualOutDate(job.locationType);
      } else if (job.actualIn) {
        minDate = convertAPIToDateFormat(job.actualIn);
      }
    }
    return minDate;
  }

  getActualMaxDate(job: any, isIn = false) {
    if (isIn) {
      return job.actualOut
        ? convertAPIToDateFormat(job.actualOut)
        : this.todayDate;
    } else {
      const index = this.wo.schedules.findIndex(
        item => item.locationType === job.locationType
      );
      if (index != -1 && index < this.wo.schedules.length - 1) {
        return this.wo.schedules[index + 1].actualIn
          ? convertAPIToDateFormat(this.wo.schedules[index + 1].actualIn)
          : this.todayDate;
      }
    }
    return this.todayDate;
  }

  getPreviousActualOutDate(locationType: string) {
    if (locationType == LocationType.PULLOUT) {
      return;
    }

    const index = this.wo.schedules.findIndex(
      item => item.locationType === locationType
    );
    if (index > 0 && this.wo.schedules[index - 1].actualOut) {
      return convertAPIToDateFormat(this.wo.schedules[index - 1].actualOut);
    }
  }

  // Assign driver when job type is delivery
  updateDriverIdForDelivery(deliveryIndex: number) {
    if (this.wo.dropLive === DropLiveType.LIVE) {
      this.wo.schedules[deliveryIndex].driverId =
        this.wo.schedules[deliveryIndex - 1].driverId;
      this.wo.schedules[deliveryIndex].jobUuid =
        this.wo.schedules[deliveryIndex - 1].jobUuid;
    } else if (this.wo.dropLive === DropLiveType.DROP) {
      this.wo.schedules[deliveryIndex].driverId = null;
      this.wo.schedules[deliveryIndex].jobUuid = null;
    }
  }

  unAssignDriver(uuid: string) {
    const index = this.wo.schedules.findIndex(a => a.uuid === uuid);
    if (index !== -1) {
      this.wo.schedules[index].driverId = null;
      this.wo.schedules[index].jobUuid = null;
    }
  }
  // Returns uuid same structure as backend generates.
  getUuid(id?: string) {
    return id ? id : generateUUID();
  }

  driverArrayClose({ activeUiid, openDriverModal }) {
    if (openDriverModal) {
      this.$bvModal.show('AssignDriversModal');
    }
    this.unAssignDriversArray(activeUiid);
  }

  unAssignDriversArray(unAssignDrivers) {
    this.wo.schedules.forEach(schedule => {
      unAssignDrivers.forEach(row => {
        if (row.uuid === schedule.uuid) {
          schedule.driverId = null;
          schedule.jobUuid = null;
        }
      });
    });
  }

  canBeTracked() {
    return this.wo.schedules.some(
      schedule => schedule.actualIn && schedule.actualOut
    );
  }

  canTripReplay(locationType: any) {
    return ![Location.DELIVERY, Location.RETURN].includes(locationType);
  }
}

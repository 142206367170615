import { ToastHelper } from '@/utils/toast.util';

import { ApiService } from '../base/api.service';

class GPSTrackingProvider extends ApiService {
  constructor() {
    super('tracking');
  }

  async getGpsData({
    jobId,
    orderNumber,
    startTime,
    endTime,
    driverId,
    carrierId
  }: any) {
    let query = `/gps-data?`;

    if (orderNumber) {
      query = `${query}&work-order-number=${orderNumber}`;
    }

    if (startTime && endTime) {
      query = `${query}&start-time=${startTime}&end-time=${endTime}`;
    }

    if (driverId) {
      query = `${query}&driver-id=${driverId}`;
    }

    if (carrierId) {
      query = `${query}&carrier-id=${carrierId}`;
    }

    if (jobId) {
      query = `${query}&job-id=${jobId}`;
    }

    try {
      const res = await this.get(query);
      return res.data;
    } catch (e) {
      ToastHelper.show('Error', 'Error while loading GPS data', 5000, 'danger');
      console.log(e);
      return {};
    }
  }
}

export const GPSTrackingService = new GPSTrackingProvider();

import { AdminConfService } from './../../shared/services/admin-conf/admin-conf.service';
import { Route, NavigationGuardNext } from 'vue-router';
import { DivisionModule, UserModule } from '@/store';
import { AuthService } from '@/shared/services/auth.service';
import { ToastHelper } from '@/utils/toast.util';
import { Role } from '@/utils/permissions/permission.model';
import { hasOptestRole } from '@/utils/permissions/permissions.util';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
  if ((hasOptestRole(UserModule.userGroups)) || DivisionModule.division || !UserModule.authorized) {
    next();
  } else {
    try {
      const divisionsResponse = await AuthService.getCurrentDivisions();
      await AdminConfService.loadConfig();

      const defaultDivision: string = divisionsResponse.data?.division;
      const divisionsList = divisionsResponse.data?.divisions;
      const divisionsAbbrList = divisionsList.map(item => item.abbr);

      if (!defaultDivision) {
        throw new Error('Server does not return the default division');
      }

      if (!divisionsList || !Array.isArray(divisionsList)) {
        throw new Error('Server does not return the division list');
      }

      //TODO: DELETE workaround which added to make systems work before all users will get division list
      DivisionModule.setDefaultDivision(defaultDivision);
      DivisionModule.setDivisionsList(divisionsList);

      const localDivision = localStorage.getItem('division');

      if (divisionsAbbrList.includes(localDivision)) {
        DivisionModule.setDivision(localDivision);
      } else {
        DivisionModule.setDivision(defaultDivision);
      }

      next();
    } catch (err) {
      console.error('Getting division list Error', err);
      ToastHelper.show(
        'Divisions',
        err.response?.data?.error || err.message,
        5000,
        'danger'
      );
    }
  }
};






























































































































import Loading from '@/shared/components/Loading.vue';
import EmailModal from '@/shared/components/modal/EmailModal.vue';
import { LocationType } from '@/shared/models/woSchedule.model';
import { WorkOrderTrackingService } from '@/shared/services/wo/tracking.service';
import { DATE_TIME_EMAIL_FORMAT, convertAPIToFormat } from '@/utils/date.util';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: { Loading, EmailModal }
})
export default class TrackingModal extends Vue {
  @Prop() wo;
  @Prop() size;

  loading = false;
  // map
  zoom = 16.3;
  radius = 100;

  // https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM?pli=1
  // Formula for fractional zoom calculation
  availableRadius = [
    { text: '100 m (326 ft)', value: 20.3, radius: 100 },
    { text: '150 m (492 ft)', value: 19.7, radius: 150 },
    { text: '200 m (656 ft)', value: 19.3, radius: 200 },
    { text: '250 m (820 ft)', value: 19, radius: 250 },
    { text: '300 m (0.18 ml)', value: 18.7, radius: 300 },
    { text: '350 m (0.22 ml)', value: 18.5, radius: 350 },
    { text: '400 m (0.25 ml)', value: 18.14, radius: 400 }
  ];

  mapCenter = {};
  map = null;
  isLocationExists = false;
  // email
  emailModal = 'email-modal';
  template = null;
  subject = null;
  clietEmails = null;

  markers = [];

  trackingInfo = null;
  message = '';
  deliverySchedule = null;
  deliveryIndex = null;
  toggleIcon = 'fullscreen';

  constructor() {
    super();
  }

  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    (this.$refs.mapRef as any).$mapPromise.then(map => {
      this.map = map;
    });
  }

  created() {
    this.getAddressLatLng();
  }

  // Get current geocordinates for the vehicle
  async getAddressLatLng() {
    this.loading = true;
    this.trackingInfo = await WorkOrderTrackingService.getWoTrackingMapDetails(
      this.wo.orderNumber,
      this.wo.category
    );

    const [mobileGps, eldGps] = this.trackingInfo;

    if (
      (!mobileGps && !eldGps) ||
      (!mobileGps.jobLocations.length && !eldGps.jobLocations.length)
    ) {
      this.loading = false;
      return;
    }

    eldGps.jobLocations.forEach(({ driverId, lng, lat, hasContainer }) => {
      this.markers.push({
        driverId,
        position: { lat, lng },
        icon: hasContainer
          ? '/img/icons/icon-truck-green.png'
          : '/img/icons/icon-truck-red.png'
      });
    });

    mobileGps.jobLocations.forEach(({ driverId, lng, lat, hasContainer }) => {
      this.markers.push({
        driverId,
        position: { lat, lng },
        icon: hasContainer
          ? '/img/icons/icon-driver-green.png'
          : '/img/icons/icon-driver-red.png'
      });
    });

    // if (this.trackingInfo && this.trackingInfo.lat > 0) {
    //   this.isLocationExists = true;
    //   this.checkSendTracking();
    // }

    debugger;

    this.mapCenter = {
      lat: this.markers[0].position.lat,
      lng: this.markers[0].position.lng
    };

    this.isLocationExists = true;
    this.loading = false;
  }

  centerMap(selectVal) {
    this.map.panTo(this.mapCenter);
    this.map.setZoom(this.zoom);
    this.availableRadius.find(item => {
      if (item.value === selectVal) {
        this.radius = item.radius;
      }
    });
  }

  hide() {
    this.$bvModal.hide('tracking-modal');
  }

  // Check whether tracking data exists for the perticualar work order
  checkSendTracking() {
    this.deliveryIndex = this.wo.schedules.findIndex(
      (row: { locationType: LocationType }) =>
        row.locationType === LocationType.DELIVERY
    );
    if (this.deliveryIndex > 0) {
      this.deliverySchedule = this.wo.schedules[this.deliveryIndex];
    }

    if (this.deliverySchedule && this.deliverySchedule.actualOut) {
      this.message =
        'Delivery completed. Send tracking id feature not available.';
    } else if (!this.trackingInfo.eldGeofenceTracking) {
      this.message = 'Send tracking id feature not available.';
    }
  }

  // Generate email template and show it as a modal window
  async generateEmailTemplate(template) {
    // Get the tracking id & tracking url
    this.loading = true;
    let trackingdata = await WorkOrderTrackingService.getTrackingId(
      this.trackingInfo.driverId,
      this.deliverySchedule.location,
      this.trackingInfo.lat,
      this.trackingInfo.lng
    );
    this.loading = false;
    if (!trackingdata) return;

    let pulloutScheduleDate = convertAPIToFormat(
      this.wo.pulloutSchedule,
      DATE_TIME_EMAIL_FORMAT
    );
    let previousDeliveryScheduleDate = convertAPIToFormat(
      this.wo.schedules[this.deliveryIndex - 1]?.actualOut,
      DATE_TIME_EMAIL_FORMAT
    );

    // Prepare email template
    const props = {
      containerNumber: this.wo.containerNumber,
      pulloutLocation: this.wo.pulloutLocation,
      pulloutSchedule:
        pulloutScheduleDate !== 'Invalid date' ? pulloutScheduleDate : 'N/A',
      deliveryAddress: `${this.wo.deliveryLocationName}(${this.wo.deliveryLocation})-${this.wo.deliveryAddress}`,
      deliveryLocation: `${this.wo.deliveryLocationName}(${this.wo.deliveryLocation})`,
      previousDeliverySchedule:
        previousDeliveryScheduleDate !== 'Invalid date'
          ? previousDeliveryScheduleDate
          : 'N/A',
      orderNumber: this.wo.orderNumber,
      customer:
        this.wo.poNumber ||
        this.wo.referenceNumber ||
        this.wo.masterBillOfLading,
      billTo: this.wo.billToName,
      type: '',
      trackingId: trackingdata.id,
      trackingUrl: trackingdata.url
    };
    this.template = this.$t(`wo.email.${template}.body`, props);
    this.subject = this.$t(`wo.email.${template}.subject`, props);
    this.clietEmails = this.wo.sendTo;

    // Open email modal
    this.$bvModal.show(this.emailModal);
  }

  toggleSize() {
    this.$emit('size', this.getIcon());
  }

  getIcon() {
    if (this.size === 'm') {
      this.toggleIcon = 'fullscreen-exit';
      return 'xl';
    } else {
      this.toggleIcon = 'fullscreen';
      return 'm';
    }
  }
}

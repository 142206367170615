import { DATE_API_FORMAT } from './../../../utils/date.util';
import moment from 'moment-timezone';
import { JobPayments } from './../../../shared/models/driverIdModal.model';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import _ from 'lodash';

import { ExcelData } from './dispatch.model';
import { DispatchService } from '@/shared/services/dispatch/dispatch.service';
import { DispatchType } from '@/pages/Dispatch/models/dispatch.model';
import {
  DATE_VIEW_FULL_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_WITH_SECONDS,
  BS_DATEPICKER_FORMAT,
  convertAPIToFormat
} from '@/utils/date.util';

import { VanType, VanModels } from '@/pages/Dispatch/Van/models/van-models';

import { ToastHelper } from '@/utils/toast.util';

@Module({
  namespaced: true,
  name: 'dispatch'
})
export default class DispatchModule extends VuexModule {
  loading = false;
  dispatches = [];
  meta: any = null;
  dispatchType = '';
  category = '';
  filters = null;
  excelData: ExcelData | any = {};

  @Mutation
  public SET_LOADING_STATUS(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public SET_FILTERS(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public SET_DISPATCHES(payload) {
    this.dispatches = payload ? [...payload] : [];
  }

  @Mutation
  public SET_META(payload) {
    this.meta = { ...payload };
  }

  @Mutation
  public SET_DISPATCH_TYPE(payload) {
    this.dispatchType = payload;
  }

  @Mutation
  public SET_CATEGORY(payload) {
    this.category = payload;
  }

  @Mutation
  public SET_EXCEL_DATA(payload) {
    this.excelData = { ...payload };
  }

  @Action
  async search(payload: { dispatchType: DispatchType; category: string }) {
    const { dispatchType, category } = { ...payload };

    this.context.commit('SET_DISPATCH_TYPE', dispatchType);
    this.context.commit('SET_CATEGORY', dispatchType);
    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await DispatchService.searchRequest(
        dispatchType,
        category,
        this.filters
      );

      res?.data
        ?.filter(item => item?.schedules)
        .forEach(item => {
          item.jobPayments = item?.schedules
            .filter(schedule => schedule.jobUuid)
            .map(schedule => ({
              driverId: schedule.driverId,
              jobUuid: schedule.jobUuid,
              payments: [],
              shouldSkip: true
            }));

          if (
            dispatchType === DispatchType.EXP &&
            ['unavailable', 'delivery', 'return'].includes(category) &&
            item.returnFreeDay
          ) {
            item.returnFreeDayLeft = moment(
              item.returnFreeDay,
              DATE_API_FORMAT
            ).diff(moment(), 'd');
          }

          item.jobPayments = _.uniqBy(item.jobPayments, 'jobUuid');
        });

      let toDateToInput = [];
      let toDateElse = [];
      let toTimeToInput = [];
      let toTimeElse = [];
      if (
        dispatchType === DispatchType.EXP ||
        dispatchType === DispatchType.BOOK
      ) {
        if (category !== 'booking') {
          switch (category) {
            case 'unavailable':
              toDateToInput = ['streetTurn'];
              toDateElse = [
                'erd',
                'cutOff',
                'deliverySchedule',
                'pulloutSchedule',
                'returnFreeDay'
              ];
              toTimeToInput = [];
              toTimeElse = ['pulloutSchedule'];
              break;
            case 'available':
              toDateToInput = [
                'pulloutSchedule',
                'deliverySchedule',
                'streetTurn'
              ];
              toDateElse = [
                'erd',
                'cutOff',
                'loadConfirmation',
                'returnFreeDay'
              ];
              toTimeToInput = ['pulloutSchedule', 'deliverySchedule'];
              toTimeElse = [];
              break;
            case 'delivery':
              toDateToInput = ['deliverySchedule', 'pickupSchedule'];
              toDateElse = [
                'erd',
                'cutOff',
                'pulloutSchedule',
                'loadConfirmation',
                'streetTurn',
                'returnFreeDay'
              ];
              toTimeToInput = ['deliverySchedule', 'pickupSchedule'];
              toTimeElse = ['pulloutSchedule'];
              break;
            case 'return':
              toDateToInput = ['pickupSchedule', 'returnSchedule'];
              toDateElse = [
                'erd',
                'cutOff',
                'pulloutSchedule',
                'loadConfirmation',
                'streetTurn',
                'deliverySchedule',
                'returnFreeDay'
              ];
              toTimeToInput = ['pickupSchedule', 'returnSchedule'];
              toTimeElse = ['pulloutSchedule'];
              break;
          }

          res.data?.forEach((item, index: number) => {
            toTimeToInput.forEach(time => {
              if (item[time]) {
                item[time + 'Time'] = convertAPIToFormat(
                  item[time],
                  DATE_TIME_FORMAT
                );
              }
            });

            toTimeElse.forEach(time => {
              if (item[time]) {
                item[time + 'Time'] = convertAPIToFormat(
                  item[time],
                  DATE_TIME_FORMAT
                );
              }
            });

            toDateToInput.forEach(date => {
              if (item[date]) {
                item[date] = convertAPIToFormat(
                  item[date],
                  BS_DATEPICKER_FORMAT
                );
              }
            });

            toDateElse.forEach(date => {
              if (item[date]) {
                item[date] = convertAPIToFormat(
                  item[date],
                  DATE_VIEW_FULL_FORMAT
                );
              }
            });
          });
        } else {
          res.data.forEach(i => {
            i.containerBalance1 = i.containerTotal1 - i.containerUsed1;
            i.containerBalance2 = i.containerTotal2 - i.containerUsed2;
            i.containerBalance3 = i.containerTotal3 - i.containerUsed3;
          });
        }
      } else if (
        dispatchType == DispatchType.VAN ||
        dispatchType == DispatchType.IMP
      ) {
        if (res.data) {
          res.data.forEach(item => {
            if (category === 'available') {
              item.lastFreeDayCount = moment(
                item.lastFreeDay,
                DATE_API_FORMAT
              ).diff(moment().startOf('day'), 'days');
            }

            if (category === 'delivery' || category === 'return') {
              item.returnFreeDayCount = moment(
                item.returnFreeDay,
                DATE_API_FORMAT
              ).diff(moment().startOf('day'), 'days');
            }

            for (const key in item) {
              if (item[key]) {
                if (
                  key === 'pulloutSchedule' ||
                  key === 'deliverySchedule' ||
                  key === 'pickupSchedule' ||
                  key === 'returnSchedule' ||
                  key === 'lastFreeDay' ||
                  key === 'pulloutActualIn' ||
                  key === 'returnFreeDay'
                ) {
                  item[`${key}Time`] = convertAPIToFormat(
                    item[key],
                    DATE_TIME_FORMAT
                  );

                  item[key] = convertAPIToFormat(
                    item[key],
                    BS_DATEPICKER_FORMAT
                  );
                }
              }
            }
          });
        }
      }

      this.context.commit('SET_DISPATCHES', res.data);
      this.context.commit('SET_META', res.meta);
    } catch (err) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('Dispatch Error', err);
      ToastHelper.show('Dispatch', err.response.data?.error, 8000, 'danger');
    }

    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async excel(payload: { dispatchType: DispatchType; category: string }) {
    const { dispatchType, category } = { ...payload };

    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await DispatchService.getDispatchesExcelData(
        dispatchType,
        category,
        this.filters
      );
      this.context.commit('SET_EXCEL_DATA', res);
    } catch (e) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('Dispatches excel Error', e);
      ToastHelper.show('Dispatches excel', e.response.data?.error);
    }
    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async setFilters(filters) {
    this.context.commit('SET_FILTERS', filters);
  }

  @Action
  async save(payload: {
    dispatchType: DispatchType;
    category: string;
    orderNumber: string;
    data: object;
  }) {
    const { dispatchType, category, orderNumber, data } = { ...payload };
    this.context.commit('SET_LOADING_STATUS', true);
    try {
      await DispatchService.saveRequest(
        dispatchType,
        category,
        orderNumber,
        data
      );

      this.context.commit('SET_LOADING_STATUS', false);
      ToastHelper.show(
        `Dispatches ${dispatchType}`,
        'Save successful',
        5000,
        'success'
      );
    } catch (e) {
      console.log('Dispatches save Error', e);
      this.context.commit('SET_LOADING_STATUS', false);
      ToastHelper.show(`Dispatches ${dispatchType}`, e.message, 5000, 'danger');
    }
  }
}

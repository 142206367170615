











import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

import Highcharts from 'highcharts';
import hcMore from 'highcharts/highcharts-more';

hcMore(Highcharts);

@Component({
  components: {
    highcharts: Chart
  }
})
export default class TraceReplaySpeed extends Vue {
  @Prop() speed: number;

  @Watch('speed')
  onSpeedChanged(value: string) {
    if (this.chart) {
      const point = this.chart.series[0].points[0];
      point.update(value);
    }
  }

  chart = null;

  plotBands = [0, 60, 95, 140];

  chartOptions = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      margin: 0,
      height: 150,
      backgroundColor: 0x000000,
      style: {
        fontFamily: 'Lucida Grande',
        fontSize: '6px'
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        },
        {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        },
        {
          // default background
        },
        {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
        }
      ]
    },

    // the value axis
    yAxis: {
      min: 0,
      max: this.plotBands[3],

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 20,
      tickWidth: 1,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        rotation: 'auto'
      },
      title: {
        enabled: false
      },
      plotBands: [
        {
          from: 0,
          to: this.plotBands[1],
          color: '#55BF3B' // green
        },
        {
          from: this.plotBands[1],
          to: this.plotBands[2],
          color: '#DDDF0D' // yellow
        },
        {
          from: this.plotBands[2],
          to: this.plotBands[3],
          color: '#DF5353' // red
        }
      ]
    },

    series: [
      {
        data: [0],
        dataLabels: false,
        tooltip: {
          enabled: false
        }
      }
    ]
  };

  chartCallback(chart) {
    this.chart = chart;
    // if (!chart.renderer.forExport) {

    //   var point = chart.series[0].points[0];
    // }
  }
}

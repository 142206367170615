import { PermissionsService } from './../../../shared/services/permission/permission.service';
import { ToastHelper } from './../../../utils/toast.util';
import router from '@/router';
import { Auth } from 'aws-amplify';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { Permission } from './../../../shared/models/permissions.model';
import { AuthService } from './../../../shared/services/auth.service';
import { Role } from './../../../utils/permissions/permission.model';
import { CurrentUser, UserCreate } from './user.model';
import { hasOptestRole } from '@/utils/permissions/permissions.util';

@Module({
  namespaced: true,
  name: 'user'
})
export default class UserModule extends VuexModule {
  authorized = false;
  currentUser: CurrentUser | null = null;
  jwtToken = null;

  userGroup: Role = Role.Default;
  permissions: Permission[] = null;
  userGroups = [Role.Default]; // To support mulitiple groups/roles.

  authError = null;

  get isAuthorized() {
    return this.authorized;
  }

  get user(): CurrentUser | null {
    return this.currentUser;
  }

  @Mutation
  public SET_USER_PERMISSIONS(permissions: Permission[]) {
    this.permissions = permissions;
  }

  @Mutation
  public setCurrentUser(data: { user: CurrentUser; role: any, userGroups: any }): void {
    this.authorized = !!data;
    this.currentUser = data?.user;
    this.userGroup = data?.role;
    this.userGroups = data?.userGroups;
    if (hasOptestRole(this.userGroups))
      router.push({ name: 'account-management' }).catch();
    else if (!data) {
      router.push('/auth').catch();
      return;
    }
    else if (router.currentRoute.name === 'auth') {
      router.push('/').catch();
    }
  }

  @Mutation
  public setAuthError(error: any): void {
    console.log(error);
    this.authError = error;
  }

  @Action
  async getUserPermissions() {
    if (hasOptestRole(this.userGroups)) return;
    try {
      const permissions = await PermissionsService.getUserPermissions();

      this.context.commit('SET_USER_PERMISSIONS', permissions);
    } catch (err) {
      console.error('Getting permissions list Error', err);
      ToastHelper.show(
        'Permissions',
        err.response?.data?.error || err.message,
        5000,
        'danger'
      );
    }
  }

  @Action
  async login(email: string, password: string) {
    this.context.commit('setAuthError', null);

    try {
      await Auth.signIn(email, password);
      await this.fetchUser();
    } catch (error) {
      console.log('Login Error', error);
      if (error) {
        this.context.commit('setAuthError', error.message || error);
      }
    }
  }

  @Action
  async createUser(userCreate: UserCreate) {
    this.context.commit('setAuthError', null);

    try {
      await AuthService.createUser(userCreate);
    } catch (e) {
      console.log('Sign Up Error', e);
      this.context.commit('setAuthError', e.response.data?.error);
      throw new Error(e);
    }
  }

  @Action({ commit: 'setCurrentUser' })
  async fetchUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const expires =
        user.getSignInUserSession().getIdToken().payload.exp -
        Math.floor(new Date().getTime() / 1000);

      setTimeout(async () => {
        console.log('Renewing Token');
        await this.fetchUser();
      }, expires * 1000);

      const role = user.signInUserSession.accessToken.payload['cognito:groups']
        ? user.signInUserSession.accessToken.payload['cognito:groups'][0]
        : Role.Default;

      return { role, user: user.attributes, userGroups: user.signInUserSession.accessToken.payload['cognito:groups'] };
    } catch (err) {
      return null;
    }
  }

  @Action({ commit: 'setCurrentUser' })
  async logout() {
    localStorage.removeItem('params');
    await Auth.signOut();
    return null;
  }
}

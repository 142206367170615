






















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import UserMenu from './UserMenu.vue';
import LanguageMenu from './LanguageMenu.vue';

import { DivisionModule } from '@/store/index';
import MenuConfig from './config/menu.json';

@Component({
  components: { UserMenu, LanguageMenu, Fragment }
})
export default class Header extends Vue {
  divisionModule = DivisionModule;

  menuConfig = MenuConfig;

  moduleChunks = [
    { key: 'Portal', to: '' },
    { key: 'YMS', to: '/#/yms/daily-in-out', external: true },
    { key: 'WMS', to: '/master/company' },
    { key: 'FMS', to: '/master/account-code' },
    { key: 'CAM', to: '/master/account-code' }
  ];

  adminChunks = [
    { key: 'navigation.admin.basic', to: '/admin/basic' },
    { key: 'navigation.admin.adminAuth', to: '/admin/roles' },
    { key: 'navigation.admin.conf', to: '/admin/config' },
    { key: 'navigation.admin.early', to: '/admin/early-warning' },
    {
      key: 'navigation.admin.earlyModal',
      cb: () => {
        const routeData = this.$router.resolve({ name: 'early-warning-modal' });
        window.open(routeData.href, 'EarlyWindow', 'height=640,width=1340');
      }
    },
    { key: 'navigation.admin.userManagment', to: '/admin/user-managment' }
  ];

  ymsChunks = [
    { key: 'Daily in/out', to: '/yms/daily-in-out', name: 'daily-in-out' },
    { key: 'Inventory', to: '/yms/inventory', name: 'inventory' },
    { key: 'Damage report', to: '/yms/damage-report', name: 'damage' }
  ];

  accMgmtChunks = [
    {
      key: 'navigation.admin.accountManagement',
      to: '/account-managment'
    }
  ];

  get page() {
    return this.$route.meta.subPage;
  }

  get hideHeaedr() {
    return this.$route.meta.hideHeader === true;
  }

  get divisionsAbbrList() {
    return this.divisionModule.divisionsList.map(item => item.abbr);
  }

  get accountName() {
    try {
      if(!localStorage['params']) return;
      let params = JSON.parse(localStorage['params']);
      if (params && params.acctName && window.name === 'clientWebWin')
        return params.acctName;
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  openEarlyWarningModal() {
    console.log('asdsd');
  }

  updateDivision(division: string) {
    this.divisionModule.setDivision(division);
    window.location.reload();
  }
}

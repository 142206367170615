





















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { Fragment } from 'vue-fragment';
import DriversSearchModal from './DriversSearchModal.vue';
import APInputModal from './APInputModal.vue';
import { DropLiveType, LocationType } from '@/shared/models/woSchedule.model';
import moment from 'moment-timezone';
import {
  DATE_API_FORMAT,
  DATE_API_FORMAT_WITHOUT_TIME,
  BS_DATEPICKER_WITH_TIME
} from '@/utils/date.util';

@Component({ components: { DriversSearchModal, APInputModal, Fragment } })
export default class AssignDriversModal extends Vue {
  @Prop() wo;

  assignToAll = false;
  schedules = [];

  search = null;

  driverId = null;

  constructor() {
    super();
  }

  created() {
    this.schedules = JSON.parse(JSON.stringify(this.wo.schedules));
  }

  @Watch('schedules')
  onSchedulesChanges() {
    const deliveryIndex = this.schedules.findIndex(
      row => row.locationType === LocationType.DELIVERY
    );
    const pickUpIndex = this.schedules.findIndex(
      row => row.locationType === LocationType.PICKUP
    );

    if (this.wo.dropLive === DropLiveType.LIVE && this.schedules[pickUpIndex]) {
      this.schedules.splice(pickUpIndex, 1);
      this.$forceUpdate();
    } else if (
      this.wo.dropLive === DropLiveType.DROP &&
      !this.schedules[pickUpIndex]
    ) {
      this.schedules.splice(deliveryIndex + 1, 0, {
        locationType: LocationType.PICKUP,
        location: this.schedules[deliveryIndex].location,
        locationName: this.schedules[deliveryIndex].locationName,
        scheduleIn: this.schedules[deliveryIndex].scheduleIn,
        scheduleInTime: this.schedules[deliveryIndex].scheduleInTime,
        city: this.schedules[deliveryIndex].city,
        state: this.schedules[deliveryIndex].state
      });
      this.$forceUpdate();
    }
    if (deliveryIndex > 0) {
      this.updateDriverIdForDelivery(deliveryIndex);
    }
  }

  updateDriverIdForDelivery(deliveryIndex: number) {
    if (this.wo.dropLive === DropLiveType.LIVE) {
      this.schedules[deliveryIndex].driverId =
        this.schedules[deliveryIndex - 1].driverId;
      this.schedules[deliveryIndex].jobUuid =
        this.schedules[deliveryIndex - 1].jobUuid;
    } else if (this.wo.dropLive === DropLiveType.DROP) {
      this.schedules[deliveryIndex].driverId = null;
      this.schedules[deliveryIndex].jobUuid = null;
    }
  }
  toggleAll(checked) {
    this.schedules.forEach(schedule => {
      if (!this.isDriverAssignAllowed(schedule)) {
        schedule.assign = checked;
      }
    });
  }

  showSearchModal() {
    this.$bvModal.show('DriversSearchModalAssignDrivers');
  }

  hideSearchModal() {
    this.$bvModal.hide('DriversSearchModalAssignDrivers');
  }

  // showAP(id: string) {
  //   this.driverId = id;
  //   this.$bvModal.show('APInputModal');
  // }

  assign(id) {
    this.driverId = id;
    this.schedules.forEach(i => {
      if (i.locationType !== LocationType.RETURN) {
        if (i.assign) {
          i.driverId = this.driverId;
          delete i.assign;
        }
      }
    });
    this.assignToAll = false;
    this.hideSearchModal();
  }

  save() {
    this.$emit('select', this.schedules);
    this.hide();
  }

  hide() {
    this.$bvModal.hide('AssignDriversModal');
  }

  isDriverAssignAllowed(schedule: any): boolean {
    if (schedule.actualInTime && schedule.actualIn) {
      const data = moment(
        `${moment(schedule.actualIn, DATE_API_FORMAT).format(
          DATE_API_FORMAT_WITHOUT_TIME
        )} ${schedule.actualInTime}`,
        BS_DATEPICKER_WITH_TIME
      );
      if (data) {
        schedule.assign = false;
        return true;
      }
    }
    if (
      [LocationType.DELIVERY, LocationType.RETURN].includes(
        schedule.locationType
      )
    ) {
      schedule.assign = false;
      return true;
    } else if (
      [LocationType.PICKUP, 'STOP'].includes(schedule.locationType) &&
      this.wo.schedules[this.wo.schedules?.length - 1].uuid === schedule.uuid
    ) {
      schedule.assign = false;
      return true;
    } else return false;
  }
}

import './shared/components';
import './shared/directives';
import './styles/styles.scss';
import '@aws-amplify/ui-vue';
import HighchartsVue from 'highcharts-vue';
import VueI18n from 'vue-i18n';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import Amplify from 'aws-amplify';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueTimepicker from 'vue2-timepicker';

import VueMask from 'v-mask';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAY-HsXXPsBUqsbQLDFO8kpNWLANwH0E7k'
    // libraries: 'places'
  }
});

// @ts-ignore
import awsExports from './aws-exports';
import { i18n } from './i18n';
import router from './router';
import store from './store';
import './shared/filters/index';

Amplify.configure(awsExports);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.component('VueSlider', VueSlider);
Vue.use(HighchartsVue);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');














import { Component, Vue } from 'vue-property-decorator';

import { DATE_TIME_FORMAT } from '@/utils/date.util';
import moment from 'moment-timezone';

@Component({})
export default class DateYearPicker extends Vue {
  selectedYear = moment().year();
  minYear = 1970;
  maxYear = moment().year();

  constructor() {
    super();
  }

  get years() {
    const years = [];

    for (let i = this.maxYear; i >= this.minYear; i--) {
      years.push(i);
    }

    return years;
  }

  changeYear() {
    this.$emit('input', this.selectedYear);
  }
}
























































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import { BIconPlus, BIconTrash } from 'bootstrap-vue';
import {
  convertAPIToViewFullFormat,
  DATE_API_FORMAT,
  DATE_TIME_FORMAT
} from '@/utils/date.util';
import { FileService } from '@/shared/services/file.service';
import { ToastHelper } from './../../../utils/toast.util';
import { FileUploadService } from '@/shared/services/fileUpload.service';
import Loading from '@/shared/components/Loading.vue';
import moment from 'moment-timezone';

@Component({
  components: {
    BIconPlus,
    BIconTrash,
    Loading
  }
})
export default class FileTable extends Vue {
  @Prop() files: any[];
  @Prop() orderNumber: string;
  @Prop() type: string;

  fileUploadService = FileUploadService;
  loading = false;
  data: any[] = [];
  categories = [
    { value: 'DO', text: 'DO', disabled: false },
    { value: 'POD', text: 'POD', disabled: false },
    { value: 'POL', text: 'POL', disabled: false },
    { value: 'BL', text: 'BL', disabled: false },
    { value: 'ITPO', text: 'ITPO', disabled: false },
    { value: 'ITRTN', text: 'ITRTN', disabled: false },
    { value: 'CF', text: 'CF', disabled: false },
    { value: 'CFS', text: 'CFS', disabled: false },
    { value: 'EM', text: 'EM', disabled: false },
    { value: 'RTN', text: 'RTN', disabled: false },
    { value: 'SCR', text: 'SCR', disabled: false },
    { value: 'OTHER', text: 'OTHER', disabled: false }
  ];
  notRequiredCategories = ['em', 'other', 'rtn', 'scr'];
  DATE_TIME_FORMAT = DATE_TIME_FORMAT;
  fileLink;
  FileTable = 0;

  constructor() {
    super();
  }

  created() {
    this.getFilesList(this.type, this.orderNumber);
  }

  getFilesList(type, orderNumber) {
    FileService.getFiles(type, orderNumber)
      .then(res => {
        this.data = res.sort((a, b) => {
          return (
            this.categories.findIndex(x => x.value == a.category) -
            this.categories.findIndex(x => x.value == b.category)
          );
        });
        if (this.data.length !== 0) {
          this.data.forEach(dataElement => {
            if (dataElement.category !== 'OTHER') {
              this.categories.forEach(ctgrsElement => {
                if (dataElement.category === ctgrsElement.value) {
                  ctgrsElement.disabled = true;
                }
              });
            }
          });
        }
      })
      .catch(err => {
        ToastHelper.show('FileTable get files', err.message, 8000, 'danger');
      });
  }

  fieldChange(item, file) {
    item.filename = file.filename;
    item.file = file.file;
    item.tempS3FileName = file.tempS3FileName;
    item.tempS3PreSignedUrl = file.tempS3PreSignedUrl;
  }

  filterCategories() {
    this.data.forEach(dataElement => {
      if (dataElement.category !== 'OTHER') {
        this.categories.forEach(ctgrsElement => {
          if (dataElement.category === ctgrsElement.value) {
            ctgrsElement.disabled = true;
          }
        });
      }
    });
  }

  selectChange(selected, item) {
    item.category = selected;
    this.categories.forEach(ctgrsElement => {
      ctgrsElement.disabled = false;
    });
    this.filterCategories();
  }

  async uploadFilesToDB(item) {
    if (item?.file) {
      await this.fileUploadService.uploadFile(
        item.tempS3PreSignedUrl,
        item.file
      );
    }
  }

  add() {
    this.data.push({
      category: null,
      documentName: null,
      filename: null,
      tempS3PreSignedUrl: null
    });
  }

  remove(item) {
    if (item.filename !== '') {
      FileService.updateFile(this.type, this.orderNumber, item.id, {
        category: item.category,
        documentName: item.documentName,
        filename: null
      })
        .then(res => {
          item.filename = null;
        })
        .catch(err => {
          ToastHelper.show(
            'FileTable remove filename and body',
            err.message,
            8000,
            'danger'
          );
        });
    }
  }

  async uploadData(item, id = null) {
    if (id) {
      if (!item.tempS3FileName) {
        await FileService.updateFile(this.type, this.orderNumber, item.id, {
          category: item.category,
          documentName: item.documentName,
          filename: item.filename,
          selected: item.selected
        });
      } else {
        await FileService.updateFile(this.type, this.orderNumber, item.id, {
          category: item.category,
          documentName: item.documentName,
          filename: item.filename,
          tempS3FileName: item.tempS3FileName,
          selected: item.selected
        });
      }
    } else {
      return await FileService.createFile(this.type, this.orderNumber, {
        category: item.category,
        documentName: item.documentName,
        filename: item.filename,
        tempS3FileName: item.tempS3FileName,
        selected: item.selected
      });
    }
  }

  async uploadCK(checked, item) {
    try {
      this.loading = true;
      await this.uploadFilesToDB(item);
      delete item.file;

      await FileService.updateFile(this.type, this.orderNumber, item.id, {
        category: item.category,
        documentName: item.documentName,
        filename: item.filename,
        selected: checked
      });

      this.loading = false;

      ToastHelper.show(
        'FileTable update file',
        'Update successful',
        8000,
        'success'
      );
    } catch (err) {
      ToastHelper.show('FileTable update file', err.message, 8000, 'danger');
    }

    this.filterCategories();
  }

  async upload(item) {
    if (item.id) {
      try {
        this.loading = true;
        await this.uploadFilesToDB(item);
        delete item.file;

        await this.uploadData(item, item.id).then(() => {
          if (this.$route.path.includes('/driver/POD') && item.id === 'pod') {
            this.$emit('woUpdated');
          }
        });

        this.loading = false;

        ToastHelper.show(
          'FileTable update file',
          'Update successful',
          8000,
          'success'
        );
      } catch (err) {
        ToastHelper.show('FileTable update file', err.message, 8000, 'danger');
      }
    } else {
      try {
        this.loading = true;
        await this.uploadFilesToDB(item);
        delete item.file;

        const res = await this.uploadData(item);

        item.id = res.id;
        this.loading = false;

        ToastHelper.show(
          'FileTable create file',
          'Create successful',
          8000,
          'success'
        );
      } catch (err) {
        ToastHelper.show('FileTable create file', err.message, 8000, 'danger');
      }
    }

    this.filterCategories();
  }

  deleteEl(index, item) {
    if (item.id) {
      FileService.deleteFile(this.type, this.orderNumber, item.id, {
        category: item.category,
        documentName: item.documentName,
        filename: item.filename
      })
        .then(res => {
          this.data.splice(index, 1);
          this.filterCategories();
        })
        .catch(err => {
          ToastHelper.show(
            'FileTable delete file',
            err.message,
            8000,
            'danger'
          );
        });
    } else {
      this.data.splice(index, 1);
      this.data.forEach(dataElement => {
        if (dataElement.category !== 'OTHER') {
          this.categories.forEach(ctgrsElement => {
            if (dataElement.category === ctgrsElement.value) {
              ctgrsElement.disabled = true;
            }
          });
        }
      });
    }
  }

  getFileLink(item) {
    FileService.getFileLink(this.type, this.orderNumber, item.id)
      .then(res => {
        this.fileLink = res.url;
        window.open(this.fileLink);
      })
      .catch(err => {
        ToastHelper.show(
          'FileTable get file link',
          err.message,
          8000,
          'danger'
        );
      });
  }

  isUpdateDate(updatedDate, createdDate, isFile) {
    if (isFile) {
      const date = updatedDate ? updatedDate : createdDate;
      return convertAPIToViewFullFormat(date);
    }
  }

  isUpdateTime(updatedDate, createdDate, isFile) {
    if (isFile) {
      const time = updatedDate ? updatedDate : createdDate;
      return moment(time, DATE_API_FORMAT).format('HH:mm');
    }
  }

  isCFRowFile(category, file) {
    if (category == 'CF' && file) {
      return 'cf-uploaded--red';
    }

    if (category == 'CFS' && file) {
      return 'cf-uploaded--green';
    }
  }

  categoriesForDelete(item) {
    return this.notRequiredCategories.some(type => type === item.id);
  }
}


























































































































































































































































































































import moment from 'moment-timezone';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AccountPayable } from '../../../../shared/models/wo.model';
import { BIconPlusCircle, BIconDashCircle } from 'bootstrap-vue';
import { DATE_API_FORMAT } from '@/utils/date.util';
import { MasterDataService } from '../../../../shared/services/mater-data/master-data.service';
import { CompanyService } from '../../../../shared/services/mater-data/company.service';
import { WorkOrderService } from '../../../../shared/services/wo/wo-import.service';
import { ToastHelper } from '@/utils/toast.util';
import {
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { WoType } from '@/shared/components/wo/models';
import { validationMixin } from 'vuelidate';
import { accPayablesValidations } from './models/bilingInfo-validation';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CurrencyFilter, formatCurrency } from '@/utils/currency';

import { ApArService } from '@/shared/services/wo/apar.service';

import { DivisionModule } from '@/store';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 10,
  allowLeadingZeroes: true
});

Component.registerHooks(['validations']);

@Component({
  components: {
    BIconPlusCircle,
    BIconDashCircle
  },
  mixins: [validationMixin]
})
export default class AccountPayables extends Vue {
  @Prop({ default: [] }) accountPayables: AccountPayable[];
  @Prop({}) updateAP: any;
  @Prop() woType!: WoType;
  @Prop() orderNumber: string;
  @Prop() canDeleteAPAR: boolean;
  @Prop({ default: false }) editMode: boolean;
  @Prop() successMsg: string;
  @Prop() woLabel: string;
  @Prop({ default: true }) showQuickBooks: boolean;

  @Watch('accountPayables', { deep: true })
  onAccountPayablesChange() {
    this.data = JSON.parse(JSON.stringify(this.accountPayables));
    this.$forceUpdate();
  }

  @Watch('updateAP', { deep: true })
  updPayablesChange(data) {
    this.data = data;
    this.$forceUpdate();
  }

  sendToQBLoading = false;

  masterDataService = MasterDataService;
  companyService = CompanyService;
  data: AccountPayable[] = [];

  currencyMask = currencyMask;

  total = 0;

  typeOptions = [
    { text: 'Driver', value: 'Driver' },
    { text: 'Vendor', value: 'Vender' }
  ];

  constructor() {
    super();
  }

  created() {
    this.data = JSON.parse(JSON.stringify(this.accountPayables));
    this.data.forEach(i => {
      i.amount = CurrencyFilter.format(i.amount as number).split('$')[1];
      i.disabled = i.invoiceAmount && i.invoiceAmount === i.paidAmount;
    });
  }

  add() {
    this.data.push({
      type: 'Vender',
      date: moment().format(DATE_API_FORMAT),
      amount: 0,
      name: '',
      driverName: '',
      remark: '',
      division: DivisionModule.division
    });
  }

  async getGLCodes(query: string) {
    const filter = TransformFiltersToJson([
      {
        search: query,
        field: 'name',
        condition: FilterConditions.BeginsWith
      },
      {
        search: '50000',
        field: 'id',
        condition: FilterConditions.GreaterOrEqual
      },
      {
        search: '59999',
        field: 'id',
        condition: FilterConditions.LessThenOrEqual
      }
    ]);
    const glCodes = await this.masterDataService.getGLCodes(filter);
    return glCodes.data;
  }

  selectVendorDriver(item, ap) {
    if (ap.type === 'Driver') {
      ap.driverCode = item ? item.id : '';
      ap.driverName = item ? `${item.firstName} ${item.lastName}` : '';
    } else {
      ap.driverCode = item ? item.id : '';
      ap.driverName = item ? item.name : '';
    }
    this.$forceUpdate();
  }

  async remove(index) {
    if (!this.data[index].id) {
      this.data.splice(index, 1);
      return;
    }

    const res = await ApArService.deleteAP(
      this.woType,
      this.orderNumber,
      this.data[index].id
    );

    if (res) {
      return;
    }

    this.data.splice(index, 1);
  }

  validateState(item) {
    const { $dirty, $error } = item;
    return $dirty ? !$error : null;
  }

  validations() {
    return {
      data: accPayablesValidations
    };
  }

  isDisabledRemoveBtn(row) {
    if (
      !this.canDeleteAPAR ||
      !row.qbId ||
      row.paidAmount == 0 ||
      row.paidAmount === null
    ) {
      return false;
    }
    return true;
  }

  isCheckBoxDisabled(item) {
    if (
      (item.type === 'Vender' && !item.invoiceNumber) ||
      (item.invoiceAmount && item.invoiceAmount === item.paidAmount) ||
      moment() > moment(item.expirationDate, DATE_API_FORMAT) ||
      item.dummyCode
    ) {
      return true;
    }

    return false;
  }

  async save(beforeSendToQB = false) {
    this.$v.data.$touch();
    if (this.$v.data.$anyError) {
      return;
    }

    const data = this.data.map(i => ({
      ...i,
      amount: formatCurrency(i.amount)
    }));

    return WorkOrderService.updateAccountPayables(
      this.woType,
      this.orderNumber,
      data,
      this.woLabel,
      this.successMsg
    )
      .then(() => {
        if (beforeSendToQB) {
          return;
        }

        ToastHelper.show(
          this.woLabel,
          `${this.woLabel} ${this.orderNumber} ${this.successMsg}`,
          8000,
          'success'
        );

        this.$emit('update');
      })
      .catch(e => {
        ToastHelper.show(this.woLabel, e.message, 8000, 'danger');
      });
  }

  rateCFM(ap) {
    ToastHelper.show(
      'Warning',
      'This feature isn’t implemented yet',
      8000,
      'warning'
    );
  }

  async sendToQB() {
    this.sendToQBLoading = true;
    const rowIds = [];
    this.data.forEach(row => {
      if (row.ck) {
        rowIds.push(row.id);
      }
    });
    if (rowIds && rowIds.length) {
      await this.save(true);

      WorkOrderService.sendQB(
        this.woType,
        this.orderNumber,
        'account-payables',
        rowIds
      ).then(() => {
        this.$emit('update');
        this.sendToQBLoading = false;
      });
    } else {
      ToastHelper.show(
        'Warning',
        'Please select Account Payables rows by checkbox before send to QB',
        8000,
        'warning'
      );
      this.sendToQBLoading = false;
    }
  }

  onKeywordChanged(event) {
    if (event.target.value.length >= 10) {
      event.preventDefault();
    }
  }

  calculateTotal() {
    if (this.data.length) {
      return this.data
        .map(ap => ap.amount)
        .reduce((value, currentValue) => {
          return parseFloat(value as any) + formatCurrency(currentValue);
        }, 0);
    } else {
      return 0;
    }
  }
}

export const TraceMock = {
  "startLocation": "1 Branch Musaffah, Musaffah Industrial, Abu Dhabi, United Arab Emirates",
  "alarmData": [
    {
      "time": 20220518081744,
      "localTimeTxt": "2022-05-18 01:17:44",
      "alarmType": 8,
      "alarmName": "High RPM",
      "alarmValue": 4833,
      "strAlarmValue": "4833 rpm",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.35149,
      "longitude": 54.49918,
      "location": "No GPS Data"
    },
    {
      "time": 20220518082345,
      "localTimeTxt": "2022-05-18 01:23:45",
      "alarmType": 8,
      "alarmName": "High RPM",
      "alarmValue": 5297,
      "strAlarmValue": "5297 rpm",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.35174,
      "longitude": 54.50198,
      "location": "No GPS Data"
    },
    {
      "time": 20220518082945,
      "localTimeTxt": "2022-05-18 01:29:45",
      "alarmType": 8,
      "alarmName": "High RPM",
      "alarmValue": 4285,
      "strAlarmValue": "4285 rpm",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.39049,
      "longitude": 54.52406,
      "location": "No GPS Data"
    },
    {
      "time": 20220518083545,
      "localTimeTxt": "2022-05-18 01:35:45",
      "alarmType": 8,
      "alarmName": "High RPM",
      "alarmValue": 5079,
      "strAlarmValue": "5079 rpm",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.44023,
      "longitude": 54.58334,
      "location": "No GPS Data"
    },
    {
      "time": 20220518084746,
      "localTimeTxt": "2022-05-18 01:47:46",
      "alarmType": 8,
      "alarmName": "High RPM",
      "alarmValue": 3769,
      "strAlarmValue": "3769 rpm",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.62417,
      "longitude": 54.71934,
      "location": "No GPS Data"
    },
    {
      "time": 20220518085546,
      "localTimeTxt": "2022-05-18 01:55:46",
      "alarmType": 8,
      "alarmName": "High RPM",
      "alarmValue": 5226,
      "strAlarmValue": "5226 rpm",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.74021,
      "longitude": 54.82728,
      "location": "No GPS Data"
    },
    {
      "time": 20220518085146,
      "localTimeTxt": "2022-05-18 01:51:46",
      "alarmType": 8,
      "alarmName": "High RPM",
      "alarmValue": 3510,
      "strAlarmValue": "3510 rpm",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.68343,
      "longitude": 54.77128,
      "location": "No GPS Data"
    },
    {
      "time": 20220518085746,
      "localTimeTxt": "2022-05-18 01:57:46",
      "alarmType": 3,
      "alarmName": "Engine Temperature",
      "alarmValue": 107,
      "strAlarmValue": "224.6 F",
      "fenceId": null,
      "dataType": 1,
      "latitude": 24.77186,
      "longitude": 54.85024,
      "location": "No GPS Data"
    }
  ],
  "endTimeText": "2022-05-18 01:48:23",
  "traceData": [
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:53",
      "speed": 3.7804208,
      "direction": 85.118256,
      "lat": 24.351491666666668,
      "lng": 54.499181666666665,
      "dataPoint": true,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:53",
      "speed": 4.61435,
      "direction": 85.118256,
      "lat": 24.351494866666666,
      "lng": 54.499219133333334,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:53",
      "speed": 5.4482784,
      "direction": 85.118256,
      "lat": 24.351498066666664,
      "lng": 54.4992566,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:54",
      "speed": 6.2822075,
      "direction": 85.118256,
      "lat": 24.351501266666663,
      "lng": 54.49929406666667,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:54",
      "speed": 7.1161366,
      "direction": 85.118256,
      "lat": 24.35150446666666,
      "lng": 54.49933153333334,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:55",
      "speed": 7.950065,
      "direction": 85.118256,
      "lat": 24.35150766666666,
      "lng": 54.49936900000001,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:55",
      "speed": 8.783994,
      "direction": 85.118256,
      "lat": 24.351510866666658,
      "lng": 54.49940646666668,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:55",
      "speed": 9.617923,
      "direction": 85.118256,
      "lat": 24.351514066666656,
      "lng": 54.499443933333346,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:56",
      "speed": 10.451852,
      "direction": 85.118256,
      "lat": 24.351517266666654,
      "lng": 54.499481400000015,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:56",
      "speed": 11.285781,
      "direction": 85.118256,
      "lat": 24.351520466666653,
      "lng": 54.49951886666668,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:57",
      "speed": 12.11971,
      "direction": 85.118256,
      "lat": 24.35152366666665,
      "lng": 54.49955633333335,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:57",
      "speed": 12.953639,
      "direction": 85.118256,
      "lat": 24.35152686666665,
      "lng": 54.49959380000002,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:57",
      "speed": 13.787567,
      "direction": 85.118256,
      "lat": 24.351530066666648,
      "lng": 54.49963126666669,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:58",
      "speed": 14.621496,
      "direction": 85.118256,
      "lat": 24.351533266666646,
      "lng": 54.49966873333336,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:58",
      "speed": 15.455425,
      "direction": 85.118256,
      "lat": 24.351536466666644,
      "lng": 54.49970620000003,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:59",
      "speed": 16.289354,
      "direction": 85.118256,
      "lat": 24.351539666666643,
      "lng": 54.499743666666696,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:59",
      "speed": 17.123283,
      "direction": 85.118256,
      "lat": 24.35154286666664,
      "lng": 54.499781133333364,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:17:59",
      "speed": 17.957212,
      "direction": 85.118256,
      "lat": 24.35154606666664,
      "lng": 54.49981860000003,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:00",
      "speed": 18.791142,
      "direction": 85.118256,
      "lat": 24.351549266666638,
      "lng": 54.4998560666667,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:00",
      "speed": 19.62507,
      "direction": 85.118256,
      "lat": 24.351552466666636,
      "lng": 54.49989353333337,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:01",
      "speed": 20.458998,
      "direction": 85.118256,
      "lat": 24.351555666666634,
      "lng": 54.49993100000004,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:01",
      "speed": 21.292927,
      "direction": 85.118256,
      "lat": 24.351558866666632,
      "lng": 54.49996846666671,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:01",
      "speed": 22.126856,
      "direction": 85.118256,
      "lat": 24.35156206666663,
      "lng": 54.50000593333338,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:02",
      "speed": 22.960785,
      "direction": 85.118256,
      "lat": 24.35156526666663,
      "lng": 54.500043400000045,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:02",
      "speed": 23.794714,
      "direction": 85.118256,
      "lat": 24.351568466666627,
      "lng": 54.500080866666714,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:03",
      "speed": 24.628643,
      "direction": 85.118256,
      "lat": 24.351571666666626,
      "lng": 54.50011833333338,
      "dataPoint": false,
      "rpmDivd": 0.172
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:03",
      "speed": 24.628658,
      "direction": 80.036194,
      "lat": 24.35157166666667,
      "lng": 54.50011833333333,
      "dataPoint": true,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:03",
      "speed": 23.806362,
      "direction": 80.036194,
      "lat": 24.351575133333334,
      "lng": 54.500138066666665,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:03",
      "speed": 22.984064,
      "direction": 80.036194,
      "lat": 24.3515786,
      "lng": 54.5001578,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:04",
      "speed": 22.161768,
      "direction": 80.036194,
      "lat": 24.351582066666666,
      "lng": 54.50017753333333,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:04",
      "speed": 21.339472,
      "direction": 80.036194,
      "lat": 24.35158553333333,
      "lng": 54.50019726666666,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:05",
      "speed": 20.517176,
      "direction": 80.036194,
      "lat": 24.351588999999997,
      "lng": 54.50021699999999,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:05",
      "speed": 19.694878,
      "direction": 80.036194,
      "lat": 24.351592466666663,
      "lng": 54.500236733333324,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:05",
      "speed": 18.872581,
      "direction": 80.036194,
      "lat": 24.35159593333333,
      "lng": 54.500256466666656,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:06",
      "speed": 18.050285,
      "direction": 80.036194,
      "lat": 24.351599399999994,
      "lng": 54.50027619999999,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:06",
      "speed": 17.22799,
      "direction": 80.036194,
      "lat": 24.35160286666666,
      "lng": 54.50029593333332,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:07",
      "speed": 16.405691,
      "direction": 80.036194,
      "lat": 24.351606333333326,
      "lng": 54.50031566666665,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:07",
      "speed": 15.583395,
      "direction": 80.036194,
      "lat": 24.35160979999999,
      "lng": 54.50033539999998,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:07",
      "speed": 14.761098,
      "direction": 80.036194,
      "lat": 24.351613266666657,
      "lng": 54.500355133333315,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:08",
      "speed": 13.938802,
      "direction": 80.036194,
      "lat": 24.351616733333323,
      "lng": 54.50037486666665,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:08",
      "speed": 13.116505,
      "direction": 80.036194,
      "lat": 24.35162019999999,
      "lng": 54.50039459999998,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:09",
      "speed": 12.294209,
      "direction": 80.036194,
      "lat": 24.351623666666654,
      "lng": 54.50041433333331,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:09",
      "speed": 11.471911,
      "direction": 80.036194,
      "lat": 24.35162713333332,
      "lng": 54.50043406666664,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:09",
      "speed": 10.649615,
      "direction": 80.036194,
      "lat": 24.351630599999986,
      "lng": 54.500453799999974,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:10",
      "speed": 9.827318,
      "direction": 80.036194,
      "lat": 24.35163406666665,
      "lng": 54.500473533333306,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:10",
      "speed": 9.005022,
      "direction": 80.036194,
      "lat": 24.351637533333317,
      "lng": 54.50049326666664,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:11",
      "speed": 8.182725,
      "direction": 80.036194,
      "lat": 24.351640999999983,
      "lng": 54.50051299999997,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:11",
      "speed": 7.3604283,
      "direction": 80.036194,
      "lat": 24.35164446666665,
      "lng": 54.5005327333333,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:11",
      "speed": 6.5381317,
      "direction": 80.036194,
      "lat": 24.351647933333314,
      "lng": 54.50055246666663,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:12",
      "speed": 5.715835,
      "direction": 80.036194,
      "lat": 24.35165139999998,
      "lng": 54.500572199999965,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:12",
      "speed": 4.8935385,
      "direction": 80.036194,
      "lat": 24.351654866666646,
      "lng": 54.5005919333333,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:13",
      "speed": 4.071242,
      "direction": 80.036194,
      "lat": 24.35165833333331,
      "lng": 54.50061166666663,
      "dataPoint": false,
      "rpmDivd": 0.251
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:13",
      "speed": 4.0712223,
      "direction": 29.74488,
      "lat": 24.351658333333333,
      "lng": 54.500611666666664,
      "dataPoint": true,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:13",
      "speed": 4.874729,
      "direction": 29.74488,
      "lat": 24.351661133333334,
      "lng": 54.50061326666666,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:13",
      "speed": 5.6782355,
      "direction": 29.74488,
      "lat": 24.351663933333334,
      "lng": 54.50061486666666,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:14",
      "speed": 6.4817424,
      "direction": 29.74488,
      "lat": 24.351666733333335,
      "lng": 54.500616466666656,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:14",
      "speed": 7.2852488,
      "direction": 29.74488,
      "lat": 24.351669533333336,
      "lng": 54.500618066666654,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:15",
      "speed": 8.088756,
      "direction": 29.74488,
      "lat": 24.351672333333337,
      "lng": 54.50061966666665,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:15",
      "speed": 8.892262,
      "direction": 29.74488,
      "lat": 24.351675133333337,
      "lng": 54.50062126666665,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:15",
      "speed": 9.695769,
      "direction": 29.74488,
      "lat": 24.351677933333338,
      "lng": 54.500622866666646,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:16",
      "speed": 10.499275,
      "direction": 29.74488,
      "lat": 24.35168073333334,
      "lng": 54.50062446666664,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:16",
      "speed": 11.302782,
      "direction": 29.74488,
      "lat": 24.35168353333334,
      "lng": 54.50062606666664,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:17",
      "speed": 12.106289,
      "direction": 29.74488,
      "lat": 24.35168633333334,
      "lng": 54.50062766666664,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:17",
      "speed": 12.909796,
      "direction": 29.74488,
      "lat": 24.35168913333334,
      "lng": 54.500629266666635,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:17",
      "speed": 13.713303,
      "direction": 29.74488,
      "lat": 24.351691933333342,
      "lng": 54.50063086666663,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:18",
      "speed": 14.5168085,
      "direction": 29.74488,
      "lat": 24.351694733333343,
      "lng": 54.50063246666663,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:18",
      "speed": 15.320315,
      "direction": 29.74488,
      "lat": 24.351697533333343,
      "lng": 54.50063406666663,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:19",
      "speed": 16.123823,
      "direction": 29.74488,
      "lat": 24.351700333333344,
      "lng": 54.500635666666625,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:19",
      "speed": 16.927328,
      "direction": 29.74488,
      "lat": 24.351703133333345,
      "lng": 54.50063726666662,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:19",
      "speed": 17.730835,
      "direction": 29.74488,
      "lat": 24.351705933333346,
      "lng": 54.50063886666662,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:20",
      "speed": 18.534342,
      "direction": 29.74488,
      "lat": 24.351708733333346,
      "lng": 54.50064046666662,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:20",
      "speed": 19.337849,
      "direction": 29.74488,
      "lat": 24.351711533333347,
      "lng": 54.500642066666614,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:21",
      "speed": 20.141356,
      "direction": 29.74488,
      "lat": 24.351714333333348,
      "lng": 54.50064366666661,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:21",
      "speed": 20.944862,
      "direction": 29.74488,
      "lat": 24.35171713333335,
      "lng": 54.50064526666661,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:21",
      "speed": 21.74837,
      "direction": 29.74488,
      "lat": 24.35171993333335,
      "lng": 54.50064686666661,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:22",
      "speed": 22.551876,
      "direction": 29.74488,
      "lat": 24.35172273333335,
      "lng": 54.500648466666604,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:22",
      "speed": 23.355383,
      "direction": 29.74488,
      "lat": 24.35172553333335,
      "lng": 54.5006500666666,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:23",
      "speed": 24.15889,
      "direction": 29.74488,
      "lat": 24.35172833333335,
      "lng": 54.5006516666666,
      "dataPoint": false,
      "rpmDivd": 0.728
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:23",
      "speed": 24.158901,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:23",
      "speed": 23.309761,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:23",
      "speed": 22.46062,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:24",
      "speed": 21.61148,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:24",
      "speed": 20.76234,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:25",
      "speed": 19.9132,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:25",
      "speed": 19.064062,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:25",
      "speed": 18.214922,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:26",
      "speed": 17.365782,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:26",
      "speed": 16.516642,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:27",
      "speed": 15.667501,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:27",
      "speed": 14.818361,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:27",
      "speed": 13.969221,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:28",
      "speed": 13.120081,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:28",
      "speed": 12.270941,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:29",
      "speed": 11.421802,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:29",
      "speed": 10.572661,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:29",
      "speed": 9.723521,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:30",
      "speed": 8.874381,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:30",
      "speed": 8.025241,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:31",
      "speed": 7.176101,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:31",
      "speed": 6.326961,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:31",
      "speed": 5.4778214,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:32",
      "speed": 4.628681,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:32",
      "speed": 3.7795413,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:33",
      "speed": 2.9304013,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.668
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:33",
      "speed": 2.9303854,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:33",
      "speed": 3.8806343,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:33",
      "speed": 4.830883,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:34",
      "speed": 5.781132,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:34",
      "speed": 6.731381,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:35",
      "speed": 7.6816297,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:35",
      "speed": 8.631879,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:35",
      "speed": 9.582128,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:36",
      "speed": 10.532376,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:36",
      "speed": 11.482625,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:37",
      "speed": 12.432875,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:37",
      "speed": 13.383123,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:37",
      "speed": 14.333372,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:38",
      "speed": 15.283621,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:38",
      "speed": 16.23387,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:39",
      "speed": 17.184118,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:39",
      "speed": 18.134367,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:39",
      "speed": 19.084616,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:40",
      "speed": 20.034864,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:40",
      "speed": 20.985115,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:41",
      "speed": 21.935364,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:41",
      "speed": 22.885612,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:41",
      "speed": 23.835861,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:42",
      "speed": 24.78611,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:42",
      "speed": 25.736359,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:43",
      "speed": 26.686607,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.712
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:43",
      "speed": 26.68664,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:43",
      "speed": 26.80475,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:43",
      "speed": 26.92286,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:44",
      "speed": 27.04097,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:44",
      "speed": 27.15908,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:45",
      "speed": 27.27719,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:45",
      "speed": 27.3953,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:45",
      "speed": 27.51341,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:46",
      "speed": 27.63152,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:46",
      "speed": 27.74963,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:47",
      "speed": 27.86774,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:47",
      "speed": 27.98585,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:47",
      "speed": 28.10396,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:48",
      "speed": 28.22207,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:48",
      "speed": 28.34018,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:49",
      "speed": 28.45829,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:49",
      "speed": 28.576399,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:49",
      "speed": 28.69451,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:50",
      "speed": 28.81262,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:50",
      "speed": 28.930729,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:51",
      "speed": 29.04884,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:51",
      "speed": 29.16695,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:51",
      "speed": 29.285059,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:52",
      "speed": 29.40317,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:52",
      "speed": 29.52128,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:53",
      "speed": 29.639389,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.303
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:53",
      "speed": 29.639393,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:53",
      "speed": 29.255535,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:53",
      "speed": 28.871677,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:54",
      "speed": 28.48782,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:54",
      "speed": 28.103962,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:55",
      "speed": 27.720104,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:55",
      "speed": 27.336246,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:55",
      "speed": 26.952389,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:56",
      "speed": 26.568531,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:56",
      "speed": 26.184673,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:57",
      "speed": 25.800816,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:57",
      "speed": 25.416958,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:57",
      "speed": 25.0331,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:58",
      "speed": 24.649242,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:58",
      "speed": 24.265385,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:59",
      "speed": 23.881527,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:59",
      "speed": 23.49767,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:18:59",
      "speed": 23.113811,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:00",
      "speed": 22.729954,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:00",
      "speed": 22.346094,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:01",
      "speed": 21.962236,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:01",
      "speed": 21.578379,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:01",
      "speed": 21.19452,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:02",
      "speed": 20.810663,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:02",
      "speed": 20.426805,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:03",
      "speed": 20.042948,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.65
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:03",
      "speed": 20.04294,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:03",
      "speed": 19.98299,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:03",
      "speed": 19.92304,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:04",
      "speed": 19.86309,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:04",
      "speed": 19.80314,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:05",
      "speed": 19.74319,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:05",
      "speed": 19.68324,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:05",
      "speed": 19.623291,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:06",
      "speed": 19.56334,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:06",
      "speed": 19.50339,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:07",
      "speed": 19.44344,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:07",
      "speed": 19.38349,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:07",
      "speed": 19.32354,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:08",
      "speed": 19.26359,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:08",
      "speed": 19.20364,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:09",
      "speed": 19.14369,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:09",
      "speed": 19.08374,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:09",
      "speed": 19.02379,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:10",
      "speed": 18.96384,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:10",
      "speed": 18.90389,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:11",
      "speed": 18.84394,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:11",
      "speed": 18.78399,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:11",
      "speed": 18.724041,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:12",
      "speed": 18.66409,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:12",
      "speed": 18.60414,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:13",
      "speed": 18.54419,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.394
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:13",
      "speed": 18.544193,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:13",
      "speed": 18.903893,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:13",
      "speed": 19.263592,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:14",
      "speed": 19.62329,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:14",
      "speed": 19.982988,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:15",
      "speed": 20.342688,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:15",
      "speed": 20.702387,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:15",
      "speed": 21.062086,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:16",
      "speed": 21.421783,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:16",
      "speed": 21.781483,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:17",
      "speed": 22.141182,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:17",
      "speed": 22.500881,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:17",
      "speed": 22.86058,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:18",
      "speed": 23.22028,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:18",
      "speed": 23.579977,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:19",
      "speed": 23.939676,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:19",
      "speed": 24.299376,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:19",
      "speed": 24.659075,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:20",
      "speed": 25.018774,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:20",
      "speed": 25.378471,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:21",
      "speed": 25.73817,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:21",
      "speed": 26.09787,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:21",
      "speed": 26.45757,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:22",
      "speed": 26.817268,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:22",
      "speed": 27.176966,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:23",
      "speed": 27.536665,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.031
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:23",
      "speed": 27.536674,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:23",
      "speed": 27.684313,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:23",
      "speed": 27.83195,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:24",
      "speed": 27.979588,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:24",
      "speed": 28.127226,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:25",
      "speed": 28.274864,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:25",
      "speed": 28.4225,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:25",
      "speed": 28.570139,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:26",
      "speed": 28.717777,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:26",
      "speed": 28.865414,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:27",
      "speed": 29.013052,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:27",
      "speed": 29.16069,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:27",
      "speed": 29.308329,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:28",
      "speed": 29.455965,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:28",
      "speed": 29.603603,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:29",
      "speed": 29.751242,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:29",
      "speed": 29.898878,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:29",
      "speed": 30.046516,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:30",
      "speed": 30.194155,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:30",
      "speed": 30.341793,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:31",
      "speed": 30.48943,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:31",
      "speed": 30.637068,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:31",
      "speed": 30.784706,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:32",
      "speed": 30.932343,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:32",
      "speed": 31.07998,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:33",
      "speed": 31.22762,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.211
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:33",
      "speed": 31.227617,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:33",
      "speed": 31.1963,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:33",
      "speed": 31.164984,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:34",
      "speed": 31.133667,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:34",
      "speed": 31.10235,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:35",
      "speed": 31.071033,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:35",
      "speed": 31.039717,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:35",
      "speed": 31.0084,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:36",
      "speed": 30.977083,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:36",
      "speed": 30.945766,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:37",
      "speed": 30.91445,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:37",
      "speed": 30.883135,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:37",
      "speed": 30.851818,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:38",
      "speed": 30.820501,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:38",
      "speed": 30.789185,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:39",
      "speed": 30.757868,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:39",
      "speed": 30.726551,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:39",
      "speed": 30.695234,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:40",
      "speed": 30.663918,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:40",
      "speed": 30.6326,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:41",
      "speed": 30.601284,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:41",
      "speed": 30.569967,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:41",
      "speed": 30.53865,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:42",
      "speed": 30.507334,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:42",
      "speed": 30.476017,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:43",
      "speed": 30.4447,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.41
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:43",
      "speed": 30.44469,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:43",
      "speed": 29.466703,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:43",
      "speed": 28.488714,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:44",
      "speed": 27.510727,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:44",
      "speed": 26.53274,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:45",
      "speed": 25.554752,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:45",
      "speed": 24.576763,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:45",
      "speed": 23.598776,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:46",
      "speed": 22.620789,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:46",
      "speed": 21.642801,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:47",
      "speed": 20.664812,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:47",
      "speed": 19.686825,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:47",
      "speed": 18.708838,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:48",
      "speed": 17.73085,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:48",
      "speed": 16.752861,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:49",
      "speed": 15.774874,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:49",
      "speed": 14.796886,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:49",
      "speed": 13.818898,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:50",
      "speed": 12.840911,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:50",
      "speed": 11.862923,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:51",
      "speed": 10.884935,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:51",
      "speed": 9.906947,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:51",
      "speed": 8.92896,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:52",
      "speed": 7.9509716,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:52",
      "speed": 6.972984,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:53",
      "speed": 5.994996,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.623
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:53",
      "speed": 5.994987,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:53",
      "speed": 6.3108425,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:53",
      "speed": 6.626698,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:54",
      "speed": 6.9425535,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:54",
      "speed": 7.258409,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:55",
      "speed": 7.574265,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:55",
      "speed": 7.8901205,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:55",
      "speed": 8.205976,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:56",
      "speed": 8.5218315,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:56",
      "speed": 8.837687,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:57",
      "speed": 9.1535425,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:57",
      "speed": 9.4693985,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:57",
      "speed": 9.785254,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:58",
      "speed": 10.1011095,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:58",
      "speed": 10.416965,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:59",
      "speed": 10.7328205,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:59",
      "speed": 11.048676,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:19:59",
      "speed": 11.3645315,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:00",
      "speed": 11.680387,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:00",
      "speed": 11.996243,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:01",
      "speed": 12.3120985,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:01",
      "speed": 12.627954,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:01",
      "speed": 12.9438095,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:02",
      "speed": 13.259665,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:02",
      "speed": 13.5755205,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:03",
      "speed": 13.891376,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:03",
      "speed": 13.891369,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:03",
      "speed": 14.426443,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:03",
      "speed": 14.961517,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:04",
      "speed": 15.4965925,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:04",
      "speed": 16.031666,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:05",
      "speed": 16.566742,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:05",
      "speed": 17.101816,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:05",
      "speed": 17.63689,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:06",
      "speed": 18.171965,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:06",
      "speed": 18.707039,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:07",
      "speed": 19.242113,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:07",
      "speed": 19.777187,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:07",
      "speed": 20.312262,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:08",
      "speed": 20.847336,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:08",
      "speed": 21.382412,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:09",
      "speed": 21.917486,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:09",
      "speed": 22.45256,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:09",
      "speed": 22.987635,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:10",
      "speed": 23.522709,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:10",
      "speed": 24.057783,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:11",
      "speed": 24.592857,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:11",
      "speed": 25.127932,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:11",
      "speed": 25.663006,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:12",
      "speed": 26.198082,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:12",
      "speed": 26.733156,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:13",
      "speed": 27.26823,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.36
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:13",
      "speed": 27.268244,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:13",
      "speed": 26.943441,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:13",
      "speed": 26.618637,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:14",
      "speed": 26.293835,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:14",
      "speed": 25.96903,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:15",
      "speed": 25.644228,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:15",
      "speed": 25.319424,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:15",
      "speed": 24.994621,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:16",
      "speed": 24.669817,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:16",
      "speed": 24.345015,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:17",
      "speed": 24.02021,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:17",
      "speed": 23.695408,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:17",
      "speed": 23.370604,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:18",
      "speed": 23.045801,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:18",
      "speed": 22.720997,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:19",
      "speed": 22.396194,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:19",
      "speed": 22.07139,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:19",
      "speed": 21.746588,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:20",
      "speed": 21.421783,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:20",
      "speed": 21.096981,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:21",
      "speed": 20.772177,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:21",
      "speed": 20.447374,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:21",
      "speed": 20.12257,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:22",
      "speed": 19.797768,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:22",
      "speed": 19.472963,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:23",
      "speed": 19.14816,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:23",
      "speed": 19.148167,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:23",
      "speed": 18.871683,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:23",
      "speed": 18.595198,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:24",
      "speed": 18.318714,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:24",
      "speed": 18.042229,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:25",
      "speed": 17.765745,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:25",
      "speed": 17.48926,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:25",
      "speed": 17.212776,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:26",
      "speed": 16.93629,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:26",
      "speed": 16.659807,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:27",
      "speed": 16.383322,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:27",
      "speed": 16.106838,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:27",
      "speed": 15.830354,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:28",
      "speed": 15.553869,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:28",
      "speed": 15.277385,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:29",
      "speed": 15.0009,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:29",
      "speed": 14.724416,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:29",
      "speed": 14.447931,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:30",
      "speed": 14.171447,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:30",
      "speed": 13.894962,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:31",
      "speed": 13.618478,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:31",
      "speed": 13.341993,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:31",
      "speed": 13.065509,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:32",
      "speed": 12.789024,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:32",
      "speed": 12.51254,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:33",
      "speed": 12.236055,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.31
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:33",
      "speed": 12.236037,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:33",
      "speed": 11.896023,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:33",
      "speed": 11.556008,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:34",
      "speed": 11.215994,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:34",
      "speed": 10.875979,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:35",
      "speed": 10.535965,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:35",
      "speed": 10.1959505,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:35",
      "speed": 9.855936,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:36",
      "speed": 9.515922,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:36",
      "speed": 9.175907,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:37",
      "speed": 8.835893,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:37",
      "speed": 8.495878,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:37",
      "speed": 8.155864,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:38",
      "speed": 7.8158493,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:38",
      "speed": 7.475835,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:39",
      "speed": 7.1358204,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:39",
      "speed": 6.795806,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:39",
      "speed": 6.4557915,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:40",
      "speed": 6.1157775,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:40",
      "speed": 5.775763,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:41",
      "speed": 5.4357486,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:41",
      "speed": 5.095734,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:41",
      "speed": 4.7557197,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:42",
      "speed": 4.415705,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:42",
      "speed": 4.0756907,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:43",
      "speed": 3.735676,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:43",
      "speed": 3.735682,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:43",
      "speed": 3.9638486,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:43",
      "speed": 4.192015,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:44",
      "speed": 4.420182,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:44",
      "speed": 4.648349,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:45",
      "speed": 4.8765154,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:45",
      "speed": 5.104682,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:45",
      "speed": 5.3328485,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:46",
      "speed": 5.561015,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:46",
      "speed": 5.789182,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:47",
      "speed": 6.017349,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:47",
      "speed": 6.2455153,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:47",
      "speed": 6.473682,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:48",
      "speed": 6.7018485,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:48",
      "speed": 6.9300156,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:49",
      "speed": 7.158182,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:49",
      "speed": 7.3863487,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:49",
      "speed": 7.6145153,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:50",
      "speed": 7.842682,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:50",
      "speed": 8.070848,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:51",
      "speed": 8.299015,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:51",
      "speed": 8.527182,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:51",
      "speed": 8.755348,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:52",
      "speed": 8.983516,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:52",
      "speed": 9.211682,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:53",
      "speed": 9.439849,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:53",
      "speed": 9.439867,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:53",
      "speed": 9.284176,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:53",
      "speed": 9.128485,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:54",
      "speed": 8.972794,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:54",
      "speed": 8.817102,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:55",
      "speed": 8.661411,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:55",
      "speed": 8.50572,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:55",
      "speed": 8.350029,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:56",
      "speed": 8.194338,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:56",
      "speed": 8.038647,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:57",
      "speed": 7.882956,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:57",
      "speed": 7.727265,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:57",
      "speed": 7.5715737,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:58",
      "speed": 7.4158826,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:58",
      "speed": 7.2601914,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:59",
      "speed": 7.1045003,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:59",
      "speed": 6.948809,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:20:59",
      "speed": 6.793118,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:00",
      "speed": 6.637427,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:00",
      "speed": 6.4817357,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:01",
      "speed": 6.3260446,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:01",
      "speed": 6.170354,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:01",
      "speed": 6.0146627,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:02",
      "speed": 5.8589716,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:02",
      "speed": 5.7032804,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:03",
      "speed": 5.5475893,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:03",
      "speed": 5.5476,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:03",
      "speed": 5.4572277,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:03",
      "speed": 5.366855,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:04",
      "speed": 5.276483,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:04",
      "speed": 5.186111,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:05",
      "speed": 5.095739,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:05",
      "speed": 5.0053663,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:05",
      "speed": 4.9149942,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:06",
      "speed": 4.824622,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:06",
      "speed": 4.7342496,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:07",
      "speed": 4.6438775,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:07",
      "speed": 4.5535054,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:07",
      "speed": 4.4631333,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:08",
      "speed": 4.372761,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:08",
      "speed": 4.2823887,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:09",
      "speed": 4.1920166,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:09",
      "speed": 4.101644,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:09",
      "speed": 4.011272,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:10",
      "speed": 3.9208999,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:10",
      "speed": 3.8305275,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:11",
      "speed": 3.7401555,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:11",
      "speed": 3.6497831,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:11",
      "speed": 3.5594108,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:12",
      "speed": 3.4690387,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:12",
      "speed": 3.3786664,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:13",
      "speed": 3.2882943,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:13",
      "speed": 3.288295,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:13",
      "speed": 3.942374,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:13",
      "speed": 4.5964527,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:14",
      "speed": 5.2505317,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:14",
      "speed": 5.9046106,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:15",
      "speed": 6.5586896,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:15",
      "speed": 7.2127686,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:15",
      "speed": 7.866847,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:16",
      "speed": 8.520926,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:16",
      "speed": 9.175005,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:17",
      "speed": 9.829083,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:17",
      "speed": 10.483163,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:17",
      "speed": 11.137241,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:18",
      "speed": 11.791321,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:18",
      "speed": 12.445399,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:19",
      "speed": 13.099479,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:19",
      "speed": 13.753557,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:19",
      "speed": 14.407636,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:20",
      "speed": 15.061715,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:20",
      "speed": 15.715794,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:21",
      "speed": 16.369873,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:21",
      "speed": 17.023952,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:21",
      "speed": 17.67803,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:22",
      "speed": 18.33211,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:22",
      "speed": 18.986189,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:23",
      "speed": 19.640266,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.911
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:23",
      "speed": 19.640293,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:23",
      "speed": 19.1428,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:23",
      "speed": 18.645304,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:24",
      "speed": 18.14781,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:24",
      "speed": 17.650316,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:25",
      "speed": 17.15282,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:25",
      "speed": 16.655327,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:25",
      "speed": 16.157831,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:26",
      "speed": 15.660337,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:26",
      "speed": 15.162843,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:27",
      "speed": 14.665349,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:27",
      "speed": 14.167854,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:27",
      "speed": 13.67036,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:28",
      "speed": 13.172865,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:28",
      "speed": 12.675371,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:29",
      "speed": 12.177876,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:29",
      "speed": 11.680382,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:29",
      "speed": 11.182888,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:30",
      "speed": 10.685393,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:30",
      "speed": 10.187899,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:31",
      "speed": 9.690404,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:31",
      "speed": 9.19291,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:31",
      "speed": 8.6954155,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:32",
      "speed": 8.197921,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:32",
      "speed": 7.7004266,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:33",
      "speed": 7.202932,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.113
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:33",
      "speed": 7.202932,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:33",
      "speed": 7.8113775,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:33",
      "speed": 8.419823,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:34",
      "speed": 9.028269,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:34",
      "speed": 9.636714,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:35",
      "speed": 10.24516,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:35",
      "speed": 10.853605,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:35",
      "speed": 11.46205,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:36",
      "speed": 12.070497,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:36",
      "speed": 12.678942,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:37",
      "speed": 13.287388,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:37",
      "speed": 13.895833,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:37",
      "speed": 14.504278,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:38",
      "speed": 15.112724,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:38",
      "speed": 15.721169,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:39",
      "speed": 16.329615,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:39",
      "speed": 16.93806,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:39",
      "speed": 17.546507,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:40",
      "speed": 18.154951,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:40",
      "speed": 18.763397,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:41",
      "speed": 19.371843,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:41",
      "speed": 19.98029,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:41",
      "speed": 20.588734,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:42",
      "speed": 21.19718,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:42",
      "speed": 21.805626,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:43",
      "speed": 22.41407,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.811
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:43",
      "speed": 22.414093,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:43",
      "speed": 22.723684,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:43",
      "speed": 23.033276,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:44",
      "speed": 23.342867,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:44",
      "speed": 23.652458,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:45",
      "speed": 23.96205,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:45",
      "speed": 24.271639,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:45",
      "speed": 24.58123,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:46",
      "speed": 24.890821,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:46",
      "speed": 25.200413,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:47",
      "speed": 25.510004,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:47",
      "speed": 25.819595,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:47",
      "speed": 26.129187,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:48",
      "speed": 26.438778,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:48",
      "speed": 26.74837,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:49",
      "speed": 27.05796,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:49",
      "speed": 27.36755,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:49",
      "speed": 27.677141,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:50",
      "speed": 27.986732,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:50",
      "speed": 28.296324,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:51",
      "speed": 28.605915,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:51",
      "speed": 28.915506,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:51",
      "speed": 29.225098,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:52",
      "speed": 29.534689,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:52",
      "speed": 29.84428,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:53",
      "speed": 30.153872,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.129
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:53",
      "speed": 30.153889,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:53",
      "speed": 29.600025,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:53",
      "speed": 29.04616,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:54",
      "speed": 28.492296,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:54",
      "speed": 27.93843,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:55",
      "speed": 27.384567,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:55",
      "speed": 26.830702,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:55",
      "speed": 26.276838,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:56",
      "speed": 25.722973,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:56",
      "speed": 25.16911,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:57",
      "speed": 24.615244,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:57",
      "speed": 24.06138,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:57",
      "speed": 23.507515,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:58",
      "speed": 22.953651,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:58",
      "speed": 22.399786,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:59",
      "speed": 21.845922,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:59",
      "speed": 21.292057,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:21:59",
      "speed": 20.738194,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:00",
      "speed": 20.184328,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:00",
      "speed": 19.630465,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:01",
      "speed": 19.0766,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:01",
      "speed": 18.522736,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:01",
      "speed": 17.96887,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:02",
      "speed": 17.415007,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:02",
      "speed": 16.861141,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:03",
      "speed": 16.307278,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:03",
      "speed": 16.307259,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:03",
      "speed": 16.55869,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:03",
      "speed": 16.810122,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:04",
      "speed": 17.061552,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:04",
      "speed": 17.312983,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:05",
      "speed": 17.564415,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:05",
      "speed": 17.815845,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:05",
      "speed": 18.067276,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:06",
      "speed": 18.318708,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:06",
      "speed": 18.570139,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:07",
      "speed": 18.82157,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:07",
      "speed": 19.073,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:07",
      "speed": 19.324432,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:08",
      "speed": 19.575863,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:08",
      "speed": 19.827293,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:09",
      "speed": 20.078726,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:09",
      "speed": 20.330156,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:09",
      "speed": 20.581587,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:10",
      "speed": 20.83302,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:10",
      "speed": 21.08445,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:11",
      "speed": 21.33588,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:11",
      "speed": 21.587313,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:11",
      "speed": 21.838743,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:12",
      "speed": 22.090174,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:12",
      "speed": 22.341606,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:13",
      "speed": 22.593037,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:13",
      "speed": 22.593046,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:13",
      "speed": 21.829803,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:13",
      "speed": 21.066563,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:14",
      "speed": 20.30332,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:14",
      "speed": 19.540077,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:15",
      "speed": 18.776834,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:15",
      "speed": 18.013594,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:15",
      "speed": 17.25035,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:16",
      "speed": 16.487108,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:16",
      "speed": 15.723866,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:17",
      "speed": 14.960624,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:17",
      "speed": 14.197382,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:17",
      "speed": 13.434139,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:18",
      "speed": 12.6708975,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:18",
      "speed": 11.907655,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:19",
      "speed": 11.144413,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:19",
      "speed": 10.38117,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:19",
      "speed": 9.6179285,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:20",
      "speed": 8.854686,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:20",
      "speed": 8.091444,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:21",
      "speed": 7.328202,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:21",
      "speed": 6.5649595,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:21",
      "speed": 5.8017173,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:22",
      "speed": 5.038475,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:22",
      "speed": 4.275233,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:23",
      "speed": 3.5119905,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.054
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:23",
      "speed": 3.5119886,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:23",
      "speed": 4.6555085,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:23",
      "speed": 5.799029,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:24",
      "speed": 6.9425488,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:24",
      "speed": 8.086069,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:25",
      "speed": 9.2295885,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:25",
      "speed": 10.373109,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:25",
      "speed": 11.516628,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:26",
      "speed": 12.660149,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:26",
      "speed": 13.803669,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:27",
      "speed": 14.947188,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:27",
      "speed": 16.090708,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:27",
      "speed": 17.234228,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:28",
      "speed": 18.377748,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:28",
      "speed": 19.521269,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:29",
      "speed": 20.66479,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:29",
      "speed": 21.80831,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:29",
      "speed": 22.951828,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:30",
      "speed": 24.095348,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:30",
      "speed": 25.238869,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:31",
      "speed": 26.38239,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:31",
      "speed": 27.52591,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:31",
      "speed": 28.669428,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:32",
      "speed": 29.812948,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:32",
      "speed": 30.956469,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:33",
      "speed": 32.099987,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 1.24
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:33",
      "speed": 32.10002,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:33",
      "speed": 31.556894,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:33",
      "speed": 31.013765,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:34",
      "speed": 30.470638,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:34",
      "speed": 29.92751,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:35",
      "speed": 29.384382,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:35",
      "speed": 28.841255,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:35",
      "speed": 28.298126,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:36",
      "speed": 27.755,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:36",
      "speed": 27.211872,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:37",
      "speed": 26.668743,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:37",
      "speed": 26.125616,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:37",
      "speed": 25.582487,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:38",
      "speed": 25.03936,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:38",
      "speed": 24.496233,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:39",
      "speed": 23.953104,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:39",
      "speed": 23.409977,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:39",
      "speed": 22.86685,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:40",
      "speed": 22.32372,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:40",
      "speed": 21.780594,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:41",
      "speed": 21.237465,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:41",
      "speed": 20.694338,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:41",
      "speed": 20.15121,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:42",
      "speed": 19.608082,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:42",
      "speed": 19.064955,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:43",
      "speed": 18.521828,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.617
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:43",
      "speed": 18.521826,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:43",
      "speed": 18.04491,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:43",
      "speed": 17.567997,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:44",
      "speed": 17.091082,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:44",
      "speed": 16.614168,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:45",
      "speed": 16.137253,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:45",
      "speed": 15.660339,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:45",
      "speed": 15.183425,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:46",
      "speed": 14.706511,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:46",
      "speed": 14.229596,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:47",
      "speed": 13.752682,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:47",
      "speed": 13.275767,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:47",
      "speed": 12.798853,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:48",
      "speed": 12.321938,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:48",
      "speed": 11.845023,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:49",
      "speed": 11.368109,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:49",
      "speed": 10.891194,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:49",
      "speed": 10.41428,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:50",
      "speed": 9.937366,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:50",
      "speed": 9.460451,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:51",
      "speed": 8.983537,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:51",
      "speed": 8.506622,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:51",
      "speed": 8.029708,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:52",
      "speed": 7.5527935,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:52",
      "speed": 7.075879,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:53",
      "speed": 6.5989647,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.52
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:53",
      "speed": 6.5989594,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:53",
      "speed": 6.620434,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:53",
      "speed": 6.641908,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:54",
      "speed": 6.663383,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:54",
      "speed": 6.6848574,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:55",
      "speed": 6.7063317,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:55",
      "speed": 6.727806,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:55",
      "speed": 6.7492805,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:56",
      "speed": 6.770755,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:56",
      "speed": 6.7922297,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:57",
      "speed": 6.813704,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:57",
      "speed": 6.8351784,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:57",
      "speed": 6.8566527,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:58",
      "speed": 6.878127,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:58",
      "speed": 6.8996015,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:59",
      "speed": 6.9210763,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:59",
      "speed": 6.9425507,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:22:59",
      "speed": 6.964025,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:00",
      "speed": 6.9854994,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:00",
      "speed": 7.0069737,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:01",
      "speed": 7.028448,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:01",
      "speed": 7.049923,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:01",
      "speed": 7.0713973,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:02",
      "speed": 7.0928717,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:02",
      "speed": 7.114346,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:03",
      "speed": 7.1358204,
      "direction": "NaN",
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": false,
      "rpmDivd": 0.316
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:03",
      "speed": 7.1358237,
      "direction": 292.61987,
      "lat": 24.351728333333334,
      "lng": 54.50065166666667,
      "dataPoint": true,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:03",
      "speed": 7.6315284,
      "direction": 292.61987,
      "lat": 24.351729666666667,
      "lng": 54.50064846666667,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:03",
      "speed": 8.127233,
      "direction": 292.61987,
      "lat": 24.351731,
      "lng": 54.500645266666666,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:04",
      "speed": 8.622937,
      "direction": 292.61987,
      "lat": 24.351732333333334,
      "lng": 54.500642066666664,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:04",
      "speed": 9.118642,
      "direction": 292.61987,
      "lat": 24.351733666666668,
      "lng": 54.50063886666666,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:05",
      "speed": 9.614346,
      "direction": 292.61987,
      "lat": 24.351735,
      "lng": 54.50063566666666,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:05",
      "speed": 10.11005,
      "direction": 292.61987,
      "lat": 24.351736333333335,
      "lng": 54.50063246666666,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:05",
      "speed": 10.605755,
      "direction": 292.61987,
      "lat": 24.35173766666667,
      "lng": 54.50062926666666,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:06",
      "speed": 11.1014595,
      "direction": 292.61987,
      "lat": 24.351739000000002,
      "lng": 54.500626066666655,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:06",
      "speed": 11.597164,
      "direction": 292.61987,
      "lat": 24.351740333333336,
      "lng": 54.50062286666665,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:07",
      "speed": 12.092868,
      "direction": 292.61987,
      "lat": 24.35174166666667,
      "lng": 54.50061966666665,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:07",
      "speed": 12.5885725,
      "direction": 292.61987,
      "lat": 24.351743000000003,
      "lng": 54.50061646666665,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:07",
      "speed": 13.084277,
      "direction": 292.61987,
      "lat": 24.351744333333336,
      "lng": 54.50061326666665,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:08",
      "speed": 13.579982,
      "direction": 292.61987,
      "lat": 24.35174566666667,
      "lng": 54.500610066666646,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:08",
      "speed": 14.0756855,
      "direction": 292.61987,
      "lat": 24.351747000000003,
      "lng": 54.500606866666644,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:09",
      "speed": 14.57139,
      "direction": 292.61987,
      "lat": 24.351748333333337,
      "lng": 54.50060366666664,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:09",
      "speed": 15.067095,
      "direction": 292.61987,
      "lat": 24.35174966666667,
      "lng": 54.50060046666664,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:09",
      "speed": 15.562799,
      "direction": 292.61987,
      "lat": 24.351751000000004,
      "lng": 54.50059726666664,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:10",
      "speed": 16.058504,
      "direction": 292.61987,
      "lat": 24.351752333333337,
      "lng": 54.500594066666636,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:10",
      "speed": 16.554209,
      "direction": 292.61987,
      "lat": 24.35175366666667,
      "lng": 54.500590866666634,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:11",
      "speed": 17.049913,
      "direction": 292.61987,
      "lat": 24.351755000000004,
      "lng": 54.50058766666663,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:11",
      "speed": 17.545616,
      "direction": 292.61987,
      "lat": 24.351756333333338,
      "lng": 54.50058446666663,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:11",
      "speed": 18.04132,
      "direction": 292.61987,
      "lat": 24.35175766666667,
      "lng": 54.50058126666663,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:12",
      "speed": 18.537025,
      "direction": 292.61987,
      "lat": 24.351759000000005,
      "lng": 54.50057806666663,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:12",
      "speed": 19.03273,
      "direction": 292.61987,
      "lat": 24.35176033333334,
      "lng": 54.500574866666625,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:13",
      "speed": 19.528435,
      "direction": 292.61987,
      "lat": 24.351761666666672,
      "lng": 54.50057166666662,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:13",
      "speed": 19.528446,
      "direction": 3.722431,
      "lat": 24.35176166666667,
      "lng": 54.500571666666666,
      "dataPoint": true,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:13",
      "speed": 19.357544,
      "direction": 3.722431,
      "lat": 24.351789333333336,
      "lng": 54.500573466666665,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:13",
      "speed": 19.186642,
      "direction": 3.722431,
      "lat": 24.351817000000004,
      "lng": 54.500575266666665,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:14",
      "speed": 19.01574,
      "direction": 3.722431,
      "lat": 24.351844666666672,
      "lng": 54.500577066666665,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:14",
      "speed": 18.844837,
      "direction": 3.722431,
      "lat": 24.35187233333334,
      "lng": 54.500578866666665,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:15",
      "speed": 18.673935,
      "direction": 3.722431,
      "lat": 24.351900000000008,
      "lng": 54.500580666666664,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:15",
      "speed": 18.503033,
      "direction": 3.722431,
      "lat": 24.351927666666676,
      "lng": 54.500582466666664,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:15",
      "speed": 18.33213,
      "direction": 3.722431,
      "lat": 24.351955333333343,
      "lng": 54.500584266666664,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:16",
      "speed": 18.161228,
      "direction": 3.722431,
      "lat": 24.35198300000001,
      "lng": 54.50058606666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:16",
      "speed": 17.990326,
      "direction": 3.722431,
      "lat": 24.35201066666668,
      "lng": 54.50058786666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:17",
      "speed": 17.819424,
      "direction": 3.722431,
      "lat": 24.352038333333347,
      "lng": 54.50058966666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:17",
      "speed": 17.648521,
      "direction": 3.722431,
      "lat": 24.352066000000015,
      "lng": 54.50059146666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:17",
      "speed": 17.47762,
      "direction": 3.722431,
      "lat": 24.352093666666683,
      "lng": 54.50059326666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:18",
      "speed": 17.306717,
      "direction": 3.722431,
      "lat": 24.35212133333335,
      "lng": 54.50059506666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:18",
      "speed": 17.135815,
      "direction": 3.722431,
      "lat": 24.35214900000002,
      "lng": 54.50059686666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:19",
      "speed": 16.964912,
      "direction": 3.722431,
      "lat": 24.352176666666686,
      "lng": 54.50059866666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:19",
      "speed": 16.79401,
      "direction": 3.722431,
      "lat": 24.352204333333354,
      "lng": 54.50060046666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:19",
      "speed": 16.623108,
      "direction": 3.722431,
      "lat": 24.352232000000022,
      "lng": 54.50060226666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:20",
      "speed": 16.452206,
      "direction": 3.722431,
      "lat": 24.35225966666669,
      "lng": 54.50060406666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:20",
      "speed": 16.281303,
      "direction": 3.722431,
      "lat": 24.352287333333358,
      "lng": 54.50060586666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:21",
      "speed": 16.110401,
      "direction": 3.722431,
      "lat": 24.352315000000026,
      "lng": 54.50060766666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:21",
      "speed": 15.9395,
      "direction": 3.722431,
      "lat": 24.352342666666694,
      "lng": 54.50060946666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:21",
      "speed": 15.768598,
      "direction": 3.722431,
      "lat": 24.35237033333336,
      "lng": 54.50061126666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:22",
      "speed": 15.597695,
      "direction": 3.722431,
      "lat": 24.35239800000003,
      "lng": 54.50061306666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:22",
      "speed": 15.426793,
      "direction": 3.722431,
      "lat": 24.352425666666697,
      "lng": 54.50061486666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:23",
      "speed": 15.255891,
      "direction": 3.722431,
      "lat": 24.352453333333365,
      "lng": 54.50061666666666,
      "dataPoint": false,
      "rpmDivd": 1.383
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:23",
      "speed": 15.255898,
      "direction": 81.393524,
      "lat": 24.352453333333333,
      "lng": 54.500616666666666,
      "dataPoint": true,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:23",
      "speed": 15.306901,
      "direction": 81.393524,
      "lat": 24.352457066666666,
      "lng": 54.500641333333334,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:23",
      "speed": 15.357903,
      "direction": 81.393524,
      "lat": 24.3524608,
      "lng": 54.500666,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:24",
      "speed": 15.408905,
      "direction": 81.393524,
      "lat": 24.352464533333332,
      "lng": 54.50069066666667,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:24",
      "speed": 15.459908,
      "direction": 81.393524,
      "lat": 24.352468266666666,
      "lng": 54.50071533333334,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:25",
      "speed": 15.51091,
      "direction": 81.393524,
      "lat": 24.352472,
      "lng": 54.50074000000001,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:25",
      "speed": 15.561912,
      "direction": 81.393524,
      "lat": 24.352475733333332,
      "lng": 54.500764666666676,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:25",
      "speed": 15.612914,
      "direction": 81.393524,
      "lat": 24.352479466666665,
      "lng": 54.500789333333344,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:26",
      "speed": 15.663917,
      "direction": 81.393524,
      "lat": 24.352483199999998,
      "lng": 54.50081400000001,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:26",
      "speed": 15.714918,
      "direction": 81.393524,
      "lat": 24.35248693333333,
      "lng": 54.50083866666668,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:27",
      "speed": 15.765921,
      "direction": 81.393524,
      "lat": 24.352490666666665,
      "lng": 54.50086333333335,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:27",
      "speed": 15.816923,
      "direction": 81.393524,
      "lat": 24.352494399999998,
      "lng": 54.50088800000002,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:27",
      "speed": 15.867925,
      "direction": 81.393524,
      "lat": 24.35249813333333,
      "lng": 54.500912666666686,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:28",
      "speed": 15.918927,
      "direction": 81.393524,
      "lat": 24.352501866666664,
      "lng": 54.500937333333354,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:28",
      "speed": 15.96993,
      "direction": 81.393524,
      "lat": 24.352505599999997,
      "lng": 54.50096200000002,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:29",
      "speed": 16.020931,
      "direction": 81.393524,
      "lat": 24.35250933333333,
      "lng": 54.50098666666669,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:29",
      "speed": 16.071934,
      "direction": 81.393524,
      "lat": 24.352513066666663,
      "lng": 54.50101133333336,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:29",
      "speed": 16.122936,
      "direction": 81.393524,
      "lat": 24.352516799999997,
      "lng": 54.50103600000003,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:30",
      "speed": 16.173939,
      "direction": 81.393524,
      "lat": 24.35252053333333,
      "lng": 54.501060666666696,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:30",
      "speed": 16.224941,
      "direction": 81.393524,
      "lat": 24.352524266666663,
      "lng": 54.501085333333364,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:31",
      "speed": 16.275944,
      "direction": 81.393524,
      "lat": 24.352527999999996,
      "lng": 54.50111000000003,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:31",
      "speed": 16.326944,
      "direction": 81.393524,
      "lat": 24.35253173333333,
      "lng": 54.5011346666667,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:31",
      "speed": 16.377947,
      "direction": 81.393524,
      "lat": 24.352535466666662,
      "lng": 54.50115933333337,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:32",
      "speed": 16.42895,
      "direction": 81.393524,
      "lat": 24.352539199999995,
      "lng": 54.50118400000004,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:32",
      "speed": 16.479952,
      "direction": 81.393524,
      "lat": 24.35254293333333,
      "lng": 54.501208666666706,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:33",
      "speed": 16.530954,
      "direction": 81.393524,
      "lat": 24.35254666666666,
      "lng": 54.501233333333374,
      "dataPoint": false,
      "rpmDivd": 0.477
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:33",
      "speed": 16.530952,
      "direction": 89.10014,
      "lat": 24.352546666666665,
      "lng": 54.50123333333333,
      "dataPoint": true,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:33",
      "speed": 16.190939,
      "direction": 89.10014,
      "lat": 24.352547066666666,
      "lng": 54.501258799999995,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:33",
      "speed": 15.850925,
      "direction": 89.10014,
      "lat": 24.352547466666667,
      "lng": 54.50128426666666,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:34",
      "speed": 15.510913,
      "direction": 89.10014,
      "lat": 24.35254786666667,
      "lng": 54.50130973333332,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:34",
      "speed": 15.170899,
      "direction": 89.10014,
      "lat": 24.35254826666667,
      "lng": 54.501335199999986,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:35",
      "speed": 14.830886,
      "direction": 89.10014,
      "lat": 24.35254866666667,
      "lng": 54.50136066666665,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:35",
      "speed": 14.490872,
      "direction": 89.10014,
      "lat": 24.352549066666672,
      "lng": 54.50138613333331,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:35",
      "speed": 14.150859,
      "direction": 89.10014,
      "lat": 24.352549466666673,
      "lng": 54.501411599999976,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:36",
      "speed": 13.810845,
      "direction": 89.10014,
      "lat": 24.352549866666674,
      "lng": 54.50143706666664,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:36",
      "speed": 13.470833,
      "direction": 89.10014,
      "lat": 24.352550266666675,
      "lng": 54.5014625333333,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:37",
      "speed": 13.130819,
      "direction": 89.10014,
      "lat": 24.352550666666676,
      "lng": 54.501487999999966,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:37",
      "speed": 12.790806,
      "direction": 89.10014,
      "lat": 24.352551066666678,
      "lng": 54.50151346666663,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:37",
      "speed": 12.450792,
      "direction": 89.10014,
      "lat": 24.35255146666668,
      "lng": 54.50153893333329,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:38",
      "speed": 12.110779,
      "direction": 89.10014,
      "lat": 24.35255186666668,
      "lng": 54.50156439999996,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:38",
      "speed": 11.770766,
      "direction": 89.10014,
      "lat": 24.35255226666668,
      "lng": 54.50158986666662,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:39",
      "speed": 11.430753,
      "direction": 89.10014,
      "lat": 24.352552666666682,
      "lng": 54.501615333333284,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:39",
      "speed": 11.090739,
      "direction": 89.10014,
      "lat": 24.352553066666683,
      "lng": 54.50164079999995,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:39",
      "speed": 10.750726,
      "direction": 89.10014,
      "lat": 24.352553466666684,
      "lng": 54.50166626666661,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:40",
      "speed": 10.410712,
      "direction": 89.10014,
      "lat": 24.352553866666685,
      "lng": 54.501691733333274,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:40",
      "speed": 10.070699,
      "direction": 89.10014,
      "lat": 24.352554266666687,
      "lng": 54.50171719999994,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:41",
      "speed": 9.730686,
      "direction": 89.10014,
      "lat": 24.352554666666688,
      "lng": 54.5017426666666,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:41",
      "speed": 9.390673,
      "direction": 89.10014,
      "lat": 24.35255506666669,
      "lng": 54.501768133333265,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:41",
      "speed": 9.050659,
      "direction": 89.10014,
      "lat": 24.35255546666669,
      "lng": 54.50179359999993,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:42",
      "speed": 8.710646,
      "direction": 89.10014,
      "lat": 24.35255586666669,
      "lng": 54.50181906666659,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:42",
      "speed": 8.370632,
      "direction": 89.10014,
      "lat": 24.352556266666692,
      "lng": 54.501844533333255,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:43",
      "speed": 8.030619,
      "direction": 89.10014,
      "lat": 24.352556666666693,
      "lng": 54.50186999999992,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:43",
      "speed": 8.030599,
      "direction": 172.52844,
      "lat": 24.35255666666667,
      "lng": 54.50187,
      "dataPoint": true,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:43",
      "speed": 8.146918,
      "direction": 172.52844,
      "lat": 24.352524133333336,
      "lng": 54.50187426666666,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:43",
      "speed": 8.263239,
      "direction": 172.52844,
      "lat": 24.352491600000004,
      "lng": 54.501878533333326,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:44",
      "speed": 8.379559,
      "direction": 172.52844,
      "lat": 24.352459066666672,
      "lng": 54.50188279999999,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:44",
      "speed": 8.495878,
      "direction": 172.52844,
      "lat": 24.35242653333334,
      "lng": 54.501887066666654,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:45",
      "speed": 8.612199,
      "direction": 172.52844,
      "lat": 24.352394000000007,
      "lng": 54.50189133333332,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:45",
      "speed": 8.7285185,
      "direction": 172.52844,
      "lat": 24.352361466666675,
      "lng": 54.50189559999998,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:45",
      "speed": 8.844839,
      "direction": 172.52844,
      "lat": 24.352328933333343,
      "lng": 54.50189986666665,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:46",
      "speed": 8.961159,
      "direction": 172.52844,
      "lat": 24.35229640000001,
      "lng": 54.50190413333331,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:46",
      "speed": 9.077478,
      "direction": 172.52844,
      "lat": 24.35226386666668,
      "lng": 54.50190839999998,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:47",
      "speed": 9.193799,
      "direction": 172.52844,
      "lat": 24.352231333333346,
      "lng": 54.50191266666664,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:47",
      "speed": 9.310119,
      "direction": 172.52844,
      "lat": 24.352198800000014,
      "lng": 54.501916933333305,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:47",
      "speed": 9.426438,
      "direction": 172.52844,
      "lat": 24.352166266666682,
      "lng": 54.50192119999997,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:48",
      "speed": 9.542759,
      "direction": 172.52844,
      "lat": 24.35213373333335,
      "lng": 54.501925466666634,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:48",
      "speed": 9.659079,
      "direction": 172.52844,
      "lat": 24.352101200000018,
      "lng": 54.5019297333333,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:49",
      "speed": 9.775398,
      "direction": 172.52844,
      "lat": 24.352068666666685,
      "lng": 54.50193399999996,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:49",
      "speed": 9.891719,
      "direction": 172.52844,
      "lat": 24.352036133333353,
      "lng": 54.50193826666663,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:49",
      "speed": 10.0080385,
      "direction": 172.52844,
      "lat": 24.35200360000002,
      "lng": 54.50194253333329,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:50",
      "speed": 10.124358,
      "direction": 172.52844,
      "lat": 24.35197106666669,
      "lng": 54.501946799999956,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:50",
      "speed": 10.240679,
      "direction": 172.52844,
      "lat": 24.351938533333357,
      "lng": 54.50195106666662,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:51",
      "speed": 10.356998,
      "direction": 172.52844,
      "lat": 24.351906000000024,
      "lng": 54.501955333333285,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:51",
      "speed": 10.473319,
      "direction": 172.52844,
      "lat": 24.351873466666692,
      "lng": 54.50195959999995,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:51",
      "speed": 10.589639,
      "direction": 172.52844,
      "lat": 24.35184093333336,
      "lng": 54.501963866666614,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:52",
      "speed": 10.705958,
      "direction": 172.52844,
      "lat": 24.351808400000028,
      "lng": 54.50196813333328,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:52",
      "speed": 10.822279,
      "direction": 172.52844,
      "lat": 24.351775866666696,
      "lng": 54.50197239999994,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:53",
      "speed": 10.938599,
      "direction": 172.52844,
      "lat": 24.351743333333363,
      "lng": 54.50197666666661,
      "dataPoint": false,
      "rpmDivd": 1.025
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:53",
      "speed": 10.938615,
      "direction": 176.1705,
      "lat": 24.351743333333335,
      "lng": 54.501976666666664,
      "dataPoint": true,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:53",
      "speed": 10.890297,
      "direction": 176.1705,
      "lat": 24.351710466666667,
      "lng": 54.50197886666666,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:53",
      "speed": 10.841979,
      "direction": 176.1705,
      "lat": 24.3516776,
      "lng": 54.50198106666666,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:54",
      "speed": 10.793661,
      "direction": 176.1705,
      "lat": 24.35164473333333,
      "lng": 54.501983266666656,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:54",
      "speed": 10.745344,
      "direction": 176.1705,
      "lat": 24.351611866666662,
      "lng": 54.50198546666665,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:55",
      "speed": 10.697026,
      "direction": 176.1705,
      "lat": 24.351578999999994,
      "lng": 54.50198766666665,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:55",
      "speed": 10.648708,
      "direction": 176.1705,
      "lat": 24.351546133333326,
      "lng": 54.50198986666665,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:55",
      "speed": 10.60039,
      "direction": 176.1705,
      "lat": 24.351513266666657,
      "lng": 54.501992066666645,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:56",
      "speed": 10.552073,
      "direction": 176.1705,
      "lat": 24.35148039999999,
      "lng": 54.50199426666664,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:56",
      "speed": 10.503755,
      "direction": 176.1705,
      "lat": 24.35144753333332,
      "lng": 54.50199646666664,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:57",
      "speed": 10.455437,
      "direction": 176.1705,
      "lat": 24.351414666666653,
      "lng": 54.50199866666664,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:57",
      "speed": 10.40712,
      "direction": 176.1705,
      "lat": 24.351381799999984,
      "lng": 54.502000866666634,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:57",
      "speed": 10.358802,
      "direction": 176.1705,
      "lat": 24.351348933333316,
      "lng": 54.50200306666663,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:58",
      "speed": 10.310484,
      "direction": 176.1705,
      "lat": 24.351316066666648,
      "lng": 54.50200526666663,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:58",
      "speed": 10.262166,
      "direction": 176.1705,
      "lat": 24.35128319999998,
      "lng": 54.502007466666626,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:59",
      "speed": 10.213848,
      "direction": 176.1705,
      "lat": 24.35125033333331,
      "lng": 54.50200966666662,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:59",
      "speed": 10.16553,
      "direction": 176.1705,
      "lat": 24.351217466666643,
      "lng": 54.50201186666662,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:23:59",
      "speed": 10.117212,
      "direction": 176.1705,
      "lat": 24.351184599999975,
      "lng": 54.50201406666662,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:00",
      "speed": 10.068894,
      "direction": 176.1705,
      "lat": 24.351151733333307,
      "lng": 54.502016266666615,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:00",
      "speed": 10.020577,
      "direction": 176.1705,
      "lat": 24.35111886666664,
      "lng": 54.50201846666661,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:01",
      "speed": 9.9722595,
      "direction": 176.1705,
      "lat": 24.35108599999997,
      "lng": 54.50202066666661,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:01",
      "speed": 9.923942,
      "direction": 176.1705,
      "lat": 24.351053133333302,
      "lng": 54.50202286666661,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:01",
      "speed": 9.875624,
      "direction": 176.1705,
      "lat": 24.351020266666634,
      "lng": 54.502025066666604,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:02",
      "speed": 9.827306,
      "direction": 176.1705,
      "lat": 24.350987399999966,
      "lng": 54.5020272666666,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:02",
      "speed": 9.778988,
      "direction": 176.1705,
      "lat": 24.350954533333297,
      "lng": 54.5020294666666,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:03",
      "speed": 9.73067,
      "direction": 176.1705,
      "lat": 24.35092166666663,
      "lng": 54.502031666666596,
      "dataPoint": false,
      "rpmDivd": 0.491
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:03",
      "speed": 9.730669,
      "direction": 169.97794,
      "lat": 24.350921666666668,
      "lng": 54.50203166666667,
      "dataPoint": true,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:03",
      "speed": 9.540082,
      "direction": 169.97794,
      "lat": 24.350890733333333,
      "lng": 54.50203713333333,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:03",
      "speed": 9.349496,
      "direction": 169.97794,
      "lat": 24.3508598,
      "lng": 54.502042599999996,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:04",
      "speed": 9.158909,
      "direction": 169.97794,
      "lat": 24.350828866666664,
      "lng": 54.50204806666666,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:04",
      "speed": 8.968323,
      "direction": 169.97794,
      "lat": 24.35079793333333,
      "lng": 54.502053533333324,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:05",
      "speed": 8.777736,
      "direction": 169.97794,
      "lat": 24.350766999999994,
      "lng": 54.50205899999999,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:05",
      "speed": 8.587149,
      "direction": 169.97794,
      "lat": 24.35073606666666,
      "lng": 54.50206446666665,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:05",
      "speed": 8.396563,
      "direction": 169.97794,
      "lat": 24.350705133333324,
      "lng": 54.50206993333332,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:06",
      "speed": 8.205976,
      "direction": 169.97794,
      "lat": 24.35067419999999,
      "lng": 54.50207539999998,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:06",
      "speed": 8.015389,
      "direction": 169.97794,
      "lat": 24.350643266666655,
      "lng": 54.502080866666645,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:07",
      "speed": 7.8248024,
      "direction": 169.97794,
      "lat": 24.35061233333332,
      "lng": 54.50208633333331,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:07",
      "speed": 7.634216,
      "direction": 169.97794,
      "lat": 24.350581399999985,
      "lng": 54.502091799999974,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:07",
      "speed": 7.443629,
      "direction": 169.97794,
      "lat": 24.35055046666665,
      "lng": 54.50209726666664,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:08",
      "speed": 7.253042,
      "direction": 169.97794,
      "lat": 24.350519533333316,
      "lng": 54.5021027333333,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:08",
      "speed": 7.0624557,
      "direction": 169.97794,
      "lat": 24.35048859999998,
      "lng": 54.50210819999997,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:09",
      "speed": 6.871869,
      "direction": 169.97794,
      "lat": 24.350457666666646,
      "lng": 54.50211366666663,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:09",
      "speed": 6.6812825,
      "direction": 169.97794,
      "lat": 24.35042673333331,
      "lng": 54.502119133333295,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:09",
      "speed": 6.4906955,
      "direction": 169.97794,
      "lat": 24.350395799999976,
      "lng": 54.50212459999996,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:10",
      "speed": 6.300109,
      "direction": 169.97794,
      "lat": 24.35036486666664,
      "lng": 54.50213006666662,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:10",
      "speed": 6.1095223,
      "direction": 169.97794,
      "lat": 24.350333933333307,
      "lng": 54.50213553333329,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:11",
      "speed": 5.918936,
      "direction": 169.97794,
      "lat": 24.350302999999972,
      "lng": 54.50214099999995,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:11",
      "speed": 5.728349,
      "direction": 169.97794,
      "lat": 24.350272066666637,
      "lng": 54.502146466666616,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:11",
      "speed": 5.537762,
      "direction": 169.97794,
      "lat": 24.350241133333302,
      "lng": 54.50215193333328,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:12",
      "speed": 5.3471756,
      "direction": 169.97794,
      "lat": 24.350210199999967,
      "lng": 54.502157399999945,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:12",
      "speed": 5.156589,
      "direction": 169.97794,
      "lat": 24.350179266666633,
      "lng": 54.50216286666661,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:13",
      "speed": 4.9660025,
      "direction": 169.97794,
      "lat": 24.350148333333298,
      "lng": 54.50216833333327,
      "dataPoint": false,
      "rpmDivd": 0.413
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:13",
      "speed": 4.9659967,
      "direction": 176.1171,
      "lat": 24.350148333333333,
      "lng": 54.50216833333333,
      "dataPoint": true,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:13",
      "speed": 5.898351,
      "direction": 176.1171,
      "lat": 24.3500894,
      "lng": 54.50217233333333,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:13",
      "speed": 6.8307056,
      "direction": 176.1171,
      "lat": 24.35003046666667,
      "lng": 54.502176333333324,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:14",
      "speed": 7.76306,
      "direction": 176.1171,
      "lat": 24.34997153333334,
      "lng": 54.50218033333332,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:14",
      "speed": 8.695415,
      "direction": 176.1171,
      "lat": 24.349912600000007,
      "lng": 54.50218433333332,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:15",
      "speed": 9.6277685,
      "direction": 176.1171,
      "lat": 24.349853666666675,
      "lng": 54.502188333333315,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:15",
      "speed": 10.560123,
      "direction": 176.1171,
      "lat": 24.349794733333344,
      "lng": 54.50219233333331,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:15",
      "speed": 11.492477,
      "direction": 176.1171,
      "lat": 24.349735800000012,
      "lng": 54.50219633333331,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:16",
      "speed": 12.424832,
      "direction": 176.1171,
      "lat": 24.34967686666668,
      "lng": 54.502200333333306,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:16",
      "speed": 13.357186,
      "direction": 176.1171,
      "lat": 24.34961793333335,
      "lng": 54.5022043333333,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:17",
      "speed": 14.289541,
      "direction": 176.1171,
      "lat": 24.349559000000017,
      "lng": 54.5022083333333,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:17",
      "speed": 15.221895,
      "direction": 176.1171,
      "lat": 24.349500066666685,
      "lng": 54.5022123333333,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:17",
      "speed": 16.15425,
      "direction": 176.1171,
      "lat": 24.349441133333354,
      "lng": 54.502216333333294,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:18",
      "speed": 17.086605,
      "direction": 176.1171,
      "lat": 24.349382200000022,
      "lng": 54.50222033333329,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:18",
      "speed": 18.018959,
      "direction": 176.1171,
      "lat": 24.34932326666669,
      "lng": 54.50222433333329,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:19",
      "speed": 18.951313,
      "direction": 176.1171,
      "lat": 24.34926433333336,
      "lng": 54.502228333333285,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:19",
      "speed": 19.883667,
      "direction": 176.1171,
      "lat": 24.349205400000027,
      "lng": 54.50223233333328,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:19",
      "speed": 20.816023,
      "direction": 176.1171,
      "lat": 24.349146466666696,
      "lng": 54.50223633333328,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:20",
      "speed": 21.748377,
      "direction": 176.1171,
      "lat": 24.349087533333364,
      "lng": 54.502240333333276,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:20",
      "speed": 22.68073,
      "direction": 176.1171,
      "lat": 24.349028600000032,
      "lng": 54.50224433333327,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:21",
      "speed": 23.613085,
      "direction": 176.1171,
      "lat": 24.3489696666667,
      "lng": 54.50224833333327,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:21",
      "speed": 24.54544,
      "direction": 176.1171,
      "lat": 24.34891073333337,
      "lng": 54.50225233333327,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:21",
      "speed": 25.477795,
      "direction": 176.1171,
      "lat": 24.348851800000038,
      "lng": 54.502256333333264,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:22",
      "speed": 26.410149,
      "direction": 176.1171,
      "lat": 24.348792866666706,
      "lng": 54.50226033333326,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:22",
      "speed": 27.342503,
      "direction": 176.1171,
      "lat": 24.348733933333374,
      "lng": 54.50226433333326,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:23",
      "speed": 28.274858,
      "direction": 176.1171,
      "lat": 24.348675000000043,
      "lng": 54.502268333333255,
      "dataPoint": false,
      "rpmDivd": 1.018
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:23",
      "speed": 28.274864,
      "direction": 177.13759,
      "lat": 24.348675,
      "lng": 54.50226833333333,
      "dataPoint": true,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:23",
      "speed": 28.242653,
      "direction": 177.13759,
      "lat": 24.348613666666665,
      "lng": 54.5022714,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:23",
      "speed": 28.21044,
      "direction": 177.13759,
      "lat": 24.34855233333333,
      "lng": 54.50227446666666,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:24",
      "speed": 28.178228,
      "direction": 177.13759,
      "lat": 24.348490999999996,
      "lng": 54.50227753333333,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:24",
      "speed": 28.146015,
      "direction": 177.13759,
      "lat": 24.34842966666666,
      "lng": 54.50228059999999,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:25",
      "speed": 28.113804,
      "direction": 177.13759,
      "lat": 24.348368333333326,
      "lng": 54.502283666666656,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:25",
      "speed": 28.08159,
      "direction": 177.13759,
      "lat": 24.34830699999999,
      "lng": 54.50228673333332,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:25",
      "speed": 28.04938,
      "direction": 177.13759,
      "lat": 24.348245666666656,
      "lng": 54.502289799999986,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:26",
      "speed": 28.017166,
      "direction": 177.13759,
      "lat": 24.34818433333332,
      "lng": 54.50229286666665,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:26",
      "speed": 27.984955,
      "direction": 177.13759,
      "lat": 24.348122999999987,
      "lng": 54.502295933333315,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:27",
      "speed": 27.952742,
      "direction": 177.13759,
      "lat": 24.348061666666652,
      "lng": 54.50229899999998,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:27",
      "speed": 27.92053,
      "direction": 177.13759,
      "lat": 24.348000333333317,
      "lng": 54.502302066666644,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:27",
      "speed": 27.888317,
      "direction": 177.13759,
      "lat": 24.347938999999982,
      "lng": 54.50230513333331,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:28",
      "speed": 27.856106,
      "direction": 177.13759,
      "lat": 24.347877666666648,
      "lng": 54.50230819999997,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:28",
      "speed": 27.823893,
      "direction": 177.13759,
      "lat": 24.347816333333313,
      "lng": 54.50231126666664,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:29",
      "speed": 27.791681,
      "direction": 177.13759,
      "lat": 24.347754999999978,
      "lng": 54.5023143333333,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:29",
      "speed": 27.759468,
      "direction": 177.13759,
      "lat": 24.347693666666643,
      "lng": 54.50231739999997,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:29",
      "speed": 27.727257,
      "direction": 177.13759,
      "lat": 24.34763233333331,
      "lng": 54.50232046666663,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:30",
      "speed": 27.695044,
      "direction": 177.13759,
      "lat": 24.347570999999974,
      "lng": 54.502323533333296,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:30",
      "speed": 27.662832,
      "direction": 177.13759,
      "lat": 24.34750966666664,
      "lng": 54.50232659999996,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:31",
      "speed": 27.63062,
      "direction": 177.13759,
      "lat": 24.347448333333304,
      "lng": 54.502329666666625,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:31",
      "speed": 27.598408,
      "direction": 177.13759,
      "lat": 24.34738699999997,
      "lng": 54.50233273333329,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:31",
      "speed": 27.566195,
      "direction": 177.13759,
      "lat": 24.347325666666634,
      "lng": 54.502335799999955,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:32",
      "speed": 27.533983,
      "direction": 177.13759,
      "lat": 24.3472643333333,
      "lng": 54.50233886666662,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:32",
      "speed": 27.50177,
      "direction": 177.13759,
      "lat": 24.347202999999965,
      "lng": 54.502341933333284,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:33",
      "speed": 27.469559,
      "direction": 177.13759,
      "lat": 24.34714166666663,
      "lng": 54.50234499999995,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:33",
      "speed": 27.469566,
      "direction": 178.5602,
      "lat": 24.347141666666666,
      "lng": 54.502345,
      "dataPoint": true,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:33",
      "speed": 27.65568,
      "direction": 178.5602,
      "lat": 24.347104533333333,
      "lng": 54.50234593333333,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:33",
      "speed": 27.841793,
      "direction": 178.5602,
      "lat": 24.3470674,
      "lng": 54.50234686666666,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:34",
      "speed": 28.027906,
      "direction": 178.5602,
      "lat": 24.347030266666668,
      "lng": 54.502347799999995,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:34",
      "speed": 28.21402,
      "direction": 178.5602,
      "lat": 24.346993133333335,
      "lng": 54.50234873333333,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:35",
      "speed": 28.400133,
      "direction": 178.5602,
      "lat": 24.346956000000002,
      "lng": 54.50234966666666,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:35",
      "speed": 28.586246,
      "direction": 178.5602,
      "lat": 24.34691886666667,
      "lng": 54.50235059999999,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:35",
      "speed": 28.77236,
      "direction": 178.5602,
      "lat": 24.346881733333337,
      "lng": 54.502351533333325,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:36",
      "speed": 28.958473,
      "direction": 178.5602,
      "lat": 24.346844600000004,
      "lng": 54.50235246666666,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:36",
      "speed": 29.144587,
      "direction": 178.5602,
      "lat": 24.34680746666667,
      "lng": 54.50235339999999,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:37",
      "speed": 29.3307,
      "direction": 178.5602,
      "lat": 24.34677033333334,
      "lng": 54.50235433333332,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:37",
      "speed": 29.516813,
      "direction": 178.5602,
      "lat": 24.346733200000006,
      "lng": 54.502355266666655,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:37",
      "speed": 29.702927,
      "direction": 178.5602,
      "lat": 24.346696066666674,
      "lng": 54.50235619999999,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:38",
      "speed": 29.88904,
      "direction": 178.5602,
      "lat": 24.34665893333334,
      "lng": 54.50235713333332,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:38",
      "speed": 30.075153,
      "direction": 178.5602,
      "lat": 24.34662180000001,
      "lng": 54.50235806666665,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:39",
      "speed": 30.261267,
      "direction": 178.5602,
      "lat": 24.346584666666676,
      "lng": 54.502358999999984,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:39",
      "speed": 30.44738,
      "direction": 178.5602,
      "lat": 24.346547533333343,
      "lng": 54.50235993333332,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:39",
      "speed": 30.633493,
      "direction": 178.5602,
      "lat": 24.34651040000001,
      "lng": 54.50236086666665,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:40",
      "speed": 30.819607,
      "direction": 178.5602,
      "lat": 24.346473266666678,
      "lng": 54.50236179999998,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:40",
      "speed": 31.00572,
      "direction": 178.5602,
      "lat": 24.346436133333345,
      "lng": 54.502362733333314,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:41",
      "speed": 31.191833,
      "direction": 178.5602,
      "lat": 24.346399000000012,
      "lng": 54.502363666666646,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:41",
      "speed": 31.377947,
      "direction": 178.5602,
      "lat": 24.34636186666668,
      "lng": 54.50236459999998,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:41",
      "speed": 31.56406,
      "direction": 178.5602,
      "lat": 24.346324733333347,
      "lng": 54.50236553333331,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:42",
      "speed": 31.750174,
      "direction": 178.5602,
      "lat": 24.346287600000014,
      "lng": 54.50236646666664,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:42",
      "speed": 31.936287,
      "direction": 178.5602,
      "lat": 24.34625046666668,
      "lng": 54.502367399999976,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:43",
      "speed": 32.1224,
      "direction": 178.5602,
      "lat": 24.34621333333335,
      "lng": 54.50236833333331,
      "dataPoint": false,
      "rpmDivd": 1.365
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:43",
      "speed": 32.122395,
      "direction": 203.1986,
      "lat": 24.346213333333335,
      "lng": 54.50236833333334,
      "dataPoint": true,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:43",
      "speed": 31.97923,
      "direction": 203.1986,
      "lat": 24.346211466666666,
      "lng": 54.502367533333334,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:43",
      "speed": 31.836067,
      "direction": 203.1986,
      "lat": 24.346209599999998,
      "lng": 54.50236673333333,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:44",
      "speed": 31.692905,
      "direction": 203.1986,
      "lat": 24.34620773333333,
      "lng": 54.50236593333333,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:44",
      "speed": 31.549742,
      "direction": 203.1986,
      "lat": 24.34620586666666,
      "lng": 54.50236513333333,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:45",
      "speed": 31.406578,
      "direction": 203.1986,
      "lat": 24.346203999999993,
      "lng": 54.502364333333325,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:45",
      "speed": 31.263414,
      "direction": 203.1986,
      "lat": 24.346202133333325,
      "lng": 54.50236353333332,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:45",
      "speed": 31.12025,
      "direction": 203.1986,
      "lat": 24.346200266666656,
      "lng": 54.50236273333332,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:46",
      "speed": 30.977087,
      "direction": 203.1986,
      "lat": 24.346198399999988,
      "lng": 54.50236193333332,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:46",
      "speed": 30.833925,
      "direction": 203.1986,
      "lat": 24.34619653333332,
      "lng": 54.502361133333316,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:47",
      "speed": 30.690762,
      "direction": 203.1986,
      "lat": 24.34619466666665,
      "lng": 54.502360333333314,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:47",
      "speed": 30.547598,
      "direction": 203.1986,
      "lat": 24.346192799999983,
      "lng": 54.50235953333331,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:47",
      "speed": 30.404434,
      "direction": 203.1986,
      "lat": 24.346190933333315,
      "lng": 54.50235873333331,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:48",
      "speed": 30.26127,
      "direction": 203.1986,
      "lat": 24.346189066666646,
      "lng": 54.50235793333331,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:48",
      "speed": 30.118109,
      "direction": 203.1986,
      "lat": 24.346187199999978,
      "lng": 54.502357133333305,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:49",
      "speed": 29.974945,
      "direction": 203.1986,
      "lat": 24.34618533333331,
      "lng": 54.5023563333333,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:49",
      "speed": 29.831781,
      "direction": 203.1986,
      "lat": 24.34618346666664,
      "lng": 54.5023555333333,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:49",
      "speed": 29.688618,
      "direction": 203.1986,
      "lat": 24.346181599999973,
      "lng": 54.5023547333333,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:50",
      "speed": 29.545454,
      "direction": 203.1986,
      "lat": 24.346179733333305,
      "lng": 54.502353933333296,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:50",
      "speed": 29.40229,
      "direction": 203.1986,
      "lat": 24.346177866666636,
      "lng": 54.502353133333294,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:51",
      "speed": 29.259129,
      "direction": 203.1986,
      "lat": 24.346175999999968,
      "lng": 54.50235233333329,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:51",
      "speed": 29.115965,
      "direction": 203.1986,
      "lat": 24.3461741333333,
      "lng": 54.50235153333329,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:51",
      "speed": 28.972801,
      "direction": 203.1986,
      "lat": 24.34617226666663,
      "lng": 54.50235073333329,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:52",
      "speed": 28.829638,
      "direction": 203.1986,
      "lat": 24.346170399999963,
      "lng": 54.502349933333285,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:52",
      "speed": 28.686474,
      "direction": 203.1986,
      "lat": 24.346168533333294,
      "lng": 54.50234913333328,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:53",
      "speed": 28.543312,
      "direction": 203.1986,
      "lat": 24.346166666666626,
      "lng": 54.50234833333328,
      "dataPoint": false,
      "rpmDivd": 1.12
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:53",
      "speed": 28.543295,
      "direction": 179.85233,
      "lat": 24.346166666666665,
      "lng": 54.50234833333333,
      "dataPoint": true,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:53",
      "speed": 28.384026,
      "direction": 179.85233,
      "lat": 24.346140799999997,
      "lng": 54.502348399999995,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:53",
      "speed": 28.224754,
      "direction": 179.85233,
      "lat": 24.34611493333333,
      "lng": 54.50234846666666,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:54",
      "speed": 28.065485,
      "direction": 179.85233,
      "lat": 24.34608906666666,
      "lng": 54.502348533333326,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:54",
      "speed": 27.906216,
      "direction": 179.85233,
      "lat": 24.346063199999993,
      "lng": 54.50234859999999,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:55",
      "speed": 27.746944,
      "direction": 179.85233,
      "lat": 24.346037333333324,
      "lng": 54.502348666666656,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:55",
      "speed": 27.587675,
      "direction": 179.85233,
      "lat": 24.346011466666656,
      "lng": 54.50234873333332,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:55",
      "speed": 27.428406,
      "direction": 179.85233,
      "lat": 24.345985599999988,
      "lng": 54.502348799999986,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:56",
      "speed": 27.269135,
      "direction": 179.85233,
      "lat": 24.34595973333332,
      "lng": 54.50234886666665,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:56",
      "speed": 27.109865,
      "direction": 179.85233,
      "lat": 24.345933866666652,
      "lng": 54.502348933333316,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:57",
      "speed": 26.950596,
      "direction": 179.85233,
      "lat": 24.345907999999984,
      "lng": 54.50234899999998,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:57",
      "speed": 26.791325,
      "direction": 179.85233,
      "lat": 24.345882133333316,
      "lng": 54.502349066666646,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:57",
      "speed": 26.632055,
      "direction": 179.85233,
      "lat": 24.345856266666647,
      "lng": 54.50234913333331,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:58",
      "speed": 26.472784,
      "direction": 179.85233,
      "lat": 24.34583039999998,
      "lng": 54.502349199999976,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:58",
      "speed": 26.313515,
      "direction": 179.85233,
      "lat": 24.34580453333331,
      "lng": 54.50234926666664,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:59",
      "speed": 26.154245,
      "direction": 179.85233,
      "lat": 24.345778666666643,
      "lng": 54.502349333333306,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:59",
      "speed": 25.994974,
      "direction": 179.85233,
      "lat": 24.345752799999975,
      "lng": 54.50234939999997,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:24:59",
      "speed": 25.835705,
      "direction": 179.85233,
      "lat": 24.345726933333307,
      "lng": 54.50234946666664,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:00",
      "speed": 25.676435,
      "direction": 179.85233,
      "lat": 24.34570106666664,
      "lng": 54.5023495333333,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:00",
      "speed": 25.517164,
      "direction": 179.85233,
      "lat": 24.34567519999997,
      "lng": 54.50234959999997,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:01",
      "speed": 25.357895,
      "direction": 179.85233,
      "lat": 24.345649333333302,
      "lng": 54.50234966666663,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:01",
      "speed": 25.198626,
      "direction": 179.85233,
      "lat": 24.345623466666634,
      "lng": 54.5023497333333,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:01",
      "speed": 25.039354,
      "direction": 179.85233,
      "lat": 24.345597599999966,
      "lng": 54.50234979999996,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:02",
      "speed": 24.880085,
      "direction": 179.85233,
      "lat": 24.345571733333298,
      "lng": 54.50234986666663,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:02",
      "speed": 24.720816,
      "direction": 179.85233,
      "lat": 24.34554586666663,
      "lng": 54.50234993333329,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:03",
      "speed": 24.561544,
      "direction": 179.85233,
      "lat": 24.34551999999996,
      "lng": 54.50234999999996,
      "dataPoint": false,
      "rpmDivd": 0.374
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:03",
      "speed": 24.561552,
      "direction": 76.077484,
      "lat": 24.34552,
      "lng": 54.50235,
      "dataPoint": true,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:03",
      "speed": 24.035425,
      "direction": 76.077484,
      "lat": 24.3455298,
      "lng": 54.502389533333336,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:03",
      "speed": 23.509298,
      "direction": 76.077484,
      "lat": 24.345539600000002,
      "lng": 54.50242906666667,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:04",
      "speed": 22.983171,
      "direction": 76.077484,
      "lat": 24.345549400000003,
      "lng": 54.50246860000001,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:04",
      "speed": 22.457045,
      "direction": 76.077484,
      "lat": 24.345559200000004,
      "lng": 54.50250813333334,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:05",
      "speed": 21.93092,
      "direction": 76.077484,
      "lat": 24.345569000000005,
      "lng": 54.50254766666668,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:05",
      "speed": 21.404793,
      "direction": 76.077484,
      "lat": 24.345578800000006,
      "lng": 54.502587200000015,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:05",
      "speed": 20.878666,
      "direction": 76.077484,
      "lat": 24.345588600000006,
      "lng": 54.50262673333335,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:06",
      "speed": 20.35254,
      "direction": 76.077484,
      "lat": 24.345598400000007,
      "lng": 54.50266626666669,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:06",
      "speed": 19.826412,
      "direction": 76.077484,
      "lat": 24.345608200000008,
      "lng": 54.50270580000002,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:07",
      "speed": 19.300285,
      "direction": 76.077484,
      "lat": 24.34561800000001,
      "lng": 54.50274533333336,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:07",
      "speed": 18.774158,
      "direction": 76.077484,
      "lat": 24.34562780000001,
      "lng": 54.502784866666694,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:07",
      "speed": 18.248032,
      "direction": 76.077484,
      "lat": 24.34563760000001,
      "lng": 54.50282440000003,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:08",
      "speed": 17.721905,
      "direction": 76.077484,
      "lat": 24.34564740000001,
      "lng": 54.502863933333366,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:08",
      "speed": 17.195778,
      "direction": 76.077484,
      "lat": 24.345657200000012,
      "lng": 54.5029034666667,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:09",
      "speed": 16.669653,
      "direction": 76.077484,
      "lat": 24.345667000000013,
      "lng": 54.50294300000004,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:09",
      "speed": 16.143526,
      "direction": 76.077484,
      "lat": 24.345676800000014,
      "lng": 54.50298253333337,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:09",
      "speed": 15.617398,
      "direction": 76.077484,
      "lat": 24.345686600000015,
      "lng": 54.50302206666671,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:10",
      "speed": 15.091272,
      "direction": 76.077484,
      "lat": 24.345696400000016,
      "lng": 54.503061600000045,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:10",
      "speed": 14.5651455,
      "direction": 76.077484,
      "lat": 24.345706200000016,
      "lng": 54.50310113333338,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:11",
      "speed": 14.039019,
      "direction": 76.077484,
      "lat": 24.345716000000017,
      "lng": 54.50314066666672,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:11",
      "speed": 13.512892,
      "direction": 76.077484,
      "lat": 24.345725800000018,
      "lng": 54.50318020000005,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:11",
      "speed": 12.986765,
      "direction": 76.077484,
      "lat": 24.34573560000002,
      "lng": 54.50321973333339,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:12",
      "speed": 12.460639,
      "direction": 76.077484,
      "lat": 24.34574540000002,
      "lng": 54.503259266666724,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:12",
      "speed": 11.934512,
      "direction": 76.077484,
      "lat": 24.34575520000002,
      "lng": 54.50329880000006,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:13",
      "speed": 11.408385,
      "direction": 76.077484,
      "lat": 24.34576500000002,
      "lng": 54.503338333333396,
      "dataPoint": false,
      "rpmDivd": 0.335
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:13",
      "speed": 11.40837,
      "direction": 88.45333,
      "lat": 24.345765,
      "lng": 54.50333833333333,
      "dataPoint": true,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:13",
      "speed": 11.300102,
      "direction": 88.45333,
      "lat": 24.34576686666667,
      "lng": 54.503407466666665,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:13",
      "speed": 11.191834,
      "direction": 88.45333,
      "lat": 24.345768733333337,
      "lng": 54.5034766,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:14",
      "speed": 11.083567,
      "direction": 88.45333,
      "lat": 24.345770600000005,
      "lng": 54.50354573333333,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:14",
      "speed": 10.975299,
      "direction": 88.45333,
      "lat": 24.345772466666673,
      "lng": 54.503614866666666,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:15",
      "speed": 10.867031,
      "direction": 88.45333,
      "lat": 24.345774333333342,
      "lng": 54.503684,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:15",
      "speed": 10.758763,
      "direction": 88.45333,
      "lat": 24.34577620000001,
      "lng": 54.50375313333333,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:15",
      "speed": 10.650496,
      "direction": 88.45333,
      "lat": 24.34577806666668,
      "lng": 54.50382226666667,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:16",
      "speed": 10.542228,
      "direction": 88.45333,
      "lat": 24.345779933333347,
      "lng": 54.5038914,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:16",
      "speed": 10.43396,
      "direction": 88.45333,
      "lat": 24.345781800000015,
      "lng": 54.503960533333334,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:17",
      "speed": 10.325692,
      "direction": 88.45333,
      "lat": 24.345783666666684,
      "lng": 54.50402966666667,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:17",
      "speed": 10.217424,
      "direction": 88.45333,
      "lat": 24.345785533333352,
      "lng": 54.5040988,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:17",
      "speed": 10.109157,
      "direction": 88.45333,
      "lat": 24.34578740000002,
      "lng": 54.504167933333335,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:18",
      "speed": 10.000889,
      "direction": 88.45333,
      "lat": 24.34578926666669,
      "lng": 54.50423706666667,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:18",
      "speed": 9.892621,
      "direction": 88.45333,
      "lat": 24.345791133333357,
      "lng": 54.5043062,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:19",
      "speed": 9.784353,
      "direction": 88.45333,
      "lat": 24.345793000000025,
      "lng": 54.504375333333336,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:19",
      "speed": 9.676085,
      "direction": 88.45333,
      "lat": 24.345794866666694,
      "lng": 54.50444446666667,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:19",
      "speed": 9.567818,
      "direction": 88.45333,
      "lat": 24.345796733333362,
      "lng": 54.5045136,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:20",
      "speed": 9.45955,
      "direction": 88.45333,
      "lat": 24.34579860000003,
      "lng": 54.504582733333336,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:20",
      "speed": 9.351282,
      "direction": 88.45333,
      "lat": 24.3458004666667,
      "lng": 54.50465186666667,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:21",
      "speed": 9.243014,
      "direction": 88.45333,
      "lat": 24.345802333333367,
      "lng": 54.504721,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:21",
      "speed": 9.134747,
      "direction": 88.45333,
      "lat": 24.345804200000035,
      "lng": 54.50479013333334,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:21",
      "speed": 9.026479,
      "direction": 88.45333,
      "lat": 24.345806066666704,
      "lng": 54.50485926666667,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:22",
      "speed": 8.918211,
      "direction": 88.45333,
      "lat": 24.345807933333372,
      "lng": 54.504928400000004,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:22",
      "speed": 8.809943,
      "direction": 88.45333,
      "lat": 24.34580980000004,
      "lng": 54.50499753333334,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:23",
      "speed": 8.701675,
      "direction": 88.45333,
      "lat": 24.34581166666671,
      "lng": 54.50506666666667,
      "dataPoint": false,
      "rpmDivd": 0.463
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:23",
      "speed": 8.701679,
      "direction": 88.24895,
      "lat": 24.345811666666666,
      "lng": 54.505066666666664,
      "dataPoint": true,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:23",
      "speed": 9.570503,
      "direction": 88.24895,
      "lat": 24.3458142,
      "lng": 54.50514953333333,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:23",
      "speed": 10.439328,
      "direction": 88.24895,
      "lat": 24.345816733333333,
      "lng": 54.5052324,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:24",
      "speed": 11.308152,
      "direction": 88.24895,
      "lat": 24.345819266666666,
      "lng": 54.50531526666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:24",
      "speed": 12.176977,
      "direction": 88.24895,
      "lat": 24.3458218,
      "lng": 54.50539813333333,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:25",
      "speed": 13.045801,
      "direction": 88.24895,
      "lat": 24.345824333333333,
      "lng": 54.505480999999996,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:25",
      "speed": 13.914626,
      "direction": 88.24895,
      "lat": 24.345826866666666,
      "lng": 54.50556386666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:25",
      "speed": 14.78345,
      "direction": 88.24895,
      "lat": 24.3458294,
      "lng": 54.50564673333333,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:26",
      "speed": 15.652275,
      "direction": 88.24895,
      "lat": 24.345831933333333,
      "lng": 54.505729599999995,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:26",
      "speed": 16.5211,
      "direction": 88.24895,
      "lat": 24.345834466666666,
      "lng": 54.50581246666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:27",
      "speed": 17.389923,
      "direction": 88.24895,
      "lat": 24.345837,
      "lng": 54.50589533333333,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:27",
      "speed": 18.258749,
      "direction": 88.24895,
      "lat": 24.345839533333333,
      "lng": 54.505978199999994,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:27",
      "speed": 19.127573,
      "direction": 88.24895,
      "lat": 24.345842066666666,
      "lng": 54.50606106666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:28",
      "speed": 19.996397,
      "direction": 88.24895,
      "lat": 24.3458446,
      "lng": 54.50614393333333,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:28",
      "speed": 20.865221,
      "direction": 88.24895,
      "lat": 24.345847133333333,
      "lng": 54.50622679999999,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:29",
      "speed": 21.734045,
      "direction": 88.24895,
      "lat": 24.345849666666666,
      "lng": 54.50630966666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:29",
      "speed": 22.60287,
      "direction": 88.24895,
      "lat": 24.3458522,
      "lng": 54.506392533333326,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:29",
      "speed": 23.471695,
      "direction": 88.24895,
      "lat": 24.345854733333333,
      "lng": 54.50647539999999,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:30",
      "speed": 24.340519,
      "direction": 88.24895,
      "lat": 24.345857266666666,
      "lng": 54.50655826666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:30",
      "speed": 25.209343,
      "direction": 88.24895,
      "lat": 24.3458598,
      "lng": 54.506641133333325,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:31",
      "speed": 26.078169,
      "direction": 88.24895,
      "lat": 24.345862333333333,
      "lng": 54.50672399999999,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:31",
      "speed": 26.946993,
      "direction": 88.24895,
      "lat": 24.345864866666666,
      "lng": 54.50680686666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:31",
      "speed": 27.815817,
      "direction": 88.24895,
      "lat": 24.3458674,
      "lng": 54.506889733333324,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:32",
      "speed": 28.68464,
      "direction": 88.24895,
      "lat": 24.345869933333333,
      "lng": 54.50697259999999,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:32",
      "speed": 29.553467,
      "direction": 88.24895,
      "lat": 24.345872466666666,
      "lng": 54.50705546666666,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:33",
      "speed": 30.42229,
      "direction": 88.24895,
      "lat": 24.345875,
      "lng": 54.50713833333332,
      "dataPoint": false,
      "rpmDivd": 1.442
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:33",
      "speed": 30.422321,
      "direction": 86.892426,
      "lat": 24.345875,
      "lng": 54.50713833333333,
      "dataPoint": true,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:33",
      "speed": 30.156574,
      "direction": 86.892426,
      "lat": 24.345877066666667,
      "lng": 54.5071764,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:33",
      "speed": 29.890825,
      "direction": 86.892426,
      "lat": 24.345879133333334,
      "lng": 54.50721446666667,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:34",
      "speed": 29.625078,
      "direction": 86.892426,
      "lat": 24.3458812,
      "lng": 54.507252533333336,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:34",
      "speed": 29.359331,
      "direction": 86.892426,
      "lat": 24.345883266666668,
      "lng": 54.507290600000005,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:35",
      "speed": 29.093582,
      "direction": 86.892426,
      "lat": 24.345885333333335,
      "lng": 54.50732866666667,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:35",
      "speed": 28.827835,
      "direction": 86.892426,
      "lat": 24.345887400000002,
      "lng": 54.50736673333334,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:35",
      "speed": 28.562086,
      "direction": 86.892426,
      "lat": 24.34588946666667,
      "lng": 54.50740480000001,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:36",
      "speed": 28.296339,
      "direction": 86.892426,
      "lat": 24.345891533333337,
      "lng": 54.50744286666668,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:36",
      "speed": 28.030592,
      "direction": 86.892426,
      "lat": 24.345893600000004,
      "lng": 54.50748093333335,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:37",
      "speed": 27.764843,
      "direction": 86.892426,
      "lat": 24.34589566666667,
      "lng": 54.507519000000016,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:37",
      "speed": 27.499096,
      "direction": 86.892426,
      "lat": 24.345897733333338,
      "lng": 54.507557066666685,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:37",
      "speed": 27.233349,
      "direction": 86.892426,
      "lat": 24.345899800000005,
      "lng": 54.50759513333335,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:38",
      "speed": 26.9676,
      "direction": 86.892426,
      "lat": 24.345901866666672,
      "lng": 54.50763320000002,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:38",
      "speed": 26.701853,
      "direction": 86.892426,
      "lat": 24.34590393333334,
      "lng": 54.50767126666669,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:39",
      "speed": 26.436104,
      "direction": 86.892426,
      "lat": 24.345906000000006,
      "lng": 54.50770933333336,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:39",
      "speed": 26.170357,
      "direction": 86.892426,
      "lat": 24.345908066666674,
      "lng": 54.50774740000003,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:39",
      "speed": 25.90461,
      "direction": 86.892426,
      "lat": 24.34591013333334,
      "lng": 54.5077854666667,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:40",
      "speed": 25.63886,
      "direction": 86.892426,
      "lat": 24.345912200000008,
      "lng": 54.507823533333365,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:40",
      "speed": 25.373114,
      "direction": 86.892426,
      "lat": 24.345914266666675,
      "lng": 54.507861600000034,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:41",
      "speed": 25.107367,
      "direction": 86.892426,
      "lat": 24.345916333333342,
      "lng": 54.5078996666667,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:41",
      "speed": 24.841618,
      "direction": 86.892426,
      "lat": 24.34591840000001,
      "lng": 54.50793773333337,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:41",
      "speed": 24.57587,
      "direction": 86.892426,
      "lat": 24.345920466666676,
      "lng": 54.50797580000004,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:42",
      "speed": 24.310122,
      "direction": 86.892426,
      "lat": 24.345922533333344,
      "lng": 54.50801386666671,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:42",
      "speed": 24.044374,
      "direction": 86.892426,
      "lat": 24.34592460000001,
      "lng": 54.50805193333338,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:43",
      "speed": 23.778627,
      "direction": 86.892426,
      "lat": 24.345926666666678,
      "lng": 54.508090000000045,
      "dataPoint": false,
      "rpmDivd": 0.659
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:43",
      "speed": 23.778624,
      "direction": 87.64448,
      "lat": 24.345926666666667,
      "lng": 54.50809,
      "dataPoint": true,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:43",
      "speed": 23.736568,
      "direction": 87.64448,
      "lat": 24.3459286,
      "lng": 54.508137000000005,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:43",
      "speed": 23.694515,
      "direction": 87.64448,
      "lat": 24.345930533333334,
      "lng": 54.50818400000001,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:44",
      "speed": 23.65246,
      "direction": 87.64448,
      "lat": 24.345932466666667,
      "lng": 54.50823100000001,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:44",
      "speed": 23.610405,
      "direction": 87.64448,
      "lat": 24.3459344,
      "lng": 54.50827800000001,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:45",
      "speed": 23.568352,
      "direction": 87.64448,
      "lat": 24.345936333333334,
      "lng": 54.50832500000001,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:45",
      "speed": 23.526297,
      "direction": 87.64448,
      "lat": 24.345938266666668,
      "lng": 54.508372000000016,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:45",
      "speed": 23.484243,
      "direction": 87.64448,
      "lat": 24.3459402,
      "lng": 54.50841900000002,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:46",
      "speed": 23.442188,
      "direction": 87.64448,
      "lat": 24.345942133333335,
      "lng": 54.50846600000002,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:46",
      "speed": 23.400133,
      "direction": 87.64448,
      "lat": 24.345944066666668,
      "lng": 54.50851300000002,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:47",
      "speed": 23.35808,
      "direction": 87.64448,
      "lat": 24.345946,
      "lng": 54.508560000000024,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:47",
      "speed": 23.316025,
      "direction": 87.64448,
      "lat": 24.345947933333335,
      "lng": 54.508607000000026,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:47",
      "speed": 23.27397,
      "direction": 87.64448,
      "lat": 24.34594986666667,
      "lng": 54.50865400000003,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:48",
      "speed": 23.231916,
      "direction": 87.64448,
      "lat": 24.3459518,
      "lng": 54.50870100000003,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:48",
      "speed": 23.189861,
      "direction": 87.64448,
      "lat": 24.345953733333335,
      "lng": 54.50874800000003,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:49",
      "speed": 23.147806,
      "direction": 87.64448,
      "lat": 24.34595566666667,
      "lng": 54.508795000000035,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:49",
      "speed": 23.105753,
      "direction": 87.64448,
      "lat": 24.345957600000002,
      "lng": 54.50884200000004,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:49",
      "speed": 23.063698,
      "direction": 87.64448,
      "lat": 24.345959533333335,
      "lng": 54.50888900000004,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:50",
      "speed": 23.021643,
      "direction": 87.64448,
      "lat": 24.34596146666667,
      "lng": 54.50893600000004,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:50",
      "speed": 22.97959,
      "direction": 87.64448,
      "lat": 24.345963400000002,
      "lng": 54.50898300000004,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:51",
      "speed": 22.937534,
      "direction": 87.64448,
      "lat": 24.345965333333336,
      "lng": 54.509030000000045,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:51",
      "speed": 22.895481,
      "direction": 87.64448,
      "lat": 24.34596726666667,
      "lng": 54.50907700000005,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:51",
      "speed": 22.853426,
      "direction": 87.64448,
      "lat": 24.345969200000003,
      "lng": 54.50912400000005,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:52",
      "speed": 22.81137,
      "direction": 87.64448,
      "lat": 24.345971133333336,
      "lng": 54.50917100000005,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:52",
      "speed": 22.769318,
      "direction": 87.64448,
      "lat": 24.34597306666667,
      "lng": 54.509218000000054,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:53",
      "speed": 22.727262,
      "direction": 87.64448,
      "lat": 24.345975000000003,
      "lng": 54.509265000000056,
      "dataPoint": false,
      "rpmDivd": 1.47
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:53",
      "speed": 22.727264,
      "direction": 87.96083,
      "lat": 24.345975,
      "lng": 54.509265,
      "dataPoint": true,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:53",
      "speed": 22.47136,
      "direction": 87.96083,
      "lat": 24.345977333333334,
      "lng": 54.50933053333333,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:53",
      "speed": 22.215454,
      "direction": 87.96083,
      "lat": 24.34597966666667,
      "lng": 54.50939606666667,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:54",
      "speed": 21.959547,
      "direction": 87.96083,
      "lat": 24.345982000000003,
      "lng": 54.5094616,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:54",
      "speed": 21.703642,
      "direction": 87.96083,
      "lat": 24.345984333333337,
      "lng": 54.509527133333336,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:55",
      "speed": 21.447737,
      "direction": 87.96083,
      "lat": 24.345986666666672,
      "lng": 54.50959266666667,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:55",
      "speed": 21.191832,
      "direction": 87.96083,
      "lat": 24.345989000000007,
      "lng": 54.509658200000004,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:55",
      "speed": 20.935926,
      "direction": 87.96083,
      "lat": 24.34599133333334,
      "lng": 54.50972373333334,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:56",
      "speed": 20.68002,
      "direction": 87.96083,
      "lat": 24.345993666666676,
      "lng": 54.50978926666667,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:56",
      "speed": 20.424114,
      "direction": 87.96083,
      "lat": 24.34599600000001,
      "lng": 54.50985480000001,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:57",
      "speed": 20.16821,
      "direction": 87.96083,
      "lat": 24.345998333333345,
      "lng": 54.50992033333334,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:57",
      "speed": 19.912304,
      "direction": 87.96083,
      "lat": 24.34600066666668,
      "lng": 54.509985866666675,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:57",
      "speed": 19.656397,
      "direction": 87.96083,
      "lat": 24.346003000000014,
      "lng": 54.51005140000001,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:58",
      "speed": 19.400492,
      "direction": 87.96083,
      "lat": 24.34600533333335,
      "lng": 54.51011693333334,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:58",
      "speed": 19.144587,
      "direction": 87.96083,
      "lat": 24.346007666666683,
      "lng": 54.51018246666668,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:59",
      "speed": 18.888681,
      "direction": 87.96083,
      "lat": 24.346010000000017,
      "lng": 54.51024800000001,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:59",
      "speed": 18.632776,
      "direction": 87.96083,
      "lat": 24.346012333333352,
      "lng": 54.510313533333346,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:25:59",
      "speed": 18.37687,
      "direction": 87.96083,
      "lat": 24.346014666666687,
      "lng": 54.51037906666668,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:00",
      "speed": 18.120964,
      "direction": 87.96083,
      "lat": 24.34601700000002,
      "lng": 54.510444600000014,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:00",
      "speed": 17.865059,
      "direction": 87.96083,
      "lat": 24.346019333333356,
      "lng": 54.51051013333335,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:01",
      "speed": 17.609154,
      "direction": 87.96083,
      "lat": 24.34602166666669,
      "lng": 54.51057566666668,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:01",
      "speed": 17.353249,
      "direction": 87.96083,
      "lat": 24.346024000000025,
      "lng": 54.510641200000016,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:01",
      "speed": 17.097342,
      "direction": 87.96083,
      "lat": 24.34602633333336,
      "lng": 54.51070673333335,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:02",
      "speed": 16.841436,
      "direction": 87.96083,
      "lat": 24.346028666666694,
      "lng": 54.510772266666685,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:02",
      "speed": 16.585531,
      "direction": 87.96083,
      "lat": 24.34603100000003,
      "lng": 54.51083780000002,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:03",
      "speed": 16.329626,
      "direction": 87.96083,
      "lat": 24.346033333333363,
      "lng": 54.51090333333335,
      "dataPoint": false,
      "rpmDivd": 0.746
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:03",
      "speed": 16.329628,
      "direction": 88.08233,
      "lat": 24.346033333333335,
      "lng": 54.51090333333333,
      "dataPoint": true,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:03",
      "speed": 16.324259,
      "direction": 88.08233,
      "lat": 24.346034333333336,
      "lng": 54.5109332,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:03",
      "speed": 16.31889,
      "direction": 88.08233,
      "lat": 24.346035333333337,
      "lng": 54.51096306666666,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:04",
      "speed": 16.31352,
      "direction": 88.08233,
      "lat": 24.346036333333338,
      "lng": 54.51099293333333,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:04",
      "speed": 16.308153,
      "direction": 88.08233,
      "lat": 24.34603733333334,
      "lng": 54.51102279999999,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:05",
      "speed": 16.302784,
      "direction": 88.08233,
      "lat": 24.34603833333334,
      "lng": 54.51105266666666,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:05",
      "speed": 16.297415,
      "direction": 88.08233,
      "lat": 24.34603933333334,
      "lng": 54.51108253333332,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:05",
      "speed": 16.292046,
      "direction": 88.08233,
      "lat": 24.34604033333334,
      "lng": 54.51111239999999,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:06",
      "speed": 16.286676,
      "direction": 88.08233,
      "lat": 24.346041333333343,
      "lng": 54.51114226666665,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:06",
      "speed": 16.281307,
      "direction": 88.08233,
      "lat": 24.346042333333344,
      "lng": 54.51117213333332,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:07",
      "speed": 16.27594,
      "direction": 88.08233,
      "lat": 24.346043333333345,
      "lng": 54.51120199999998,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:07",
      "speed": 16.27057,
      "direction": 88.08233,
      "lat": 24.346044333333346,
      "lng": 54.51123186666665,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:07",
      "speed": 16.265202,
      "direction": 88.08233,
      "lat": 24.346045333333347,
      "lng": 54.51126173333331,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:08",
      "speed": 16.259832,
      "direction": 88.08233,
      "lat": 24.346046333333348,
      "lng": 54.51129159999998,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:08",
      "speed": 16.254463,
      "direction": 88.08233,
      "lat": 24.34604733333335,
      "lng": 54.511321466666644,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:09",
      "speed": 16.249094,
      "direction": 88.08233,
      "lat": 24.34604833333335,
      "lng": 54.51135133333331,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:09",
      "speed": 16.243725,
      "direction": 88.08233,
      "lat": 24.34604933333335,
      "lng": 54.511381199999974,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:09",
      "speed": 16.238358,
      "direction": 88.08233,
      "lat": 24.346050333333352,
      "lng": 54.51141106666664,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:10",
      "speed": 16.232988,
      "direction": 88.08233,
      "lat": 24.346051333333353,
      "lng": 54.511440933333304,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:10",
      "speed": 16.22762,
      "direction": 88.08233,
      "lat": 24.346052333333354,
      "lng": 54.51147079999997,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:11",
      "speed": 16.22225,
      "direction": 88.08233,
      "lat": 24.346053333333355,
      "lng": 54.511500666666635,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:11",
      "speed": 16.21688,
      "direction": 88.08233,
      "lat": 24.346054333333356,
      "lng": 54.5115305333333,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:11",
      "speed": 16.211512,
      "direction": 88.08233,
      "lat": 24.346055333333357,
      "lng": 54.511560399999965,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:12",
      "speed": 16.206144,
      "direction": 88.08233,
      "lat": 24.346056333333358,
      "lng": 54.51159026666663,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:12",
      "speed": 16.200775,
      "direction": 88.08233,
      "lat": 24.34605733333336,
      "lng": 54.511620133333295,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:13",
      "speed": 16.195406,
      "direction": 88.08233,
      "lat": 24.34605833333336,
      "lng": 54.51164999999996,
      "dataPoint": false,
      "rpmDivd": 0.128
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:13",
      "speed": 16.195412,
      "direction": 109.846886,
      "lat": 24.346058333333332,
      "lng": 54.51165,
      "dataPoint": true,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:13",
      "speed": 16.003035,
      "direction": 109.846886,
      "lat": 24.346050199999997,
      "lng": 54.51167253333334,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:13",
      "speed": 15.810658,
      "direction": 109.846886,
      "lat": 24.346042066666662,
      "lng": 54.511695066666675,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:14",
      "speed": 15.618281,
      "direction": 109.846886,
      "lat": 24.346033933333327,
      "lng": 54.51171760000001,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:14",
      "speed": 15.425905,
      "direction": 109.846886,
      "lat": 24.346025799999993,
      "lng": 54.51174013333335,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:15",
      "speed": 15.233528,
      "direction": 109.846886,
      "lat": 24.346017666666658,
      "lng": 54.511762666666684,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:15",
      "speed": 15.041152,
      "direction": 109.846886,
      "lat": 24.346009533333323,
      "lng": 54.51178520000002,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:15",
      "speed": 14.848775,
      "direction": 109.846886,
      "lat": 24.346001399999988,
      "lng": 54.511807733333356,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:16",
      "speed": 14.656399,
      "direction": 109.846886,
      "lat": 24.345993266666653,
      "lng": 54.51183026666669,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:16",
      "speed": 14.464022,
      "direction": 109.846886,
      "lat": 24.34598513333332,
      "lng": 54.51185280000003,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:17",
      "speed": 14.271645,
      "direction": 109.846886,
      "lat": 24.345976999999984,
      "lng": 54.511875333333364,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:17",
      "speed": 14.079268,
      "direction": 109.846886,
      "lat": 24.34596886666665,
      "lng": 54.5118978666667,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:17",
      "speed": 13.886891,
      "direction": 109.846886,
      "lat": 24.345960733333314,
      "lng": 54.51192040000004,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:18",
      "speed": 13.694515,
      "direction": 109.846886,
      "lat": 24.34595259999998,
      "lng": 54.51194293333337,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:18",
      "speed": 13.502138,
      "direction": 109.846886,
      "lat": 24.345944466666644,
      "lng": 54.51196546666671,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:19",
      "speed": 13.309762,
      "direction": 109.846886,
      "lat": 24.34593633333331,
      "lng": 54.511988000000045,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:19",
      "speed": 13.117385,
      "direction": 109.846886,
      "lat": 24.345928199999975,
      "lng": 54.51201053333338,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:19",
      "speed": 12.925009,
      "direction": 109.846886,
      "lat": 24.34592006666664,
      "lng": 54.51203306666672,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:20",
      "speed": 12.732632,
      "direction": 109.846886,
      "lat": 24.345911933333305,
      "lng": 54.51205560000005,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:20",
      "speed": 12.540255,
      "direction": 109.846886,
      "lat": 24.34590379999997,
      "lng": 54.51207813333339,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:21",
      "speed": 12.347878,
      "direction": 109.846886,
      "lat": 24.345895666666635,
      "lng": 54.512100666666726,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:21",
      "speed": 12.155501,
      "direction": 109.846886,
      "lat": 24.3458875333333,
      "lng": 54.51212320000006,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:21",
      "speed": 11.963125,
      "direction": 109.846886,
      "lat": 24.345879399999966,
      "lng": 54.5121457333334,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:22",
      "speed": 11.770748,
      "direction": 109.846886,
      "lat": 24.34587126666663,
      "lng": 54.512168266666734,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:22",
      "speed": 11.578372,
      "direction": 109.846886,
      "lat": 24.345863133333296,
      "lng": 54.51219080000007,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:23",
      "speed": 11.385995,
      "direction": 109.846886,
      "lat": 24.34585499999996,
      "lng": 54.512213333333406,
      "dataPoint": false,
      "rpmDivd": 0.445
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:23",
      "speed": 11.386002,
      "direction": 76.459724,
      "lat": 24.345855,
      "lng": 54.512213333333335,
      "dataPoint": true,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:23",
      "speed": 11.890654,
      "direction": 76.459724,
      "lat": 24.345865933333332,
      "lng": 54.51225873333333,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:23",
      "speed": 12.395306,
      "direction": 76.459724,
      "lat": 24.345876866666664,
      "lng": 54.51230413333333,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:24",
      "speed": 12.899959,
      "direction": 76.459724,
      "lat": 24.345887799999996,
      "lng": 54.51234953333333,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:24",
      "speed": 13.404611,
      "direction": 76.459724,
      "lat": 24.34589873333333,
      "lng": 54.512394933333326,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:25",
      "speed": 13.909263,
      "direction": 76.459724,
      "lat": 24.34590966666666,
      "lng": 54.51244033333332,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:25",
      "speed": 14.413915,
      "direction": 76.459724,
      "lat": 24.345920599999992,
      "lng": 54.51248573333332,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:25",
      "speed": 14.918567,
      "direction": 76.459724,
      "lat": 24.345931533333324,
      "lng": 54.51253113333332,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:26",
      "speed": 15.42322,
      "direction": 76.459724,
      "lat": 24.345942466666656,
      "lng": 54.512576533333316,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:26",
      "speed": 15.927872,
      "direction": 76.459724,
      "lat": 24.34595339999999,
      "lng": 54.512621933333314,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:27",
      "speed": 16.432524,
      "direction": 76.459724,
      "lat": 24.34596433333332,
      "lng": 54.51266733333331,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:27",
      "speed": 16.937176,
      "direction": 76.459724,
      "lat": 24.345975266666652,
      "lng": 54.51271273333331,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:27",
      "speed": 17.441828,
      "direction": 76.459724,
      "lat": 24.345986199999984,
      "lng": 54.51275813333331,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:28",
      "speed": 17.94648,
      "direction": 76.459724,
      "lat": 24.345997133333316,
      "lng": 54.512803533333305,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:28",
      "speed": 18.451132,
      "direction": 76.459724,
      "lat": 24.34600806666665,
      "lng": 54.5128489333333,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:29",
      "speed": 18.955786,
      "direction": 76.459724,
      "lat": 24.34601899999998,
      "lng": 54.5128943333333,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:29",
      "speed": 19.460438,
      "direction": 76.459724,
      "lat": 24.346029933333313,
      "lng": 54.5129397333333,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:29",
      "speed": 19.96509,
      "direction": 76.459724,
      "lat": 24.346040866666645,
      "lng": 54.512985133333295,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:30",
      "speed": 20.469742,
      "direction": 76.459724,
      "lat": 24.346051799999977,
      "lng": 54.51303053333329,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:30",
      "speed": 20.974394,
      "direction": 76.459724,
      "lat": 24.34606273333331,
      "lng": 54.51307593333329,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:31",
      "speed": 21.479046,
      "direction": 76.459724,
      "lat": 24.34607366666664,
      "lng": 54.51312133333329,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:31",
      "speed": 21.983698,
      "direction": 76.459724,
      "lat": 24.346084599999973,
      "lng": 54.513166733333286,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:31",
      "speed": 22.48835,
      "direction": 76.459724,
      "lat": 24.346095533333305,
      "lng": 54.51321213333328,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:32",
      "speed": 22.993002,
      "direction": 76.459724,
      "lat": 24.346106466666637,
      "lng": 54.51325753333328,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:32",
      "speed": 23.497656,
      "direction": 76.459724,
      "lat": 24.34611739999997,
      "lng": 54.51330293333328,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:33",
      "speed": 24.002308,
      "direction": 76.459724,
      "lat": 24.3461283333333,
      "lng": 54.513348333333276,
      "dataPoint": false,
      "rpmDivd": 0.165
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:33",
      "speed": 24.002317,
      "direction": 88.11796,
      "lat": 24.346128333333333,
      "lng": 54.51334833333333,
      "dataPoint": true,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:33",
      "speed": 23.348238,
      "direction": 88.11796,
      "lat": 24.3461308,
      "lng": 54.5134234,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:33",
      "speed": 22.69416,
      "direction": 88.11796,
      "lat": 24.34613326666667,
      "lng": 54.51349846666667,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:34",
      "speed": 22.040081,
      "direction": 88.11796,
      "lat": 24.346135733333337,
      "lng": 54.513573533333336,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:34",
      "speed": 21.386002,
      "direction": 88.11796,
      "lat": 24.346138200000006,
      "lng": 54.5136486,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:35",
      "speed": 20.731922,
      "direction": 88.11796,
      "lat": 24.346140666666674,
      "lng": 54.51372366666667,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:35",
      "speed": 20.077845,
      "direction": 88.11796,
      "lat": 24.346143133333342,
      "lng": 54.51379873333334,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:35",
      "speed": 19.423765,
      "direction": 88.11796,
      "lat": 24.34614560000001,
      "lng": 54.513873800000006,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:36",
      "speed": 18.769686,
      "direction": 88.11796,
      "lat": 24.34614806666668,
      "lng": 54.513948866666674,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:36",
      "speed": 18.115608,
      "direction": 88.11796,
      "lat": 24.346150533333347,
      "lng": 54.51402393333334,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:37",
      "speed": 17.461529,
      "direction": 88.11796,
      "lat": 24.346153000000015,
      "lng": 54.51409900000001,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:37",
      "speed": 16.80745,
      "direction": 88.11796,
      "lat": 24.346155466666684,
      "lng": 54.514174066666676,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:37",
      "speed": 16.15337,
      "direction": 88.11796,
      "lat": 24.34615793333335,
      "lng": 54.514249133333344,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:38",
      "speed": 15.499291,
      "direction": 88.11796,
      "lat": 24.34616040000002,
      "lng": 54.51432420000001,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:38",
      "speed": 14.845213,
      "direction": 88.11796,
      "lat": 24.34616286666669,
      "lng": 54.51439926666668,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:39",
      "speed": 14.191134,
      "direction": 88.11796,
      "lat": 24.346165333333357,
      "lng": 54.51447433333335,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:39",
      "speed": 13.537055,
      "direction": 88.11796,
      "lat": 24.346167800000025,
      "lng": 54.514549400000014,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:39",
      "speed": 12.882977,
      "direction": 88.11796,
      "lat": 24.346170266666693,
      "lng": 54.51462446666668,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:40",
      "speed": 12.228897,
      "direction": 88.11796,
      "lat": 24.34617273333336,
      "lng": 54.51469953333335,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:40",
      "speed": 11.574819,
      "direction": 88.11796,
      "lat": 24.34617520000003,
      "lng": 54.51477460000002,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:41",
      "speed": 10.92074,
      "direction": 88.11796,
      "lat": 24.346177666666698,
      "lng": 54.514849666666684,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:41",
      "speed": 10.266661,
      "direction": 88.11796,
      "lat": 24.346180133333366,
      "lng": 54.51492473333335,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:41",
      "speed": 9.612582,
      "direction": 88.11796,
      "lat": 24.346182600000034,
      "lng": 54.51499980000002,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:42",
      "speed": 8.958503,
      "direction": 88.11796,
      "lat": 24.346185066666703,
      "lng": 54.51507486666669,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:42",
      "speed": 8.304424,
      "direction": 88.11796,
      "lat": 24.34618753333337,
      "lng": 54.515149933333355,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:43",
      "speed": 7.6503453,
      "direction": 88.11796,
      "lat": 24.34619000000004,
      "lng": 54.51522500000002,
      "dataPoint": false,
      "rpmDivd": 1.041
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:43",
      "speed": 7.650319,
      "direction": 88.05921,
      "lat": 24.34619,
      "lng": 54.515225,
      "dataPoint": true,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:43",
      "speed": 7.809589,
      "direction": 88.05921,
      "lat": 24.346193133333333,
      "lng": 54.515317466666666,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:43",
      "speed": 7.968859,
      "direction": 88.05921,
      "lat": 24.346196266666666,
      "lng": 54.51540993333333,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:44",
      "speed": 8.128129,
      "direction": 88.05921,
      "lat": 24.3461994,
      "lng": 54.515502399999995,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:44",
      "speed": 8.287399,
      "direction": 88.05921,
      "lat": 24.346202533333333,
      "lng": 54.51559486666666,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:45",
      "speed": 8.446669,
      "direction": 88.05921,
      "lat": 24.346205666666666,
      "lng": 54.515687333333325,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:45",
      "speed": 8.605939,
      "direction": 88.05921,
      "lat": 24.3462088,
      "lng": 54.51577979999999,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:45",
      "speed": 8.765209,
      "direction": 88.05921,
      "lat": 24.346211933333333,
      "lng": 54.515872266666655,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:46",
      "speed": 8.9244795,
      "direction": 88.05921,
      "lat": 24.346215066666666,
      "lng": 54.51596473333332,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:46",
      "speed": 9.083749,
      "direction": 88.05921,
      "lat": 24.3462182,
      "lng": 54.516057199999985,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:47",
      "speed": 9.243019,
      "direction": 88.05921,
      "lat": 24.346221333333332,
      "lng": 54.51614966666665,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:47",
      "speed": 9.402289,
      "direction": 88.05921,
      "lat": 24.346224466666666,
      "lng": 54.516242133333314,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:47",
      "speed": 9.561559,
      "direction": 88.05921,
      "lat": 24.3462276,
      "lng": 54.51633459999998,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:48",
      "speed": 9.720829,
      "direction": 88.05921,
      "lat": 24.346230733333332,
      "lng": 54.516427066666644,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:48",
      "speed": 9.880099,
      "direction": 88.05921,
      "lat": 24.346233866666665,
      "lng": 54.51651953333331,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:49",
      "speed": 10.039369,
      "direction": 88.05921,
      "lat": 24.346237,
      "lng": 54.516611999999974,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:49",
      "speed": 10.198639,
      "direction": 88.05921,
      "lat": 24.346240133333332,
      "lng": 54.51670446666664,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:49",
      "speed": 10.357909,
      "direction": 88.05921,
      "lat": 24.346243266666665,
      "lng": 54.5167969333333,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:50",
      "speed": 10.5171795,
      "direction": 88.05921,
      "lat": 24.3462464,
      "lng": 54.51688939999997,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:50",
      "speed": 10.676449,
      "direction": 88.05921,
      "lat": 24.34624953333333,
      "lng": 54.51698186666663,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:51",
      "speed": 10.835719,
      "direction": 88.05921,
      "lat": 24.346252666666665,
      "lng": 54.5170743333333,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:51",
      "speed": 10.994989,
      "direction": 88.05921,
      "lat": 24.346255799999998,
      "lng": 54.51716679999996,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:51",
      "speed": 11.154259,
      "direction": 88.05921,
      "lat": 24.34625893333333,
      "lng": 54.51725926666663,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:52",
      "speed": 11.313529,
      "direction": 88.05921,
      "lat": 24.346262066666664,
      "lng": 54.51735173333329,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:52",
      "speed": 11.472799,
      "direction": 88.05921,
      "lat": 24.346265199999998,
      "lng": 54.51744419999996,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:53",
      "speed": 11.632069,
      "direction": 88.05921,
      "lat": 24.34626833333333,
      "lng": 54.51753666666662,
      "dataPoint": false,
      "rpmDivd": 0.229
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:53",
      "speed": 11.632064,
      "direction": 88.28621,
      "lat": 24.346268333333335,
      "lng": 54.517536666666665,
      "dataPoint": true,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:53",
      "speed": 11.547061,
      "direction": 88.28621,
      "lat": 24.346271333333334,
      "lng": 54.51763693333333,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:53",
      "speed": 11.462057,
      "direction": 88.28621,
      "lat": 24.346274333333334,
      "lng": 54.51773719999999,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:54",
      "speed": 11.377054,
      "direction": 88.28621,
      "lat": 24.346277333333333,
      "lng": 54.517837466666656,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:54",
      "speed": 11.29205,
      "direction": 88.28621,
      "lat": 24.346280333333333,
      "lng": 54.51793773333332,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:55",
      "speed": 11.207047,
      "direction": 88.28621,
      "lat": 24.346283333333332,
      "lng": 54.51803799999998,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:55",
      "speed": 11.122044,
      "direction": 88.28621,
      "lat": 24.34628633333333,
      "lng": 54.51813826666665,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:55",
      "speed": 11.037041,
      "direction": 88.28621,
      "lat": 24.34628933333333,
      "lng": 54.51823853333331,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:56",
      "speed": 10.952037,
      "direction": 88.28621,
      "lat": 24.34629233333333,
      "lng": 54.518338799999974,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:56",
      "speed": 10.867034,
      "direction": 88.28621,
      "lat": 24.34629533333333,
      "lng": 54.51843906666664,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:57",
      "speed": 10.78203,
      "direction": 88.28621,
      "lat": 24.34629833333333,
      "lng": 54.5185393333333,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:57",
      "speed": 10.697027,
      "direction": 88.28621,
      "lat": 24.34630133333333,
      "lng": 54.518639599999965,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:57",
      "speed": 10.612024,
      "direction": 88.28621,
      "lat": 24.34630433333333,
      "lng": 54.51873986666663,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:58",
      "speed": 10.52702,
      "direction": 88.28621,
      "lat": 24.34630733333333,
      "lng": 54.51884013333329,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:58",
      "speed": 10.442018,
      "direction": 88.28621,
      "lat": 24.346310333333328,
      "lng": 54.518940399999956,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:59",
      "speed": 10.357014,
      "direction": 88.28621,
      "lat": 24.346313333333327,
      "lng": 54.51904066666662,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:59",
      "speed": 10.272011,
      "direction": 88.28621,
      "lat": 24.346316333333327,
      "lng": 54.51914093333328,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:26:59",
      "speed": 10.187007,
      "direction": 88.28621,
      "lat": 24.346319333333327,
      "lng": 54.519241199999946,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:00",
      "speed": 10.102004,
      "direction": 88.28621,
      "lat": 24.346322333333326,
      "lng": 54.51934146666661,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:00",
      "speed": 10.017,
      "direction": 88.28621,
      "lat": 24.346325333333326,
      "lng": 54.519441733333274,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:01",
      "speed": 9.931997,
      "direction": 88.28621,
      "lat": 24.346328333333325,
      "lng": 54.51954199999994,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:01",
      "speed": 9.846993,
      "direction": 88.28621,
      "lat": 24.346331333333325,
      "lng": 54.5196422666666,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:01",
      "speed": 9.761991,
      "direction": 88.28621,
      "lat": 24.346334333333324,
      "lng": 54.519742533333265,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:02",
      "speed": 9.676988,
      "direction": 88.28621,
      "lat": 24.346337333333324,
      "lng": 54.51984279999993,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:02",
      "speed": 9.591984,
      "direction": 88.28621,
      "lat": 24.346340333333323,
      "lng": 54.51994306666659,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:03",
      "speed": 9.506981,
      "direction": 88.28621,
      "lat": 24.346343333333323,
      "lng": 54.520043333333255,
      "dataPoint": false,
      "rpmDivd": 1.03
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:03",
      "speed": 9.506975,
      "direction": 89.30554,
      "lat": 24.346343333333333,
      "lng": 54.520043333333334,
      "dataPoint": true,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:03",
      "speed": 9.513239,
      "direction": 89.30554,
      "lat": 24.346344533333333,
      "lng": 54.52014233333333,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:03",
      "speed": 9.519502,
      "direction": 89.30554,
      "lat": 24.346345733333333,
      "lng": 54.52024133333333,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:04",
      "speed": 9.525765,
      "direction": 89.30554,
      "lat": 24.346346933333333,
      "lng": 54.52034033333333,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:04",
      "speed": 9.532028,
      "direction": 89.30554,
      "lat": 24.346348133333333,
      "lng": 54.52043933333333,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:05",
      "speed": 9.538292,
      "direction": 89.30554,
      "lat": 24.346349333333333,
      "lng": 54.52053833333333,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:05",
      "speed": 9.544555,
      "direction": 89.30554,
      "lat": 24.346350533333332,
      "lng": 54.520637333333326,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:05",
      "speed": 9.550818,
      "direction": 89.30554,
      "lat": 24.346351733333332,
      "lng": 54.520736333333325,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:06",
      "speed": 9.557082,
      "direction": 89.30554,
      "lat": 24.346352933333332,
      "lng": 54.52083533333332,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:06",
      "speed": 9.563345,
      "direction": 89.30554,
      "lat": 24.34635413333333,
      "lng": 54.52093433333332,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:07",
      "speed": 9.569609,
      "direction": 89.30554,
      "lat": 24.34635533333333,
      "lng": 54.52103333333332,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:07",
      "speed": 9.575871,
      "direction": 89.30554,
      "lat": 24.34635653333333,
      "lng": 54.52113233333332,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:07",
      "speed": 9.582135,
      "direction": 89.30554,
      "lat": 24.34635773333333,
      "lng": 54.52123133333332,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:08",
      "speed": 9.588399,
      "direction": 89.30554,
      "lat": 24.34635893333333,
      "lng": 54.52133033333332,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:08",
      "speed": 9.594662,
      "direction": 89.30554,
      "lat": 24.34636013333333,
      "lng": 54.521429333333316,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:09",
      "speed": 9.600925,
      "direction": 89.30554,
      "lat": 24.34636133333333,
      "lng": 54.521528333333315,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:09",
      "speed": 9.607188,
      "direction": 89.30554,
      "lat": 24.34636253333333,
      "lng": 54.52162733333331,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:09",
      "speed": 9.613452,
      "direction": 89.30554,
      "lat": 24.34636373333333,
      "lng": 54.52172633333331,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:10",
      "speed": 9.619715,
      "direction": 89.30554,
      "lat": 24.34636493333333,
      "lng": 54.52182533333331,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:10",
      "speed": 9.625978,
      "direction": 89.30554,
      "lat": 24.34636613333333,
      "lng": 54.52192433333331,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:11",
      "speed": 9.632242,
      "direction": 89.30554,
      "lat": 24.34636733333333,
      "lng": 54.52202333333331,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:11",
      "speed": 9.638505,
      "direction": 89.30554,
      "lat": 24.34636853333333,
      "lng": 54.52212233333331,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:11",
      "speed": 9.644769,
      "direction": 89.30554,
      "lat": 24.34636973333333,
      "lng": 54.522221333333306,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:12",
      "speed": 9.6510315,
      "direction": 89.30554,
      "lat": 24.34637093333333,
      "lng": 54.522320333333305,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:12",
      "speed": 9.657295,
      "direction": 89.30554,
      "lat": 24.34637213333333,
      "lng": 54.5224193333333,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:13",
      "speed": 9.663559,
      "direction": 89.30554,
      "lat": 24.34637333333333,
      "lng": 54.5225183333333,
      "dataPoint": false,
      "rpmDivd": 1.403
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:13",
      "speed": 9.663561,
      "direction": 85.47245,
      "lat": 24.346373333333332,
      "lng": 54.52251833333333,
      "dataPoint": true,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:13",
      "speed": 9.38976,
      "direction": 85.47245,
      "lat": 24.346380333333332,
      "lng": 54.52260673333333,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:13",
      "speed": 9.115958,
      "direction": 85.47245,
      "lat": 24.346387333333332,
      "lng": 54.522695133333336,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:14",
      "speed": 8.842157,
      "direction": 85.47245,
      "lat": 24.346394333333333,
      "lng": 54.52278353333334,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:14",
      "speed": 8.5683565,
      "direction": 85.47245,
      "lat": 24.346401333333333,
      "lng": 54.52287193333334,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:15",
      "speed": 8.294556,
      "direction": 85.47245,
      "lat": 24.346408333333333,
      "lng": 54.522960333333344,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:15",
      "speed": 8.020754,
      "direction": 85.47245,
      "lat": 24.346415333333333,
      "lng": 54.52304873333335,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:15",
      "speed": 7.746953,
      "direction": 85.47245,
      "lat": 24.346422333333333,
      "lng": 54.52313713333335,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:16",
      "speed": 7.473152,
      "direction": 85.47245,
      "lat": 24.346429333333333,
      "lng": 54.52322553333335,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:16",
      "speed": 7.199351,
      "direction": 85.47245,
      "lat": 24.346436333333333,
      "lng": 54.523313933333355,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:17",
      "speed": 6.92555,
      "direction": 85.47245,
      "lat": 24.346443333333333,
      "lng": 54.52340233333336,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:17",
      "speed": 6.6517487,
      "direction": 85.47245,
      "lat": 24.346450333333333,
      "lng": 54.52349073333336,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:17",
      "speed": 6.3779473,
      "direction": 85.47245,
      "lat": 24.346457333333333,
      "lng": 54.523579133333364,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:18",
      "speed": 6.1041465,
      "direction": 85.47245,
      "lat": 24.346464333333333,
      "lng": 54.52366753333337,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:18",
      "speed": 5.830345,
      "direction": 85.47245,
      "lat": 24.346471333333334,
      "lng": 54.52375593333337,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:19",
      "speed": 5.5565443,
      "direction": 85.47245,
      "lat": 24.346478333333334,
      "lng": 54.52384433333337,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:19",
      "speed": 5.282743,
      "direction": 85.47245,
      "lat": 24.346485333333334,
      "lng": 54.523932733333375,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:19",
      "speed": 5.008942,
      "direction": 85.47245,
      "lat": 24.346492333333334,
      "lng": 54.52402113333338,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:20",
      "speed": 4.735141,
      "direction": 85.47245,
      "lat": 24.346499333333334,
      "lng": 54.52410953333338,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:20",
      "speed": 4.46134,
      "direction": 85.47245,
      "lat": 24.346506333333334,
      "lng": 54.52419793333338,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:21",
      "speed": 4.1875386,
      "direction": 85.47245,
      "lat": 24.346513333333334,
      "lng": 54.524286333333386,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:21",
      "speed": 3.9137375,
      "direction": 85.47245,
      "lat": 24.346520333333334,
      "lng": 54.52437473333339,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:21",
      "speed": 3.6399364,
      "direction": 85.47245,
      "lat": 24.346527333333334,
      "lng": 54.52446313333339,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:22",
      "speed": 3.3661354,
      "direction": 85.47245,
      "lat": 24.346534333333334,
      "lng": 54.524551533333394,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:22",
      "speed": 3.0923343,
      "direction": 85.47245,
      "lat": 24.346541333333334,
      "lng": 54.5246399333334,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:23",
      "speed": 2.8185332,
      "direction": 85.47245,
      "lat": 24.346548333333335,
      "lng": 54.5247283333334,
      "dataPoint": false,
      "rpmDivd": 0.069
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:23",
      "speed": 2.8185387,
      "direction": 72.63255,
      "lat": 24.346548333333335,
      "lng": 54.524728333333336,
      "dataPoint": true,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:23",
      "speed": 3.8958454,
      "direction": 72.63255,
      "lat": 24.346573666666668,
      "lng": 54.52480933333334,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:23",
      "speed": 4.973152,
      "direction": 72.63255,
      "lat": 24.346599,
      "lng": 54.52489033333334,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:24",
      "speed": 6.0504584,
      "direction": 72.63255,
      "lat": 24.346624333333335,
      "lng": 54.52497133333334,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:24",
      "speed": 7.127765,
      "direction": 72.63255,
      "lat": 24.346649666666668,
      "lng": 54.52505233333334,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:25",
      "speed": 8.205072,
      "direction": 72.63255,
      "lat": 24.346675,
      "lng": 54.52513333333334,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:25",
      "speed": 9.282378,
      "direction": 72.63255,
      "lat": 24.346700333333334,
      "lng": 54.525214333333345,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:25",
      "speed": 10.359685,
      "direction": 72.63255,
      "lat": 24.346725666666668,
      "lng": 54.52529533333335,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:26",
      "speed": 11.436992,
      "direction": 72.63255,
      "lat": 24.346751,
      "lng": 54.52537633333335,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:26",
      "speed": 12.514298,
      "direction": 72.63255,
      "lat": 24.346776333333334,
      "lng": 54.52545733333335,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:27",
      "speed": 13.591605,
      "direction": 72.63255,
      "lat": 24.346801666666668,
      "lng": 54.52553833333335,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:27",
      "speed": 14.668912,
      "direction": 72.63255,
      "lat": 24.346827,
      "lng": 54.52561933333335,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:27",
      "speed": 15.746219,
      "direction": 72.63255,
      "lat": 24.346852333333334,
      "lng": 54.525700333333354,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:28",
      "speed": 16.823524,
      "direction": 72.63255,
      "lat": 24.346877666666668,
      "lng": 54.525781333333356,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:28",
      "speed": 17.900831,
      "direction": 72.63255,
      "lat": 24.346903,
      "lng": 54.52586233333336,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:29",
      "speed": 18.978138,
      "direction": 72.63255,
      "lat": 24.346928333333334,
      "lng": 54.52594333333336,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:29",
      "speed": 20.055445,
      "direction": 72.63255,
      "lat": 24.346953666666668,
      "lng": 54.52602433333336,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:29",
      "speed": 21.132751,
      "direction": 72.63255,
      "lat": 24.346979,
      "lng": 54.52610533333336,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:30",
      "speed": 22.210058,
      "direction": 72.63255,
      "lat": 24.347004333333334,
      "lng": 54.526186333333364,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:30",
      "speed": 23.287365,
      "direction": 72.63255,
      "lat": 24.347029666666668,
      "lng": 54.526267333333365,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:31",
      "speed": 24.364672,
      "direction": 72.63255,
      "lat": 24.347055,
      "lng": 54.52634833333337,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:31",
      "speed": 25.441978,
      "direction": 72.63255,
      "lat": 24.347080333333334,
      "lng": 54.52642933333337,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:31",
      "speed": 26.519285,
      "direction": 72.63255,
      "lat": 24.347105666666668,
      "lng": 54.52651033333337,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:32",
      "speed": 27.596592,
      "direction": 72.63255,
      "lat": 24.347131,
      "lng": 54.52659133333337,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:32",
      "speed": 28.673899,
      "direction": 72.63255,
      "lat": 24.347156333333334,
      "lng": 54.52667233333337,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:33",
      "speed": 29.751205,
      "direction": 72.63255,
      "lat": 24.347181666666668,
      "lng": 54.526753333333374,
      "dataPoint": false,
      "rpmDivd": 0.547
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:33",
      "speed": 29.75124,
      "direction": 353.06055,
      "lat": 24.347181666666668,
      "lng": 54.52675333333333,
      "dataPoint": true,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:33",
      "speed": 28.669458,
      "direction": 353.06055,
      "lat": 24.3473312,
      "lng": 54.52673513333333,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:33",
      "speed": 27.587677,
      "direction": 353.06055,
      "lat": 24.34748073333333,
      "lng": 54.52671693333333,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:34",
      "speed": 26.505896,
      "direction": 353.06055,
      "lat": 24.347630266666663,
      "lng": 54.52669873333333,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:34",
      "speed": 25.424116,
      "direction": 353.06055,
      "lat": 24.347779799999994,
      "lng": 54.526680533333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:35",
      "speed": 24.342335,
      "direction": 353.06055,
      "lat": 24.347929333333326,
      "lng": 54.526662333333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:35",
      "speed": 23.260553,
      "direction": 353.06055,
      "lat": 24.348078866666658,
      "lng": 54.526644133333335,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:35",
      "speed": 22.178772,
      "direction": 353.06055,
      "lat": 24.34822839999999,
      "lng": 54.526625933333335,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:36",
      "speed": 21.09699,
      "direction": 353.06055,
      "lat": 24.34837793333332,
      "lng": 54.526607733333336,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:36",
      "speed": 20.01521,
      "direction": 353.06055,
      "lat": 24.348527466666653,
      "lng": 54.526589533333336,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:37",
      "speed": 18.933428,
      "direction": 353.06055,
      "lat": 24.348676999999984,
      "lng": 54.52657133333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:37",
      "speed": 17.851648,
      "direction": 353.06055,
      "lat": 24.348826533333316,
      "lng": 54.52655313333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:37",
      "speed": 16.769867,
      "direction": 353.06055,
      "lat": 24.348976066666648,
      "lng": 54.52653493333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:38",
      "speed": 15.688086,
      "direction": 353.06055,
      "lat": 24.34912559999998,
      "lng": 54.52651673333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:38",
      "speed": 14.606304,
      "direction": 353.06055,
      "lat": 24.34927513333331,
      "lng": 54.52649853333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:39",
      "speed": 13.524523,
      "direction": 353.06055,
      "lat": 24.349424666666643,
      "lng": 54.52648033333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:39",
      "speed": 12.442742,
      "direction": 353.06055,
      "lat": 24.349574199999974,
      "lng": 54.52646213333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:39",
      "speed": 11.360961,
      "direction": 353.06055,
      "lat": 24.349723733333306,
      "lng": 54.52644393333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:40",
      "speed": 10.27918,
      "direction": 353.06055,
      "lat": 24.349873266666638,
      "lng": 54.52642573333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:40",
      "speed": 9.197399,
      "direction": 353.06055,
      "lat": 24.35002279999997,
      "lng": 54.52640753333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:41",
      "speed": 8.115618,
      "direction": 353.06055,
      "lat": 24.3501723333333,
      "lng": 54.52638933333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:41",
      "speed": 7.0338364,
      "direction": 353.06055,
      "lat": 24.350321866666633,
      "lng": 54.52637113333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:41",
      "speed": 5.9520555,
      "direction": 353.06055,
      "lat": 24.350471399999964,
      "lng": 54.52635293333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:42",
      "speed": 4.870274,
      "direction": 353.06055,
      "lat": 24.350620933333296,
      "lng": 54.52633473333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:42",
      "speed": 3.7884932,
      "direction": 353.06055,
      "lat": 24.350770466666628,
      "lng": 54.52631653333334,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:43",
      "speed": 2.706712,
      "direction": 353.06055,
      "lat": 24.35091999999996,
      "lng": 54.526298333333344,
      "dataPoint": false,
      "rpmDivd": 1.267
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:43",
      "speed": 2.706692,
      "direction": 353.6169,
      "lat": 24.35092,
      "lng": 54.52629833333334,
      "dataPoint": true,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:43",
      "speed": 3.2551887,
      "direction": 353.6169,
      "lat": 24.351017733333332,
      "lng": 54.5262874,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:43",
      "speed": 3.8036854,
      "direction": 353.6169,
      "lat": 24.351115466666666,
      "lng": 54.526276466666665,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:44",
      "speed": 4.352182,
      "direction": 353.6169,
      "lat": 24.3512132,
      "lng": 54.52626553333333,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:44",
      "speed": 4.9006786,
      "direction": 353.6169,
      "lat": 24.351310933333334,
      "lng": 54.526254599999994,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:45",
      "speed": 5.4491754,
      "direction": 353.6169,
      "lat": 24.351408666666668,
      "lng": 54.52624366666666,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:45",
      "speed": 5.997672,
      "direction": 353.6169,
      "lat": 24.3515064,
      "lng": 54.52623273333332,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:45",
      "speed": 6.546169,
      "direction": 353.6169,
      "lat": 24.351604133333336,
      "lng": 54.52622179999999,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:46",
      "speed": 7.0946655,
      "direction": 353.6169,
      "lat": 24.35170186666667,
      "lng": 54.52621086666665,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:46",
      "speed": 7.643162,
      "direction": 353.6169,
      "lat": 24.351799600000003,
      "lng": 54.52619993333332,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:47",
      "speed": 8.191659,
      "direction": 353.6169,
      "lat": 24.351897333333337,
      "lng": 54.52618899999998,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:47",
      "speed": 8.740155,
      "direction": 353.6169,
      "lat": 24.35199506666667,
      "lng": 54.526178066666645,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:47",
      "speed": 9.288652,
      "direction": 353.6169,
      "lat": 24.352092800000005,
      "lng": 54.52616713333331,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:48",
      "speed": 9.837149,
      "direction": 353.6169,
      "lat": 24.35219053333334,
      "lng": 54.526156199999974,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:48",
      "speed": 10.385645,
      "direction": 353.6169,
      "lat": 24.352288266666672,
      "lng": 54.52614526666664,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:49",
      "speed": 10.934142,
      "direction": 353.6169,
      "lat": 24.352386000000006,
      "lng": 54.5261343333333,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:49",
      "speed": 11.482638,
      "direction": 353.6169,
      "lat": 24.35248373333334,
      "lng": 54.52612339999997,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:49",
      "speed": 12.031136,
      "direction": 353.6169,
      "lat": 24.352581466666674,
      "lng": 54.52611246666663,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:50",
      "speed": 12.579632,
      "direction": 353.6169,
      "lat": 24.352679200000008,
      "lng": 54.526101533333296,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:50",
      "speed": 13.128129,
      "direction": 353.6169,
      "lat": 24.35277693333334,
      "lng": 54.52609059999996,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:51",
      "speed": 13.676625,
      "direction": 353.6169,
      "lat": 24.352874666666676,
      "lng": 54.526079666666625,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:51",
      "speed": 14.225122,
      "direction": 353.6169,
      "lat": 24.35297240000001,
      "lng": 54.52606873333329,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:51",
      "speed": 14.773619,
      "direction": 353.6169,
      "lat": 24.353070133333343,
      "lng": 54.526057799999954,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:52",
      "speed": 15.322115,
      "direction": 353.6169,
      "lat": 24.353167866666677,
      "lng": 54.52604686666662,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:52",
      "speed": 15.870612,
      "direction": 353.6169,
      "lat": 24.35326560000001,
      "lng": 54.52603593333328,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:53",
      "speed": 16.41911,
      "direction": 353.6169,
      "lat": 24.353363333333345,
      "lng": 54.52602499999995,
      "dataPoint": false,
      "rpmDivd": 0.827
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:53",
      "speed": 16.419106,
      "direction": 355.16486,
      "lat": 24.353363333333334,
      "lng": 54.526025,
      "dataPoint": true,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:53",
      "speed": 16.194517,
      "direction": 355.16486,
      "lat": 24.353465,
      "lng": 54.526016399999996,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:53",
      "speed": 15.96993,
      "direction": 355.16486,
      "lat": 24.353566666666666,
      "lng": 54.526007799999995,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:54",
      "speed": 15.745342,
      "direction": 355.16486,
      "lat": 24.35366833333333,
      "lng": 54.525999199999994,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:54",
      "speed": 15.520755,
      "direction": 355.16486,
      "lat": 24.353769999999997,
      "lng": 54.52599059999999,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:55",
      "speed": 15.296166,
      "direction": 355.16486,
      "lat": 24.353871666666663,
      "lng": 54.52598199999999,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:55",
      "speed": 15.071579,
      "direction": 355.16486,
      "lat": 24.35397333333333,
      "lng": 54.52597339999999,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:55",
      "speed": 14.846992,
      "direction": 355.16486,
      "lat": 24.354074999999995,
      "lng": 54.52596479999999,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:56",
      "speed": 14.622403,
      "direction": 355.16486,
      "lat": 24.35417666666666,
      "lng": 54.52595619999999,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:56",
      "speed": 14.397816,
      "direction": 355.16486,
      "lat": 24.354278333333326,
      "lng": 54.52594759999999,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:57",
      "speed": 14.173227,
      "direction": 355.16486,
      "lat": 24.354379999999992,
      "lng": 54.52593899999999,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:57",
      "speed": 13.94864,
      "direction": 355.16486,
      "lat": 24.354481666666658,
      "lng": 54.525930399999986,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:57",
      "speed": 13.724052,
      "direction": 355.16486,
      "lat": 24.354583333333323,
      "lng": 54.525921799999985,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:58",
      "speed": 13.499464,
      "direction": 355.16486,
      "lat": 24.35468499999999,
      "lng": 54.525913199999984,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:58",
      "speed": 13.274877,
      "direction": 355.16486,
      "lat": 24.354786666666655,
      "lng": 54.52590459999998,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:59",
      "speed": 13.050289,
      "direction": 355.16486,
      "lat": 24.35488833333332,
      "lng": 54.52589599999998,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:59",
      "speed": 12.825701,
      "direction": 355.16486,
      "lat": 24.354989999999987,
      "lng": 54.52588739999998,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:27:59",
      "speed": 12.601113,
      "direction": 355.16486,
      "lat": 24.355091666666652,
      "lng": 54.52587879999998,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:00",
      "speed": 12.376526,
      "direction": 355.16486,
      "lat": 24.355193333333318,
      "lng": 54.52587019999998,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:00",
      "speed": 12.1519375,
      "direction": 355.16486,
      "lat": 24.355294999999984,
      "lng": 54.52586159999998,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:01",
      "speed": 11.92735,
      "direction": 355.16486,
      "lat": 24.35539666666665,
      "lng": 54.52585299999998,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:01",
      "speed": 11.702763,
      "direction": 355.16486,
      "lat": 24.355498333333315,
      "lng": 54.525844399999976,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:01",
      "speed": 11.478174,
      "direction": 355.16486,
      "lat": 24.35559999999998,
      "lng": 54.525835799999975,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:02",
      "speed": 11.253587,
      "direction": 355.16486,
      "lat": 24.355701666666647,
      "lng": 54.52582719999997,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:02",
      "speed": 11.028999,
      "direction": 355.16486,
      "lat": 24.355803333333313,
      "lng": 54.52581859999997,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:03",
      "speed": 10.804411,
      "direction": 355.16486,
      "lat": 24.35590499999998,
      "lng": 54.52580999999997,
      "dataPoint": false,
      "rpmDivd": 1.268
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:03",
      "speed": 10.804398,
      "direction": 357.143,
      "lat": 24.355905,
      "lng": 54.52581,
      "dataPoint": true,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:03",
      "speed": 11.3573675,
      "direction": 357.143,
      "lat": 24.356010533333333,
      "lng": 54.52580473333333,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:03",
      "speed": 11.910337,
      "direction": 357.143,
      "lat": 24.356116066666665,
      "lng": 54.52579946666666,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:04",
      "speed": 12.463307,
      "direction": 357.143,
      "lat": 24.356221599999998,
      "lng": 54.52579419999999,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:04",
      "speed": 13.016277,
      "direction": 357.143,
      "lat": 24.35632713333333,
      "lng": 54.525788933333324,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:05",
      "speed": 13.569247,
      "direction": 357.143,
      "lat": 24.356432666666663,
      "lng": 54.525783666666655,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:05",
      "speed": 14.122217,
      "direction": 357.143,
      "lat": 24.356538199999996,
      "lng": 54.525778399999986,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:05",
      "speed": 14.675187,
      "direction": 357.143,
      "lat": 24.35664373333333,
      "lng": 54.52577313333332,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:06",
      "speed": 15.228158,
      "direction": 357.143,
      "lat": 24.35674926666666,
      "lng": 54.52576786666665,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:06",
      "speed": 15.781128,
      "direction": 357.143,
      "lat": 24.356854799999994,
      "lng": 54.52576259999998,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:07",
      "speed": 16.334097,
      "direction": 357.143,
      "lat": 24.356960333333326,
      "lng": 54.52575733333331,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:07",
      "speed": 16.887068,
      "direction": 357.143,
      "lat": 24.35706586666666,
      "lng": 54.52575206666664,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:07",
      "speed": 17.440037,
      "direction": 357.143,
      "lat": 24.35717139999999,
      "lng": 54.52574679999997,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:08",
      "speed": 17.993008,
      "direction": 357.143,
      "lat": 24.357276933333324,
      "lng": 54.5257415333333,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:08",
      "speed": 18.545977,
      "direction": 357.143,
      "lat": 24.357382466666657,
      "lng": 54.525736266666634,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:09",
      "speed": 19.098948,
      "direction": 357.143,
      "lat": 24.35748799999999,
      "lng": 54.525730999999965,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:09",
      "speed": 19.651918,
      "direction": 357.143,
      "lat": 24.357593533333322,
      "lng": 54.525725733333296,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:09",
      "speed": 20.204887,
      "direction": 357.143,
      "lat": 24.357699066666655,
      "lng": 54.52572046666663,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:10",
      "speed": 20.757858,
      "direction": 357.143,
      "lat": 24.357804599999987,
      "lng": 54.52571519999996,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:10",
      "speed": 21.310827,
      "direction": 357.143,
      "lat": 24.35791013333332,
      "lng": 54.52570993333329,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:11",
      "speed": 21.863798,
      "direction": 357.143,
      "lat": 24.358015666666653,
      "lng": 54.52570466666662,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:11",
      "speed": 22.416767,
      "direction": 357.143,
      "lat": 24.358121199999985,
      "lng": 54.52569939999995,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:11",
      "speed": 22.969738,
      "direction": 357.143,
      "lat": 24.358226733333318,
      "lng": 54.52569413333328,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:12",
      "speed": 23.522707,
      "direction": 357.143,
      "lat": 24.35833226666665,
      "lng": 54.52568886666661,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:12",
      "speed": 24.075678,
      "direction": 357.143,
      "lat": 24.358437799999983,
      "lng": 54.525683599999944,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:13",
      "speed": 24.628647,
      "direction": 357.143,
      "lat": 24.358543333333316,
      "lng": 54.525678333333275,
      "dataPoint": false,
      "rpmDivd": 0.388
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:13",
      "speed": 24.628658,
      "direction": 357.07632,
      "lat": 24.358543333333333,
      "lng": 54.52567833333333,
      "dataPoint": true,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:13",
      "speed": 24.234959,
      "direction": 357.07632,
      "lat": 24.35864906666667,
      "lng": 54.52567293333333,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:13",
      "speed": 23.841259,
      "direction": 357.07632,
      "lat": 24.358754800000003,
      "lng": 54.52566753333333,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:14",
      "speed": 23.447557,
      "direction": 357.07632,
      "lat": 24.35886053333334,
      "lng": 54.525662133333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:14",
      "speed": 23.053858,
      "direction": 357.07632,
      "lat": 24.358966266666673,
      "lng": 54.525656733333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:15",
      "speed": 22.660158,
      "direction": 357.07632,
      "lat": 24.35907200000001,
      "lng": 54.525651333333336,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:15",
      "speed": 22.266459,
      "direction": 357.07632,
      "lat": 24.359177733333343,
      "lng": 54.52564593333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:15",
      "speed": 21.872759,
      "direction": 357.07632,
      "lat": 24.359283466666678,
      "lng": 54.52564053333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:16",
      "speed": 21.47906,
      "direction": 357.07632,
      "lat": 24.359389200000013,
      "lng": 54.52563513333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:16",
      "speed": 21.085358,
      "direction": 357.07632,
      "lat": 24.359494933333348,
      "lng": 54.52562973333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:17",
      "speed": 20.691658,
      "direction": 357.07632,
      "lat": 24.359600666666683,
      "lng": 54.52562433333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:17",
      "speed": 20.297958,
      "direction": 357.07632,
      "lat": 24.359706400000018,
      "lng": 54.52561893333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:17",
      "speed": 19.904259,
      "direction": 357.07632,
      "lat": 24.359812133333353,
      "lng": 54.52561353333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:18",
      "speed": 19.51056,
      "direction": 357.07632,
      "lat": 24.359917866666688,
      "lng": 54.52560813333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:18",
      "speed": 19.116858,
      "direction": 357.07632,
      "lat": 24.360023600000023,
      "lng": 54.52560273333334,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:19",
      "speed": 18.723158,
      "direction": 357.07632,
      "lat": 24.360129333333358,
      "lng": 54.525597333333344,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:19",
      "speed": 18.329458,
      "direction": 357.07632,
      "lat": 24.360235066666693,
      "lng": 54.525591933333345,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:19",
      "speed": 17.935759,
      "direction": 357.07632,
      "lat": 24.360340800000028,
      "lng": 54.525586533333346,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:20",
      "speed": 17.542059,
      "direction": 357.07632,
      "lat": 24.360446533333363,
      "lng": 54.52558113333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:20",
      "speed": 17.148357,
      "direction": 357.07632,
      "lat": 24.360552266666698,
      "lng": 54.52557573333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:21",
      "speed": 16.754658,
      "direction": 357.07632,
      "lat": 24.360658000000033,
      "lng": 54.52557033333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:21",
      "speed": 16.360958,
      "direction": 357.07632,
      "lat": 24.360763733333368,
      "lng": 54.52556493333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:21",
      "speed": 15.967258,
      "direction": 357.07632,
      "lat": 24.360869466666703,
      "lng": 54.52555953333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:22",
      "speed": 15.573558,
      "direction": 357.07632,
      "lat": 24.360975200000038,
      "lng": 54.52555413333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:22",
      "speed": 15.179858,
      "direction": 357.07632,
      "lat": 24.361080933333373,
      "lng": 54.52554873333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:23",
      "speed": 14.786159,
      "direction": 357.07632,
      "lat": 24.361186666666708,
      "lng": 54.52554333333335,
      "dataPoint": false,
      "rpmDivd": 1.083
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:23",
      "speed": 14.786143,
      "direction": 357.00992,
      "lat": 24.361186666666665,
      "lng": 54.52554333333333,
      "dataPoint": true,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:23",
      "speed": 15.114526,
      "direction": 357.00992,
      "lat": 24.3612926,
      "lng": 54.525537799999995,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:23",
      "speed": 15.442907,
      "direction": 357.00992,
      "lat": 24.361398533333332,
      "lng": 54.52553226666666,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:24",
      "speed": 15.77129,
      "direction": 357.00992,
      "lat": 24.361504466666666,
      "lng": 54.52552673333332,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:24",
      "speed": 16.099672,
      "direction": 357.00992,
      "lat": 24.3616104,
      "lng": 54.525521199999986,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:25",
      "speed": 16.428055,
      "direction": 357.00992,
      "lat": 24.361716333333334,
      "lng": 54.52551566666665,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:25",
      "speed": 16.756437,
      "direction": 357.00992,
      "lat": 24.361822266666668,
      "lng": 54.52551013333331,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:25",
      "speed": 17.08482,
      "direction": 357.00992,
      "lat": 24.3619282,
      "lng": 54.52550459999998,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:26",
      "speed": 17.4132,
      "direction": 357.00992,
      "lat": 24.362034133333335,
      "lng": 54.52549906666664,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:26",
      "speed": 17.741583,
      "direction": 357.00992,
      "lat": 24.36214006666667,
      "lng": 54.525493533333304,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:27",
      "speed": 18.069965,
      "direction": 357.00992,
      "lat": 24.362246000000003,
      "lng": 54.52548799999997,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:27",
      "speed": 18.398348,
      "direction": 357.00992,
      "lat": 24.362351933333336,
      "lng": 54.52548246666663,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:27",
      "speed": 18.72673,
      "direction": 357.00992,
      "lat": 24.36245786666667,
      "lng": 54.525476933333294,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:28",
      "speed": 19.055113,
      "direction": 357.00992,
      "lat": 24.362563800000004,
      "lng": 54.52547139999996,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:28",
      "speed": 19.383495,
      "direction": 357.00992,
      "lat": 24.362669733333338,
      "lng": 54.52546586666662,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:29",
      "speed": 19.711876,
      "direction": 357.00992,
      "lat": 24.36277566666667,
      "lng": 54.525460333333285,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:29",
      "speed": 20.040258,
      "direction": 357.00992,
      "lat": 24.362881600000005,
      "lng": 54.52545479999995,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:29",
      "speed": 20.36864,
      "direction": 357.00992,
      "lat": 24.36298753333334,
      "lng": 54.52544926666661,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:30",
      "speed": 20.697023,
      "direction": 357.00992,
      "lat": 24.363093466666673,
      "lng": 54.525443733333276,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:30",
      "speed": 21.025406,
      "direction": 357.00992,
      "lat": 24.363199400000006,
      "lng": 54.52543819999994,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:31",
      "speed": 21.353788,
      "direction": 357.00992,
      "lat": 24.36330533333334,
      "lng": 54.5254326666666,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:31",
      "speed": 21.68217,
      "direction": 357.00992,
      "lat": 24.363411266666674,
      "lng": 54.52542713333327,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:31",
      "speed": 22.010551,
      "direction": 357.00992,
      "lat": 24.363517200000008,
      "lng": 54.52542159999993,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:32",
      "speed": 22.338934,
      "direction": 357.00992,
      "lat": 24.36362313333334,
      "lng": 54.525416066666594,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:32",
      "speed": 22.667316,
      "direction": 357.00992,
      "lat": 24.363729066666675,
      "lng": 54.52541053333326,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:33",
      "speed": 22.995699,
      "direction": 357.00992,
      "lat": 24.36383500000001,
      "lng": 54.52540499999992,
      "dataPoint": false,
      "rpmDivd": 0.1
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:33",
      "speed": 22.995697,
      "direction": 357.19434,
      "lat": 24.363835,
      "lng": 54.525405,
      "dataPoint": true,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:33",
      "speed": 23.002855,
      "direction": 357.19434,
      "lat": 24.363942466666668,
      "lng": 54.52539973333333,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:33",
      "speed": 23.010012,
      "direction": 357.19434,
      "lat": 24.364049933333334,
      "lng": 54.52539446666666,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:34",
      "speed": 23.01717,
      "direction": 357.19434,
      "lat": 24.3641574,
      "lng": 54.52538919999999,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:34",
      "speed": 23.024328,
      "direction": 357.19434,
      "lat": 24.364264866666666,
      "lng": 54.52538393333332,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:35",
      "speed": 23.031487,
      "direction": 357.19434,
      "lat": 24.364372333333332,
      "lng": 54.525378666666654,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:35",
      "speed": 23.038643,
      "direction": 357.19434,
      "lat": 24.364479799999998,
      "lng": 54.525373399999985,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:35",
      "speed": 23.045801,
      "direction": 357.19434,
      "lat": 24.364587266666664,
      "lng": 54.525368133333316,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:36",
      "speed": 23.05296,
      "direction": 357.19434,
      "lat": 24.36469473333333,
      "lng": 54.52536286666665,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:36",
      "speed": 23.060118,
      "direction": 357.19434,
      "lat": 24.364802199999996,
      "lng": 54.52535759999998,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:37",
      "speed": 23.067274,
      "direction": 357.19434,
      "lat": 24.364909666666662,
      "lng": 54.52535233333331,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:37",
      "speed": 23.074432,
      "direction": 357.19434,
      "lat": 24.36501713333333,
      "lng": 54.52534706666664,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:37",
      "speed": 23.08159,
      "direction": 357.19434,
      "lat": 24.365124599999994,
      "lng": 54.52534179999997,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:38",
      "speed": 23.088749,
      "direction": 357.19434,
      "lat": 24.36523206666666,
      "lng": 54.5253365333333,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:38",
      "speed": 23.095905,
      "direction": 357.19434,
      "lat": 24.365339533333326,
      "lng": 54.52533126666663,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:39",
      "speed": 23.103064,
      "direction": 357.19434,
      "lat": 24.365446999999993,
      "lng": 54.525325999999964,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:39",
      "speed": 23.110222,
      "direction": 357.19434,
      "lat": 24.36555446666666,
      "lng": 54.525320733333295,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:39",
      "speed": 23.11738,
      "direction": 357.19434,
      "lat": 24.365661933333325,
      "lng": 54.525315466666626,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:40",
      "speed": 23.124537,
      "direction": 357.19434,
      "lat": 24.36576939999999,
      "lng": 54.52531019999996,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:40",
      "speed": 23.131695,
      "direction": 357.19434,
      "lat": 24.365876866666657,
      "lng": 54.52530493333329,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:41",
      "speed": 23.138853,
      "direction": 357.19434,
      "lat": 24.365984333333323,
      "lng": 54.52529966666662,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:41",
      "speed": 23.14601,
      "direction": 357.19434,
      "lat": 24.36609179999999,
      "lng": 54.52529439999995,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:41",
      "speed": 23.153168,
      "direction": 357.19434,
      "lat": 24.366199266666655,
      "lng": 54.52528913333328,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:42",
      "speed": 23.160326,
      "direction": 357.19434,
      "lat": 24.36630673333332,
      "lng": 54.52528386666661,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:42",
      "speed": 23.167484,
      "direction": 357.19434,
      "lat": 24.366414199999987,
      "lng": 54.52527859999994,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:43",
      "speed": 23.17464,
      "direction": 357.19434,
      "lat": 24.366521666666653,
      "lng": 54.525273333333274,
      "dataPoint": false,
      "rpmDivd": 0.934
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:43",
      "speed": 23.17465,
      "direction": 356.51932,
      "lat": 24.366521666666667,
      "lng": 54.52527333333333,
      "dataPoint": true,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:43",
      "speed": 22.720106,
      "direction": 356.51932,
      "lat": 24.3666236,
      "lng": 54.52526713333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:43",
      "speed": 22.265562,
      "direction": 356.51932,
      "lat": 24.366725533333334,
      "lng": 54.52526093333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:44",
      "speed": 21.811016,
      "direction": 356.51932,
      "lat": 24.366827466666667,
      "lng": 54.52525473333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:44",
      "speed": 21.356472,
      "direction": 356.51932,
      "lat": 24.3669294,
      "lng": 54.525248533333325,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:45",
      "speed": 20.901928,
      "direction": 356.51932,
      "lat": 24.367031333333333,
      "lng": 54.525242333333324,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:45",
      "speed": 20.447384,
      "direction": 356.51932,
      "lat": 24.367133266666666,
      "lng": 54.52523613333332,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:45",
      "speed": 19.99284,
      "direction": 356.51932,
      "lat": 24.3672352,
      "lng": 54.52522993333332,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:46",
      "speed": 19.538294,
      "direction": 356.51932,
      "lat": 24.367337133333333,
      "lng": 54.52522373333332,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:46",
      "speed": 19.08375,
      "direction": 356.51932,
      "lat": 24.367439066666666,
      "lng": 54.52521753333332,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:47",
      "speed": 18.629206,
      "direction": 356.51932,
      "lat": 24.367541,
      "lng": 54.52521133333332,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:47",
      "speed": 18.174662,
      "direction": 356.51932,
      "lat": 24.367642933333332,
      "lng": 54.525205133333316,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:47",
      "speed": 17.720118,
      "direction": 356.51932,
      "lat": 24.367744866666666,
      "lng": 54.525198933333314,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:48",
      "speed": 17.265572,
      "direction": 356.51932,
      "lat": 24.3678468,
      "lng": 54.52519273333331,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:48",
      "speed": 16.811028,
      "direction": 356.51932,
      "lat": 24.367948733333332,
      "lng": 54.52518653333331,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:49",
      "speed": 16.356483,
      "direction": 356.51932,
      "lat": 24.368050666666665,
      "lng": 54.52518033333331,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:49",
      "speed": 15.901939,
      "direction": 356.51932,
      "lat": 24.3681526,
      "lng": 54.52517413333331,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:49",
      "speed": 15.447394,
      "direction": 356.51932,
      "lat": 24.36825453333333,
      "lng": 54.52516793333331,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:50",
      "speed": 14.99285,
      "direction": 356.51932,
      "lat": 24.368356466666665,
      "lng": 54.525161733333306,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:50",
      "speed": 14.538305,
      "direction": 356.51932,
      "lat": 24.368458399999998,
      "lng": 54.525155533333304,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:51",
      "speed": 14.083761,
      "direction": 356.51932,
      "lat": 24.36856033333333,
      "lng": 54.5251493333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:51",
      "speed": 13.629217,
      "direction": 356.51932,
      "lat": 24.368662266666664,
      "lng": 54.5251431333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:51",
      "speed": 13.174672,
      "direction": 356.51932,
      "lat": 24.368764199999998,
      "lng": 54.5251369333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:52",
      "speed": 12.720128,
      "direction": 356.51932,
      "lat": 24.36886613333333,
      "lng": 54.5251307333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:52",
      "speed": 12.265584,
      "direction": 356.51932,
      "lat": 24.368968066666664,
      "lng": 54.5251245333333,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:53",
      "speed": 11.811039,
      "direction": 356.51932,
      "lat": 24.369069999999997,
      "lng": 54.525118333333296,
      "dataPoint": false,
      "rpmDivd": 1.342
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:53",
      "speed": 11.811019,
      "direction": 356.94666,
      "lat": 24.36907,
      "lng": 54.52511833333333,
      "dataPoint": true,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:53",
      "speed": 12.203824,
      "direction": 356.94666,
      "lat": 24.369178733333335,
      "lng": 54.525112533333335,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:53",
      "speed": 12.59663,
      "direction": 356.94666,
      "lat": 24.36928746666667,
      "lng": 54.52510673333334,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:54",
      "speed": 12.989435,
      "direction": 356.94666,
      "lat": 24.369396200000004,
      "lng": 54.52510093333334,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:54",
      "speed": 13.382241,
      "direction": 356.94666,
      "lat": 24.36950493333334,
      "lng": 54.525095133333345,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:55",
      "speed": 13.775046,
      "direction": 356.94666,
      "lat": 24.369613666666673,
      "lng": 54.52508933333335,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:55",
      "speed": 14.167852,
      "direction": 356.94666,
      "lat": 24.369722400000008,
      "lng": 54.52508353333335,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:55",
      "speed": 14.5606575,
      "direction": 356.94666,
      "lat": 24.369831133333342,
      "lng": 54.525077733333355,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:56",
      "speed": 14.953464,
      "direction": 356.94666,
      "lat": 24.369939866666677,
      "lng": 54.52507193333336,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:56",
      "speed": 15.346269,
      "direction": 356.94666,
      "lat": 24.37004860000001,
      "lng": 54.52506613333336,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:57",
      "speed": 15.739075,
      "direction": 356.94666,
      "lat": 24.370157333333346,
      "lng": 54.525060333333364,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:57",
      "speed": 16.13188,
      "direction": 356.94666,
      "lat": 24.37026606666668,
      "lng": 54.52505453333337,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:57",
      "speed": 16.524685,
      "direction": 356.94666,
      "lat": 24.370374800000015,
      "lng": 54.52504873333337,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:58",
      "speed": 16.917492,
      "direction": 356.94666,
      "lat": 24.37048353333335,
      "lng": 54.525042933333374,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:58",
      "speed": 17.310297,
      "direction": 356.94666,
      "lat": 24.370592266666684,
      "lng": 54.52503713333338,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:59",
      "speed": 17.703102,
      "direction": 356.94666,
      "lat": 24.370701000000018,
      "lng": 54.52503133333338,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:59",
      "speed": 18.095907,
      "direction": 356.94666,
      "lat": 24.370809733333353,
      "lng": 54.525025533333384,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:28:59",
      "speed": 18.488714,
      "direction": 356.94666,
      "lat": 24.370918466666687,
      "lng": 54.52501973333339,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:00",
      "speed": 18.88152,
      "direction": 356.94666,
      "lat": 24.37102720000002,
      "lng": 54.52501393333339,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:00",
      "speed": 19.274324,
      "direction": 356.94666,
      "lat": 24.371135933333356,
      "lng": 54.525008133333394,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:01",
      "speed": 19.66713,
      "direction": 356.94666,
      "lat": 24.37124466666669,
      "lng": 54.5250023333334,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:01",
      "speed": 20.059937,
      "direction": 356.94666,
      "lat": 24.371353400000025,
      "lng": 54.5249965333334,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:01",
      "speed": 20.452742,
      "direction": 356.94666,
      "lat": 24.37146213333336,
      "lng": 54.524990733333404,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:02",
      "speed": 20.845547,
      "direction": 356.94666,
      "lat": 24.371570866666694,
      "lng": 54.52498493333341,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:02",
      "speed": 21.238352,
      "direction": 356.94666,
      "lat": 24.37167960000003,
      "lng": 54.52497913333341,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:03",
      "speed": 21.631157,
      "direction": 356.94666,
      "lat": 24.371788333333363,
      "lng": 54.52497333333341,
      "dataPoint": false,
      "rpmDivd": 0.144
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:03",
      "speed": 21.631166,
      "direction": 357.24695,
      "lat": 24.371788333333335,
      "lng": 54.524973333333335,
      "dataPoint": true,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:03",
      "speed": 21.310837,
      "direction": 357.24695,
      "lat": 24.372107200000002,
      "lng": 54.524958000000005,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:03",
      "speed": 20.990509,
      "direction": 357.24695,
      "lat": 24.37242606666667,
      "lng": 54.524942666666675,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:04",
      "speed": 20.67018,
      "direction": 357.24695,
      "lat": 24.372744933333337,
      "lng": 54.524927333333345,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:04",
      "speed": 20.349852,
      "direction": 357.24695,
      "lat": 24.373063800000004,
      "lng": 54.524912000000015,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:05",
      "speed": 20.029522,
      "direction": 357.24695,
      "lat": 24.37338266666667,
      "lng": 54.524896666666685,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:05",
      "speed": 19.709192,
      "direction": 357.24695,
      "lat": 24.37370153333334,
      "lng": 54.524881333333354,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:05",
      "speed": 19.388865,
      "direction": 357.24695,
      "lat": 24.374020400000006,
      "lng": 54.524866000000024,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:06",
      "speed": 19.068535,
      "direction": 357.24695,
      "lat": 24.374339266666674,
      "lng": 54.524850666666694,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:06",
      "speed": 18.748207,
      "direction": 357.24695,
      "lat": 24.37465813333334,
      "lng": 54.524835333333364,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:07",
      "speed": 18.427877,
      "direction": 357.24695,
      "lat": 24.37497700000001,
      "lng": 54.524820000000034,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:07",
      "speed": 18.107548,
      "direction": 357.24695,
      "lat": 24.375295866666676,
      "lng": 54.524804666666704,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:07",
      "speed": 17.78722,
      "direction": 357.24695,
      "lat": 24.375614733333343,
      "lng": 54.52478933333337,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:08",
      "speed": 17.46689,
      "direction": 357.24695,
      "lat": 24.37593360000001,
      "lng": 54.52477400000004,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:08",
      "speed": 17.146563,
      "direction": 357.24695,
      "lat": 24.376252466666678,
      "lng": 54.52475866666671,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:09",
      "speed": 16.826233,
      "direction": 357.24695,
      "lat": 24.376571333333345,
      "lng": 54.52474333333338,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:09",
      "speed": 16.505905,
      "direction": 357.24695,
      "lat": 24.376890200000012,
      "lng": 54.52472800000005,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:09",
      "speed": 16.185575,
      "direction": 357.24695,
      "lat": 24.37720906666668,
      "lng": 54.52471266666672,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:10",
      "speed": 15.865247,
      "direction": 357.24695,
      "lat": 24.377527933333347,
      "lng": 54.52469733333339,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:10",
      "speed": 15.544917,
      "direction": 357.24695,
      "lat": 24.377846800000015,
      "lng": 54.52468200000006,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:11",
      "speed": 15.224588,
      "direction": 357.24695,
      "lat": 24.378165666666682,
      "lng": 54.52466666666673,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:11",
      "speed": 14.90426,
      "direction": 357.24695,
      "lat": 24.37848453333335,
      "lng": 54.5246513333334,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:11",
      "speed": 14.583931,
      "direction": 357.24695,
      "lat": 24.378803400000017,
      "lng": 54.52463600000007,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:12",
      "speed": 14.263602,
      "direction": 357.24695,
      "lat": 24.379122266666684,
      "lng": 54.52462066666674,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:12",
      "speed": 13.943274,
      "direction": 357.24695,
      "lat": 24.37944113333335,
      "lng": 54.52460533333341,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:13",
      "speed": 13.622944,
      "direction": 357.24695,
      "lat": 24.37976000000002,
      "lng": 54.52459000000008,
      "dataPoint": false,
      "rpmDivd": 1.281
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:13",
      "speed": 13.622937,
      "direction": 356.91455,
      "lat": 24.37976,
      "lng": 54.52459,
      "dataPoint": true,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:13",
      "speed": 13.414455,
      "direction": 356.91455,
      "lat": 24.3798676,
      "lng": 54.52458420000001,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:13",
      "speed": 13.205973,
      "direction": 356.91455,
      "lat": 24.3799752,
      "lng": 54.52457840000001,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:14",
      "speed": 12.997491,
      "direction": 356.91455,
      "lat": 24.3800828,
      "lng": 54.52457260000001,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:14",
      "speed": 12.789008,
      "direction": 356.91455,
      "lat": 24.3801904,
      "lng": 54.52456680000002,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:15",
      "speed": 12.580526,
      "direction": 356.91455,
      "lat": 24.380298,
      "lng": 54.52456100000002,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:15",
      "speed": 12.372044,
      "direction": 356.91455,
      "lat": 24.3804056,
      "lng": 54.52455520000002,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:15",
      "speed": 12.163562,
      "direction": 356.91455,
      "lat": 24.3805132,
      "lng": 54.524549400000026,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:16",
      "speed": 11.955079,
      "direction": 356.91455,
      "lat": 24.3806208,
      "lng": 54.52454360000003,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:16",
      "speed": 11.746597,
      "direction": 356.91455,
      "lat": 24.3807284,
      "lng": 54.52453780000003,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:17",
      "speed": 11.538115,
      "direction": 356.91455,
      "lat": 24.380836,
      "lng": 54.524532000000036,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:17",
      "speed": 11.329633,
      "direction": 356.91455,
      "lat": 24.3809436,
      "lng": 54.52452620000004,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:17",
      "speed": 11.121151,
      "direction": 356.91455,
      "lat": 24.381051199999998,
      "lng": 54.52452040000004,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:18",
      "speed": 10.912668,
      "direction": 356.91455,
      "lat": 24.381158799999998,
      "lng": 54.524514600000046,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:18",
      "speed": 10.704186,
      "direction": 356.91455,
      "lat": 24.381266399999998,
      "lng": 54.52450880000005,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:19",
      "speed": 10.495704,
      "direction": 356.91455,
      "lat": 24.381373999999997,
      "lng": 54.52450300000005,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:19",
      "speed": 10.287222,
      "direction": 356.91455,
      "lat": 24.381481599999997,
      "lng": 54.524497200000056,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:19",
      "speed": 10.078739,
      "direction": 356.91455,
      "lat": 24.381589199999997,
      "lng": 54.52449140000006,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:20",
      "speed": 9.870257,
      "direction": 356.91455,
      "lat": 24.381696799999997,
      "lng": 54.52448560000006,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:20",
      "speed": 9.661775,
      "direction": 356.91455,
      "lat": 24.381804399999996,
      "lng": 54.524479800000066,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:21",
      "speed": 9.453293,
      "direction": 356.91455,
      "lat": 24.381911999999996,
      "lng": 54.52447400000007,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:21",
      "speed": 9.24481,
      "direction": 356.91455,
      "lat": 24.382019599999996,
      "lng": 54.52446820000007,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:21",
      "speed": 9.036328,
      "direction": 356.91455,
      "lat": 24.382127199999996,
      "lng": 54.524462400000075,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:22",
      "speed": 8.827847,
      "direction": 356.91455,
      "lat": 24.382234799999996,
      "lng": 54.52445660000008,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:22",
      "speed": 8.619364,
      "direction": 356.91455,
      "lat": 24.382342399999995,
      "lng": 54.52445080000008,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:23",
      "speed": 8.410882,
      "direction": 356.91455,
      "lat": 24.382449999999995,
      "lng": 54.524445000000085,
      "dataPoint": false,
      "rpmDivd": 1.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:23",
      "speed": 8.410877,
      "direction": 357.07162,
      "lat": 24.38245,
      "lng": 54.524445,
      "dataPoint": true,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:23",
      "speed": 8.5298815,
      "direction": 357.07162,
      "lat": 24.382556866666665,
      "lng": 54.524439533333336,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:23",
      "speed": 8.648886,
      "direction": 357.07162,
      "lat": 24.38266373333333,
      "lng": 54.52443406666667,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:24",
      "speed": 8.767891,
      "direction": 357.07162,
      "lat": 24.382770599999997,
      "lng": 54.52442860000001,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:24",
      "speed": 8.886895,
      "direction": 357.07162,
      "lat": 24.382877466666663,
      "lng": 54.52442313333334,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:25",
      "speed": 9.005899,
      "direction": 357.07162,
      "lat": 24.38298433333333,
      "lng": 54.52441766666668,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:25",
      "speed": 9.124904,
      "direction": 357.07162,
      "lat": 24.383091199999996,
      "lng": 54.524412200000015,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:25",
      "speed": 9.243908,
      "direction": 357.07162,
      "lat": 24.38319806666666,
      "lng": 54.52440673333335,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:26",
      "speed": 9.362913,
      "direction": 357.07162,
      "lat": 24.383304933333328,
      "lng": 54.524401266666686,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:26",
      "speed": 9.481917,
      "direction": 357.07162,
      "lat": 24.383411799999994,
      "lng": 54.52439580000002,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:27",
      "speed": 9.600922,
      "direction": 357.07162,
      "lat": 24.38351866666666,
      "lng": 54.52439033333336,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:27",
      "speed": 9.719926,
      "direction": 357.07162,
      "lat": 24.383625533333326,
      "lng": 54.52438486666669,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:27",
      "speed": 9.83893,
      "direction": 357.07162,
      "lat": 24.383732399999992,
      "lng": 54.52437940000003,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:28",
      "speed": 9.957935,
      "direction": 357.07162,
      "lat": 24.38383926666666,
      "lng": 54.524373933333365,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:28",
      "speed": 10.07694,
      "direction": 357.07162,
      "lat": 24.383946133333325,
      "lng": 54.5243684666667,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:29",
      "speed": 10.195944,
      "direction": 357.07162,
      "lat": 24.38405299999999,
      "lng": 54.52436300000004,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:29",
      "speed": 10.314948,
      "direction": 357.07162,
      "lat": 24.384159866666657,
      "lng": 54.52435753333337,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:29",
      "speed": 10.433952,
      "direction": 357.07162,
      "lat": 24.384266733333323,
      "lng": 54.52435206666671,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:30",
      "speed": 10.552958,
      "direction": 357.07162,
      "lat": 24.38437359999999,
      "lng": 54.524346600000044,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:30",
      "speed": 10.671962,
      "direction": 357.07162,
      "lat": 24.384480466666655,
      "lng": 54.52434113333338,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:31",
      "speed": 10.790966,
      "direction": 357.07162,
      "lat": 24.38458733333332,
      "lng": 54.524335666666715,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:31",
      "speed": 10.90997,
      "direction": 357.07162,
      "lat": 24.384694199999988,
      "lng": 54.52433020000005,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:31",
      "speed": 11.028975,
      "direction": 357.07162,
      "lat": 24.384801066666654,
      "lng": 54.52432473333339,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:32",
      "speed": 11.14798,
      "direction": 357.07162,
      "lat": 24.38490793333332,
      "lng": 54.52431926666672,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:32",
      "speed": 11.266984,
      "direction": 357.07162,
      "lat": 24.385014799999986,
      "lng": 54.52431380000006,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:33",
      "speed": 11.385988,
      "direction": 357.07162,
      "lat": 24.385121666666652,
      "lng": 54.524308333333394,
      "dataPoint": false,
      "rpmDivd": 0.705
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:33",
      "speed": 11.386002,
      "direction": 357.2957,
      "lat": 24.385121666666667,
      "lng": 54.52430833333333,
      "dataPoint": true,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:33",
      "speed": 11.658907,
      "direction": 357.2957,
      "lat": 24.385228933333334,
      "lng": 54.524303266666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:33",
      "speed": 11.931812,
      "direction": 357.2957,
      "lat": 24.3853362,
      "lng": 54.5242982,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:34",
      "speed": 12.204719,
      "direction": 357.2957,
      "lat": 24.38544346666667,
      "lng": 54.52429313333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:34",
      "speed": 12.477624,
      "direction": 357.2957,
      "lat": 24.385550733333336,
      "lng": 54.524288066666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:35",
      "speed": 12.750529,
      "direction": 357.2957,
      "lat": 24.385658000000003,
      "lng": 54.524283,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:35",
      "speed": 13.023435,
      "direction": 357.2957,
      "lat": 24.38576526666667,
      "lng": 54.52427793333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:35",
      "speed": 13.296341,
      "direction": 357.2957,
      "lat": 24.385872533333337,
      "lng": 54.524272866666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:36",
      "speed": 13.569246,
      "direction": 357.2957,
      "lat": 24.385979800000005,
      "lng": 54.5242678,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:36",
      "speed": 13.842152,
      "direction": 357.2957,
      "lat": 24.386087066666672,
      "lng": 54.52426273333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:37",
      "speed": 14.115057,
      "direction": 357.2957,
      "lat": 24.38619433333334,
      "lng": 54.524257666666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:37",
      "speed": 14.387962,
      "direction": 357.2957,
      "lat": 24.386301600000007,
      "lng": 54.5242526,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:37",
      "speed": 14.660869,
      "direction": 357.2957,
      "lat": 24.386408866666674,
      "lng": 54.52424753333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:38",
      "speed": 14.933774,
      "direction": 357.2957,
      "lat": 24.38651613333334,
      "lng": 54.524242466666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:38",
      "speed": 15.206679,
      "direction": 357.2957,
      "lat": 24.38662340000001,
      "lng": 54.5242374,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:39",
      "speed": 15.479585,
      "direction": 357.2957,
      "lat": 24.386730666666676,
      "lng": 54.52423233333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:39",
      "speed": 15.75249,
      "direction": 357.2957,
      "lat": 24.386837933333343,
      "lng": 54.524227266666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:39",
      "speed": 16.025396,
      "direction": 357.2957,
      "lat": 24.38694520000001,
      "lng": 54.5242222,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:40",
      "speed": 16.298302,
      "direction": 357.2957,
      "lat": 24.387052466666677,
      "lng": 54.52421713333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:40",
      "speed": 16.571207,
      "direction": 357.2957,
      "lat": 24.387159733333345,
      "lng": 54.524212066666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:41",
      "speed": 16.844112,
      "direction": 357.2957,
      "lat": 24.387267000000012,
      "lng": 54.524207,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:41",
      "speed": 17.117018,
      "direction": 357.2957,
      "lat": 24.38737426666668,
      "lng": 54.52420193333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:41",
      "speed": 17.389923,
      "direction": 357.2957,
      "lat": 24.387481533333347,
      "lng": 54.524196866666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:42",
      "speed": 17.662828,
      "direction": 357.2957,
      "lat": 24.387588800000014,
      "lng": 54.5241918,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:42",
      "speed": 17.935736,
      "direction": 357.2957,
      "lat": 24.38769606666668,
      "lng": 54.52418673333333,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:43",
      "speed": 18.208641,
      "direction": 357.2957,
      "lat": 24.38780333333335,
      "lng": 54.524181666666664,
      "dataPoint": false,
      "rpmDivd": 1.18
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:43",
      "speed": 18.208654,
      "direction": 357.37164,
      "lat": 24.387803333333334,
      "lng": 54.524181666666664,
      "dataPoint": true,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:43",
      "speed": 17.989435,
      "direction": 357.37164,
      "lat": 24.3879108,
      "lng": 54.52417673333333,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:43",
      "speed": 17.770214,
      "direction": 357.37164,
      "lat": 24.388018266666666,
      "lng": 54.52417179999999,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:44",
      "speed": 17.550995,
      "direction": 357.37164,
      "lat": 24.388125733333332,
      "lng": 54.524166866666654,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:44",
      "speed": 17.331774,
      "direction": 357.37164,
      "lat": 24.3882332,
      "lng": 54.52416193333332,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:45",
      "speed": 17.112555,
      "direction": 357.37164,
      "lat": 24.388340666666664,
      "lng": 54.52415699999998,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:45",
      "speed": 16.893335,
      "direction": 357.37164,
      "lat": 24.38844813333333,
      "lng": 54.524152066666645,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:45",
      "speed": 16.674114,
      "direction": 357.37164,
      "lat": 24.388555599999997,
      "lng": 54.52414713333331,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:46",
      "speed": 16.454895,
      "direction": 357.37164,
      "lat": 24.388663066666663,
      "lng": 54.52414219999997,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:46",
      "speed": 16.235674,
      "direction": 357.37164,
      "lat": 24.38877053333333,
      "lng": 54.524137266666635,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:47",
      "speed": 16.016455,
      "direction": 357.37164,
      "lat": 24.388877999999995,
      "lng": 54.5241323333333,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:47",
      "speed": 15.797235,
      "direction": 357.37164,
      "lat": 24.38898546666666,
      "lng": 54.52412739999996,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:47",
      "speed": 15.578014,
      "direction": 357.37164,
      "lat": 24.389092933333327,
      "lng": 54.524122466666626,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:48",
      "speed": 15.358794,
      "direction": 357.37164,
      "lat": 24.389200399999993,
      "lng": 54.52411753333329,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:48",
      "speed": 15.139574,
      "direction": 357.37164,
      "lat": 24.38930786666666,
      "lng": 54.52411259999995,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:49",
      "speed": 14.920355,
      "direction": 357.37164,
      "lat": 24.389415333333325,
      "lng": 54.524107666666616,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:49",
      "speed": 14.701135,
      "direction": 357.37164,
      "lat": 24.38952279999999,
      "lng": 54.52410273333328,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:49",
      "speed": 14.4819145,
      "direction": 357.37164,
      "lat": 24.389630266666657,
      "lng": 54.52409779999994,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:50",
      "speed": 14.262694,
      "direction": 357.37164,
      "lat": 24.389737733333323,
      "lng": 54.52409286666661,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:50",
      "speed": 14.043474,
      "direction": 357.37164,
      "lat": 24.38984519999999,
      "lng": 54.52408793333327,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:51",
      "speed": 13.824254,
      "direction": 357.37164,
      "lat": 24.389952666666655,
      "lng": 54.52408299999993,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:51",
      "speed": 13.605035,
      "direction": 357.37164,
      "lat": 24.39006013333332,
      "lng": 54.5240780666666,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:51",
      "speed": 13.385815,
      "direction": 357.37164,
      "lat": 24.390167599999987,
      "lng": 54.52407313333326,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:52",
      "speed": 13.1665945,
      "direction": 357.37164,
      "lat": 24.390275066666653,
      "lng": 54.524068199999924,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:52",
      "speed": 12.947374,
      "direction": 357.37164,
      "lat": 24.39038253333332,
      "lng": 54.52406326666659,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:53",
      "speed": 12.728154,
      "direction": 357.37164,
      "lat": 24.390489999999986,
      "lng": 54.52405833333325,
      "dataPoint": false,
      "rpmDivd": 0.765
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:53",
      "speed": 12.728163,
      "direction": 357.4728,
      "lat": 24.39049,
      "lng": 54.524058333333336,
      "dataPoint": true,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:53",
      "speed": 12.414097,
      "direction": 357.4728,
      "lat": 24.390595733333335,
      "lng": 54.52405366666667,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:53",
      "speed": 12.100032,
      "direction": 357.4728,
      "lat": 24.39070146666667,
      "lng": 54.524049,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:54",
      "speed": 11.785966,
      "direction": 357.4728,
      "lat": 24.390807200000005,
      "lng": 54.52404433333333,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:54",
      "speed": 11.471901,
      "direction": 357.4728,
      "lat": 24.39091293333334,
      "lng": 54.52403966666666,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:55",
      "speed": 11.157835,
      "direction": 357.4728,
      "lat": 24.391018666666675,
      "lng": 54.52403499999999,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:55",
      "speed": 10.843769,
      "direction": 357.4728,
      "lat": 24.39112440000001,
      "lng": 54.52403033333332,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:55",
      "speed": 10.529704,
      "direction": 357.4728,
      "lat": 24.391230133333345,
      "lng": 54.52402566666665,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:56",
      "speed": 10.215638,
      "direction": 357.4728,
      "lat": 24.39133586666668,
      "lng": 54.52402099999998,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:56",
      "speed": 9.901573,
      "direction": 357.4728,
      "lat": 24.391441600000014,
      "lng": 54.524016333333314,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:57",
      "speed": 9.587507,
      "direction": 357.4728,
      "lat": 24.39154733333335,
      "lng": 54.524011666666645,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:57",
      "speed": 9.273441,
      "direction": 357.4728,
      "lat": 24.391653066666684,
      "lng": 54.524006999999976,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:57",
      "speed": 8.959376,
      "direction": 357.4728,
      "lat": 24.39175880000002,
      "lng": 54.52400233333331,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:58",
      "speed": 8.64531,
      "direction": 357.4728,
      "lat": 24.391864533333354,
      "lng": 54.52399766666664,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:58",
      "speed": 8.331245,
      "direction": 357.4728,
      "lat": 24.39197026666669,
      "lng": 54.52399299999997,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:59",
      "speed": 8.0171795,
      "direction": 357.4728,
      "lat": 24.392076000000024,
      "lng": 54.5239883333333,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:59",
      "speed": 7.703114,
      "direction": 357.4728,
      "lat": 24.39218173333336,
      "lng": 54.52398366666663,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:29:59",
      "speed": 7.389048,
      "direction": 357.4728,
      "lat": 24.392287466666694,
      "lng": 54.52397899999996,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:00",
      "speed": 7.0749826,
      "direction": 357.4728,
      "lat": 24.39239320000003,
      "lng": 54.52397433333329,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:00",
      "speed": 6.760917,
      "direction": 357.4728,
      "lat": 24.392498933333364,
      "lng": 54.52396966666662,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:01",
      "speed": 6.4468517,
      "direction": 357.4728,
      "lat": 24.3926046666667,
      "lng": 54.523964999999954,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:01",
      "speed": 6.1327863,
      "direction": 357.4728,
      "lat": 24.392710400000034,
      "lng": 54.523960333333285,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:01",
      "speed": 5.8187203,
      "direction": 357.4728,
      "lat": 24.39281613333337,
      "lng": 54.523955666666616,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:02",
      "speed": 5.504655,
      "direction": 357.4728,
      "lat": 24.392921866666704,
      "lng": 54.52395099999995,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:02",
      "speed": 5.1905894,
      "direction": 357.4728,
      "lat": 24.39302760000004,
      "lng": 54.52394633333328,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:03",
      "speed": 4.876524,
      "direction": 357.4728,
      "lat": 24.393133333333374,
      "lng": 54.52394166666661,
      "dataPoint": false,
      "rpmDivd": 0.066
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:03",
      "speed": 4.876519,
      "direction": 357.9669,
      "lat": 24.393133333333335,
      "lng": 54.523941666666666,
      "dataPoint": true,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:03",
      "speed": 5.5279145,
      "direction": 357.9669,
      "lat": 24.393244133333337,
      "lng": 54.523937733333334,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:03",
      "speed": 6.1793103,
      "direction": 357.9669,
      "lat": 24.393354933333338,
      "lng": 54.5239338,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:04",
      "speed": 6.8307056,
      "direction": 357.9669,
      "lat": 24.39346573333334,
      "lng": 54.52392986666667,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:04",
      "speed": 7.4821014,
      "direction": 357.9669,
      "lat": 24.39357653333334,
      "lng": 54.52392593333334,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:05",
      "speed": 8.133497,
      "direction": 357.9669,
      "lat": 24.393687333333343,
      "lng": 54.523922000000006,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:05",
      "speed": 8.784892,
      "direction": 357.9669,
      "lat": 24.393798133333345,
      "lng": 54.523918066666674,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:05",
      "speed": 9.436288,
      "direction": 357.9669,
      "lat": 24.393908933333346,
      "lng": 54.52391413333334,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:06",
      "speed": 10.087684,
      "direction": 357.9669,
      "lat": 24.394019733333348,
      "lng": 54.52391020000001,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:06",
      "speed": 10.739079,
      "direction": 357.9669,
      "lat": 24.39413053333335,
      "lng": 54.52390626666668,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:07",
      "speed": 11.390474,
      "direction": 357.9669,
      "lat": 24.39424133333335,
      "lng": 54.523902333333346,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:07",
      "speed": 12.04187,
      "direction": 357.9669,
      "lat": 24.394352133333353,
      "lng": 54.523898400000014,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:07",
      "speed": 12.693266,
      "direction": 357.9669,
      "lat": 24.394462933333354,
      "lng": 54.52389446666668,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:08",
      "speed": 13.344662,
      "direction": 357.9669,
      "lat": 24.394573733333356,
      "lng": 54.52389053333335,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:08",
      "speed": 13.996057,
      "direction": 357.9669,
      "lat": 24.394684533333358,
      "lng": 54.52388660000002,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:09",
      "speed": 14.647452,
      "direction": 357.9669,
      "lat": 24.39479533333336,
      "lng": 54.52388266666669,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:09",
      "speed": 15.298848,
      "direction": 357.9669,
      "lat": 24.39490613333336,
      "lng": 54.523878733333355,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:09",
      "speed": 15.950244,
      "direction": 357.9669,
      "lat": 24.395016933333363,
      "lng": 54.52387480000002,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:10",
      "speed": 16.601639,
      "direction": 357.9669,
      "lat": 24.395127733333364,
      "lng": 54.52387086666669,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:10",
      "speed": 17.253035,
      "direction": 357.9669,
      "lat": 24.395238533333366,
      "lng": 54.52386693333336,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:11",
      "speed": 17.90443,
      "direction": 357.9669,
      "lat": 24.395349333333368,
      "lng": 54.52386300000003,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:11",
      "speed": 18.555826,
      "direction": 357.9669,
      "lat": 24.39546013333337,
      "lng": 54.523859066666695,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:11",
      "speed": 19.207222,
      "direction": 357.9669,
      "lat": 24.39557093333337,
      "lng": 54.52385513333336,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:12",
      "speed": 19.858618,
      "direction": 357.9669,
      "lat": 24.395681733333372,
      "lng": 54.52385120000003,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:12",
      "speed": 20.510012,
      "direction": 357.9669,
      "lat": 24.395792533333374,
      "lng": 54.5238472666667,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:13",
      "speed": 21.161407,
      "direction": 357.9669,
      "lat": 24.395903333333376,
      "lng": 54.52384333333337,
      "dataPoint": false,
      "rpmDivd": 0.809
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:13",
      "speed": 21.16141,
      "direction": 357.70374,
      "lat": 24.395903333333333,
      "lng": 54.52384333333333,
      "dataPoint": true,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:13",
      "speed": 21.243729,
      "direction": 357.70374,
      "lat": 24.3960114,
      "lng": 54.523838999999995,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:13",
      "speed": 21.326048,
      "direction": 357.70374,
      "lat": 24.396119466666665,
      "lng": 54.52383466666666,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:14",
      "speed": 21.408365,
      "direction": 357.70374,
      "lat": 24.39622753333333,
      "lng": 54.52383033333332,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:14",
      "speed": 21.490685,
      "direction": 357.70374,
      "lat": 24.396335599999997,
      "lng": 54.523825999999985,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:15",
      "speed": 21.573004,
      "direction": 357.70374,
      "lat": 24.396443666666663,
      "lng": 54.52382166666665,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:15",
      "speed": 21.655323,
      "direction": 357.70374,
      "lat": 24.39655173333333,
      "lng": 54.52381733333331,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:15",
      "speed": 21.737642,
      "direction": 357.70374,
      "lat": 24.396659799999995,
      "lng": 54.523812999999976,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:16",
      "speed": 21.81996,
      "direction": 357.70374,
      "lat": 24.39676786666666,
      "lng": 54.52380866666664,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:16",
      "speed": 21.902279,
      "direction": 357.70374,
      "lat": 24.396875933333327,
      "lng": 54.5238043333333,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:17",
      "speed": 21.984598,
      "direction": 357.70374,
      "lat": 24.396983999999993,
      "lng": 54.523799999999966,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:17",
      "speed": 22.066917,
      "direction": 357.70374,
      "lat": 24.39709206666666,
      "lng": 54.52379566666663,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:17",
      "speed": 22.149237,
      "direction": 357.70374,
      "lat": 24.397200133333325,
      "lng": 54.52379133333329,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:18",
      "speed": 22.231554,
      "direction": 357.70374,
      "lat": 24.39730819999999,
      "lng": 54.523786999999956,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:18",
      "speed": 22.313873,
      "direction": 357.70374,
      "lat": 24.397416266666657,
      "lng": 54.52378266666662,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:19",
      "speed": 22.396193,
      "direction": 357.70374,
      "lat": 24.397524333333322,
      "lng": 54.52377833333328,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:19",
      "speed": 22.478512,
      "direction": 357.70374,
      "lat": 24.39763239999999,
      "lng": 54.523773999999946,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:19",
      "speed": 22.560831,
      "direction": 357.70374,
      "lat": 24.397740466666654,
      "lng": 54.52376966666661,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:20",
      "speed": 22.64315,
      "direction": 357.70374,
      "lat": 24.39784853333332,
      "lng": 54.52376533333327,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:20",
      "speed": 22.725468,
      "direction": 357.70374,
      "lat": 24.397956599999986,
      "lng": 54.523760999999936,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:21",
      "speed": 22.807787,
      "direction": 357.70374,
      "lat": 24.398064666666652,
      "lng": 54.5237566666666,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:21",
      "speed": 22.890106,
      "direction": 357.70374,
      "lat": 24.39817273333332,
      "lng": 54.52375233333326,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:21",
      "speed": 22.972425,
      "direction": 357.70374,
      "lat": 24.398280799999984,
      "lng": 54.52374799999993,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:22",
      "speed": 23.054745,
      "direction": 357.70374,
      "lat": 24.39838886666665,
      "lng": 54.52374366666659,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:22",
      "speed": 23.137062,
      "direction": 357.70374,
      "lat": 24.398496933333316,
      "lng": 54.52373933333325,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:23",
      "speed": 23.219381,
      "direction": 357.70374,
      "lat": 24.398604999999982,
      "lng": 54.52373499999992,
      "dataPoint": false,
      "rpmDivd": 0.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:23",
      "speed": 23.21939,
      "direction": 357.8367,
      "lat": 24.398605,
      "lng": 54.523735,
      "dataPoint": true,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:23",
      "speed": 22.719212,
      "direction": 357.8367,
      "lat": 24.398702066666665,
      "lng": 54.52373133333334,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:23",
      "speed": 22.219032,
      "direction": 357.8367,
      "lat": 24.39879913333333,
      "lng": 54.52372766666667,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:24",
      "speed": 21.718855,
      "direction": 357.8367,
      "lat": 24.398896199999996,
      "lng": 54.52372400000001,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:24",
      "speed": 21.218676,
      "direction": 357.8367,
      "lat": 24.39899326666666,
      "lng": 54.523720333333344,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:25",
      "speed": 20.718496,
      "direction": 357.8367,
      "lat": 24.399090333333326,
      "lng": 54.52371666666668,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:25",
      "speed": 20.218317,
      "direction": 357.8367,
      "lat": 24.39918739999999,
      "lng": 54.523713000000015,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:25",
      "speed": 19.718138,
      "direction": 357.8367,
      "lat": 24.399284466666657,
      "lng": 54.52370933333335,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:26",
      "speed": 19.21796,
      "direction": 357.8367,
      "lat": 24.399381533333322,
      "lng": 54.523705666666686,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:26",
      "speed": 18.717781,
      "direction": 357.8367,
      "lat": 24.399478599999988,
      "lng": 54.52370200000002,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:27",
      "speed": 18.217602,
      "direction": 357.8367,
      "lat": 24.399575666666653,
      "lng": 54.52369833333336,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:27",
      "speed": 17.717422,
      "direction": 357.8367,
      "lat": 24.39967273333332,
      "lng": 54.52369466666669,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:27",
      "speed": 17.217245,
      "direction": 357.8367,
      "lat": 24.399769799999984,
      "lng": 54.52369100000003,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:28",
      "speed": 16.717066,
      "direction": 357.8367,
      "lat": 24.39986686666665,
      "lng": 54.52368733333336,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:28",
      "speed": 16.216887,
      "direction": 357.8367,
      "lat": 24.399963933333314,
      "lng": 54.5236836666667,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:29",
      "speed": 15.716707,
      "direction": 357.8367,
      "lat": 24.40006099999998,
      "lng": 54.523680000000034,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:29",
      "speed": 15.216529,
      "direction": 357.8367,
      "lat": 24.400158066666645,
      "lng": 54.52367633333337,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:29",
      "speed": 14.71635,
      "direction": 357.8367,
      "lat": 24.40025513333331,
      "lng": 54.523672666666705,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:30",
      "speed": 14.216171,
      "direction": 357.8367,
      "lat": 24.400352199999976,
      "lng": 54.52366900000004,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:30",
      "speed": 13.715992,
      "direction": 357.8367,
      "lat": 24.40044926666664,
      "lng": 54.523665333333376,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:31",
      "speed": 13.215813,
      "direction": 357.8367,
      "lat": 24.400546333333306,
      "lng": 54.52366166666671,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:31",
      "speed": 12.715634,
      "direction": 357.8367,
      "lat": 24.40064339999997,
      "lng": 54.52365800000005,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:31",
      "speed": 12.215455,
      "direction": 357.8367,
      "lat": 24.400740466666637,
      "lng": 54.52365433333338,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:32",
      "speed": 11.715277,
      "direction": 357.8367,
      "lat": 24.400837533333302,
      "lng": 54.52365066666672,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:32",
      "speed": 11.215097,
      "direction": 357.8367,
      "lat": 24.400934599999967,
      "lng": 54.523647000000054,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:33",
      "speed": 10.714919,
      "direction": 357.8367,
      "lat": 24.401031666666633,
      "lng": 54.52364333333339,
      "dataPoint": false,
      "rpmDivd": 1.117
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:33",
      "speed": 10.714921,
      "direction": 356.92743,
      "lat": 24.40103166666667,
      "lng": 54.52364333333333,
      "dataPoint": true,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:33",
      "speed": 10.52165,
      "direction": 356.92743,
      "lat": 24.401132266666668,
      "lng": 54.52363793333333,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:33",
      "speed": 10.328379,
      "direction": 356.92743,
      "lat": 24.401232866666668,
      "lng": 54.523632533333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:34",
      "speed": 10.135108,
      "direction": 356.92743,
      "lat": 24.401333466666667,
      "lng": 54.523627133333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:34",
      "speed": 9.941836,
      "direction": 356.92743,
      "lat": 24.401434066666667,
      "lng": 54.523621733333336,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:35",
      "speed": 9.748566,
      "direction": 356.92743,
      "lat": 24.401534666666667,
      "lng": 54.52361633333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:35",
      "speed": 9.555294,
      "direction": 356.92743,
      "lat": 24.401635266666666,
      "lng": 54.52361093333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:35",
      "speed": 9.362023,
      "direction": 356.92743,
      "lat": 24.401735866666666,
      "lng": 54.52360553333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:36",
      "speed": 9.168752,
      "direction": 356.92743,
      "lat": 24.401836466666666,
      "lng": 54.52360013333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:36",
      "speed": 8.975481,
      "direction": 356.92743,
      "lat": 24.401937066666665,
      "lng": 54.52359473333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:37",
      "speed": 8.78221,
      "direction": 356.92743,
      "lat": 24.402037666666665,
      "lng": 54.52358933333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:37",
      "speed": 8.588939,
      "direction": 356.92743,
      "lat": 24.402138266666665,
      "lng": 54.52358393333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:37",
      "speed": 8.395668,
      "direction": 356.92743,
      "lat": 24.402238866666664,
      "lng": 54.52357853333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:38",
      "speed": 8.202396,
      "direction": 356.92743,
      "lat": 24.402339466666664,
      "lng": 54.52357313333334,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:38",
      "speed": 8.009126,
      "direction": 356.92743,
      "lat": 24.402440066666664,
      "lng": 54.523567733333344,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:39",
      "speed": 7.8158545,
      "direction": 356.92743,
      "lat": 24.402540666666663,
      "lng": 54.523562333333345,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:39",
      "speed": 7.6225834,
      "direction": 356.92743,
      "lat": 24.402641266666663,
      "lng": 54.523556933333346,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:39",
      "speed": 7.429312,
      "direction": 356.92743,
      "lat": 24.402741866666663,
      "lng": 54.52355153333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:40",
      "speed": 7.236041,
      "direction": 356.92743,
      "lat": 24.402842466666662,
      "lng": 54.52354613333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:40",
      "speed": 7.04277,
      "direction": 356.92743,
      "lat": 24.402943066666662,
      "lng": 54.52354073333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:41",
      "speed": 6.8494987,
      "direction": 356.92743,
      "lat": 24.40304366666666,
      "lng": 54.52353533333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:41",
      "speed": 6.6562276,
      "direction": 356.92743,
      "lat": 24.40314426666666,
      "lng": 54.52352993333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:41",
      "speed": 6.4629564,
      "direction": 356.92743,
      "lat": 24.40324486666666,
      "lng": 54.52352453333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:42",
      "speed": 6.2696853,
      "direction": 356.92743,
      "lat": 24.40334546666666,
      "lng": 54.52351913333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:42",
      "speed": 6.076414,
      "direction": 356.92743,
      "lat": 24.40344606666666,
      "lng": 54.52351373333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:43",
      "speed": 5.8831434,
      "direction": 356.92743,
      "lat": 24.40354666666666,
      "lng": 54.52350833333335,
      "dataPoint": false,
      "rpmDivd": 1.436
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:43",
      "speed": 5.88314,
      "direction": 343.2791,
      "lat": 24.403546666666667,
      "lng": 54.52350833333333,
      "dataPoint": true,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:43",
      "speed": 5.729239,
      "direction": 343.2791,
      "lat": 24.40367626666667,
      "lng": 54.523469399999996,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:43",
      "speed": 5.575338,
      "direction": 343.2791,
      "lat": 24.40380586666667,
      "lng": 54.52343046666666,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:44",
      "speed": 5.421437,
      "direction": 343.2791,
      "lat": 24.40393546666667,
      "lng": 54.523391533333324,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:44",
      "speed": 5.2675357,
      "direction": 343.2791,
      "lat": 24.40406506666667,
      "lng": 54.52335259999999,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:45",
      "speed": 5.1136346,
      "direction": 343.2791,
      "lat": 24.404194666666672,
      "lng": 54.52331366666665,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:45",
      "speed": 4.9597335,
      "direction": 343.2791,
      "lat": 24.404324266666674,
      "lng": 54.52327473333332,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:45",
      "speed": 4.8058324,
      "direction": 343.2791,
      "lat": 24.404453866666675,
      "lng": 54.52323579999998,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:46",
      "speed": 4.6519313,
      "direction": 343.2791,
      "lat": 24.404583466666676,
      "lng": 54.523196866666645,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:46",
      "speed": 4.49803,
      "direction": 343.2791,
      "lat": 24.404713066666677,
      "lng": 54.52315793333331,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:47",
      "speed": 4.344129,
      "direction": 343.2791,
      "lat": 24.404842666666678,
      "lng": 54.52311899999997,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:47",
      "speed": 4.190228,
      "direction": 343.2791,
      "lat": 24.40497226666668,
      "lng": 54.52308006666664,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:47",
      "speed": 4.036327,
      "direction": 343.2791,
      "lat": 24.40510186666668,
      "lng": 54.5230411333333,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:48",
      "speed": 3.8824255,
      "direction": 343.2791,
      "lat": 24.40523146666668,
      "lng": 54.523002199999965,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:48",
      "speed": 3.7285244,
      "direction": 343.2791,
      "lat": 24.405361066666682,
      "lng": 54.52296326666663,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:49",
      "speed": 3.5746233,
      "direction": 343.2791,
      "lat": 24.405490666666683,
      "lng": 54.52292433333329,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:49",
      "speed": 3.4207222,
      "direction": 343.2791,
      "lat": 24.405620266666684,
      "lng": 54.52288539999996,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:49",
      "speed": 3.2668211,
      "direction": 343.2791,
      "lat": 24.405749866666685,
      "lng": 54.52284646666662,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:50",
      "speed": 3.11292,
      "direction": 343.2791,
      "lat": 24.405879466666686,
      "lng": 54.522807533333285,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:50",
      "speed": 2.959019,
      "direction": 343.2791,
      "lat": 24.406009066666687,
      "lng": 54.52276859999995,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:51",
      "speed": 2.8051178,
      "direction": 343.2791,
      "lat": 24.40613866666669,
      "lng": 54.52272966666661,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:51",
      "speed": 2.6512167,
      "direction": 343.2791,
      "lat": 24.40626826666669,
      "lng": 54.52269073333328,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:51",
      "speed": 2.4973156,
      "direction": 343.2791,
      "lat": 24.40639786666669,
      "lng": 54.52265179999994,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:52",
      "speed": 2.3434145,
      "direction": 343.2791,
      "lat": 24.40652746666669,
      "lng": 54.522612866666606,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:52",
      "speed": 2.1895134,
      "direction": 343.2791,
      "lat": 24.406657066666693,
      "lng": 54.52257393333327,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:53",
      "speed": 2.0356123,
      "direction": 343.2791,
      "lat": 24.406786666666694,
      "lng": 54.522534999999934,
      "dataPoint": false,
      "rpmDivd": 0.896
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:53",
      "speed": 2.0356112,
      "direction": 290.3904,
      "lat": 24.406786666666665,
      "lng": 54.522535,
      "dataPoint": true,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:53",
      "speed": 2.501788,
      "direction": 290.3904,
      "lat": 24.4068224,
      "lng": 54.52243886666666,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:53",
      "speed": 2.9679644,
      "direction": 290.3904,
      "lat": 24.406858133333333,
      "lng": 54.522342733333325,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:54",
      "speed": 3.4341412,
      "direction": 290.3904,
      "lat": 24.406893866666667,
      "lng": 54.52224659999999,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:54",
      "speed": 3.900318,
      "direction": 290.3904,
      "lat": 24.4069296,
      "lng": 54.52215046666665,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:55",
      "speed": 4.3664947,
      "direction": 290.3904,
      "lat": 24.406965333333336,
      "lng": 54.522054333333315,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:55",
      "speed": 4.832671,
      "direction": 290.3904,
      "lat": 24.40700106666667,
      "lng": 54.52195819999998,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:55",
      "speed": 5.2988477,
      "direction": 290.3904,
      "lat": 24.407036800000004,
      "lng": 54.52186206666664,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:56",
      "speed": 5.7650247,
      "direction": 290.3904,
      "lat": 24.407072533333338,
      "lng": 54.521765933333306,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:56",
      "speed": 6.231201,
      "direction": 290.3904,
      "lat": 24.407108266666672,
      "lng": 54.52166979999997,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:57",
      "speed": 6.6973777,
      "direction": 290.3904,
      "lat": 24.407144000000006,
      "lng": 54.52157366666663,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:57",
      "speed": 7.1635547,
      "direction": 290.3904,
      "lat": 24.40717973333334,
      "lng": 54.5214775333333,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:57",
      "speed": 7.629731,
      "direction": 290.3904,
      "lat": 24.407215466666674,
      "lng": 54.52138139999996,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:58",
      "speed": 8.095908,
      "direction": 290.3904,
      "lat": 24.407251200000008,
      "lng": 54.521285266666624,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:58",
      "speed": 8.562084,
      "direction": 290.3904,
      "lat": 24.407286933333342,
      "lng": 54.52118913333329,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:59",
      "speed": 9.028261,
      "direction": 290.3904,
      "lat": 24.407322666666676,
      "lng": 54.52109299999995,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:59",
      "speed": 9.494438,
      "direction": 290.3904,
      "lat": 24.40735840000001,
      "lng": 54.520996866666614,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:30:59",
      "speed": 9.960614,
      "direction": 290.3904,
      "lat": 24.407394133333344,
      "lng": 54.52090073333328,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:00",
      "speed": 10.426791,
      "direction": 290.3904,
      "lat": 24.40742986666668,
      "lng": 54.52080459999994,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:00",
      "speed": 10.892968,
      "direction": 290.3904,
      "lat": 24.407465600000013,
      "lng": 54.520708466666605,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:01",
      "speed": 11.359144,
      "direction": 290.3904,
      "lat": 24.407501333333347,
      "lng": 54.52061233333327,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:01",
      "speed": 11.825321,
      "direction": 290.3904,
      "lat": 24.40753706666668,
      "lng": 54.52051619999993,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:01",
      "speed": 12.291498,
      "direction": 290.3904,
      "lat": 24.407572800000015,
      "lng": 54.520420066666595,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:02",
      "speed": 12.757674,
      "direction": 290.3904,
      "lat": 24.40760853333335,
      "lng": 54.52032393333326,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:02",
      "speed": 13.223851,
      "direction": 290.3904,
      "lat": 24.407644266666683,
      "lng": 54.52022779999992,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:03",
      "speed": 13.690028,
      "direction": 290.3904,
      "lat": 24.407680000000017,
      "lng": 54.520131666666586,
      "dataPoint": false,
      "rpmDivd": 1.291
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:03",
      "speed": 13.690045,
      "direction": 290.09445,
      "lat": 24.40768,
      "lng": 54.520131666666664,
      "dataPoint": true,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:03",
      "speed": 13.683782,
      "direction": 290.09445,
      "lat": 24.407719266666668,
      "lng": 54.52002433333333,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:03",
      "speed": 13.677519,
      "direction": 290.09445,
      "lat": 24.407758533333336,
      "lng": 54.519917,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:04",
      "speed": 13.671255,
      "direction": 290.09445,
      "lat": 24.407797800000004,
      "lng": 54.51980966666667,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:04",
      "speed": 13.664992,
      "direction": 290.09445,
      "lat": 24.407837066666673,
      "lng": 54.519702333333335,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:05",
      "speed": 13.658729,
      "direction": 290.09445,
      "lat": 24.40787633333334,
      "lng": 54.519595,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:05",
      "speed": 13.652466,
      "direction": 290.09445,
      "lat": 24.40791560000001,
      "lng": 54.51948766666667,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:05",
      "speed": 13.646202,
      "direction": 290.09445,
      "lat": 24.407954866666678,
      "lng": 54.51938033333334,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:06",
      "speed": 13.639938,
      "direction": 290.09445,
      "lat": 24.407994133333347,
      "lng": 54.519273000000005,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:06",
      "speed": 13.633676,
      "direction": 290.09445,
      "lat": 24.408033400000015,
      "lng": 54.51916566666667,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:07",
      "speed": 13.627412,
      "direction": 290.09445,
      "lat": 24.408072666666683,
      "lng": 54.51905833333334,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:07",
      "speed": 13.621149,
      "direction": 290.09445,
      "lat": 24.408111933333352,
      "lng": 54.51895100000001,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:07",
      "speed": 13.614885,
      "direction": 290.09445,
      "lat": 24.40815120000002,
      "lng": 54.518843666666676,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:08",
      "speed": 13.608622,
      "direction": 290.09445,
      "lat": 24.40819046666669,
      "lng": 54.518736333333344,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:08",
      "speed": 13.602359,
      "direction": 290.09445,
      "lat": 24.408229733333357,
      "lng": 54.51862900000001,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:09",
      "speed": 13.596095,
      "direction": 290.09445,
      "lat": 24.408269000000026,
      "lng": 54.51852166666668,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:09",
      "speed": 13.589832,
      "direction": 290.09445,
      "lat": 24.408308266666694,
      "lng": 54.51841433333335,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:09",
      "speed": 13.583569,
      "direction": 290.09445,
      "lat": 24.408347533333362,
      "lng": 54.518307000000014,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:10",
      "speed": 13.577306,
      "direction": 290.09445,
      "lat": 24.40838680000003,
      "lng": 54.51819966666668,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:10",
      "speed": 13.571042,
      "direction": 290.09445,
      "lat": 24.4084260666667,
      "lng": 54.51809233333335,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:11",
      "speed": 13.564778,
      "direction": 290.09445,
      "lat": 24.408465333333368,
      "lng": 54.51798500000002,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:11",
      "speed": 13.558516,
      "direction": 290.09445,
      "lat": 24.408504600000036,
      "lng": 54.517877666666685,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:11",
      "speed": 13.552252,
      "direction": 290.09445,
      "lat": 24.408543866666705,
      "lng": 54.51777033333335,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:12",
      "speed": 13.545989,
      "direction": 290.09445,
      "lat": 24.408583133333373,
      "lng": 54.51766300000002,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:12",
      "speed": 13.539725,
      "direction": 290.09445,
      "lat": 24.40862240000004,
      "lng": 54.51755566666669,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:13",
      "speed": 13.533462,
      "direction": 290.09445,
      "lat": 24.40866166666671,
      "lng": 54.517448333333355,
      "dataPoint": false,
      "rpmDivd": 1.13
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:13",
      "speed": 13.533459,
      "direction": 294.70486,
      "lat": 24.408661666666667,
      "lng": 54.517448333333334,
      "dataPoint": true,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:13",
      "speed": 13.749099,
      "direction": 294.70486,
      "lat": 24.408709266666666,
      "lng": 54.51734486666667,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:13",
      "speed": 13.964739,
      "direction": 294.70486,
      "lat": 24.408756866666664,
      "lng": 54.5172414,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:14",
      "speed": 14.180379,
      "direction": 294.70486,
      "lat": 24.408804466666663,
      "lng": 54.51713793333334,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:14",
      "speed": 14.396019,
      "direction": 294.70486,
      "lat": 24.40885206666666,
      "lng": 54.51703446666667,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:15",
      "speed": 14.611659,
      "direction": 294.70486,
      "lat": 24.40889966666666,
      "lng": 54.51693100000001,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:15",
      "speed": 14.827299,
      "direction": 294.70486,
      "lat": 24.408947266666658,
      "lng": 54.51682753333334,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:15",
      "speed": 15.042939,
      "direction": 294.70486,
      "lat": 24.408994866666657,
      "lng": 54.516724066666676,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:16",
      "speed": 15.258578,
      "direction": 294.70486,
      "lat": 24.409042466666655,
      "lng": 54.51662060000001,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:16",
      "speed": 15.474218,
      "direction": 294.70486,
      "lat": 24.409090066666653,
      "lng": 54.516517133333345,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:17",
      "speed": 15.689858,
      "direction": 294.70486,
      "lat": 24.409137666666652,
      "lng": 54.51641366666668,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:17",
      "speed": 15.9054985,
      "direction": 294.70486,
      "lat": 24.40918526666665,
      "lng": 54.516310200000014,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:17",
      "speed": 16.12114,
      "direction": 294.70486,
      "lat": 24.40923286666665,
      "lng": 54.51620673333335,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:18",
      "speed": 16.336779,
      "direction": 294.70486,
      "lat": 24.409280466666647,
      "lng": 54.51610326666668,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:18",
      "speed": 16.55242,
      "direction": 294.70486,
      "lat": 24.409328066666646,
      "lng": 54.51599980000002,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:19",
      "speed": 16.768059,
      "direction": 294.70486,
      "lat": 24.409375666666644,
      "lng": 54.51589633333335,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:19",
      "speed": 16.983698,
      "direction": 294.70486,
      "lat": 24.409423266666643,
      "lng": 54.515792866666686,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:19",
      "speed": 17.199339,
      "direction": 294.70486,
      "lat": 24.40947086666664,
      "lng": 54.51568940000002,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:20",
      "speed": 17.414978,
      "direction": 294.70486,
      "lat": 24.40951846666664,
      "lng": 54.515585933333355,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:20",
      "speed": 17.63062,
      "direction": 294.70486,
      "lat": 24.40956606666664,
      "lng": 54.51548246666669,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:21",
      "speed": 17.846258,
      "direction": 294.70486,
      "lat": 24.409613666666637,
      "lng": 54.515379000000024,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:21",
      "speed": 18.0619,
      "direction": 294.70486,
      "lat": 24.409661266666635,
      "lng": 54.51527553333336,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:21",
      "speed": 18.277538,
      "direction": 294.70486,
      "lat": 24.409708866666634,
      "lng": 54.51517206666669,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:22",
      "speed": 18.49318,
      "direction": 294.70486,
      "lat": 24.409756466666632,
      "lng": 54.51506860000003,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:22",
      "speed": 18.708818,
      "direction": 294.70486,
      "lat": 24.40980406666663,
      "lng": 54.51496513333336,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:23",
      "speed": 18.92446,
      "direction": 294.70486,
      "lat": 24.40985166666663,
      "lng": 54.5148616666667,
      "dataPoint": false,
      "rpmDivd": 0.181
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:23",
      "speed": 18.924475,
      "direction": 293.91074,
      "lat": 24.40985166666667,
      "lng": 54.51486166666667,
      "dataPoint": true,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:23",
      "speed": 19.302963,
      "direction": 293.91074,
      "lat": 24.4098942,
      "lng": 54.514765733333334,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:23",
      "speed": 19.681452,
      "direction": 293.91074,
      "lat": 24.409936733333332,
      "lng": 54.5146698,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:24",
      "speed": 20.059942,
      "direction": 293.91074,
      "lat": 24.409979266666664,
      "lng": 54.514573866666666,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:24",
      "speed": 20.43843,
      "direction": 293.91074,
      "lat": 24.410021799999996,
      "lng": 54.51447793333333,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:25",
      "speed": 20.81692,
      "direction": 293.91074,
      "lat": 24.410064333333327,
      "lng": 54.514382,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:25",
      "speed": 21.195408,
      "direction": 293.91074,
      "lat": 24.41010686666666,
      "lng": 54.51428606666666,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:25",
      "speed": 21.573896,
      "direction": 293.91074,
      "lat": 24.41014939999999,
      "lng": 54.51419013333333,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:26",
      "speed": 21.952385,
      "direction": 293.91074,
      "lat": 24.410191933333323,
      "lng": 54.514094199999995,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:26",
      "speed": 22.330875,
      "direction": 293.91074,
      "lat": 24.410234466666655,
      "lng": 54.51399826666666,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:27",
      "speed": 22.709364,
      "direction": 293.91074,
      "lat": 24.410276999999986,
      "lng": 54.51390233333333,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:27",
      "speed": 23.087852,
      "direction": 293.91074,
      "lat": 24.41031953333332,
      "lng": 54.51380639999999,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:27",
      "speed": 23.466341,
      "direction": 293.91074,
      "lat": 24.41036206666665,
      "lng": 54.51371046666666,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:28",
      "speed": 23.84483,
      "direction": 293.91074,
      "lat": 24.410404599999982,
      "lng": 54.513614533333325,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:28",
      "speed": 24.22332,
      "direction": 293.91074,
      "lat": 24.410447133333314,
      "lng": 54.51351859999999,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:29",
      "speed": 24.601809,
      "direction": 293.91074,
      "lat": 24.410489666666646,
      "lng": 54.513422666666656,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:29",
      "speed": 24.980297,
      "direction": 293.91074,
      "lat": 24.410532199999977,
      "lng": 54.51332673333332,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:29",
      "speed": 25.358786,
      "direction": 293.91074,
      "lat": 24.41057473333331,
      "lng": 54.51323079999999,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:30",
      "speed": 25.737274,
      "direction": 293.91074,
      "lat": 24.41061726666664,
      "lng": 54.513134866666654,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:30",
      "speed": 26.115763,
      "direction": 293.91074,
      "lat": 24.410659799999973,
      "lng": 54.51303893333332,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:31",
      "speed": 26.494253,
      "direction": 293.91074,
      "lat": 24.410702333333305,
      "lng": 54.512942999999986,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:31",
      "speed": 26.872742,
      "direction": 293.91074,
      "lat": 24.410744866666636,
      "lng": 54.51284706666665,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:31",
      "speed": 27.25123,
      "direction": 293.91074,
      "lat": 24.41078739999997,
      "lng": 54.51275113333332,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:32",
      "speed": 27.629719,
      "direction": 293.91074,
      "lat": 24.4108299333333,
      "lng": 54.51265519999998,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:32",
      "speed": 28.008207,
      "direction": 293.91074,
      "lat": 24.410872466666632,
      "lng": 54.51255926666665,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:33",
      "speed": 28.386698,
      "direction": 293.91074,
      "lat": 24.410914999999964,
      "lng": 54.512463333333315,
      "dataPoint": false,
      "rpmDivd": 0.212
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:33",
      "speed": 28.386711,
      "direction": 294.1538,
      "lat": 24.410915,
      "lng": 54.512463333333336,
      "dataPoint": true,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:33",
      "speed": 28.122753,
      "direction": 294.1538,
      "lat": 24.410950666666665,
      "lng": 54.5123838,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:33",
      "speed": 27.858795,
      "direction": 294.1538,
      "lat": 24.41098633333333,
      "lng": 54.51230426666667,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:34",
      "speed": 27.594837,
      "direction": 294.1538,
      "lat": 24.411021999999996,
      "lng": 54.51222473333333,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:34",
      "speed": 27.33088,
      "direction": 294.1538,
      "lat": 24.41105766666666,
      "lng": 54.5121452,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:35",
      "speed": 27.066923,
      "direction": 294.1538,
      "lat": 24.411093333333326,
      "lng": 54.512065666666665,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:35",
      "speed": 26.802965,
      "direction": 294.1538,
      "lat": 24.411128999999992,
      "lng": 54.51198613333333,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:35",
      "speed": 26.539007,
      "direction": 294.1538,
      "lat": 24.411164666666657,
      "lng": 54.511906599999996,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:36",
      "speed": 26.27505,
      "direction": 294.1538,
      "lat": 24.411200333333323,
      "lng": 54.51182706666666,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:36",
      "speed": 26.011091,
      "direction": 294.1538,
      "lat": 24.411235999999988,
      "lng": 54.51174753333333,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:37",
      "speed": 25.747133,
      "direction": 294.1538,
      "lat": 24.411271666666654,
      "lng": 54.51166799999999,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:37",
      "speed": 25.483175,
      "direction": 294.1538,
      "lat": 24.41130733333332,
      "lng": 54.51158846666666,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:37",
      "speed": 25.219217,
      "direction": 294.1538,
      "lat": 24.411342999999984,
      "lng": 54.511508933333324,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:38",
      "speed": 24.95526,
      "direction": 294.1538,
      "lat": 24.41137866666665,
      "lng": 54.51142939999999,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:38",
      "speed": 24.691301,
      "direction": 294.1538,
      "lat": 24.411414333333315,
      "lng": 54.511349866666656,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:39",
      "speed": 24.427345,
      "direction": 294.1538,
      "lat": 24.41144999999998,
      "lng": 54.51127033333332,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:39",
      "speed": 24.163387,
      "direction": 294.1538,
      "lat": 24.411485666666646,
      "lng": 54.51119079999999,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:39",
      "speed": 23.89943,
      "direction": 294.1538,
      "lat": 24.41152133333331,
      "lng": 54.51111126666665,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:40",
      "speed": 23.635471,
      "direction": 294.1538,
      "lat": 24.411556999999977,
      "lng": 54.51103173333332,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:40",
      "speed": 23.371513,
      "direction": 294.1538,
      "lat": 24.411592666666643,
      "lng": 54.510952199999984,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:41",
      "speed": 23.107555,
      "direction": 294.1538,
      "lat": 24.411628333333308,
      "lng": 54.51087266666665,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:41",
      "speed": 22.843597,
      "direction": 294.1538,
      "lat": 24.411663999999973,
      "lng": 54.510793133333316,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:41",
      "speed": 22.57964,
      "direction": 294.1538,
      "lat": 24.41169966666664,
      "lng": 54.51071359999998,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:42",
      "speed": 22.315681,
      "direction": 294.1538,
      "lat": 24.411735333333304,
      "lng": 54.51063406666665,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:42",
      "speed": 22.051725,
      "direction": 294.1538,
      "lat": 24.41177099999997,
      "lng": 54.51055453333331,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:43",
      "speed": 21.787767,
      "direction": 294.1538,
      "lat": 24.411806666666635,
      "lng": 54.51047499999998,
      "dataPoint": false,
      "rpmDivd": 0.921
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:43",
      "speed": 21.787752,
      "direction": 295.18494,
      "lat": 24.411806666666667,
      "lng": 54.510475,
      "dataPoint": true,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:43",
      "speed": 21.02451,
      "direction": 295.18494,
      "lat": 24.411844066666667,
      "lng": 54.510395466666665,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:43",
      "speed": 20.261269,
      "direction": 295.18494,
      "lat": 24.411881466666667,
      "lng": 54.51031593333333,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:44",
      "speed": 19.498026,
      "direction": 295.18494,
      "lat": 24.411918866666667,
      "lng": 54.5102364,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:44",
      "speed": 18.734783,
      "direction": 295.18494,
      "lat": 24.411956266666667,
      "lng": 54.51015686666666,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:45",
      "speed": 17.97154,
      "direction": 295.18494,
      "lat": 24.411993666666667,
      "lng": 54.51007733333333,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:45",
      "speed": 17.2083,
      "direction": 295.18494,
      "lat": 24.412031066666668,
      "lng": 54.509997799999994,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:45",
      "speed": 16.445057,
      "direction": 295.18494,
      "lat": 24.412068466666668,
      "lng": 54.50991826666666,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:46",
      "speed": 15.681814,
      "direction": 295.18494,
      "lat": 24.412105866666668,
      "lng": 54.509838733333325,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:46",
      "speed": 14.918572,
      "direction": 295.18494,
      "lat": 24.412143266666668,
      "lng": 54.50975919999999,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:47",
      "speed": 14.15533,
      "direction": 295.18494,
      "lat": 24.412180666666668,
      "lng": 54.509679666666656,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:47",
      "speed": 13.392088,
      "direction": 295.18494,
      "lat": 24.412218066666668,
      "lng": 54.50960013333332,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:47",
      "speed": 12.628845,
      "direction": 295.18494,
      "lat": 24.412255466666668,
      "lng": 54.50952059999999,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:48",
      "speed": 11.865603,
      "direction": 295.18494,
      "lat": 24.412292866666668,
      "lng": 54.50944106666665,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:48",
      "speed": 11.102361,
      "direction": 295.18494,
      "lat": 24.412330266666668,
      "lng": 54.50936153333332,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:49",
      "speed": 10.339119,
      "direction": 295.18494,
      "lat": 24.41236766666667,
      "lng": 54.509281999999985,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:49",
      "speed": 9.575876,
      "direction": 295.18494,
      "lat": 24.41240506666667,
      "lng": 54.50920246666665,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:49",
      "speed": 8.812634,
      "direction": 295.18494,
      "lat": 24.41244246666667,
      "lng": 54.509122933333316,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:50",
      "speed": 8.049392,
      "direction": 295.18494,
      "lat": 24.41247986666667,
      "lng": 54.50904339999998,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:50",
      "speed": 7.28615,
      "direction": 295.18494,
      "lat": 24.41251726666667,
      "lng": 54.50896386666665,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:51",
      "speed": 6.5229077,
      "direction": 295.18494,
      "lat": 24.41255466666667,
      "lng": 54.50888433333331,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:51",
      "speed": 5.7596655,
      "direction": 295.18494,
      "lat": 24.41259206666667,
      "lng": 54.50880479999998,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:51",
      "speed": 4.9964232,
      "direction": 295.18494,
      "lat": 24.41262946666667,
      "lng": 54.508725266666644,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:52",
      "speed": 4.233181,
      "direction": 295.18494,
      "lat": 24.41266686666667,
      "lng": 54.50864573333331,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:52",
      "speed": 3.4699388,
      "direction": 295.18494,
      "lat": 24.41270426666667,
      "lng": 54.508566199999976,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:53",
      "speed": 2.7066965,
      "direction": 295.18494,
      "lat": 24.41274166666667,
      "lng": 54.50848666666664,
      "dataPoint": false,
      "rpmDivd": 0.09
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:53",
      "speed": 2.706692,
      "direction": 304.4975,
      "lat": 24.412741666666665,
      "lng": 54.50848666666667,
      "dataPoint": true,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:53",
      "speed": 3.750893,
      "direction": 304.4975,
      "lat": 24.4127922,
      "lng": 54.508413133333335,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:53",
      "speed": 4.795094,
      "direction": 304.4975,
      "lat": 24.41284273333333,
      "lng": 54.5083396,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:54",
      "speed": 5.8392954,
      "direction": 304.4975,
      "lat": 24.412893266666664,
      "lng": 54.508266066666664,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:54",
      "speed": 6.8834963,
      "direction": 304.4975,
      "lat": 24.412943799999997,
      "lng": 54.50819253333333,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:55",
      "speed": 7.9276977,
      "direction": 304.4975,
      "lat": 24.41299433333333,
      "lng": 54.50811899999999,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:55",
      "speed": 8.971899,
      "direction": 304.4975,
      "lat": 24.413044866666663,
      "lng": 54.50804546666666,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:55",
      "speed": 10.0161,
      "direction": 304.4975,
      "lat": 24.413095399999996,
      "lng": 54.50797193333332,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:56",
      "speed": 11.060301,
      "direction": 304.4975,
      "lat": 24.41314593333333,
      "lng": 54.50789839999999,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:56",
      "speed": 12.104502,
      "direction": 304.4975,
      "lat": 24.413196466666662,
      "lng": 54.50782486666665,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:57",
      "speed": 13.148703,
      "direction": 304.4975,
      "lat": 24.413246999999995,
      "lng": 54.50775133333332,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:57",
      "speed": 14.192904,
      "direction": 304.4975,
      "lat": 24.413297533333328,
      "lng": 54.50767779999998,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:57",
      "speed": 15.237105,
      "direction": 304.4975,
      "lat": 24.41334806666666,
      "lng": 54.50760426666665,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:58",
      "speed": 16.281307,
      "direction": 304.4975,
      "lat": 24.413398599999994,
      "lng": 54.50753073333331,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:58",
      "speed": 17.325508,
      "direction": 304.4975,
      "lat": 24.413449133333327,
      "lng": 54.507457199999976,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:59",
      "speed": 18.369709,
      "direction": 304.4975,
      "lat": 24.41349966666666,
      "lng": 54.50738366666664,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:59",
      "speed": 19.41391,
      "direction": 304.4975,
      "lat": 24.413550199999992,
      "lng": 54.507310133333306,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:31:59",
      "speed": 20.45811,
      "direction": 304.4975,
      "lat": 24.413600733333325,
      "lng": 54.50723659999997,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:00",
      "speed": 21.502312,
      "direction": 304.4975,
      "lat": 24.41365126666666,
      "lng": 54.507163066666635,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:00",
      "speed": 22.546513,
      "direction": 304.4975,
      "lat": 24.41370179999999,
      "lng": 54.5070895333333,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:01",
      "speed": 23.590714,
      "direction": 304.4975,
      "lat": 24.413752333333324,
      "lng": 54.507015999999965,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:01",
      "speed": 24.634914,
      "direction": 304.4975,
      "lat": 24.413802866666657,
      "lng": 54.50694246666663,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:01",
      "speed": 25.679117,
      "direction": 304.4975,
      "lat": 24.41385339999999,
      "lng": 54.506868933333294,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:02",
      "speed": 26.723318,
      "direction": 304.4975,
      "lat": 24.413903933333323,
      "lng": 54.50679539999996,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:02",
      "speed": 27.767519,
      "direction": 304.4975,
      "lat": 24.413954466666656,
      "lng": 54.506721866666624,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:03",
      "speed": 28.81172,
      "direction": 304.4975,
      "lat": 24.41400499999999,
      "lng": 54.50664833333329,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:03",
      "speed": 28.811728,
      "direction": 352.60635,
      "lat": 24.414005,
      "lng": 54.50664833333333,
      "dataPoint": true,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:03",
      "speed": 28.003748,
      "direction": 352.60635,
      "lat": 24.4140872,
      "lng": 54.50663766666666,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:03",
      "speed": 27.195768,
      "direction": 352.60635,
      "lat": 24.414169400000002,
      "lng": 54.506626999999995,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:04",
      "speed": 26.387787,
      "direction": 352.60635,
      "lat": 24.414251600000004,
      "lng": 54.50661633333333,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:04",
      "speed": 25.579807,
      "direction": 352.60635,
      "lat": 24.414333800000005,
      "lng": 54.50660566666666,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:05",
      "speed": 24.771828,
      "direction": 352.60635,
      "lat": 24.414416000000006,
      "lng": 54.50659499999999,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:05",
      "speed": 23.963848,
      "direction": 352.60635,
      "lat": 24.414498200000008,
      "lng": 54.50658433333332,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:05",
      "speed": 23.155867,
      "direction": 352.60635,
      "lat": 24.41458040000001,
      "lng": 54.506573666666654,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:06",
      "speed": 22.347887,
      "direction": 352.60635,
      "lat": 24.41466260000001,
      "lng": 54.506562999999986,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:06",
      "speed": 21.539907,
      "direction": 352.60635,
      "lat": 24.414744800000012,
      "lng": 54.50655233333332,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:07",
      "speed": 20.731928,
      "direction": 352.60635,
      "lat": 24.414827000000013,
      "lng": 54.50654166666665,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:07",
      "speed": 19.923948,
      "direction": 352.60635,
      "lat": 24.414909200000015,
      "lng": 54.50653099999998,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:07",
      "speed": 19.115967,
      "direction": 352.60635,
      "lat": 24.414991400000016,
      "lng": 54.50652033333331,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:08",
      "speed": 18.307987,
      "direction": 352.60635,
      "lat": 24.415073600000017,
      "lng": 54.506509666666645,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:08",
      "speed": 17.500008,
      "direction": 352.60635,
      "lat": 24.41515580000002,
      "lng": 54.50649899999998,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:09",
      "speed": 16.692028,
      "direction": 352.60635,
      "lat": 24.41523800000002,
      "lng": 54.50648833333331,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:09",
      "speed": 15.8840475,
      "direction": 352.60635,
      "lat": 24.41532020000002,
      "lng": 54.50647766666664,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:09",
      "speed": 15.076068,
      "direction": 352.60635,
      "lat": 24.415402400000023,
      "lng": 54.50646699999997,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:10",
      "speed": 14.268087,
      "direction": 352.60635,
      "lat": 24.415484600000024,
      "lng": 54.506456333333304,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:10",
      "speed": 13.460108,
      "direction": 352.60635,
      "lat": 24.415566800000025,
      "lng": 54.506445666666636,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:11",
      "speed": 12.652127,
      "direction": 352.60635,
      "lat": 24.415649000000027,
      "lng": 54.50643499999997,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:11",
      "speed": 11.844148,
      "direction": 352.60635,
      "lat": 24.415731200000028,
      "lng": 54.5064243333333,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:11",
      "speed": 11.036167,
      "direction": 352.60635,
      "lat": 24.41581340000003,
      "lng": 54.50641366666663,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:12",
      "speed": 10.228188,
      "direction": 352.60635,
      "lat": 24.41589560000003,
      "lng": 54.50640299999996,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:12",
      "speed": 9.420208,
      "direction": 352.60635,
      "lat": 24.415977800000032,
      "lng": 54.506392333333295,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:13",
      "speed": 8.612227,
      "direction": 352.60635,
      "lat": 24.416060000000034,
      "lng": 54.50638166666663,
      "dataPoint": false,
      "rpmDivd": 1.022
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:13",
      "speed": 8.612202,
      "direction": 41.061832,
      "lat": 24.41606,
      "lng": 54.50638166666667,
      "dataPoint": true,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:13",
      "speed": 9.105222,
      "direction": 41.061832,
      "lat": 24.41612986666667,
      "lng": 54.506442533333335,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:13",
      "speed": 9.598242,
      "direction": 41.061832,
      "lat": 24.416199733333336,
      "lng": 54.5065034,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:14",
      "speed": 10.091262,
      "direction": 41.061832,
      "lat": 24.416269600000003,
      "lng": 54.506564266666665,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:14",
      "speed": 10.584282,
      "direction": 41.061832,
      "lat": 24.41633946666667,
      "lng": 54.50662513333333,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:15",
      "speed": 11.077302,
      "direction": 41.061832,
      "lat": 24.416409333333338,
      "lng": 54.506685999999995,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:15",
      "speed": 11.570322,
      "direction": 41.061832,
      "lat": 24.416479200000005,
      "lng": 54.50674686666666,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:15",
      "speed": 12.063342,
      "direction": 41.061832,
      "lat": 24.416549066666672,
      "lng": 54.506807733333325,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:16",
      "speed": 12.556362,
      "direction": 41.061832,
      "lat": 24.41661893333334,
      "lng": 54.50686859999999,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:16",
      "speed": 13.049381,
      "direction": 41.061832,
      "lat": 24.416688800000006,
      "lng": 54.506929466666655,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:17",
      "speed": 13.542401,
      "direction": 41.061832,
      "lat": 24.416758666666674,
      "lng": 54.50699033333332,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:17",
      "speed": 14.035421,
      "direction": 41.061832,
      "lat": 24.41682853333334,
      "lng": 54.507051199999985,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:17",
      "speed": 14.528441,
      "direction": 41.061832,
      "lat": 24.416898400000008,
      "lng": 54.50711206666665,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:18",
      "speed": 15.0214615,
      "direction": 41.061832,
      "lat": 24.416968266666675,
      "lng": 54.507172933333315,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:18",
      "speed": 15.514482,
      "direction": 41.061832,
      "lat": 24.417038133333342,
      "lng": 54.50723379999998,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:19",
      "speed": 16.007502,
      "direction": 41.061832,
      "lat": 24.41710800000001,
      "lng": 54.507294666666645,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:19",
      "speed": 16.500523,
      "direction": 41.061832,
      "lat": 24.417177866666677,
      "lng": 54.50735553333331,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:19",
      "speed": 16.993542,
      "direction": 41.061832,
      "lat": 24.417247733333344,
      "lng": 54.507416399999975,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:20",
      "speed": 17.48656,
      "direction": 41.061832,
      "lat": 24.41731760000001,
      "lng": 54.50747726666664,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:20",
      "speed": 17.979582,
      "direction": 41.061832,
      "lat": 24.41738746666668,
      "lng": 54.507538133333306,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:21",
      "speed": 18.4726,
      "direction": 41.061832,
      "lat": 24.417457333333346,
      "lng": 54.50759899999997,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:21",
      "speed": 18.965622,
      "direction": 41.061832,
      "lat": 24.417527200000013,
      "lng": 54.507659866666636,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:21",
      "speed": 19.458641,
      "direction": 41.061832,
      "lat": 24.41759706666668,
      "lng": 54.5077207333333,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:22",
      "speed": 19.951662,
      "direction": 41.061832,
      "lat": 24.417666933333347,
      "lng": 54.507781599999966,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:22",
      "speed": 20.444681,
      "direction": 41.061832,
      "lat": 24.417736800000014,
      "lng": 54.50784246666663,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:23",
      "speed": 20.937702,
      "direction": 41.061832,
      "lat": 24.41780666666668,
      "lng": 54.507903333333296,
      "dataPoint": false,
      "rpmDivd": 0.638
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:23",
      "speed": 20.937716,
      "direction": 52.4543,
      "lat": 24.417806666666667,
      "lng": 54.50790333333333,
      "dataPoint": true,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:23",
      "speed": 21.323364,
      "direction": 52.4543,
      "lat": 24.417868666666667,
      "lng": 54.507984,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:23",
      "speed": 21.709011,
      "direction": 52.4543,
      "lat": 24.417930666666667,
      "lng": 54.50806466666667,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:24",
      "speed": 22.09466,
      "direction": 52.4543,
      "lat": 24.417992666666667,
      "lng": 54.50814533333334,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:24",
      "speed": 22.480307,
      "direction": 52.4543,
      "lat": 24.418054666666666,
      "lng": 54.50822600000001,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:25",
      "speed": 22.865955,
      "direction": 52.4543,
      "lat": 24.418116666666666,
      "lng": 54.50830666666668,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:25",
      "speed": 23.251602,
      "direction": 52.4543,
      "lat": 24.418178666666666,
      "lng": 54.508387333333346,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:25",
      "speed": 23.63725,
      "direction": 52.4543,
      "lat": 24.418240666666666,
      "lng": 54.508468000000015,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:26",
      "speed": 24.022898,
      "direction": 52.4543,
      "lat": 24.418302666666666,
      "lng": 54.508548666666684,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:26",
      "speed": 24.408546,
      "direction": 52.4543,
      "lat": 24.418364666666665,
      "lng": 54.50862933333335,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:27",
      "speed": 24.794193,
      "direction": 52.4543,
      "lat": 24.418426666666665,
      "lng": 54.50871000000002,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:27",
      "speed": 25.179842,
      "direction": 52.4543,
      "lat": 24.418488666666665,
      "lng": 54.50879066666669,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:27",
      "speed": 25.565489,
      "direction": 52.4543,
      "lat": 24.418550666666665,
      "lng": 54.50887133333336,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:28",
      "speed": 25.951138,
      "direction": 52.4543,
      "lat": 24.418612666666665,
      "lng": 54.50895200000003,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:28",
      "speed": 26.336784,
      "direction": 52.4543,
      "lat": 24.418674666666664,
      "lng": 54.5090326666667,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:29",
      "speed": 26.722433,
      "direction": 52.4543,
      "lat": 24.418736666666664,
      "lng": 54.50911333333337,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:29",
      "speed": 27.10808,
      "direction": 52.4543,
      "lat": 24.418798666666664,
      "lng": 54.509194000000036,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:29",
      "speed": 27.493729,
      "direction": 52.4543,
      "lat": 24.418860666666664,
      "lng": 54.509274666666705,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:30",
      "speed": 27.879375,
      "direction": 52.4543,
      "lat": 24.418922666666663,
      "lng": 54.509355333333374,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:30",
      "speed": 28.265024,
      "direction": 52.4543,
      "lat": 24.418984666666663,
      "lng": 54.50943600000004,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:31",
      "speed": 28.650671,
      "direction": 52.4543,
      "lat": 24.419046666666663,
      "lng": 54.50951666666671,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:31",
      "speed": 29.03632,
      "direction": 52.4543,
      "lat": 24.419108666666663,
      "lng": 54.50959733333338,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:31",
      "speed": 29.421967,
      "direction": 52.4543,
      "lat": 24.419170666666663,
      "lng": 54.50967800000005,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:32",
      "speed": 29.807615,
      "direction": 52.4543,
      "lat": 24.419232666666662,
      "lng": 54.50975866666672,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:32",
      "speed": 30.193262,
      "direction": 52.4543,
      "lat": 24.419294666666662,
      "lng": 54.50983933333339,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:33",
      "speed": 30.57891,
      "direction": 52.4543,
      "lat": 24.419356666666662,
      "lng": 54.50992000000006,
      "dataPoint": false,
      "rpmDivd": 0.745
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:33",
      "speed": 30.578907,
      "direction": 63.451668,
      "lat": 24.419356666666665,
      "lng": 54.50992,
      "dataPoint": true,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:33",
      "speed": 29.948092,
      "direction": 63.451668,
      "lat": 24.41940233333333,
      "lng": 54.5100114,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:33",
      "speed": 29.317276,
      "direction": 63.451668,
      "lat": 24.419447999999996,
      "lng": 54.510102800000006,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:34",
      "speed": 28.68646,
      "direction": 63.451668,
      "lat": 24.41949366666666,
      "lng": 54.51019420000001,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:34",
      "speed": 28.055645,
      "direction": 63.451668,
      "lat": 24.419539333333326,
      "lng": 54.51028560000001,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:35",
      "speed": 27.42483,
      "direction": 63.451668,
      "lat": 24.41958499999999,
      "lng": 54.51037700000001,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:35",
      "speed": 26.794014,
      "direction": 63.451668,
      "lat": 24.419630666666656,
      "lng": 54.510468400000015,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:35",
      "speed": 26.163198,
      "direction": 63.451668,
      "lat": 24.41967633333332,
      "lng": 54.51055980000002,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:36",
      "speed": 25.532383,
      "direction": 63.451668,
      "lat": 24.419721999999986,
      "lng": 54.51065120000002,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:36",
      "speed": 24.901567,
      "direction": 63.451668,
      "lat": 24.41976766666665,
      "lng": 54.51074260000002,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:37",
      "speed": 24.270752,
      "direction": 63.451668,
      "lat": 24.419813333333316,
      "lng": 54.510834000000024,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:37",
      "speed": 23.639936,
      "direction": 63.451668,
      "lat": 24.41985899999998,
      "lng": 54.510925400000026,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:37",
      "speed": 23.00912,
      "direction": 63.451668,
      "lat": 24.419904666666646,
      "lng": 54.51101680000003,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:38",
      "speed": 22.378305,
      "direction": 63.451668,
      "lat": 24.41995033333331,
      "lng": 54.51110820000003,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:38",
      "speed": 21.74749,
      "direction": 63.451668,
      "lat": 24.419995999999976,
      "lng": 54.51119960000003,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:39",
      "speed": 21.116674,
      "direction": 63.451668,
      "lat": 24.42004166666664,
      "lng": 54.511291000000035,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:39",
      "speed": 20.485859,
      "direction": 63.451668,
      "lat": 24.420087333333306,
      "lng": 54.51138240000004,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:39",
      "speed": 19.855043,
      "direction": 63.451668,
      "lat": 24.42013299999997,
      "lng": 54.51147380000004,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:40",
      "speed": 19.224228,
      "direction": 63.451668,
      "lat": 24.420178666666637,
      "lng": 54.51156520000004,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:40",
      "speed": 18.593412,
      "direction": 63.451668,
      "lat": 24.4202243333333,
      "lng": 54.511656600000045,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:41",
      "speed": 17.962595,
      "direction": 63.451668,
      "lat": 24.420269999999967,
      "lng": 54.51174800000005,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:41",
      "speed": 17.33178,
      "direction": 63.451668,
      "lat": 24.42031566666663,
      "lng": 54.51183940000005,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:41",
      "speed": 16.700964,
      "direction": 63.451668,
      "lat": 24.420361333333297,
      "lng": 54.51193080000005,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:42",
      "speed": 16.070148,
      "direction": 63.451668,
      "lat": 24.420406999999962,
      "lng": 54.512022200000054,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:42",
      "speed": 15.439334,
      "direction": 63.451668,
      "lat": 24.420452666666627,
      "lng": 54.512113600000056,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:43",
      "speed": 14.808518,
      "direction": 63.451668,
      "lat": 24.420498333333292,
      "lng": 54.51220500000006,
      "dataPoint": false,
      "rpmDivd": 0.435
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:43",
      "speed": 14.808513,
      "direction": 73.359604,
      "lat": 24.420498333333335,
      "lng": 54.512205,
      "dataPoint": true,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:43",
      "speed": 14.372758,
      "direction": 73.359604,
      "lat": 24.4205286,
      "lng": 54.51230626666667,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:43",
      "speed": 13.937004,
      "direction": 73.359604,
      "lat": 24.420558866666667,
      "lng": 54.51240753333334,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:44",
      "speed": 13.501249,
      "direction": 73.359604,
      "lat": 24.420589133333333,
      "lng": 54.512508800000006,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:44",
      "speed": 13.065495,
      "direction": 73.359604,
      "lat": 24.4206194,
      "lng": 54.512610066666674,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:45",
      "speed": 12.629741,
      "direction": 73.359604,
      "lat": 24.420649666666666,
      "lng": 54.51271133333334,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:45",
      "speed": 12.193986,
      "direction": 73.359604,
      "lat": 24.420679933333332,
      "lng": 54.51281260000001,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:45",
      "speed": 11.758231,
      "direction": 73.359604,
      "lat": 24.4207102,
      "lng": 54.51291386666668,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:46",
      "speed": 11.322477,
      "direction": 73.359604,
      "lat": 24.420740466666665,
      "lng": 54.51301513333335,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:46",
      "speed": 10.886723,
      "direction": 73.359604,
      "lat": 24.42077073333333,
      "lng": 54.513116400000015,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:47",
      "speed": 10.450968,
      "direction": 73.359604,
      "lat": 24.420800999999997,
      "lng": 54.513217666666684,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:47",
      "speed": 10.015214,
      "direction": 73.359604,
      "lat": 24.420831266666664,
      "lng": 54.51331893333335,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:47",
      "speed": 9.579459,
      "direction": 73.359604,
      "lat": 24.42086153333333,
      "lng": 54.51342020000002,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:48",
      "speed": 9.143705,
      "direction": 73.359604,
      "lat": 24.420891799999996,
      "lng": 54.51352146666669,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:48",
      "speed": 8.707951,
      "direction": 73.359604,
      "lat": 24.420922066666662,
      "lng": 54.51362273333336,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:49",
      "speed": 8.272196,
      "direction": 73.359604,
      "lat": 24.42095233333333,
      "lng": 54.513724000000025,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:49",
      "speed": 7.8364415,
      "direction": 73.359604,
      "lat": 24.420982599999995,
      "lng": 54.51382526666669,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:49",
      "speed": 7.400687,
      "direction": 73.359604,
      "lat": 24.42101286666666,
      "lng": 54.51392653333336,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:50",
      "speed": 6.964933,
      "direction": 73.359604,
      "lat": 24.421043133333328,
      "lng": 54.51402780000003,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:50",
      "speed": 6.529178,
      "direction": 73.359604,
      "lat": 24.421073399999994,
      "lng": 54.5141290666667,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:51",
      "speed": 6.093424,
      "direction": 73.359604,
      "lat": 24.42110366666666,
      "lng": 54.514230333333366,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:51",
      "speed": 5.6576695,
      "direction": 73.359604,
      "lat": 24.421133933333326,
      "lng": 54.514331600000034,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:51",
      "speed": 5.221915,
      "direction": 73.359604,
      "lat": 24.421164199999993,
      "lng": 54.5144328666667,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:52",
      "speed": 4.7861605,
      "direction": 73.359604,
      "lat": 24.42119446666666,
      "lng": 54.51453413333337,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:52",
      "speed": 4.350406,
      "direction": 73.359604,
      "lat": 24.421224733333325,
      "lng": 54.51463540000004,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:53",
      "speed": 3.9146516,
      "direction": 73.359604,
      "lat": 24.42125499999999,
      "lng": 54.51473666666671,
      "dataPoint": false,
      "rpmDivd": 1.423
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:53",
      "speed": 3.9146369,
      "direction": 80.43257,
      "lat": 24.421255,
      "lng": 54.514736666666664,
      "dataPoint": true,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:53",
      "speed": 4.2045436,
      "direction": 80.43257,
      "lat": 24.421273866666667,
      "lng": 54.5148486,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:53",
      "speed": 4.49445,
      "direction": 80.43257,
      "lat": 24.421292733333335,
      "lng": 54.51496053333334,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:54",
      "speed": 4.784357,
      "direction": 80.43257,
      "lat": 24.421311600000003,
      "lng": 54.51507246666667,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:54",
      "speed": 5.0742636,
      "direction": 80.43257,
      "lat": 24.42133046666667,
      "lng": 54.51518440000001,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:55",
      "speed": 5.36417,
      "direction": 80.43257,
      "lat": 24.42134933333334,
      "lng": 54.515296333333346,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:55",
      "speed": 5.654077,
      "direction": 80.43257,
      "lat": 24.421368200000007,
      "lng": 54.51540826666668,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:55",
      "speed": 5.9439836,
      "direction": 80.43257,
      "lat": 24.421387066666675,
      "lng": 54.51552020000002,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:56",
      "speed": 6.23389,
      "direction": 80.43257,
      "lat": 24.421405933333343,
      "lng": 54.515632133333355,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:56",
      "speed": 6.523797,
      "direction": 80.43257,
      "lat": 24.42142480000001,
      "lng": 54.51574406666669,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:57",
      "speed": 6.8137035,
      "direction": 80.43257,
      "lat": 24.42144366666668,
      "lng": 54.51585600000003,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:57",
      "speed": 7.10361,
      "direction": 80.43257,
      "lat": 24.421462533333347,
      "lng": 54.515967933333364,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:57",
      "speed": 7.393517,
      "direction": 80.43257,
      "lat": 24.421481400000015,
      "lng": 54.5160798666667,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:58",
      "speed": 7.6834235,
      "direction": 80.43257,
      "lat": 24.421500266666683,
      "lng": 54.51619180000004,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:58",
      "speed": 7.97333,
      "direction": 80.43257,
      "lat": 24.42151913333335,
      "lng": 54.51630373333337,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:59",
      "speed": 8.263237,
      "direction": 80.43257,
      "lat": 24.42153800000002,
      "lng": 54.51641566666671,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:59",
      "speed": 8.5531435,
      "direction": 80.43257,
      "lat": 24.421556866666688,
      "lng": 54.516527600000046,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:32:59",
      "speed": 8.84305,
      "direction": 80.43257,
      "lat": 24.421575733333356,
      "lng": 54.51663953333338,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:00",
      "speed": 9.1329565,
      "direction": 80.43257,
      "lat": 24.421594600000024,
      "lng": 54.51675146666672,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:00",
      "speed": 9.422864,
      "direction": 80.43257,
      "lat": 24.42161346666669,
      "lng": 54.516863400000055,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:01",
      "speed": 9.71277,
      "direction": 80.43257,
      "lat": 24.42163233333336,
      "lng": 54.51697533333339,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:01",
      "speed": 10.002677,
      "direction": 80.43257,
      "lat": 24.421651200000028,
      "lng": 54.51708726666673,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:01",
      "speed": 10.292583,
      "direction": 80.43257,
      "lat": 24.421670066666696,
      "lng": 54.517199200000064,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:02",
      "speed": 10.58249,
      "direction": 80.43257,
      "lat": 24.421688933333364,
      "lng": 54.5173111333334,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:02",
      "speed": 10.872396,
      "direction": 80.43257,
      "lat": 24.421707800000032,
      "lng": 54.51742306666674,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:03",
      "speed": 11.162304,
      "direction": 80.43257,
      "lat": 24.4217266666667,
      "lng": 54.51753500000007,
      "dataPoint": false,
      "rpmDivd": 0.431
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:03",
      "speed": 11.162308,
      "direction": 80.53202,
      "lat": 24.421726666666668,
      "lng": 54.517535,
      "dataPoint": true,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:03",
      "speed": 11.768965,
      "direction": 80.53202,
      "lat": 24.421744933333336,
      "lng": 54.51764453333334,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:03",
      "speed": 12.375621,
      "direction": 80.53202,
      "lat": 24.421763200000004,
      "lng": 54.517754066666676,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:04",
      "speed": 12.982278,
      "direction": 80.53202,
      "lat": 24.421781466666673,
      "lng": 54.51786360000001,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:04",
      "speed": 13.588934,
      "direction": 80.53202,
      "lat": 24.42179973333334,
      "lng": 54.51797313333335,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:05",
      "speed": 14.195591,
      "direction": 80.53202,
      "lat": 24.42181800000001,
      "lng": 54.518082666666686,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:05",
      "speed": 14.802248,
      "direction": 80.53202,
      "lat": 24.421836266666677,
      "lng": 54.51819220000002,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:05",
      "speed": 15.408904,
      "direction": 80.53202,
      "lat": 24.421854533333345,
      "lng": 54.51830173333336,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:06",
      "speed": 16.01556,
      "direction": 80.53202,
      "lat": 24.421872800000013,
      "lng": 54.518411266666696,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:06",
      "speed": 16.622217,
      "direction": 80.53202,
      "lat": 24.42189106666668,
      "lng": 54.51852080000003,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:07",
      "speed": 17.228874,
      "direction": 80.53202,
      "lat": 24.42190933333335,
      "lng": 54.51863033333337,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:07",
      "speed": 17.835531,
      "direction": 80.53202,
      "lat": 24.421927600000018,
      "lng": 54.51873986666671,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:07",
      "speed": 18.442188,
      "direction": 80.53202,
      "lat": 24.421945866666686,
      "lng": 54.51884940000004,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:08",
      "speed": 19.048845,
      "direction": 80.53202,
      "lat": 24.421964133333354,
      "lng": 54.51895893333338,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:08",
      "speed": 19.6555,
      "direction": 80.53202,
      "lat": 24.421982400000022,
      "lng": 54.51906846666672,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:09",
      "speed": 20.262157,
      "direction": 80.53202,
      "lat": 24.42200066666669,
      "lng": 54.51917800000005,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:09",
      "speed": 20.868814,
      "direction": 80.53202,
      "lat": 24.42201893333336,
      "lng": 54.51928753333339,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:09",
      "speed": 21.475471,
      "direction": 80.53202,
      "lat": 24.422037200000027,
      "lng": 54.51939706666673,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:10",
      "speed": 22.082129,
      "direction": 80.53202,
      "lat": 24.422055466666695,
      "lng": 54.519506600000064,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:10",
      "speed": 22.688784,
      "direction": 80.53202,
      "lat": 24.422073733333363,
      "lng": 54.5196161333334,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:11",
      "speed": 23.29544,
      "direction": 80.53202,
      "lat": 24.42209200000003,
      "lng": 54.51972566666674,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:11",
      "speed": 23.902098,
      "direction": 80.53202,
      "lat": 24.4221102666667,
      "lng": 54.519835200000074,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:11",
      "speed": 24.508755,
      "direction": 80.53202,
      "lat": 24.422128533333368,
      "lng": 54.51994473333341,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:12",
      "speed": 25.115412,
      "direction": 80.53202,
      "lat": 24.422146800000036,
      "lng": 54.52005426666675,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:12",
      "speed": 25.722067,
      "direction": 80.53202,
      "lat": 24.422165066666704,
      "lng": 54.520163800000084,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:13",
      "speed": 26.328724,
      "direction": 80.53202,
      "lat": 24.422183333333372,
      "lng": 54.52027333333342,
      "dataPoint": false,
      "rpmDivd": 0.907
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:13",
      "speed": 26.32873,
      "direction": 74.79381,
      "lat": 24.422183333333333,
      "lng": 54.520273333333336,
      "dataPoint": true,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:13",
      "speed": 25.77755,
      "direction": 74.79381,
      "lat": 24.422213866666667,
      "lng": 54.52038566666667,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:13",
      "speed": 25.22637,
      "direction": 74.79381,
      "lat": 24.4222444,
      "lng": 54.520498,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:14",
      "speed": 24.67519,
      "direction": 74.79381,
      "lat": 24.422274933333334,
      "lng": 54.52061033333334,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:14",
      "speed": 24.12401,
      "direction": 74.79381,
      "lat": 24.422305466666668,
      "lng": 54.52072266666667,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:15",
      "speed": 23.57283,
      "direction": 74.79381,
      "lat": 24.422336,
      "lng": 54.520835000000005,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:15",
      "speed": 23.02165,
      "direction": 74.79381,
      "lat": 24.422366533333335,
      "lng": 54.52094733333334,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:15",
      "speed": 22.47047,
      "direction": 74.79381,
      "lat": 24.42239706666667,
      "lng": 54.52105966666667,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:16",
      "speed": 21.91929,
      "direction": 74.79381,
      "lat": 24.422427600000002,
      "lng": 54.52117200000001,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:16",
      "speed": 21.368109,
      "direction": 74.79381,
      "lat": 24.422458133333336,
      "lng": 54.52128433333334,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:17",
      "speed": 20.816929,
      "direction": 74.79381,
      "lat": 24.42248866666667,
      "lng": 54.521396666666675,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:17",
      "speed": 20.265749,
      "direction": 74.79381,
      "lat": 24.422519200000004,
      "lng": 54.52150900000001,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:17",
      "speed": 19.71457,
      "direction": 74.79381,
      "lat": 24.422549733333337,
      "lng": 54.52162133333334,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:18",
      "speed": 19.16339,
      "direction": 74.79381,
      "lat": 24.42258026666667,
      "lng": 54.52173366666668,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:18",
      "speed": 18.61221,
      "direction": 74.79381,
      "lat": 24.422610800000005,
      "lng": 54.52184600000001,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:19",
      "speed": 18.06103,
      "direction": 74.79381,
      "lat": 24.42264133333334,
      "lng": 54.521958333333345,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:19",
      "speed": 17.50985,
      "direction": 74.79381,
      "lat": 24.422671866666672,
      "lng": 54.52207066666668,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:19",
      "speed": 16.95867,
      "direction": 74.79381,
      "lat": 24.422702400000006,
      "lng": 54.52218300000001,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:20",
      "speed": 16.40749,
      "direction": 74.79381,
      "lat": 24.42273293333334,
      "lng": 54.522295333333346,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:20",
      "speed": 15.85631,
      "direction": 74.79381,
      "lat": 24.422763466666673,
      "lng": 54.52240766666668,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:21",
      "speed": 15.30513,
      "direction": 74.79381,
      "lat": 24.422794000000007,
      "lng": 54.522520000000014,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:21",
      "speed": 14.753949,
      "direction": 74.79381,
      "lat": 24.42282453333334,
      "lng": 54.52263233333335,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:21",
      "speed": 14.202769,
      "direction": 74.79381,
      "lat": 24.422855066666674,
      "lng": 54.52274466666668,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:22",
      "speed": 13.651589,
      "direction": 74.79381,
      "lat": 24.422885600000008,
      "lng": 54.522857000000016,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:22",
      "speed": 13.1004095,
      "direction": 74.79381,
      "lat": 24.42291613333334,
      "lng": 54.52296933333335,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:23",
      "speed": 12.54923,
      "direction": 74.79381,
      "lat": 24.422946666666675,
      "lng": 54.523081666666684,
      "dataPoint": false,
      "rpmDivd": 1.133
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:23",
      "speed": 12.549208,
      "direction": 74.39716,
      "lat": 24.422946666666668,
      "lng": 54.52308166666667,
      "dataPoint": true,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:23",
      "speed": 12.79706,
      "direction": 74.39716,
      "lat": 24.422978800000003,
      "lng": 54.523196733333336,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:23",
      "speed": 13.044912,
      "direction": 74.39716,
      "lat": 24.423010933333337,
      "lng": 54.5233118,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:24",
      "speed": 13.292765,
      "direction": 74.39716,
      "lat": 24.423043066666672,
      "lng": 54.52342686666667,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:24",
      "speed": 13.540617,
      "direction": 74.39716,
      "lat": 24.423075200000007,
      "lng": 54.523541933333334,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:25",
      "speed": 13.788468,
      "direction": 74.39716,
      "lat": 24.42310733333334,
      "lng": 54.523657,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:25",
      "speed": 14.036321,
      "direction": 74.39716,
      "lat": 24.423139466666676,
      "lng": 54.523772066666666,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:25",
      "speed": 14.284173,
      "direction": 74.39716,
      "lat": 24.42317160000001,
      "lng": 54.52388713333333,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:26",
      "speed": 14.532025,
      "direction": 74.39716,
      "lat": 24.423203733333345,
      "lng": 54.5240022,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:26",
      "speed": 14.779878,
      "direction": 74.39716,
      "lat": 24.42323586666668,
      "lng": 54.524117266666664,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:27",
      "speed": 15.02773,
      "direction": 74.39716,
      "lat": 24.423268000000014,
      "lng": 54.52423233333333,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:27",
      "speed": 15.275582,
      "direction": 74.39716,
      "lat": 24.42330013333335,
      "lng": 54.524347399999996,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:27",
      "speed": 15.523435,
      "direction": 74.39716,
      "lat": 24.423332266666684,
      "lng": 54.52446246666666,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:28",
      "speed": 15.771287,
      "direction": 74.39716,
      "lat": 24.42336440000002,
      "lng": 54.52457753333333,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:28",
      "speed": 16.019138,
      "direction": 74.39716,
      "lat": 24.423396533333353,
      "lng": 54.524692599999995,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:29",
      "speed": 16.26699,
      "direction": 74.39716,
      "lat": 24.423428666666688,
      "lng": 54.52480766666666,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:29",
      "speed": 16.514843,
      "direction": 74.39716,
      "lat": 24.423460800000022,
      "lng": 54.52492273333333,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:29",
      "speed": 16.762695,
      "direction": 74.39716,
      "lat": 24.423492933333357,
      "lng": 54.52503779999999,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:30",
      "speed": 17.010548,
      "direction": 74.39716,
      "lat": 24.42352506666669,
      "lng": 54.52515286666666,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:30",
      "speed": 17.2584,
      "direction": 74.39716,
      "lat": 24.423557200000026,
      "lng": 54.525267933333325,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:31",
      "speed": 17.506252,
      "direction": 74.39716,
      "lat": 24.42358933333336,
      "lng": 54.52538299999999,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:31",
      "speed": 17.754105,
      "direction": 74.39716,
      "lat": 24.423621466666695,
      "lng": 54.52549806666666,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:31",
      "speed": 18.001957,
      "direction": 74.39716,
      "lat": 24.42365360000003,
      "lng": 54.52561313333332,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:32",
      "speed": 18.24981,
      "direction": 74.39716,
      "lat": 24.423685733333365,
      "lng": 54.52572819999999,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:32",
      "speed": 18.497662,
      "direction": 74.39716,
      "lat": 24.4237178666667,
      "lng": 54.525843266666655,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:33",
      "speed": 18.745514,
      "direction": 74.39716,
      "lat": 24.423750000000034,
      "lng": 54.52595833333332,
      "dataPoint": false,
      "rpmDivd": 1.293
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:33",
      "speed": 18.74552,
      "direction": 72.995735,
      "lat": 24.42375,
      "lng": 54.525958333333335,
      "dataPoint": true,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:33",
      "speed": 18.43414,
      "direction": 72.995735,
      "lat": 24.423787533333332,
      "lng": 54.52608106666667,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:33",
      "speed": 18.122757,
      "direction": 72.995735,
      "lat": 24.423825066666666,
      "lng": 54.526203800000005,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:34",
      "speed": 17.811377,
      "direction": 72.995735,
      "lat": 24.4238626,
      "lng": 54.52632653333334,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:34",
      "speed": 17.499994,
      "direction": 72.995735,
      "lat": 24.423900133333333,
      "lng": 54.526449266666674,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:35",
      "speed": 17.188614,
      "direction": 72.995735,
      "lat": 24.423937666666667,
      "lng": 54.52657200000001,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:35",
      "speed": 16.877234,
      "direction": 72.995735,
      "lat": 24.4239752,
      "lng": 54.52669473333334,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:35",
      "speed": 16.565851,
      "direction": 72.995735,
      "lat": 24.424012733333335,
      "lng": 54.52681746666668,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:36",
      "speed": 16.25447,
      "direction": 72.995735,
      "lat": 24.42405026666667,
      "lng": 54.52694020000001,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:36",
      "speed": 15.9430895,
      "direction": 72.995735,
      "lat": 24.424087800000002,
      "lng": 54.52706293333335,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:37",
      "speed": 15.631708,
      "direction": 72.995735,
      "lat": 24.424125333333336,
      "lng": 54.52718566666668,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:37",
      "speed": 15.320328,
      "direction": 72.995735,
      "lat": 24.42416286666667,
      "lng": 54.52730840000002,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:37",
      "speed": 15.008946,
      "direction": 72.995735,
      "lat": 24.424200400000004,
      "lng": 54.52743113333335,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:38",
      "speed": 14.697565,
      "direction": 72.995735,
      "lat": 24.424237933333337,
      "lng": 54.527553866666686,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:38",
      "speed": 14.386184,
      "direction": 72.995735,
      "lat": 24.42427546666667,
      "lng": 54.52767660000002,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:39",
      "speed": 14.074803,
      "direction": 72.995735,
      "lat": 24.424313000000005,
      "lng": 54.527799333333355,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:39",
      "speed": 13.763422,
      "direction": 72.995735,
      "lat": 24.42435053333334,
      "lng": 54.52792206666669,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:39",
      "speed": 13.452041,
      "direction": 72.995735,
      "lat": 24.424388066666673,
      "lng": 54.528044800000025,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:40",
      "speed": 13.140659,
      "direction": 72.995735,
      "lat": 24.424425600000006,
      "lng": 54.52816753333336,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:40",
      "speed": 12.829279,
      "direction": 72.995735,
      "lat": 24.42446313333334,
      "lng": 54.528290266666694,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:41",
      "speed": 12.517898,
      "direction": 72.995735,
      "lat": 24.424500666666674,
      "lng": 54.52841300000003,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:41",
      "speed": 12.206516,
      "direction": 72.995735,
      "lat": 24.424538200000008,
      "lng": 54.528535733333364,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:41",
      "speed": 11.895135,
      "direction": 72.995735,
      "lat": 24.42457573333334,
      "lng": 54.5286584666667,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:42",
      "speed": 11.583755,
      "direction": 72.995735,
      "lat": 24.424613266666675,
      "lng": 54.52878120000003,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:42",
      "speed": 11.272373,
      "direction": 72.995735,
      "lat": 24.42465080000001,
      "lng": 54.52890393333337,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:43",
      "speed": 10.960992,
      "direction": 72.995735,
      "lat": 24.424688333333343,
      "lng": 54.5290266666667,
      "dataPoint": false,
      "rpmDivd": 0.581
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:43",
      "speed": 10.960983,
      "direction": 72.220245,
      "lat": 24.424688333333332,
      "lng": 54.52902666666667,
      "dataPoint": true,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:43",
      "speed": 11.01288,
      "direction": 72.220245,
      "lat": 24.424728866666666,
      "lng": 54.529153066666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:43",
      "speed": 11.064776,
      "direction": 72.220245,
      "lat": 24.4247694,
      "lng": 54.529279466666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:44",
      "speed": 11.116673,
      "direction": 72.220245,
      "lat": 24.424809933333332,
      "lng": 54.529405866666664,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:44",
      "speed": 11.16857,
      "direction": 72.220245,
      "lat": 24.424850466666665,
      "lng": 54.52953226666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:45",
      "speed": 11.220467,
      "direction": 72.220245,
      "lat": 24.424891,
      "lng": 54.52965866666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:45",
      "speed": 11.272364,
      "direction": 72.220245,
      "lat": 24.424931533333332,
      "lng": 54.52978506666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:45",
      "speed": 11.32426,
      "direction": 72.220245,
      "lat": 24.424972066666665,
      "lng": 54.52991146666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:46",
      "speed": 11.376157,
      "direction": 72.220245,
      "lat": 24.4250126,
      "lng": 54.53003786666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:46",
      "speed": 11.428053,
      "direction": 72.220245,
      "lat": 24.425053133333332,
      "lng": 54.53016426666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:47",
      "speed": 11.47995,
      "direction": 72.220245,
      "lat": 24.425093666666665,
      "lng": 54.53029066666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:47",
      "speed": 11.531847,
      "direction": 72.220245,
      "lat": 24.4251342,
      "lng": 54.53041706666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:47",
      "speed": 11.583743,
      "direction": 72.220245,
      "lat": 24.425174733333332,
      "lng": 54.53054346666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:48",
      "speed": 11.63564,
      "direction": 72.220245,
      "lat": 24.425215266666665,
      "lng": 54.530669866666656,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:48",
      "speed": 11.687536,
      "direction": 72.220245,
      "lat": 24.4252558,
      "lng": 54.530796266666655,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:49",
      "speed": 11.739433,
      "direction": 72.220245,
      "lat": 24.425296333333332,
      "lng": 54.530922666666655,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:49",
      "speed": 11.79133,
      "direction": 72.220245,
      "lat": 24.425336866666665,
      "lng": 54.531049066666654,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:49",
      "speed": 11.843226,
      "direction": 72.220245,
      "lat": 24.4253774,
      "lng": 54.53117546666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:50",
      "speed": 11.8951235,
      "direction": 72.220245,
      "lat": 24.425417933333332,
      "lng": 54.53130186666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:50",
      "speed": 11.94702,
      "direction": 72.220245,
      "lat": 24.425458466666665,
      "lng": 54.53142826666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:51",
      "speed": 11.998917,
      "direction": 72.220245,
      "lat": 24.425499,
      "lng": 54.53155466666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:51",
      "speed": 12.050814,
      "direction": 72.220245,
      "lat": 24.425539533333332,
      "lng": 54.53168106666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:51",
      "speed": 12.10271,
      "direction": 72.220245,
      "lat": 24.425580066666665,
      "lng": 54.53180746666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:52",
      "speed": 12.154607,
      "direction": 72.220245,
      "lat": 24.4256206,
      "lng": 54.53193386666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:52",
      "speed": 12.206503,
      "direction": 72.220245,
      "lat": 24.42566113333333,
      "lng": 54.53206026666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:53",
      "speed": 12.2584,
      "direction": 72.220245,
      "lat": 24.425701666666665,
      "lng": 54.53218666666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:53",
      "speed": 12.258406,
      "direction": 73.831604,
      "lat": 24.425701666666665,
      "lng": 54.53218666666667,
      "dataPoint": true,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:53",
      "speed": 11.924656,
      "direction": 73.831604,
      "lat": 24.425739666666665,
      "lng": 54.532317733333336,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:53",
      "speed": 11.590906,
      "direction": 73.831604,
      "lat": 24.425777666666665,
      "lng": 54.532448800000004,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:54",
      "speed": 11.257155,
      "direction": 73.831604,
      "lat": 24.425815666666665,
      "lng": 54.53257986666667,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:54",
      "speed": 10.923406,
      "direction": 73.831604,
      "lat": 24.425853666666665,
      "lng": 54.53271093333334,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:55",
      "speed": 10.589656,
      "direction": 73.831604,
      "lat": 24.425891666666665,
      "lng": 54.53284200000001,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:55",
      "speed": 10.255906,
      "direction": 73.831604,
      "lat": 24.425929666666665,
      "lng": 54.53297306666668,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:55",
      "speed": 9.922155,
      "direction": 73.831604,
      "lat": 24.425967666666665,
      "lng": 54.533104133333346,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:56",
      "speed": 9.588406,
      "direction": 73.831604,
      "lat": 24.426005666666665,
      "lng": 54.533235200000014,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:56",
      "speed": 9.254656,
      "direction": 73.831604,
      "lat": 24.426043666666665,
      "lng": 54.53336626666668,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:57",
      "speed": 8.920906,
      "direction": 73.831604,
      "lat": 24.426081666666665,
      "lng": 54.53349733333335,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:57",
      "speed": 8.587155,
      "direction": 73.831604,
      "lat": 24.426119666666665,
      "lng": 54.53362840000002,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:57",
      "speed": 8.253406,
      "direction": 73.831604,
      "lat": 24.426157666666665,
      "lng": 54.53375946666669,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:58",
      "speed": 7.919656,
      "direction": 73.831604,
      "lat": 24.426195666666665,
      "lng": 54.533890533333356,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:58",
      "speed": 7.5859056,
      "direction": 73.831604,
      "lat": 24.426233666666665,
      "lng": 54.534021600000024,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:59",
      "speed": 7.252156,
      "direction": 73.831604,
      "lat": 24.426271666666665,
      "lng": 54.53415266666669,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:59",
      "speed": 6.9184055,
      "direction": 73.831604,
      "lat": 24.426309666666665,
      "lng": 54.53428373333336,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:33:59",
      "speed": 6.584656,
      "direction": 73.831604,
      "lat": 24.426347666666665,
      "lng": 54.53441480000003,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:00",
      "speed": 6.2509055,
      "direction": 73.831604,
      "lat": 24.426385666666665,
      "lng": 54.5345458666667,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:00",
      "speed": 5.9171557,
      "direction": 73.831604,
      "lat": 24.426423666666665,
      "lng": 54.534676933333365,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:01",
      "speed": 5.5834055,
      "direction": 73.831604,
      "lat": 24.426461666666665,
      "lng": 54.534808000000034,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:01",
      "speed": 5.2496557,
      "direction": 73.831604,
      "lat": 24.426499666666665,
      "lng": 54.5349390666667,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:01",
      "speed": 4.9159055,
      "direction": 73.831604,
      "lat": 24.426537666666665,
      "lng": 54.53507013333337,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:02",
      "speed": 4.5821557,
      "direction": 73.831604,
      "lat": 24.426575666666665,
      "lng": 54.53520120000004,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:02",
      "speed": 4.2484055,
      "direction": 73.831604,
      "lat": 24.426613666666665,
      "lng": 54.53533226666671,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:03",
      "speed": 3.9146557,
      "direction": 73.831604,
      "lat": 24.426651666666665,
      "lng": 54.535463333333375,
      "dataPoint": false,
      "rpmDivd": 0.155
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:03",
      "speed": 3.9146369,
      "direction": 73.734245,
      "lat": 24.426651666666668,
      "lng": 54.53546333333333,
      "dataPoint": true,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:03",
      "speed": 3.954007,
      "direction": 73.734245,
      "lat": 24.426690200000003,
      "lng": 54.5355954,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:03",
      "speed": 3.9933767,
      "direction": 73.734245,
      "lat": 24.426728733333338,
      "lng": 54.535727466666664,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:04",
      "speed": 4.032747,
      "direction": 73.734245,
      "lat": 24.426767266666673,
      "lng": 54.53585953333333,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:04",
      "speed": 4.072117,
      "direction": 73.734245,
      "lat": 24.426805800000007,
      "lng": 54.535991599999996,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:05",
      "speed": 4.111487,
      "direction": 73.734245,
      "lat": 24.426844333333342,
      "lng": 54.53612366666666,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:05",
      "speed": 4.150857,
      "direction": 73.734245,
      "lat": 24.426882866666677,
      "lng": 54.53625573333333,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:05",
      "speed": 4.190227,
      "direction": 73.734245,
      "lat": 24.426921400000012,
      "lng": 54.53638779999999,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:06",
      "speed": 4.2295966,
      "direction": 73.734245,
      "lat": 24.426959933333347,
      "lng": 54.53651986666666,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:06",
      "speed": 4.2689667,
      "direction": 73.734245,
      "lat": 24.42699846666668,
      "lng": 54.536651933333324,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:07",
      "speed": 4.3083367,
      "direction": 73.734245,
      "lat": 24.427037000000016,
      "lng": 54.53678399999999,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:07",
      "speed": 4.347707,
      "direction": 73.734245,
      "lat": 24.42707553333335,
      "lng": 54.536916066666656,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:07",
      "speed": 4.387077,
      "direction": 73.734245,
      "lat": 24.427114066666686,
      "lng": 54.53704813333332,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:08",
      "speed": 4.426447,
      "direction": 73.734245,
      "lat": 24.42715260000002,
      "lng": 54.53718019999999,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:08",
      "speed": 4.465817,
      "direction": 73.734245,
      "lat": 24.427191133333356,
      "lng": 54.53731226666665,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:09",
      "speed": 4.505187,
      "direction": 73.734245,
      "lat": 24.42722966666669,
      "lng": 54.53744433333332,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:09",
      "speed": 4.5445566,
      "direction": 73.734245,
      "lat": 24.427268200000025,
      "lng": 54.537576399999985,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:09",
      "speed": 4.5839267,
      "direction": 73.734245,
      "lat": 24.42730673333336,
      "lng": 54.53770846666665,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:10",
      "speed": 4.6232967,
      "direction": 73.734245,
      "lat": 24.427345266666695,
      "lng": 54.537840533333316,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:10",
      "speed": 4.662667,
      "direction": 73.734245,
      "lat": 24.42738380000003,
      "lng": 54.53797259999998,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:11",
      "speed": 4.702037,
      "direction": 73.734245,
      "lat": 24.427422333333364,
      "lng": 54.53810466666665,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:11",
      "speed": 4.741407,
      "direction": 73.734245,
      "lat": 24.4274608666667,
      "lng": 54.538236733333314,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:11",
      "speed": 4.780777,
      "direction": 73.734245,
      "lat": 24.427499400000034,
      "lng": 54.53836879999998,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:12",
      "speed": 4.820147,
      "direction": 73.734245,
      "lat": 24.42753793333337,
      "lng": 54.538500866666645,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:12",
      "speed": 4.8595166,
      "direction": 73.734245,
      "lat": 24.427576466666704,
      "lng": 54.53863293333331,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:13",
      "speed": 4.8988867,
      "direction": 73.734245,
      "lat": 24.42761500000004,
      "lng": 54.53876499999998,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:13",
      "speed": 4.8988886,
      "direction": 74.17364,
      "lat": 24.427615,
      "lng": 54.538765,
      "dataPoint": true,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:13",
      "speed": 5.8169265,
      "direction": 74.17364,
      "lat": 24.427652266666666,
      "lng": 54.538896466666664,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:13",
      "speed": 6.7349644,
      "direction": 74.17364,
      "lat": 24.427689533333332,
      "lng": 54.53902793333333,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:14",
      "speed": 7.653002,
      "direction": 74.17364,
      "lat": 24.4277268,
      "lng": 54.539159399999996,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:14",
      "speed": 8.57104,
      "direction": 74.17364,
      "lat": 24.427764066666665,
      "lng": 54.53929086666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:15",
      "speed": 9.489078,
      "direction": 74.17364,
      "lat": 24.42780133333333,
      "lng": 54.53942233333333,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:15",
      "speed": 10.407115,
      "direction": 74.17364,
      "lat": 24.427838599999998,
      "lng": 54.53955379999999,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:15",
      "speed": 11.325153,
      "direction": 74.17364,
      "lat": 24.427875866666664,
      "lng": 54.53968526666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:16",
      "speed": 12.243191,
      "direction": 74.17364,
      "lat": 24.42791313333333,
      "lng": 54.539816733333325,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:16",
      "speed": 13.161228,
      "direction": 74.17364,
      "lat": 24.427950399999997,
      "lng": 54.53994819999999,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:17",
      "speed": 14.079267,
      "direction": 74.17364,
      "lat": 24.427987666666663,
      "lng": 54.54007966666666,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:17",
      "speed": 14.997304,
      "direction": 74.17364,
      "lat": 24.42802493333333,
      "lng": 54.54021113333332,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:17",
      "speed": 15.915342,
      "direction": 74.17364,
      "lat": 24.428062199999996,
      "lng": 54.54034259999999,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:18",
      "speed": 16.83338,
      "direction": 74.17364,
      "lat": 24.428099466666662,
      "lng": 54.540474066666654,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:18",
      "speed": 17.751417,
      "direction": 74.17364,
      "lat": 24.42813673333333,
      "lng": 54.54060553333332,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:19",
      "speed": 18.669455,
      "direction": 74.17364,
      "lat": 24.428173999999995,
      "lng": 54.540736999999986,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:19",
      "speed": 19.587494,
      "direction": 74.17364,
      "lat": 24.42821126666666,
      "lng": 54.54086846666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:19",
      "speed": 20.505531,
      "direction": 74.17364,
      "lat": 24.428248533333328,
      "lng": 54.54099993333332,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:20",
      "speed": 21.423569,
      "direction": 74.17364,
      "lat": 24.428285799999994,
      "lng": 54.54113139999998,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:20",
      "speed": 22.341606,
      "direction": 74.17364,
      "lat": 24.42832306666666,
      "lng": 54.54126286666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:21",
      "speed": 23.259644,
      "direction": 74.17364,
      "lat": 24.428360333333327,
      "lng": 54.541394333333315,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:21",
      "speed": 24.177681,
      "direction": 74.17364,
      "lat": 24.428397599999993,
      "lng": 54.54152579999998,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:21",
      "speed": 25.09572,
      "direction": 74.17364,
      "lat": 24.42843486666666,
      "lng": 54.54165726666665,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:22",
      "speed": 26.013758,
      "direction": 74.17364,
      "lat": 24.428472133333326,
      "lng": 54.54178873333331,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:22",
      "speed": 26.931795,
      "direction": 74.17364,
      "lat": 24.428509399999992,
      "lng": 54.54192019999998,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:23",
      "speed": 27.849833,
      "direction": 74.17364,
      "lat": 24.42854666666666,
      "lng": 54.542051666666644,
      "dataPoint": false,
      "rpmDivd": 0.916
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:23",
      "speed": 27.849846,
      "direction": 74.17905,
      "lat": 24.428546666666666,
      "lng": 54.542051666666666,
      "dataPoint": true,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:23",
      "speed": 27.568888,
      "direction": 74.17905,
      "lat": 24.428727133333332,
      "lng": 54.54268853333333,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:23",
      "speed": 27.287928,
      "direction": 74.17905,
      "lat": 24.4289076,
      "lng": 54.5433254,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:24",
      "speed": 27.00697,
      "direction": 74.17905,
      "lat": 24.429088066666665,
      "lng": 54.54396226666667,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:24",
      "speed": 26.726011,
      "direction": 74.17905,
      "lat": 24.42926853333333,
      "lng": 54.544599133333335,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:25",
      "speed": 26.445051,
      "direction": 74.17905,
      "lat": 24.429448999999998,
      "lng": 54.545236,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:25",
      "speed": 26.164093,
      "direction": 74.17905,
      "lat": 24.429629466666665,
      "lng": 54.54587286666667,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:25",
      "speed": 25.883133,
      "direction": 74.17905,
      "lat": 24.42980993333333,
      "lng": 54.54650973333334,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:26",
      "speed": 25.602175,
      "direction": 74.17905,
      "lat": 24.429990399999998,
      "lng": 54.547146600000005,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:26",
      "speed": 25.321217,
      "direction": 74.17905,
      "lat": 24.430170866666664,
      "lng": 54.54778346666667,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:27",
      "speed": 25.040257,
      "direction": 74.17905,
      "lat": 24.43035133333333,
      "lng": 54.54842033333334,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:27",
      "speed": 24.759298,
      "direction": 74.17905,
      "lat": 24.430531799999997,
      "lng": 54.54905720000001,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:27",
      "speed": 24.47834,
      "direction": 74.17905,
      "lat": 24.430712266666664,
      "lng": 54.549694066666675,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:28",
      "speed": 24.19738,
      "direction": 74.17905,
      "lat": 24.43089273333333,
      "lng": 54.55033093333334,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:28",
      "speed": 23.916422,
      "direction": 74.17905,
      "lat": 24.431073199999997,
      "lng": 54.55096780000001,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:29",
      "speed": 23.635462,
      "direction": 74.17905,
      "lat": 24.431253666666663,
      "lng": 54.55160466666668,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:29",
      "speed": 23.354504,
      "direction": 74.17905,
      "lat": 24.43143413333333,
      "lng": 54.552241533333344,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:29",
      "speed": 23.073545,
      "direction": 74.17905,
      "lat": 24.431614599999996,
      "lng": 54.55287840000001,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:30",
      "speed": 22.792585,
      "direction": 74.17905,
      "lat": 24.431795066666663,
      "lng": 54.55351526666668,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:30",
      "speed": 22.511627,
      "direction": 74.17905,
      "lat": 24.43197553333333,
      "lng": 54.554152133333346,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:31",
      "speed": 22.230669,
      "direction": 74.17905,
      "lat": 24.432155999999996,
      "lng": 54.554789000000014,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:31",
      "speed": 21.949709,
      "direction": 74.17905,
      "lat": 24.432336466666662,
      "lng": 54.55542586666668,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:31",
      "speed": 21.66875,
      "direction": 74.17905,
      "lat": 24.43251693333333,
      "lng": 54.55606273333335,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:32",
      "speed": 21.38779,
      "direction": 74.17905,
      "lat": 24.432697399999995,
      "lng": 54.556699600000016,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:32",
      "speed": 21.106833,
      "direction": 74.17905,
      "lat": 24.43287786666666,
      "lng": 54.55733646666668,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:33",
      "speed": 20.825874,
      "direction": 74.17905,
      "lat": 24.433058333333328,
      "lng": 54.55797333333335,
      "dataPoint": false,
      "rpmDivd": 0.246
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:33",
      "speed": 20.825869,
      "direction": 74.19279,
      "lat": 24.433058333333335,
      "lng": 54.55797333333334,
      "dataPoint": true,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:33",
      "speed": 20.602175,
      "direction": 74.19279,
      "lat": 24.433094533333335,
      "lng": 54.5581012,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:33",
      "speed": 20.378483,
      "direction": 74.19279,
      "lat": 24.433130733333336,
      "lng": 54.55822906666667,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:34",
      "speed": 20.154789,
      "direction": 74.19279,
      "lat": 24.433166933333336,
      "lng": 54.558356933333336,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:34",
      "speed": 19.931095,
      "direction": 74.19279,
      "lat": 24.433203133333336,
      "lng": 54.5584848,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:35",
      "speed": 19.707401,
      "direction": 74.19279,
      "lat": 24.433239333333336,
      "lng": 54.55861266666667,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:35",
      "speed": 19.48371,
      "direction": 74.19279,
      "lat": 24.433275533333337,
      "lng": 54.558740533333335,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:35",
      "speed": 19.260015,
      "direction": 74.19279,
      "lat": 24.433311733333337,
      "lng": 54.5588684,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:36",
      "speed": 19.036322,
      "direction": 74.19279,
      "lat": 24.433347933333337,
      "lng": 54.55899626666667,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:36",
      "speed": 18.812628,
      "direction": 74.19279,
      "lat": 24.433384133333337,
      "lng": 54.559124133333334,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:37",
      "speed": 18.588936,
      "direction": 74.19279,
      "lat": 24.433420333333338,
      "lng": 54.559252,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:37",
      "speed": 18.365242,
      "direction": 74.19279,
      "lat": 24.433456533333338,
      "lng": 54.55937986666667,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:37",
      "speed": 18.141548,
      "direction": 74.19279,
      "lat": 24.43349273333334,
      "lng": 54.559507733333334,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:38",
      "speed": 17.917856,
      "direction": 74.19279,
      "lat": 24.43352893333334,
      "lng": 54.5596356,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:38",
      "speed": 17.694162,
      "direction": 74.19279,
      "lat": 24.43356513333334,
      "lng": 54.559763466666666,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:39",
      "speed": 17.470469,
      "direction": 74.19279,
      "lat": 24.43360133333334,
      "lng": 54.55989133333333,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:39",
      "speed": 17.246775,
      "direction": 74.19279,
      "lat": 24.43363753333334,
      "lng": 54.5600192,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:39",
      "speed": 17.023083,
      "direction": 74.19279,
      "lat": 24.43367373333334,
      "lng": 54.560147066666666,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:40",
      "speed": 16.799389,
      "direction": 74.19279,
      "lat": 24.43370993333334,
      "lng": 54.56027493333333,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:40",
      "speed": 16.575695,
      "direction": 74.19279,
      "lat": 24.43374613333334,
      "lng": 54.5604028,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:41",
      "speed": 16.352001,
      "direction": 74.19279,
      "lat": 24.43378233333334,
      "lng": 54.560530666666665,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:41",
      "speed": 16.12831,
      "direction": 74.19279,
      "lat": 24.43381853333334,
      "lng": 54.56065853333333,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:41",
      "speed": 15.904615,
      "direction": 74.19279,
      "lat": 24.43385473333334,
      "lng": 54.5607864,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:42",
      "speed": 15.680922,
      "direction": 74.19279,
      "lat": 24.43389093333334,
      "lng": 54.560914266666664,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:42",
      "speed": 15.457229,
      "direction": 74.19279,
      "lat": 24.43392713333334,
      "lng": 54.56104213333333,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:43",
      "speed": 15.233535,
      "direction": 74.19279,
      "lat": 24.43396333333334,
      "lng": 54.56117,
      "dataPoint": false,
      "rpmDivd": 0.956
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:43",
      "speed": 15.23353,
      "direction": 74.228676,
      "lat": 24.433963333333335,
      "lng": 54.56117,
      "dataPoint": true,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:43",
      "speed": 15.466171,
      "direction": 74.228676,
      "lat": 24.4340002,
      "lng": 54.56130053333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:43",
      "speed": 15.6988125,
      "direction": 74.228676,
      "lat": 24.434037066666665,
      "lng": 54.561431066666664,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:44",
      "speed": 15.931454,
      "direction": 74.228676,
      "lat": 24.43407393333333,
      "lng": 54.5615616,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:44",
      "speed": 16.164095,
      "direction": 74.228676,
      "lat": 24.434110799999996,
      "lng": 54.56169213333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:45",
      "speed": 16.396736,
      "direction": 74.228676,
      "lat": 24.43414766666666,
      "lng": 54.561822666666664,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:45",
      "speed": 16.629377,
      "direction": 74.228676,
      "lat": 24.434184533333326,
      "lng": 54.5619532,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:45",
      "speed": 16.862019,
      "direction": 74.228676,
      "lat": 24.43422139999999,
      "lng": 54.56208373333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:46",
      "speed": 17.09466,
      "direction": 74.228676,
      "lat": 24.434258266666657,
      "lng": 54.562214266666665,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:46",
      "speed": 17.3273,
      "direction": 74.228676,
      "lat": 24.434295133333322,
      "lng": 54.5623448,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:47",
      "speed": 17.55994,
      "direction": 74.228676,
      "lat": 24.434331999999987,
      "lng": 54.56247533333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:47",
      "speed": 17.792582,
      "direction": 74.228676,
      "lat": 24.434368866666652,
      "lng": 54.562605866666665,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:47",
      "speed": 18.025223,
      "direction": 74.228676,
      "lat": 24.434405733333318,
      "lng": 54.5627364,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:48",
      "speed": 18.257864,
      "direction": 74.228676,
      "lat": 24.434442599999983,
      "lng": 54.56286693333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:48",
      "speed": 18.490505,
      "direction": 74.228676,
      "lat": 24.434479466666648,
      "lng": 54.562997466666666,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:49",
      "speed": 18.723146,
      "direction": 74.228676,
      "lat": 24.434516333333313,
      "lng": 54.563128,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:49",
      "speed": 18.955788,
      "direction": 74.228676,
      "lat": 24.43455319999998,
      "lng": 54.56325853333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:49",
      "speed": 19.188429,
      "direction": 74.228676,
      "lat": 24.434590066666644,
      "lng": 54.563389066666666,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:50",
      "speed": 19.42107,
      "direction": 74.228676,
      "lat": 24.43462693333331,
      "lng": 54.5635196,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:50",
      "speed": 19.653711,
      "direction": 74.228676,
      "lat": 24.434663799999974,
      "lng": 54.56365013333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:51",
      "speed": 19.886353,
      "direction": 74.228676,
      "lat": 24.43470066666664,
      "lng": 54.563780666666666,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:51",
      "speed": 20.118994,
      "direction": 74.228676,
      "lat": 24.434737533333305,
      "lng": 54.5639112,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:51",
      "speed": 20.351635,
      "direction": 74.228676,
      "lat": 24.43477439999997,
      "lng": 54.56404173333333,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:52",
      "speed": 20.584276,
      "direction": 74.228676,
      "lat": 24.434811266666635,
      "lng": 54.56417226666667,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:52",
      "speed": 20.816917,
      "direction": 74.228676,
      "lat": 24.4348481333333,
      "lng": 54.5643028,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:53",
      "speed": 21.049559,
      "direction": 74.228676,
      "lat": 24.434884999999966,
      "lng": 54.564433333333334,
      "dataPoint": false,
      "rpmDivd": 0.529
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:53",
      "speed": 21.049562,
      "direction": 74.23541,
      "lat": 24.434885,
      "lng": 54.564433333333334,
      "dataPoint": true,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:53",
      "speed": 20.355219,
      "direction": 74.23541,
      "lat": 24.434921266666667,
      "lng": 54.5645618,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:53",
      "speed": 19.660873,
      "direction": 74.23541,
      "lat": 24.434957533333332,
      "lng": 54.564690266666666,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:54",
      "speed": 18.96653,
      "direction": 74.23541,
      "lat": 24.434993799999997,
      "lng": 54.56481873333333,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:54",
      "speed": 18.272184,
      "direction": 74.23541,
      "lat": 24.435030066666663,
      "lng": 54.5649472,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:55",
      "speed": 17.57784,
      "direction": 74.23541,
      "lat": 24.435066333333328,
      "lng": 54.565075666666665,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:55",
      "speed": 16.883495,
      "direction": 74.23541,
      "lat": 24.435102599999993,
      "lng": 54.56520413333333,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:55",
      "speed": 16.189152,
      "direction": 74.23541,
      "lat": 24.43513886666666,
      "lng": 54.5653326,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:56",
      "speed": 15.494807,
      "direction": 74.23541,
      "lat": 24.435175133333324,
      "lng": 54.565461066666664,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:56",
      "speed": 14.800463,
      "direction": 74.23541,
      "lat": 24.43521139999999,
      "lng": 54.56558953333333,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:57",
      "speed": 14.106118,
      "direction": 74.23541,
      "lat": 24.435247666666655,
      "lng": 54.565718,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:57",
      "speed": 13.411774,
      "direction": 74.23541,
      "lat": 24.43528393333332,
      "lng": 54.56584646666666,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:57",
      "speed": 12.717429,
      "direction": 74.23541,
      "lat": 24.435320199999985,
      "lng": 54.56597493333333,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:58",
      "speed": 12.023085,
      "direction": 74.23541,
      "lat": 24.43535646666665,
      "lng": 54.566103399999996,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:58",
      "speed": 11.32874,
      "direction": 74.23541,
      "lat": 24.435392733333316,
      "lng": 54.56623186666666,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:59",
      "speed": 10.634396,
      "direction": 74.23541,
      "lat": 24.43542899999998,
      "lng": 54.56636033333333,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:59",
      "speed": 9.940051,
      "direction": 74.23541,
      "lat": 24.435465266666647,
      "lng": 54.566488799999995,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:34:59",
      "speed": 9.245707,
      "direction": 74.23541,
      "lat": 24.435501533333312,
      "lng": 54.56661726666666,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:00",
      "speed": 8.551362,
      "direction": 74.23541,
      "lat": 24.435537799999977,
      "lng": 54.56674573333333,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:00",
      "speed": 7.857018,
      "direction": 74.23541,
      "lat": 24.435574066666643,
      "lng": 54.566874199999994,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:01",
      "speed": 7.1626735,
      "direction": 74.23541,
      "lat": 24.435610333333308,
      "lng": 54.56700266666666,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:01",
      "speed": 6.468329,
      "direction": 74.23541,
      "lat": 24.435646599999973,
      "lng": 54.567131133333326,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:01",
      "speed": 5.773985,
      "direction": 74.23541,
      "lat": 24.43568286666664,
      "lng": 54.56725959999999,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:02",
      "speed": 5.0796404,
      "direction": 74.23541,
      "lat": 24.435719133333304,
      "lng": 54.56738806666666,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:02",
      "speed": 4.385296,
      "direction": 74.23541,
      "lat": 24.43575539999997,
      "lng": 54.567516533333325,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:03",
      "speed": 3.6909513,
      "direction": 74.23541,
      "lat": 24.435791666666635,
      "lng": 54.56764499999999,
      "dataPoint": false,
      "rpmDivd": 0.816
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:03",
      "speed": 3.6909432,
      "direction": 74.17364,
      "lat": 24.435791666666667,
      "lng": 54.567645,
      "dataPoint": true,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:03",
      "speed": 3.7723677,
      "direction": 74.17364,
      "lat": 24.435828933333333,
      "lng": 54.567776466666665,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:03",
      "speed": 3.8537922,
      "direction": 74.17364,
      "lat": 24.4358662,
      "lng": 54.56790793333333,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:04",
      "speed": 3.9352167,
      "direction": 74.17364,
      "lat": 24.435903466666666,
      "lng": 54.568039399999996,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:04",
      "speed": 4.016641,
      "direction": 74.17364,
      "lat": 24.435940733333332,
      "lng": 54.56817086666666,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:05",
      "speed": 4.0980654,
      "direction": 74.17364,
      "lat": 24.435978,
      "lng": 54.56830233333333,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:05",
      "speed": 4.17949,
      "direction": 74.17364,
      "lat": 24.436015266666665,
      "lng": 54.568433799999994,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:05",
      "speed": 4.2609143,
      "direction": 74.17364,
      "lat": 24.43605253333333,
      "lng": 54.56856526666666,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:06",
      "speed": 4.3423386,
      "direction": 74.17364,
      "lat": 24.436089799999998,
      "lng": 54.568696733333326,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:06",
      "speed": 4.4237633,
      "direction": 74.17364,
      "lat": 24.436127066666664,
      "lng": 54.56882819999999,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:07",
      "speed": 4.5051875,
      "direction": 74.17364,
      "lat": 24.43616433333333,
      "lng": 54.56895966666666,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:07",
      "speed": 4.586612,
      "direction": 74.17364,
      "lat": 24.436201599999997,
      "lng": 54.56909113333332,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:07",
      "speed": 4.6680365,
      "direction": 74.17364,
      "lat": 24.436238866666663,
      "lng": 54.56922259999999,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:08",
      "speed": 4.749461,
      "direction": 74.17364,
      "lat": 24.43627613333333,
      "lng": 54.569354066666655,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:08",
      "speed": 4.8308854,
      "direction": 74.17364,
      "lat": 24.436313399999996,
      "lng": 54.56948553333332,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:09",
      "speed": 4.91231,
      "direction": 74.17364,
      "lat": 24.436350666666662,
      "lng": 54.56961699999999,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:09",
      "speed": 4.9937344,
      "direction": 74.17364,
      "lat": 24.43638793333333,
      "lng": 54.56974846666665,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:09",
      "speed": 5.0751586,
      "direction": 74.17364,
      "lat": 24.436425199999995,
      "lng": 54.56987993333332,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:10",
      "speed": 5.1565833,
      "direction": 74.17364,
      "lat": 24.43646246666666,
      "lng": 54.570011399999984,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:10",
      "speed": 5.2380075,
      "direction": 74.17364,
      "lat": 24.436499733333328,
      "lng": 54.57014286666665,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:11",
      "speed": 5.3194323,
      "direction": 74.17364,
      "lat": 24.436536999999994,
      "lng": 54.570274333333316,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:11",
      "speed": 5.4008565,
      "direction": 74.17364,
      "lat": 24.43657426666666,
      "lng": 54.57040579999998,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:11",
      "speed": 5.482281,
      "direction": 74.17364,
      "lat": 24.436611533333327,
      "lng": 54.57053726666665,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:12",
      "speed": 5.5637054,
      "direction": 74.17364,
      "lat": 24.436648799999993,
      "lng": 54.570668733333314,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:12",
      "speed": 5.6451297,
      "direction": 74.17364,
      "lat": 24.43668606666666,
      "lng": 54.57080019999998,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:13",
      "speed": 5.7265544,
      "direction": 74.17364,
      "lat": 24.436723333333326,
      "lng": 54.570931666666645,
      "dataPoint": false,
      "rpmDivd": 1.308
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:13",
      "speed": 5.7265544,
      "direction": 74.08934,
      "lat": 24.436723333333333,
      "lng": 54.57093166666667,
      "dataPoint": true,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:13",
      "speed": 6.65175,
      "direction": 74.08934,
      "lat": 24.4367606,
      "lng": 54.5710624,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:13",
      "speed": 7.5769453,
      "direction": 74.08934,
      "lat": 24.436797866666666,
      "lng": 54.57119313333334,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:14",
      "speed": 8.502141,
      "direction": 74.08934,
      "lat": 24.436835133333332,
      "lng": 54.571323866666674,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:14",
      "speed": 9.427337,
      "direction": 74.08934,
      "lat": 24.4368724,
      "lng": 54.57145460000001,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:15",
      "speed": 10.352532,
      "direction": 74.08934,
      "lat": 24.436909666666665,
      "lng": 54.571585333333346,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:15",
      "speed": 11.277728,
      "direction": 74.08934,
      "lat": 24.43694693333333,
      "lng": 54.57171606666668,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:15",
      "speed": 12.202923,
      "direction": 74.08934,
      "lat": 24.436984199999998,
      "lng": 54.57184680000002,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:16",
      "speed": 13.1281185,
      "direction": 74.08934,
      "lat": 24.437021466666664,
      "lng": 54.57197753333335,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:16",
      "speed": 14.053314,
      "direction": 74.08934,
      "lat": 24.43705873333333,
      "lng": 54.57210826666669,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:17",
      "speed": 14.97851,
      "direction": 74.08934,
      "lat": 24.437095999999997,
      "lng": 54.572239000000025,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:17",
      "speed": 15.903706,
      "direction": 74.08934,
      "lat": 24.437133266666663,
      "lng": 54.57236973333336,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:17",
      "speed": 16.828901,
      "direction": 74.08934,
      "lat": 24.43717053333333,
      "lng": 54.572500466666696,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:18",
      "speed": 17.754097,
      "direction": 74.08934,
      "lat": 24.437207799999996,
      "lng": 54.57263120000003,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:18",
      "speed": 18.679293,
      "direction": 74.08934,
      "lat": 24.437245066666662,
      "lng": 54.57276193333337,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:19",
      "speed": 19.604488,
      "direction": 74.08934,
      "lat": 24.43728233333333,
      "lng": 54.572892666666704,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:19",
      "speed": 20.529684,
      "direction": 74.08934,
      "lat": 24.437319599999995,
      "lng": 54.57302340000004,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:19",
      "speed": 21.45488,
      "direction": 74.08934,
      "lat": 24.43735686666666,
      "lng": 54.573154133333375,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:20",
      "speed": 22.380074,
      "direction": 74.08934,
      "lat": 24.437394133333328,
      "lng": 54.57328486666671,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:20",
      "speed": 23.30527,
      "direction": 74.08934,
      "lat": 24.437431399999994,
      "lng": 54.57341560000005,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:21",
      "speed": 24.230465,
      "direction": 74.08934,
      "lat": 24.43746866666666,
      "lng": 54.57354633333338,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:21",
      "speed": 25.15566,
      "direction": 74.08934,
      "lat": 24.437505933333327,
      "lng": 54.57367706666672,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:21",
      "speed": 26.080856,
      "direction": 74.08934,
      "lat": 24.437543199999993,
      "lng": 54.573807800000054,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:22",
      "speed": 27.006052,
      "direction": 74.08934,
      "lat": 24.43758046666666,
      "lng": 54.57393853333339,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:22",
      "speed": 27.931248,
      "direction": 74.08934,
      "lat": 24.437617733333326,
      "lng": 54.574069266666726,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:23",
      "speed": 28.856443,
      "direction": 74.08934,
      "lat": 24.437654999999992,
      "lng": 54.57420000000006,
      "dataPoint": false,
      "rpmDivd": 1.178
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:23",
      "speed": 28.856466,
      "direction": 74.338165,
      "lat": 24.437655,
      "lng": 54.5742,
      "dataPoint": true,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:23",
      "speed": 28.94147,
      "direction": 74.338165,
      "lat": 24.437689466666665,
      "lng": 54.57432293333333,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:23",
      "speed": 29.026472,
      "direction": 74.338165,
      "lat": 24.43772393333333,
      "lng": 54.57444586666666,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:24",
      "speed": 29.111477,
      "direction": 74.338165,
      "lat": 24.437758399999996,
      "lng": 54.57456879999999,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:24",
      "speed": 29.19648,
      "direction": 74.338165,
      "lat": 24.437792866666662,
      "lng": 54.57469173333332,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:25",
      "speed": 29.281483,
      "direction": 74.338165,
      "lat": 24.437827333333328,
      "lng": 54.57481466666665,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:25",
      "speed": 29.366486,
      "direction": 74.338165,
      "lat": 24.437861799999993,
      "lng": 54.57493759999998,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:25",
      "speed": 29.45149,
      "direction": 74.338165,
      "lat": 24.43789626666666,
      "lng": 54.57506053333331,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:26",
      "speed": 29.536493,
      "direction": 74.338165,
      "lat": 24.437930733333324,
      "lng": 54.57518346666664,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:26",
      "speed": 29.621496,
      "direction": 74.338165,
      "lat": 24.43796519999999,
      "lng": 54.57530639999997,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:27",
      "speed": 29.7065,
      "direction": 74.338165,
      "lat": 24.437999666666656,
      "lng": 54.5754293333333,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:27",
      "speed": 29.791504,
      "direction": 74.338165,
      "lat": 24.43803413333332,
      "lng": 54.57555226666663,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:27",
      "speed": 29.876507,
      "direction": 74.338165,
      "lat": 24.438068599999987,
      "lng": 54.57567519999996,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:28",
      "speed": 29.96151,
      "direction": 74.338165,
      "lat": 24.438103066666653,
      "lng": 54.575798133333286,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:28",
      "speed": 30.046513,
      "direction": 74.338165,
      "lat": 24.438137533333318,
      "lng": 54.575921066666616,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:29",
      "speed": 30.131516,
      "direction": 74.338165,
      "lat": 24.438171999999984,
      "lng": 54.576043999999946,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:29",
      "speed": 30.21652,
      "direction": 74.338165,
      "lat": 24.43820646666665,
      "lng": 54.576166933333276,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:29",
      "speed": 30.301523,
      "direction": 74.338165,
      "lat": 24.438240933333315,
      "lng": 54.576289866666606,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:30",
      "speed": 30.386526,
      "direction": 74.338165,
      "lat": 24.43827539999998,
      "lng": 54.576412799999936,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:30",
      "speed": 30.471529,
      "direction": 74.338165,
      "lat": 24.438309866666646,
      "lng": 54.576535733333266,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:31",
      "speed": 30.556534,
      "direction": 74.338165,
      "lat": 24.438344333333312,
      "lng": 54.576658666666596,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:31",
      "speed": 30.641537,
      "direction": 74.338165,
      "lat": 24.438378799999978,
      "lng": 54.576781599999926,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:31",
      "speed": 30.72654,
      "direction": 74.338165,
      "lat": 24.438413266666643,
      "lng": 54.576904533333256,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:32",
      "speed": 30.811543,
      "direction": 74.338165,
      "lat": 24.43844773333331,
      "lng": 54.577027466666586,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:32",
      "speed": 30.896545,
      "direction": 74.338165,
      "lat": 24.438482199999974,
      "lng": 54.577150399999915,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:33",
      "speed": 30.98155,
      "direction": 74.338165,
      "lat": 24.43851666666664,
      "lng": 54.577273333333245,
      "dataPoint": false,
      "rpmDivd": 0.287
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:33",
      "speed": 30.981554,
      "direction": 74.06308,
      "lat": 24.43851666666667,
      "lng": 54.57727333333333,
      "dataPoint": true,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:33",
      "speed": 30.237103,
      "direction": 74.06308,
      "lat": 24.438550666666668,
      "lng": 54.5773924,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:33",
      "speed": 29.492651,
      "direction": 74.06308,
      "lat": 24.438584666666667,
      "lng": 54.57751146666667,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:34",
      "speed": 28.748201,
      "direction": 74.06308,
      "lat": 24.438618666666667,
      "lng": 54.57763053333334,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:34",
      "speed": 28.00375,
      "direction": 74.06308,
      "lat": 24.438652666666666,
      "lng": 54.57774960000001,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:35",
      "speed": 27.259298,
      "direction": 74.06308,
      "lat": 24.438686666666666,
      "lng": 54.57786866666668,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:35",
      "speed": 26.514847,
      "direction": 74.06308,
      "lat": 24.438720666666665,
      "lng": 54.57798773333335,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:35",
      "speed": 25.770397,
      "direction": 74.06308,
      "lat": 24.438754666666664,
      "lng": 54.57810680000002,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:36",
      "speed": 25.025946,
      "direction": 74.06308,
      "lat": 24.438788666666664,
      "lng": 54.57822586666669,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:36",
      "speed": 24.281494,
      "direction": 74.06308,
      "lat": 24.438822666666663,
      "lng": 54.57834493333336,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:37",
      "speed": 23.537043,
      "direction": 74.06308,
      "lat": 24.438856666666663,
      "lng": 54.57846400000003,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:37",
      "speed": 22.792591,
      "direction": 74.06308,
      "lat": 24.438890666666662,
      "lng": 54.5785830666667,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:37",
      "speed": 22.048141,
      "direction": 74.06308,
      "lat": 24.43892466666666,
      "lng": 54.57870213333337,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:38",
      "speed": 21.30369,
      "direction": 74.06308,
      "lat": 24.43895866666666,
      "lng": 54.57882120000004,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:38",
      "speed": 20.559238,
      "direction": 74.06308,
      "lat": 24.43899266666666,
      "lng": 54.57894026666671,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:39",
      "speed": 19.814787,
      "direction": 74.06308,
      "lat": 24.43902666666666,
      "lng": 54.57905933333338,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:39",
      "speed": 19.070335,
      "direction": 74.06308,
      "lat": 24.43906066666666,
      "lng": 54.57917840000005,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:39",
      "speed": 18.325886,
      "direction": 74.06308,
      "lat": 24.43909466666666,
      "lng": 54.57929746666672,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:40",
      "speed": 17.581434,
      "direction": 74.06308,
      "lat": 24.439128666666658,
      "lng": 54.579416533333394,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:40",
      "speed": 16.836983,
      "direction": 74.06308,
      "lat": 24.439162666666657,
      "lng": 54.579535600000064,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:41",
      "speed": 16.092531,
      "direction": 74.06308,
      "lat": 24.439196666666657,
      "lng": 54.579654666666734,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:41",
      "speed": 15.348081,
      "direction": 74.06308,
      "lat": 24.439230666666656,
      "lng": 54.579773733333404,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:41",
      "speed": 14.603629,
      "direction": 74.06308,
      "lat": 24.439264666666656,
      "lng": 54.579892800000074,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:42",
      "speed": 13.859179,
      "direction": 74.06308,
      "lat": 24.439298666666655,
      "lng": 54.580011866666744,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:42",
      "speed": 13.114727,
      "direction": 74.06308,
      "lat": 24.439332666666655,
      "lng": 54.580130933333415,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:43",
      "speed": 12.370276,
      "direction": 74.06308,
      "lat": 24.439366666666654,
      "lng": 54.580250000000085,
      "dataPoint": false,
      "rpmDivd": 0.72
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:43",
      "speed": 12.370253,
      "direction": 74.332535,
      "lat": 24.43936666666667,
      "lng": 54.58025,
      "dataPoint": true,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:43",
      "speed": 12.790796,
      "direction": 74.332535,
      "lat": 24.439401333333336,
      "lng": 54.5803736,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:43",
      "speed": 13.211339,
      "direction": 74.332535,
      "lat": 24.439436000000004,
      "lng": 54.5804972,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:44",
      "speed": 13.631883,
      "direction": 74.332535,
      "lat": 24.439470666666672,
      "lng": 54.580620800000005,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:44",
      "speed": 14.052426,
      "direction": 74.332535,
      "lat": 24.43950533333334,
      "lng": 54.58074440000001,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:45",
      "speed": 14.472969,
      "direction": 74.332535,
      "lat": 24.439540000000008,
      "lng": 54.58086800000001,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:45",
      "speed": 14.893513,
      "direction": 74.332535,
      "lat": 24.439574666666676,
      "lng": 54.58099160000001,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:45",
      "speed": 15.314056,
      "direction": 74.332535,
      "lat": 24.439609333333344,
      "lng": 54.58111520000001,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:46",
      "speed": 15.734599,
      "direction": 74.332535,
      "lat": 24.439644000000012,
      "lng": 54.581238800000015,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:46",
      "speed": 16.155142,
      "direction": 74.332535,
      "lat": 24.43967866666668,
      "lng": 54.58136240000002,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:47",
      "speed": 16.575686,
      "direction": 74.332535,
      "lat": 24.439713333333348,
      "lng": 54.58148600000002,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:47",
      "speed": 16.99623,
      "direction": 74.332535,
      "lat": 24.439748000000016,
      "lng": 54.58160960000002,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:47",
      "speed": 17.416773,
      "direction": 74.332535,
      "lat": 24.439782666666684,
      "lng": 54.58173320000002,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:48",
      "speed": 17.837317,
      "direction": 74.332535,
      "lat": 24.43981733333335,
      "lng": 54.581856800000025,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:48",
      "speed": 18.25786,
      "direction": 74.332535,
      "lat": 24.43985200000002,
      "lng": 54.58198040000003,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:49",
      "speed": 18.678402,
      "direction": 74.332535,
      "lat": 24.439886666666688,
      "lng": 54.58210400000003,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:49",
      "speed": 19.098946,
      "direction": 74.332535,
      "lat": 24.439921333333356,
      "lng": 54.58222760000003,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:49",
      "speed": 19.51949,
      "direction": 74.332535,
      "lat": 24.439956000000024,
      "lng": 54.58235120000003,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:50",
      "speed": 19.940033,
      "direction": 74.332535,
      "lat": 24.43999066666669,
      "lng": 54.582474800000035,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:50",
      "speed": 20.360577,
      "direction": 74.332535,
      "lat": 24.44002533333336,
      "lng": 54.58259840000004,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:51",
      "speed": 20.781118,
      "direction": 74.332535,
      "lat": 24.440060000000027,
      "lng": 54.58272200000004,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:51",
      "speed": 21.201662,
      "direction": 74.332535,
      "lat": 24.440094666666695,
      "lng": 54.58284560000004,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:51",
      "speed": 21.622206,
      "direction": 74.332535,
      "lat": 24.440129333333363,
      "lng": 54.58296920000004,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:52",
      "speed": 22.04275,
      "direction": 74.332535,
      "lat": 24.44016400000003,
      "lng": 54.583092800000045,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:52",
      "speed": 22.463293,
      "direction": 74.332535,
      "lat": 24.4401986666667,
      "lng": 54.58321640000005,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:53",
      "speed": 22.883837,
      "direction": 74.332535,
      "lat": 24.440233333333367,
      "lng": 54.58334000000005,
      "dataPoint": false,
      "rpmDivd": 0.146
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:53",
      "speed": 22.88385,
      "direction": 74.148384,
      "lat": 24.44023333333333,
      "lng": 54.58334,
      "dataPoint": true,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:53",
      "speed": 23.13528,
      "direction": 74.148384,
      "lat": 24.440273066666666,
      "lng": 54.58347993333334,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:53",
      "speed": 23.386713,
      "direction": 74.148384,
      "lat": 24.4403128,
      "lng": 54.58361986666667,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:54",
      "speed": 23.638144,
      "direction": 74.148384,
      "lat": 24.440352533333336,
      "lng": 54.58375980000001,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:54",
      "speed": 23.889574,
      "direction": 74.148384,
      "lat": 24.44039226666667,
      "lng": 54.58389973333335,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:55",
      "speed": 24.141006,
      "direction": 74.148384,
      "lat": 24.440432000000005,
      "lng": 54.58403966666668,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:55",
      "speed": 24.392437,
      "direction": 74.148384,
      "lat": 24.44047173333334,
      "lng": 54.58417960000002,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:55",
      "speed": 24.643867,
      "direction": 74.148384,
      "lat": 24.440511466666674,
      "lng": 54.58431953333336,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:56",
      "speed": 24.8953,
      "direction": 74.148384,
      "lat": 24.44055120000001,
      "lng": 54.584459466666694,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:56",
      "speed": 25.14673,
      "direction": 74.148384,
      "lat": 24.440590933333343,
      "lng": 54.58459940000003,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:57",
      "speed": 25.39816,
      "direction": 74.148384,
      "lat": 24.440630666666678,
      "lng": 54.58473933333337,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:57",
      "speed": 25.649591,
      "direction": 74.148384,
      "lat": 24.440670400000013,
      "lng": 54.584879266666704,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:57",
      "speed": 25.901024,
      "direction": 74.148384,
      "lat": 24.440710133333347,
      "lng": 54.58501920000004,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:58",
      "speed": 26.152454,
      "direction": 74.148384,
      "lat": 24.440749866666682,
      "lng": 54.58515913333338,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:58",
      "speed": 26.403885,
      "direction": 74.148384,
      "lat": 24.440789600000016,
      "lng": 54.585299066666714,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:59",
      "speed": 26.655317,
      "direction": 74.148384,
      "lat": 24.44082933333335,
      "lng": 54.58543900000005,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:59",
      "speed": 26.906748,
      "direction": 74.148384,
      "lat": 24.440869066666686,
      "lng": 54.58557893333339,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:35:59",
      "speed": 27.158178,
      "direction": 74.148384,
      "lat": 24.44090880000002,
      "lng": 54.585718866666724,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:00",
      "speed": 27.40961,
      "direction": 74.148384,
      "lat": 24.440948533333355,
      "lng": 54.58585880000006,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:00",
      "speed": 27.661041,
      "direction": 74.148384,
      "lat": 24.44098826666669,
      "lng": 54.5859987333334,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:01",
      "speed": 27.912472,
      "direction": 74.148384,
      "lat": 24.441028000000024,
      "lng": 54.586138666666734,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:01",
      "speed": 28.163904,
      "direction": 74.148384,
      "lat": 24.44106773333336,
      "lng": 54.58627860000007,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:01",
      "speed": 28.415335,
      "direction": 74.148384,
      "lat": 24.441107466666693,
      "lng": 54.58641853333341,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:02",
      "speed": 28.666765,
      "direction": 74.148384,
      "lat": 24.441147200000028,
      "lng": 54.586558466666744,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:02",
      "speed": 28.918198,
      "direction": 74.148384,
      "lat": 24.441186933333363,
      "lng": 54.58669840000008,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:03",
      "speed": 29.169628,
      "direction": 74.148384,
      "lat": 24.441226666666697,
      "lng": 54.58683833333342,
      "dataPoint": false,
      "rpmDivd": 0.457
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:03",
      "speed": 29.169636,
      "direction": 73.81079,
      "lat": 24.441226666666665,
      "lng": 54.58683833333333,
      "dataPoint": true,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:03",
      "speed": 28.97368,
      "direction": 73.81079,
      "lat": 24.441264466666667,
      "lng": 54.586968533333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:03",
      "speed": 28.777725,
      "direction": 73.81079,
      "lat": 24.441302266666668,
      "lng": 54.587098733333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:04",
      "speed": 28.58177,
      "direction": 73.81079,
      "lat": 24.44134006666667,
      "lng": 54.587228933333336,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:04",
      "speed": 28.385813,
      "direction": 73.81079,
      "lat": 24.44137786666667,
      "lng": 54.58735913333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:05",
      "speed": 28.189857,
      "direction": 73.81079,
      "lat": 24.44141566666667,
      "lng": 54.58748933333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:05",
      "speed": 27.993902,
      "direction": 73.81079,
      "lat": 24.441453466666673,
      "lng": 54.58761953333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:05",
      "speed": 27.797947,
      "direction": 73.81079,
      "lat": 24.441491266666674,
      "lng": 54.58774973333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:06",
      "speed": 27.601992,
      "direction": 73.81079,
      "lat": 24.441529066666675,
      "lng": 54.58787993333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:06",
      "speed": 27.406036,
      "direction": 73.81079,
      "lat": 24.441566866666676,
      "lng": 54.58801013333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:07",
      "speed": 27.210081,
      "direction": 73.81079,
      "lat": 24.441604666666677,
      "lng": 54.58814033333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:07",
      "speed": 27.014124,
      "direction": 73.81079,
      "lat": 24.44164246666668,
      "lng": 54.58827053333334,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:07",
      "speed": 26.818169,
      "direction": 73.81079,
      "lat": 24.44168026666668,
      "lng": 54.588400733333344,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:08",
      "speed": 26.622213,
      "direction": 73.81079,
      "lat": 24.44171806666668,
      "lng": 54.588530933333345,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:08",
      "speed": 26.426258,
      "direction": 73.81079,
      "lat": 24.441755866666682,
      "lng": 54.588661133333346,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:09",
      "speed": 26.230303,
      "direction": 73.81079,
      "lat": 24.441793666666683,
      "lng": 54.58879133333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:09",
      "speed": 26.034348,
      "direction": 73.81079,
      "lat": 24.441831466666684,
      "lng": 54.58892153333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:09",
      "speed": 25.838392,
      "direction": 73.81079,
      "lat": 24.441869266666686,
      "lng": 54.58905173333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:10",
      "speed": 25.642435,
      "direction": 73.81079,
      "lat": 24.441907066666687,
      "lng": 54.58918193333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:10",
      "speed": 25.44648,
      "direction": 73.81079,
      "lat": 24.441944866666688,
      "lng": 54.58931213333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:11",
      "speed": 25.250525,
      "direction": 73.81079,
      "lat": 24.44198266666669,
      "lng": 54.58944233333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:11",
      "speed": 25.05457,
      "direction": 73.81079,
      "lat": 24.44202046666669,
      "lng": 54.58957253333335,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:11",
      "speed": 24.858614,
      "direction": 73.81079,
      "lat": 24.44205826666669,
      "lng": 54.589702733333354,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:12",
      "speed": 24.662659,
      "direction": 73.81079,
      "lat": 24.442096066666693,
      "lng": 54.589832933333355,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:12",
      "speed": 24.466702,
      "direction": 73.81079,
      "lat": 24.442133866666694,
      "lng": 54.589963133333356,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:13",
      "speed": 24.270746,
      "direction": 73.81079,
      "lat": 24.442171666666695,
      "lng": 54.59009333333336,
      "dataPoint": false,
      "rpmDivd": 0.649
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:13",
      "speed": 24.270748,
      "direction": 74.303276,
      "lat": 24.442171666666667,
      "lng": 54.590093333333336,
      "dataPoint": true,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:13",
      "speed": 23.443083,
      "direction": 74.303276,
      "lat": 24.4422082,
      "lng": 54.590223333333334,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:13",
      "speed": 22.615417,
      "direction": 74.303276,
      "lat": 24.442244733333332,
      "lng": 54.59035333333333,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:14",
      "speed": 21.787752,
      "direction": 74.303276,
      "lat": 24.442281266666665,
      "lng": 54.59048333333333,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:14",
      "speed": 20.960087,
      "direction": 74.303276,
      "lat": 24.442317799999998,
      "lng": 54.59061333333333,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:15",
      "speed": 20.13242,
      "direction": 74.303276,
      "lat": 24.44235433333333,
      "lng": 54.59074333333333,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:15",
      "speed": 19.304754,
      "direction": 74.303276,
      "lat": 24.442390866666663,
      "lng": 54.59087333333333,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:15",
      "speed": 18.477089,
      "direction": 74.303276,
      "lat": 24.442427399999996,
      "lng": 54.591003333333326,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:16",
      "speed": 17.649424,
      "direction": 74.303276,
      "lat": 24.44246393333333,
      "lng": 54.591133333333325,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:16",
      "speed": 16.821758,
      "direction": 74.303276,
      "lat": 24.44250046666666,
      "lng": 54.59126333333332,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:17",
      "speed": 15.994093,
      "direction": 74.303276,
      "lat": 24.442536999999994,
      "lng": 54.59139333333332,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:17",
      "speed": 15.166427,
      "direction": 74.303276,
      "lat": 24.442573533333327,
      "lng": 54.59152333333332,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:17",
      "speed": 14.338761,
      "direction": 74.303276,
      "lat": 24.44261006666666,
      "lng": 54.59165333333332,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:18",
      "speed": 13.511096,
      "direction": 74.303276,
      "lat": 24.442646599999993,
      "lng": 54.59178333333332,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:18",
      "speed": 12.683431,
      "direction": 74.303276,
      "lat": 24.442683133333325,
      "lng": 54.591913333333316,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:19",
      "speed": 11.855764,
      "direction": 74.303276,
      "lat": 24.442719666666658,
      "lng": 54.592043333333315,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:19",
      "speed": 11.028099,
      "direction": 74.303276,
      "lat": 24.44275619999999,
      "lng": 54.592173333333314,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:19",
      "speed": 10.200434,
      "direction": 74.303276,
      "lat": 24.442792733333324,
      "lng": 54.59230333333331,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:20",
      "speed": 9.372768,
      "direction": 74.303276,
      "lat": 24.442829266666656,
      "lng": 54.59243333333331,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:20",
      "speed": 8.545102,
      "direction": 74.303276,
      "lat": 24.44286579999999,
      "lng": 54.59256333333331,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:21",
      "speed": 7.717437,
      "direction": 74.303276,
      "lat": 24.442902333333322,
      "lng": 54.59269333333331,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:21",
      "speed": 6.8897715,
      "direction": 74.303276,
      "lat": 24.442938866666655,
      "lng": 54.59282333333331,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:21",
      "speed": 6.062106,
      "direction": 74.303276,
      "lat": 24.442975399999987,
      "lng": 54.592953333333305,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:22",
      "speed": 5.2344403,
      "direction": 74.303276,
      "lat": 24.44301193333332,
      "lng": 54.593083333333304,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:22",
      "speed": 4.406775,
      "direction": 74.303276,
      "lat": 24.443048466666653,
      "lng": 54.5932133333333,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:23",
      "speed": 3.5791092,
      "direction": 74.303276,
      "lat": 24.443084999999986,
      "lng": 54.5933433333333,
      "dataPoint": false,
      "rpmDivd": 0.763
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:23",
      "speed": 3.5790966,
      "direction": 74.29975,
      "lat": 24.443085,
      "lng": 54.59334333333333,
      "dataPoint": true,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:23",
      "speed": 3.5844655,
      "direction": 74.29975,
      "lat": 24.443122066666668,
      "lng": 54.59347519999999,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:23",
      "speed": 3.5898345,
      "direction": 74.29975,
      "lat": 24.443159133333335,
      "lng": 54.59360706666666,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:24",
      "speed": 3.5952032,
      "direction": 74.29975,
      "lat": 24.443196200000003,
      "lng": 54.59373893333332,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:24",
      "speed": 3.600572,
      "direction": 74.29975,
      "lat": 24.44323326666667,
      "lng": 54.59387079999998,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:25",
      "speed": 3.605941,
      "direction": 74.29975,
      "lat": 24.443270333333338,
      "lng": 54.59400266666665,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:25",
      "speed": 3.61131,
      "direction": 74.29975,
      "lat": 24.443307400000005,
      "lng": 54.59413453333331,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:25",
      "speed": 3.6166787,
      "direction": 74.29975,
      "lat": 24.443344466666673,
      "lng": 54.594266399999974,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:26",
      "speed": 3.6220477,
      "direction": 74.29975,
      "lat": 24.44338153333334,
      "lng": 54.59439826666664,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:26",
      "speed": 3.6274166,
      "direction": 74.29975,
      "lat": 24.443418600000008,
      "lng": 54.5945301333333,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:27",
      "speed": 3.6327856,
      "direction": 74.29975,
      "lat": 24.443455666666676,
      "lng": 54.594661999999964,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:27",
      "speed": 3.6381543,
      "direction": 74.29975,
      "lat": 24.443492733333343,
      "lng": 54.59479386666663,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:27",
      "speed": 3.6435232,
      "direction": 74.29975,
      "lat": 24.44352980000001,
      "lng": 54.59492573333329,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:28",
      "speed": 3.6488922,
      "direction": 74.29975,
      "lat": 24.44356686666668,
      "lng": 54.595057599999954,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:28",
      "speed": 3.654261,
      "direction": 74.29975,
      "lat": 24.443603933333346,
      "lng": 54.59518946666662,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:29",
      "speed": 3.6596298,
      "direction": 74.29975,
      "lat": 24.443641000000014,
      "lng": 54.59532133333328,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:29",
      "speed": 3.6649988,
      "direction": 74.29975,
      "lat": 24.44367806666668,
      "lng": 54.595453199999945,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:29",
      "speed": 3.6703677,
      "direction": 74.29975,
      "lat": 24.44371513333335,
      "lng": 54.59558506666661,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:30",
      "speed": 3.6757367,
      "direction": 74.29975,
      "lat": 24.443752200000016,
      "lng": 54.59571693333327,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:30",
      "speed": 3.6811054,
      "direction": 74.29975,
      "lat": 24.443789266666684,
      "lng": 54.595848799999935,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:31",
      "speed": 3.6864743,
      "direction": 74.29975,
      "lat": 24.44382633333335,
      "lng": 54.5959806666666,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:31",
      "speed": 3.6918433,
      "direction": 74.29975,
      "lat": 24.44386340000002,
      "lng": 54.59611253333326,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:31",
      "speed": 3.6972122,
      "direction": 74.29975,
      "lat": 24.443900466666687,
      "lng": 54.596244399999925,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:32",
      "speed": 3.702581,
      "direction": 74.29975,
      "lat": 24.443937533333354,
      "lng": 54.59637626666659,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:32",
      "speed": 3.7079499,
      "direction": 74.29975,
      "lat": 24.443974600000022,
      "lng": 54.59650813333325,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:33",
      "speed": 3.7133188,
      "direction": 74.29975,
      "lat": 24.44401166666669,
      "lng": 54.596639999999915,
      "dataPoint": false,
      "rpmDivd": 0.825
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:33",
      "speed": 3.7133129,
      "direction": 74.42137,
      "lat": 24.44401166666667,
      "lng": 54.59664,
      "dataPoint": true,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:33",
      "speed": 4.603613,
      "direction": 74.42137,
      "lat": 24.444048933333335,
      "lng": 54.596773666666664,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:33",
      "speed": 5.4939127,
      "direction": 74.42137,
      "lat": 24.4440862,
      "lng": 54.59690733333333,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:34",
      "speed": 6.384213,
      "direction": 74.42137,
      "lat": 24.444123466666667,
      "lng": 54.59704099999999,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:34",
      "speed": 7.274513,
      "direction": 74.42137,
      "lat": 24.444160733333334,
      "lng": 54.59717466666665,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:35",
      "speed": 8.164813,
      "direction": 74.42137,
      "lat": 24.444198,
      "lng": 54.59730833333332,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:35",
      "speed": 9.055113,
      "direction": 74.42137,
      "lat": 24.444235266666666,
      "lng": 54.59744199999998,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:35",
      "speed": 9.945413,
      "direction": 74.42137,
      "lat": 24.444272533333333,
      "lng": 54.59757566666664,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:36",
      "speed": 10.835712,
      "direction": 74.42137,
      "lat": 24.4443098,
      "lng": 54.597709333333306,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:36",
      "speed": 11.726013,
      "direction": 74.42137,
      "lat": 24.444347066666666,
      "lng": 54.59784299999997,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:37",
      "speed": 12.616313,
      "direction": 74.42137,
      "lat": 24.444384333333332,
      "lng": 54.59797666666663,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:37",
      "speed": 13.506613,
      "direction": 74.42137,
      "lat": 24.4444216,
      "lng": 54.598110333333295,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:37",
      "speed": 14.396913,
      "direction": 74.42137,
      "lat": 24.444458866666665,
      "lng": 54.59824399999996,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:38",
      "speed": 15.287213,
      "direction": 74.42137,
      "lat": 24.44449613333333,
      "lng": 54.59837766666662,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:38",
      "speed": 16.177513,
      "direction": 74.42137,
      "lat": 24.444533399999997,
      "lng": 54.598511333333285,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:39",
      "speed": 17.067812,
      "direction": 74.42137,
      "lat": 24.444570666666664,
      "lng": 54.59864499999995,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:39",
      "speed": 17.958113,
      "direction": 74.42137,
      "lat": 24.44460793333333,
      "lng": 54.59877866666661,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:39",
      "speed": 18.848413,
      "direction": 74.42137,
      "lat": 24.444645199999997,
      "lng": 54.598912333333274,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:40",
      "speed": 19.738712,
      "direction": 74.42137,
      "lat": 24.444682466666663,
      "lng": 54.59904599999994,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:40",
      "speed": 20.629013,
      "direction": 74.42137,
      "lat": 24.44471973333333,
      "lng": 54.5991796666666,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:41",
      "speed": 21.519314,
      "direction": 74.42137,
      "lat": 24.444756999999996,
      "lng": 54.599313333333264,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:41",
      "speed": 22.409613,
      "direction": 74.42137,
      "lat": 24.444794266666662,
      "lng": 54.59944699999993,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:41",
      "speed": 23.299913,
      "direction": 74.42137,
      "lat": 24.44483153333333,
      "lng": 54.59958066666659,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:42",
      "speed": 24.190212,
      "direction": 74.42137,
      "lat": 24.444868799999995,
      "lng": 54.59971433333325,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:42",
      "speed": 25.080513,
      "direction": 74.42137,
      "lat": 24.44490606666666,
      "lng": 54.599847999999916,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:43",
      "speed": 25.970814,
      "direction": 74.42137,
      "lat": 24.444943333333327,
      "lng": 54.59998166666658,
      "dataPoint": false,
      "rpmDivd": 0.78
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:43",
      "speed": 25.97082,
      "direction": 74.577835,
      "lat": 24.444943333333335,
      "lng": 54.599981666666665,
      "dataPoint": true,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:43",
      "speed": 25.168207,
      "direction": 74.577835,
      "lat": 24.4449796,
      "lng": 54.60011313333333,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:43",
      "speed": 24.365595,
      "direction": 74.577835,
      "lat": 24.445015866666665,
      "lng": 54.600244599999996,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:44",
      "speed": 23.562983,
      "direction": 74.577835,
      "lat": 24.44505213333333,
      "lng": 54.60037606666666,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:44",
      "speed": 22.76037,
      "direction": 74.577835,
      "lat": 24.445088399999996,
      "lng": 54.60050753333333,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:45",
      "speed": 21.957758,
      "direction": 74.577835,
      "lat": 24.44512466666666,
      "lng": 54.600638999999994,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:45",
      "speed": 21.155146,
      "direction": 74.577835,
      "lat": 24.445160933333327,
      "lng": 54.60077046666666,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:45",
      "speed": 20.352533,
      "direction": 74.577835,
      "lat": 24.445197199999992,
      "lng": 54.600901933333326,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:46",
      "speed": 19.549921,
      "direction": 74.577835,
      "lat": 24.445233466666657,
      "lng": 54.60103339999999,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:46",
      "speed": 18.747309,
      "direction": 74.577835,
      "lat": 24.445269733333323,
      "lng": 54.60116486666666,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:47",
      "speed": 17.944696,
      "direction": 74.577835,
      "lat": 24.445305999999988,
      "lng": 54.60129633333332,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:47",
      "speed": 17.142084,
      "direction": 74.577835,
      "lat": 24.445342266666653,
      "lng": 54.60142779999999,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:47",
      "speed": 16.339474,
      "direction": 74.577835,
      "lat": 24.44537853333332,
      "lng": 54.601559266666655,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:48",
      "speed": 15.53686,
      "direction": 74.577835,
      "lat": 24.445414799999984,
      "lng": 54.60169073333332,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:48",
      "speed": 14.734248,
      "direction": 74.577835,
      "lat": 24.44545106666665,
      "lng": 54.60182219999999,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:49",
      "speed": 13.931636,
      "direction": 74.577835,
      "lat": 24.445487333333315,
      "lng": 54.60195366666665,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:49",
      "speed": 13.129024,
      "direction": 74.577835,
      "lat": 24.44552359999998,
      "lng": 54.60208513333332,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:49",
      "speed": 12.326411,
      "direction": 74.577835,
      "lat": 24.445559866666645,
      "lng": 54.602216599999984,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:50",
      "speed": 11.5238,
      "direction": 74.577835,
      "lat": 24.44559613333331,
      "lng": 54.60234806666665,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:50",
      "speed": 10.721188,
      "direction": 74.577835,
      "lat": 24.445632399999976,
      "lng": 54.602479533333316,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:51",
      "speed": 9.918575,
      "direction": 74.577835,
      "lat": 24.44566866666664,
      "lng": 54.60261099999998,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:51",
      "speed": 9.115963,
      "direction": 74.577835,
      "lat": 24.445704933333307,
      "lng": 54.60274246666665,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:51",
      "speed": 8.313351,
      "direction": 74.577835,
      "lat": 24.445741199999972,
      "lng": 54.60287393333331,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:52",
      "speed": 7.5107384,
      "direction": 74.577835,
      "lat": 24.445777466666637,
      "lng": 54.60300539999998,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:52",
      "speed": 6.708126,
      "direction": 74.577835,
      "lat": 24.445813733333303,
      "lng": 54.603136866666645,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:53",
      "speed": 5.905514,
      "direction": 74.577835,
      "lat": 24.445849999999968,
      "lng": 54.60326833333331,
      "dataPoint": false,
      "rpmDivd": 1.103
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:53",
      "speed": 5.9055095,
      "direction": 74.21983,
      "lat": 24.44585,
      "lng": 54.60326833333333,
      "dataPoint": true,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:53",
      "speed": 5.779346,
      "direction": 74.21983,
      "lat": 24.445887133333333,
      "lng": 54.60339973333333,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:53",
      "speed": 5.653183,
      "direction": 74.21983,
      "lat": 24.445924266666665,
      "lng": 54.603531133333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:54",
      "speed": 5.5270195,
      "direction": 74.21983,
      "lat": 24.445961399999998,
      "lng": 54.603662533333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:54",
      "speed": 5.400856,
      "direction": 74.21983,
      "lat": 24.44599853333333,
      "lng": 54.603793933333336,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:55",
      "speed": 5.274693,
      "direction": 74.21983,
      "lat": 24.446035666666663,
      "lng": 54.603925333333336,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:55",
      "speed": 5.1485295,
      "direction": 74.21983,
      "lat": 24.446072799999996,
      "lng": 54.60405673333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:55",
      "speed": 5.022366,
      "direction": 74.21983,
      "lat": 24.44610993333333,
      "lng": 54.60418813333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:56",
      "speed": 4.896203,
      "direction": 74.21983,
      "lat": 24.44614706666666,
      "lng": 54.60431953333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:56",
      "speed": 4.7700396,
      "direction": 74.21983,
      "lat": 24.446184199999994,
      "lng": 54.60445093333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:57",
      "speed": 4.643876,
      "direction": 74.21983,
      "lat": 24.446221333333327,
      "lng": 54.60458233333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:57",
      "speed": 4.5177126,
      "direction": 74.21983,
      "lat": 24.44625846666666,
      "lng": 54.60471373333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:57",
      "speed": 4.3915496,
      "direction": 74.21983,
      "lat": 24.446295599999992,
      "lng": 54.60484513333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:58",
      "speed": 4.265386,
      "direction": 74.21983,
      "lat": 24.446332733333325,
      "lng": 54.60497653333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:58",
      "speed": 4.1392226,
      "direction": 74.21983,
      "lat": 24.446369866666657,
      "lng": 54.60510793333334,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:59",
      "speed": 4.0130596,
      "direction": 74.21983,
      "lat": 24.44640699999999,
      "lng": 54.605239333333344,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:59",
      "speed": 3.8868961,
      "direction": 74.21983,
      "lat": 24.446444133333323,
      "lng": 54.605370733333345,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:36:59",
      "speed": 3.760733,
      "direction": 74.21983,
      "lat": 24.446481266666655,
      "lng": 54.605502133333346,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:00",
      "speed": 3.6345694,
      "direction": 74.21983,
      "lat": 24.446518399999988,
      "lng": 54.60563353333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:00",
      "speed": 3.5084062,
      "direction": 74.21983,
      "lat": 24.44655553333332,
      "lng": 54.60576493333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:01",
      "speed": 3.382243,
      "direction": 74.21983,
      "lat": 24.446592666666653,
      "lng": 54.60589633333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:01",
      "speed": 3.2560794,
      "direction": 74.21983,
      "lat": 24.446629799999986,
      "lng": 54.60602773333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:01",
      "speed": 3.1299162,
      "direction": 74.21983,
      "lat": 24.44666693333332,
      "lng": 54.60615913333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:02",
      "speed": 3.0037527,
      "direction": 74.21983,
      "lat": 24.44670406666665,
      "lng": 54.60629053333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:02",
      "speed": 2.8775895,
      "direction": 74.21983,
      "lat": 24.446741199999984,
      "lng": 54.60642193333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:03",
      "speed": 2.7514262,
      "direction": 74.21983,
      "lat": 24.446778333333317,
      "lng": 54.60655333333335,
      "dataPoint": false,
      "rpmDivd": 1.466
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:03",
      "speed": 2.7514307,
      "direction": 74.32891,
      "lat": 24.446778333333334,
      "lng": 54.60655333333333,
      "dataPoint": true,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:03",
      "speed": 3.469934,
      "direction": 74.32891,
      "lat": 24.446815533333336,
      "lng": 54.60668593333333,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:03",
      "speed": 4.1884375,
      "direction": 74.32891,
      "lat": 24.446852733333337,
      "lng": 54.60681853333333,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:04",
      "speed": 4.906941,
      "direction": 74.32891,
      "lat": 24.44688993333334,
      "lng": 54.60695113333333,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:04",
      "speed": 5.625444,
      "direction": 74.32891,
      "lat": 24.44692713333334,
      "lng": 54.60708373333333,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:05",
      "speed": 6.3439474,
      "direction": 74.32891,
      "lat": 24.44696433333334,
      "lng": 54.607216333333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:05",
      "speed": 7.062451,
      "direction": 74.32891,
      "lat": 24.447001533333342,
      "lng": 54.607348933333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:05",
      "speed": 7.780954,
      "direction": 74.32891,
      "lat": 24.447038733333343,
      "lng": 54.607481533333335,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:06",
      "speed": 8.499457,
      "direction": 74.32891,
      "lat": 24.447075933333345,
      "lng": 54.607614133333335,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:06",
      "speed": 9.21796,
      "direction": 74.32891,
      "lat": 24.447113133333346,
      "lng": 54.607746733333336,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:07",
      "speed": 9.936464,
      "direction": 74.32891,
      "lat": 24.447150333333347,
      "lng": 54.60787933333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:07",
      "speed": 10.654967,
      "direction": 74.32891,
      "lat": 24.44718753333335,
      "lng": 54.60801193333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:07",
      "speed": 11.37347,
      "direction": 74.32891,
      "lat": 24.44722473333335,
      "lng": 54.60814453333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:08",
      "speed": 12.091974,
      "direction": 74.32891,
      "lat": 24.44726193333335,
      "lng": 54.60827713333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:08",
      "speed": 12.810477,
      "direction": 74.32891,
      "lat": 24.447299133333352,
      "lng": 54.60840973333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:09",
      "speed": 13.528981,
      "direction": 74.32891,
      "lat": 24.447336333333354,
      "lng": 54.60854233333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:09",
      "speed": 14.247484,
      "direction": 74.32891,
      "lat": 24.447373533333355,
      "lng": 54.60867493333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:09",
      "speed": 14.965987,
      "direction": 74.32891,
      "lat": 24.447410733333356,
      "lng": 54.60880753333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:10",
      "speed": 15.684491,
      "direction": 74.32891,
      "lat": 24.447447933333358,
      "lng": 54.60894013333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:10",
      "speed": 16.402994,
      "direction": 74.32891,
      "lat": 24.44748513333336,
      "lng": 54.60907273333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:11",
      "speed": 17.121498,
      "direction": 74.32891,
      "lat": 24.44752233333336,
      "lng": 54.60920533333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:11",
      "speed": 17.84,
      "direction": 74.32891,
      "lat": 24.44755953333336,
      "lng": 54.60933793333334,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:11",
      "speed": 18.558504,
      "direction": 74.32891,
      "lat": 24.447596733333363,
      "lng": 54.609470533333344,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:12",
      "speed": 19.277008,
      "direction": 74.32891,
      "lat": 24.447633933333364,
      "lng": 54.609603133333344,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:12",
      "speed": 19.99551,
      "direction": 74.32891,
      "lat": 24.447671133333365,
      "lng": 54.609735733333345,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:13",
      "speed": 20.714014,
      "direction": 74.32891,
      "lat": 24.447708333333367,
      "lng": 54.609868333333345,
      "dataPoint": false,
      "rpmDivd": 0.815
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:13",
      "speed": 20.714022,
      "direction": 74.13543,
      "lat": 24.447708333333335,
      "lng": 54.60986833333333,
      "dataPoint": true,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:13",
      "speed": 20.629019,
      "direction": 74.13543,
      "lat": 24.4477456,
      "lng": 54.609999466666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:13",
      "speed": 20.544016,
      "direction": 74.13543,
      "lat": 24.447782866666667,
      "lng": 54.6101306,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:14",
      "speed": 20.459011,
      "direction": 74.13543,
      "lat": 24.447820133333334,
      "lng": 54.61026173333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:14",
      "speed": 20.374008,
      "direction": 74.13543,
      "lat": 24.4478574,
      "lng": 54.610392866666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:15",
      "speed": 20.289005,
      "direction": 74.13543,
      "lat": 24.447894666666667,
      "lng": 54.610524,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:15",
      "speed": 20.204002,
      "direction": 74.13543,
      "lat": 24.447931933333333,
      "lng": 54.61065513333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:15",
      "speed": 20.118998,
      "direction": 74.13543,
      "lat": 24.4479692,
      "lng": 54.610786266666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:16",
      "speed": 20.033995,
      "direction": 74.13543,
      "lat": 24.448006466666666,
      "lng": 54.6109174,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:16",
      "speed": 19.948992,
      "direction": 74.13543,
      "lat": 24.448043733333332,
      "lng": 54.61104853333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:17",
      "speed": 19.863989,
      "direction": 74.13543,
      "lat": 24.448081,
      "lng": 54.611179666666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:17",
      "speed": 19.778984,
      "direction": 74.13543,
      "lat": 24.448118266666665,
      "lng": 54.6113108,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:17",
      "speed": 19.693981,
      "direction": 74.13543,
      "lat": 24.44815553333333,
      "lng": 54.61144193333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:18",
      "speed": 19.608978,
      "direction": 74.13543,
      "lat": 24.448192799999998,
      "lng": 54.611573066666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:18",
      "speed": 19.523975,
      "direction": 74.13543,
      "lat": 24.448230066666664,
      "lng": 54.6117042,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:19",
      "speed": 19.438972,
      "direction": 74.13543,
      "lat": 24.44826733333333,
      "lng": 54.61183533333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:19",
      "speed": 19.353968,
      "direction": 74.13543,
      "lat": 24.448304599999997,
      "lng": 54.611966466666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:19",
      "speed": 19.268965,
      "direction": 74.13543,
      "lat": 24.448341866666663,
      "lng": 54.6120976,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:20",
      "speed": 19.183962,
      "direction": 74.13543,
      "lat": 24.44837913333333,
      "lng": 54.61222873333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:20",
      "speed": 19.098959,
      "direction": 74.13543,
      "lat": 24.448416399999996,
      "lng": 54.612359866666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:21",
      "speed": 19.013954,
      "direction": 74.13543,
      "lat": 24.448453666666662,
      "lng": 54.612491,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:21",
      "speed": 18.928951,
      "direction": 74.13543,
      "lat": 24.44849093333333,
      "lng": 54.61262213333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:21",
      "speed": 18.843948,
      "direction": 74.13543,
      "lat": 24.448528199999995,
      "lng": 54.612753266666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:22",
      "speed": 18.758945,
      "direction": 74.13543,
      "lat": 24.44856546666666,
      "lng": 54.6128844,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:22",
      "speed": 18.673943,
      "direction": 74.13543,
      "lat": 24.448602733333328,
      "lng": 54.61301553333333,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:23",
      "speed": 18.588938,
      "direction": 74.13543,
      "lat": 24.448639999999994,
      "lng": 54.613146666666665,
      "dataPoint": false,
      "rpmDivd": 1.106
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:23",
      "speed": 18.588934,
      "direction": 73.386696,
      "lat": 24.44864,
      "lng": 54.613146666666665,
      "dataPoint": true,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:23",
      "speed": 18.739256,
      "direction": 73.386696,
      "lat": 24.448677733333334,
      "lng": 54.61327313333333,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:23",
      "speed": 18.889578,
      "direction": 73.386696,
      "lat": 24.448715466666666,
      "lng": 54.613399599999994,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:24",
      "speed": 19.0399,
      "direction": 73.386696,
      "lat": 24.4487532,
      "lng": 54.61352606666666,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:24",
      "speed": 19.190224,
      "direction": 73.386696,
      "lat": 24.44879093333333,
      "lng": 54.61365253333332,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:25",
      "speed": 19.340546,
      "direction": 73.386696,
      "lat": 24.448828666666664,
      "lng": 54.61377899999999,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:25",
      "speed": 19.490868,
      "direction": 73.386696,
      "lat": 24.448866399999996,
      "lng": 54.61390546666665,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:25",
      "speed": 19.64119,
      "direction": 73.386696,
      "lat": 24.44890413333333,
      "lng": 54.614031933333315,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:26",
      "speed": 19.791512,
      "direction": 73.386696,
      "lat": 24.44894186666666,
      "lng": 54.61415839999998,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:26",
      "speed": 19.941833,
      "direction": 73.386696,
      "lat": 24.448979599999994,
      "lng": 54.614284866666644,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:27",
      "speed": 20.092155,
      "direction": 73.386696,
      "lat": 24.449017333333327,
      "lng": 54.61441133333331,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:27",
      "speed": 20.24248,
      "direction": 73.386696,
      "lat": 24.44905506666666,
      "lng": 54.61453779999997,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:27",
      "speed": 20.392801,
      "direction": 73.386696,
      "lat": 24.449092799999992,
      "lng": 54.61466426666664,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:28",
      "speed": 20.543123,
      "direction": 73.386696,
      "lat": 24.449130533333324,
      "lng": 54.6147907333333,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:28",
      "speed": 20.693445,
      "direction": 73.386696,
      "lat": 24.449168266666657,
      "lng": 54.614917199999965,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:29",
      "speed": 20.843767,
      "direction": 73.386696,
      "lat": 24.44920599999999,
      "lng": 54.61504366666663,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:29",
      "speed": 20.99409,
      "direction": 73.386696,
      "lat": 24.449243733333322,
      "lng": 54.615170133333294,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:29",
      "speed": 21.144411,
      "direction": 73.386696,
      "lat": 24.449281466666655,
      "lng": 54.61529659999996,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:30",
      "speed": 21.294733,
      "direction": 73.386696,
      "lat": 24.449319199999987,
      "lng": 54.61542306666662,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:30",
      "speed": 21.445057,
      "direction": 73.386696,
      "lat": 24.44935693333332,
      "lng": 54.61554953333329,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:31",
      "speed": 21.595379,
      "direction": 73.386696,
      "lat": 24.449394666666652,
      "lng": 54.61567599999995,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:31",
      "speed": 21.7457,
      "direction": 73.386696,
      "lat": 24.449432399999985,
      "lng": 54.615802466666615,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:31",
      "speed": 21.896023,
      "direction": 73.386696,
      "lat": 24.449470133333318,
      "lng": 54.61592893333328,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:32",
      "speed": 22.046345,
      "direction": 73.386696,
      "lat": 24.44950786666665,
      "lng": 54.616055399999944,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:32",
      "speed": 22.196667,
      "direction": 73.386696,
      "lat": 24.449545599999983,
      "lng": 54.61618186666661,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:33",
      "speed": 22.346989,
      "direction": 73.386696,
      "lat": 24.449583333333315,
      "lng": 54.61630833333327,
      "dataPoint": false,
      "rpmDivd": 0.725
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:33",
      "speed": 22.346983,
      "direction": 66.45848,
      "lat": 24.449583333333333,
      "lng": 54.616308333333336,
      "dataPoint": true,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:33",
      "speed": 22.403355,
      "direction": 66.45848,
      "lat": 24.449630066666668,
      "lng": 54.6164156,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:33",
      "speed": 22.459724,
      "direction": 66.45848,
      "lat": 24.449676800000002,
      "lng": 54.61652286666667,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:34",
      "speed": 22.516096,
      "direction": 66.45848,
      "lat": 24.449723533333337,
      "lng": 54.61663013333334,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:34",
      "speed": 22.572468,
      "direction": 66.45848,
      "lat": 24.449770266666672,
      "lng": 54.616737400000005,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:35",
      "speed": 22.628838,
      "direction": 66.45848,
      "lat": 24.449817000000007,
      "lng": 54.61684466666667,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:35",
      "speed": 22.68521,
      "direction": 66.45848,
      "lat": 24.44986373333334,
      "lng": 54.61695193333334,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:35",
      "speed": 22.741581,
      "direction": 66.45848,
      "lat": 24.449910466666676,
      "lng": 54.61705920000001,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:36",
      "speed": 22.797953,
      "direction": 66.45848,
      "lat": 24.44995720000001,
      "lng": 54.617166466666674,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:36",
      "speed": 22.854322,
      "direction": 66.45848,
      "lat": 24.450003933333345,
      "lng": 54.61727373333334,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:37",
      "speed": 22.910694,
      "direction": 66.45848,
      "lat": 24.45005066666668,
      "lng": 54.61738100000001,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:37",
      "speed": 22.967066,
      "direction": 66.45848,
      "lat": 24.450097400000015,
      "lng": 54.617488266666676,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:37",
      "speed": 23.023436,
      "direction": 66.45848,
      "lat": 24.45014413333335,
      "lng": 54.61759553333334,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:38",
      "speed": 23.079807,
      "direction": 66.45848,
      "lat": 24.450190866666684,
      "lng": 54.61770280000001,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:38",
      "speed": 23.136179,
      "direction": 66.45848,
      "lat": 24.45023760000002,
      "lng": 54.61781006666668,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:39",
      "speed": 23.192549,
      "direction": 66.45848,
      "lat": 24.450284333333354,
      "lng": 54.617917333333345,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:39",
      "speed": 23.24892,
      "direction": 66.45848,
      "lat": 24.45033106666669,
      "lng": 54.61802460000001,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:39",
      "speed": 23.305292,
      "direction": 66.45848,
      "lat": 24.450377800000023,
      "lng": 54.61813186666668,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:40",
      "speed": 23.361664,
      "direction": 66.45848,
      "lat": 24.450424533333358,
      "lng": 54.61823913333335,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:40",
      "speed": 23.418034,
      "direction": 66.45848,
      "lat": 24.450471266666693,
      "lng": 54.618346400000014,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:41",
      "speed": 23.474405,
      "direction": 66.45848,
      "lat": 24.450518000000027,
      "lng": 54.61845366666668,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:41",
      "speed": 23.530777,
      "direction": 66.45848,
      "lat": 24.450564733333362,
      "lng": 54.61856093333335,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:41",
      "speed": 23.587147,
      "direction": 66.45848,
      "lat": 24.450611466666697,
      "lng": 54.618668200000016,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:42",
      "speed": 23.643518,
      "direction": 66.45848,
      "lat": 24.45065820000003,
      "lng": 54.61877546666668,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:42",
      "speed": 23.69989,
      "direction": 66.45848,
      "lat": 24.450704933333366,
      "lng": 54.61888273333335,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:43",
      "speed": 23.75626,
      "direction": 66.45848,
      "lat": 24.4507516666667,
      "lng": 54.61899000000002,
      "dataPoint": false,
      "rpmDivd": 0.465
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:43",
      "speed": 23.756254,
      "direction": 58.37077,
      "lat": 24.450751666666665,
      "lng": 54.61899,
      "dataPoint": true,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:43",
      "speed": 23.306183,
      "direction": 58.37077,
      "lat": 24.450812066666664,
      "lng": 54.61908806666666,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:43",
      "speed": 22.856112,
      "direction": 58.37077,
      "lat": 24.450872466666663,
      "lng": 54.61918613333333,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:44",
      "speed": 22.40604,
      "direction": 58.37077,
      "lat": 24.450932866666662,
      "lng": 54.619284199999996,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:44",
      "speed": 21.955969,
      "direction": 58.37077,
      "lat": 24.45099326666666,
      "lng": 54.61938226666666,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:45",
      "speed": 21.5059,
      "direction": 58.37077,
      "lat": 24.45105366666666,
      "lng": 54.61948033333333,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:45",
      "speed": 21.055828,
      "direction": 58.37077,
      "lat": 24.45111406666666,
      "lng": 54.619578399999995,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:45",
      "speed": 20.605757,
      "direction": 58.37077,
      "lat": 24.451174466666657,
      "lng": 54.61967646666666,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:46",
      "speed": 20.155685,
      "direction": 58.37077,
      "lat": 24.451234866666656,
      "lng": 54.61977453333333,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:46",
      "speed": 19.705614,
      "direction": 58.37077,
      "lat": 24.451295266666655,
      "lng": 54.619872599999994,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:47",
      "speed": 19.255543,
      "direction": 58.37077,
      "lat": 24.451355666666654,
      "lng": 54.61997066666666,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:47",
      "speed": 18.805471,
      "direction": 58.37077,
      "lat": 24.451416066666653,
      "lng": 54.620068733333326,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:47",
      "speed": 18.3554,
      "direction": 58.37077,
      "lat": 24.45147646666665,
      "lng": 54.62016679999999,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:48",
      "speed": 17.90533,
      "direction": 58.37077,
      "lat": 24.45153686666665,
      "lng": 54.62026486666666,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:48",
      "speed": 17.45526,
      "direction": 58.37077,
      "lat": 24.45159726666665,
      "lng": 54.620362933333325,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:49",
      "speed": 17.005188,
      "direction": 58.37077,
      "lat": 24.451657666666648,
      "lng": 54.62046099999999,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:49",
      "speed": 16.555117,
      "direction": 58.37077,
      "lat": 24.451718066666647,
      "lng": 54.62055906666666,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:49",
      "speed": 16.105045,
      "direction": 58.37077,
      "lat": 24.451778466666646,
      "lng": 54.620657133333324,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:50",
      "speed": 15.654974,
      "direction": 58.37077,
      "lat": 24.451838866666645,
      "lng": 54.62075519999999,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:50",
      "speed": 15.204903,
      "direction": 58.37077,
      "lat": 24.451899266666643,
      "lng": 54.62085326666666,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:51",
      "speed": 14.754832,
      "direction": 58.37077,
      "lat": 24.451959666666642,
      "lng": 54.62095133333332,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:51",
      "speed": 14.304761,
      "direction": 58.37077,
      "lat": 24.45202006666664,
      "lng": 54.62104939999999,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:51",
      "speed": 13.85469,
      "direction": 58.37077,
      "lat": 24.45208046666664,
      "lng": 54.621147466666656,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:52",
      "speed": 13.404618,
      "direction": 58.37077,
      "lat": 24.45214086666664,
      "lng": 54.62124553333332,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:52",
      "speed": 12.954548,
      "direction": 58.37077,
      "lat": 24.452201266666638,
      "lng": 54.62134359999999,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:53",
      "speed": 12.504477,
      "direction": 58.37077,
      "lat": 24.452261666666637,
      "lng": 54.621441666666655,
      "dataPoint": false,
      "rpmDivd": 1.398
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:53",
      "speed": 12.504469,
      "direction": 49.33543,
      "lat": 24.452261666666665,
      "lng": 54.62144166666667,
      "dataPoint": true,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:53",
      "speed": 12.499995,
      "direction": 49.33543,
      "lat": 24.452338466666664,
      "lng": 54.62153106666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:53",
      "speed": 12.4955225,
      "direction": 49.33543,
      "lat": 24.452415266666662,
      "lng": 54.62162046666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:54",
      "speed": 12.491049,
      "direction": 49.33543,
      "lat": 24.45249206666666,
      "lng": 54.62170986666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:54",
      "speed": 12.486575,
      "direction": 49.33543,
      "lat": 24.45256886666666,
      "lng": 54.62179926666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:55",
      "speed": 12.482102,
      "direction": 49.33543,
      "lat": 24.45264566666666,
      "lng": 54.62188866666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:55",
      "speed": 12.477629,
      "direction": 49.33543,
      "lat": 24.452722466666657,
      "lng": 54.62197806666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:55",
      "speed": 12.473156,
      "direction": 49.33543,
      "lat": 24.452799266666656,
      "lng": 54.62206746666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:56",
      "speed": 12.468682,
      "direction": 49.33543,
      "lat": 24.452876066666654,
      "lng": 54.62215686666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:56",
      "speed": 12.464209,
      "direction": 49.33543,
      "lat": 24.452952866666653,
      "lng": 54.62224626666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:57",
      "speed": 12.459736,
      "direction": 49.33543,
      "lat": 24.45302966666665,
      "lng": 54.62233566666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:57",
      "speed": 12.455262,
      "direction": 49.33543,
      "lat": 24.45310646666665,
      "lng": 54.62242506666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:57",
      "speed": 12.4507885,
      "direction": 49.33543,
      "lat": 24.45318326666665,
      "lng": 54.62251446666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:58",
      "speed": 12.446316,
      "direction": 49.33543,
      "lat": 24.453260066666648,
      "lng": 54.62260386666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:58",
      "speed": 12.441842,
      "direction": 49.33543,
      "lat": 24.453336866666646,
      "lng": 54.62269326666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:59",
      "speed": 12.437369,
      "direction": 49.33543,
      "lat": 24.453413666666645,
      "lng": 54.62278266666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:59",
      "speed": 12.432896,
      "direction": 49.33543,
      "lat": 24.453490466666643,
      "lng": 54.62287206666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:37:59",
      "speed": 12.428422,
      "direction": 49.33543,
      "lat": 24.453567266666642,
      "lng": 54.62296146666667,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:00",
      "speed": 12.423949,
      "direction": 49.33543,
      "lat": 24.45364406666664,
      "lng": 54.623050866666674,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:00",
      "speed": 12.419476,
      "direction": 49.33543,
      "lat": 24.45372086666664,
      "lng": 54.623140266666674,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:01",
      "speed": 12.415002,
      "direction": 49.33543,
      "lat": 24.453797666666638,
      "lng": 54.623229666666674,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:01",
      "speed": 12.410529,
      "direction": 49.33543,
      "lat": 24.453874466666637,
      "lng": 54.623319066666674,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:01",
      "speed": 12.406055,
      "direction": 49.33543,
      "lat": 24.453951266666635,
      "lng": 54.623408466666675,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:02",
      "speed": 12.401583,
      "direction": 49.33543,
      "lat": 24.454028066666634,
      "lng": 54.623497866666675,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:02",
      "speed": 12.397109,
      "direction": 49.33543,
      "lat": 24.454104866666633,
      "lng": 54.623587266666675,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:03",
      "speed": 12.392635,
      "direction": 49.33543,
      "lat": 24.45418166666663,
      "lng": 54.623676666666675,
      "dataPoint": false,
      "rpmDivd": 0.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:03",
      "speed": 12.392622,
      "direction": 40.445084,
      "lat": 24.454181666666667,
      "lng": 54.62367666666667,
      "dataPoint": true,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:03",
      "speed": 13.003752,
      "direction": 40.445084,
      "lat": 24.454276533333335,
      "lng": 54.62375753333333,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:03",
      "speed": 13.614882,
      "direction": 40.445084,
      "lat": 24.454371400000003,
      "lng": 54.6238384,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:04",
      "speed": 14.226012,
      "direction": 40.445084,
      "lat": 24.45446626666667,
      "lng": 54.62391926666666,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:04",
      "speed": 14.837142,
      "direction": 40.445084,
      "lat": 24.45456113333334,
      "lng": 54.624000133333325,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:05",
      "speed": 15.448272,
      "direction": 40.445084,
      "lat": 24.454656000000007,
      "lng": 54.62408099999999,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:05",
      "speed": 16.059402,
      "direction": 40.445084,
      "lat": 24.454750866666675,
      "lng": 54.624161866666654,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:05",
      "speed": 16.670532,
      "direction": 40.445084,
      "lat": 24.454845733333343,
      "lng": 54.62424273333332,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:06",
      "speed": 17.281662,
      "direction": 40.445084,
      "lat": 24.45494060000001,
      "lng": 54.62432359999998,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:06",
      "speed": 17.892792,
      "direction": 40.445084,
      "lat": 24.45503546666668,
      "lng": 54.62440446666665,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:07",
      "speed": 18.503922,
      "direction": 40.445084,
      "lat": 24.455130333333347,
      "lng": 54.62448533333331,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:07",
      "speed": 19.115051,
      "direction": 40.445084,
      "lat": 24.455225200000015,
      "lng": 54.624566199999975,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:07",
      "speed": 19.726183,
      "direction": 40.445084,
      "lat": 24.455320066666683,
      "lng": 54.62464706666664,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:08",
      "speed": 20.337313,
      "direction": 40.445084,
      "lat": 24.45541493333335,
      "lng": 54.624727933333304,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:08",
      "speed": 20.948442,
      "direction": 40.445084,
      "lat": 24.45550980000002,
      "lng": 54.62480879999997,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:09",
      "speed": 21.559572,
      "direction": 40.445084,
      "lat": 24.455604666666687,
      "lng": 54.62488966666663,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:09",
      "speed": 22.170702,
      "direction": 40.445084,
      "lat": 24.455699533333355,
      "lng": 54.6249705333333,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:09",
      "speed": 22.781832,
      "direction": 40.445084,
      "lat": 24.455794400000023,
      "lng": 54.62505139999996,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:10",
      "speed": 23.392962,
      "direction": 40.445084,
      "lat": 24.45588926666669,
      "lng": 54.625132266666625,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:10",
      "speed": 24.004091,
      "direction": 40.445084,
      "lat": 24.45598413333336,
      "lng": 54.62521313333329,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:11",
      "speed": 24.615223,
      "direction": 40.445084,
      "lat": 24.456079000000027,
      "lng": 54.625293999999954,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:11",
      "speed": 25.226353,
      "direction": 40.445084,
      "lat": 24.456173866666695,
      "lng": 54.62537486666662,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:11",
      "speed": 25.837482,
      "direction": 40.445084,
      "lat": 24.456268733333363,
      "lng": 54.62545573333328,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:12",
      "speed": 26.448612,
      "direction": 40.445084,
      "lat": 24.45636360000003,
      "lng": 54.62553659999995,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:12",
      "speed": 27.059742,
      "direction": 40.445084,
      "lat": 24.4564584666667,
      "lng": 54.62561746666661,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:13",
      "speed": 27.670872,
      "direction": 40.445084,
      "lat": 24.456553333333368,
      "lng": 54.625698333333276,
      "dataPoint": false,
      "rpmDivd": 0.508
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:13",
      "speed": 27.67089,
      "direction": 30.608202,
      "lat": 24.456553333333332,
      "lng": 54.62569833333333,
      "dataPoint": true,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:13",
      "speed": 27.334455,
      "direction": 30.608202,
      "lat": 24.456664333333332,
      "lng": 54.625764,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:13",
      "speed": 26.998022,
      "direction": 30.608202,
      "lat": 24.456775333333333,
      "lng": 54.62582966666666,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:14",
      "speed": 26.661587,
      "direction": 30.608202,
      "lat": 24.456886333333333,
      "lng": 54.625895333333325,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:14",
      "speed": 26.325153,
      "direction": 30.608202,
      "lat": 24.456997333333334,
      "lng": 54.62596099999999,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:15",
      "speed": 25.988718,
      "direction": 30.608202,
      "lat": 24.457108333333334,
      "lng": 54.626026666666654,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:15",
      "speed": 25.652285,
      "direction": 30.608202,
      "lat": 24.457219333333335,
      "lng": 54.62609233333332,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:15",
      "speed": 25.31585,
      "direction": 30.608202,
      "lat": 24.457330333333335,
      "lng": 54.62615799999998,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:16",
      "speed": 24.979416,
      "direction": 30.608202,
      "lat": 24.457441333333335,
      "lng": 54.62622366666665,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:16",
      "speed": 24.64298,
      "direction": 30.608202,
      "lat": 24.457552333333336,
      "lng": 54.62628933333331,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:17",
      "speed": 24.306547,
      "direction": 30.608202,
      "lat": 24.457663333333336,
      "lng": 54.626354999999975,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:17",
      "speed": 23.970112,
      "direction": 30.608202,
      "lat": 24.457774333333337,
      "lng": 54.62642066666664,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:17",
      "speed": 23.633677,
      "direction": 30.608202,
      "lat": 24.457885333333337,
      "lng": 54.626486333333304,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:18",
      "speed": 23.297243,
      "direction": 30.608202,
      "lat": 24.457996333333337,
      "lng": 54.62655199999997,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:18",
      "speed": 22.960808,
      "direction": 30.608202,
      "lat": 24.458107333333338,
      "lng": 54.62661766666663,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:19",
      "speed": 22.624374,
      "direction": 30.608202,
      "lat": 24.45821833333334,
      "lng": 54.6266833333333,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:19",
      "speed": 22.28794,
      "direction": 30.608202,
      "lat": 24.45832933333334,
      "lng": 54.62674899999996,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:19",
      "speed": 21.951506,
      "direction": 30.608202,
      "lat": 24.45844033333334,
      "lng": 54.626814666666625,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:20",
      "speed": 21.61507,
      "direction": 30.608202,
      "lat": 24.45855133333334,
      "lng": 54.62688033333329,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:20",
      "speed": 21.278637,
      "direction": 30.608202,
      "lat": 24.45866233333334,
      "lng": 54.626945999999954,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:21",
      "speed": 20.942202,
      "direction": 30.608202,
      "lat": 24.45877333333334,
      "lng": 54.62701166666662,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:21",
      "speed": 20.605768,
      "direction": 30.608202,
      "lat": 24.45888433333334,
      "lng": 54.62707733333328,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:21",
      "speed": 20.269333,
      "direction": 30.608202,
      "lat": 24.45899533333334,
      "lng": 54.62714299999995,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:22",
      "speed": 19.9329,
      "direction": 30.608202,
      "lat": 24.45910633333334,
      "lng": 54.62720866666661,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:22",
      "speed": 19.596464,
      "direction": 30.608202,
      "lat": 24.459217333333342,
      "lng": 54.627274333333276,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:23",
      "speed": 19.260029,
      "direction": 30.608202,
      "lat": 24.459328333333342,
      "lng": 54.62733999999994,
      "dataPoint": false,
      "rpmDivd": 0.69
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:23",
      "speed": 19.260014,
      "direction": 21.210865,
      "lat": 24.459328333333332,
      "lng": 54.62734,
      "dataPoint": true,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:23",
      "speed": 19.118639,
      "direction": 21.210865,
      "lat": 24.459449266666667,
      "lng": 54.62738693333333,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:23",
      "speed": 18.977264,
      "direction": 21.210865,
      "lat": 24.4595702,
      "lng": 54.62743386666666,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:24",
      "speed": 18.83589,
      "direction": 21.210865,
      "lat": 24.459691133333337,
      "lng": 54.62748079999999,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:24",
      "speed": 18.694515,
      "direction": 21.210865,
      "lat": 24.45981206666667,
      "lng": 54.62752773333332,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:25",
      "speed": 18.55314,
      "direction": 21.210865,
      "lat": 24.459933000000007,
      "lng": 54.627574666666646,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:25",
      "speed": 18.411766,
      "direction": 21.210865,
      "lat": 24.46005393333334,
      "lng": 54.627621599999976,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:25",
      "speed": 18.270393,
      "direction": 21.210865,
      "lat": 24.460174866666677,
      "lng": 54.627668533333306,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:26",
      "speed": 18.129019,
      "direction": 21.210865,
      "lat": 24.46029580000001,
      "lng": 54.627715466666636,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:26",
      "speed": 17.987644,
      "direction": 21.210865,
      "lat": 24.460416733333346,
      "lng": 54.627762399999966,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:27",
      "speed": 17.84627,
      "direction": 21.210865,
      "lat": 24.46053766666668,
      "lng": 54.627809333333296,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:27",
      "speed": 17.704895,
      "direction": 21.210865,
      "lat": 24.460658600000016,
      "lng": 54.627856266666626,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:27",
      "speed": 17.56352,
      "direction": 21.210865,
      "lat": 24.46077953333335,
      "lng": 54.627903199999956,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:28",
      "speed": 17.422146,
      "direction": 21.210865,
      "lat": 24.460900466666686,
      "lng": 54.627950133333286,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:28",
      "speed": 17.280771,
      "direction": 21.210865,
      "lat": 24.46102140000002,
      "lng": 54.627997066666616,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:29",
      "speed": 17.139397,
      "direction": 21.210865,
      "lat": 24.461142333333356,
      "lng": 54.628043999999946,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:29",
      "speed": 16.998022,
      "direction": 21.210865,
      "lat": 24.46126326666669,
      "lng": 54.628090933333276,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:29",
      "speed": 16.856647,
      "direction": 21.210865,
      "lat": 24.461384200000026,
      "lng": 54.628137866666606,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:30",
      "speed": 16.715273,
      "direction": 21.210865,
      "lat": 24.46150513333336,
      "lng": 54.628184799999936,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:30",
      "speed": 16.573898,
      "direction": 21.210865,
      "lat": 24.461626066666696,
      "lng": 54.628231733333266,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:31",
      "speed": 16.432526,
      "direction": 21.210865,
      "lat": 24.46174700000003,
      "lng": 54.628278666666596,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:31",
      "speed": 16.291151,
      "direction": 21.210865,
      "lat": 24.461867933333366,
      "lng": 54.628325599999926,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:31",
      "speed": 16.149776,
      "direction": 21.210865,
      "lat": 24.4619888666667,
      "lng": 54.628372533333255,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:32",
      "speed": 16.008402,
      "direction": 21.210865,
      "lat": 24.462109800000036,
      "lng": 54.628419466666585,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:32",
      "speed": 15.867027,
      "direction": 21.210865,
      "lat": 24.46223073333337,
      "lng": 54.628466399999915,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:33",
      "speed": 15.725653,
      "direction": 21.210865,
      "lat": 24.462351666666706,
      "lng": 54.628513333333245,
      "dataPoint": false,
      "rpmDivd": 0.414
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:33",
      "speed": 15.725656,
      "direction": 20.796898,
      "lat": 24.462351666666667,
      "lng": 54.62851333333333,
      "dataPoint": true,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:33",
      "speed": 15.433959,
      "direction": 20.796898,
      "lat": 24.462473133333333,
      "lng": 54.628559466666665,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:33",
      "speed": 15.142262,
      "direction": 20.796898,
      "lat": 24.4625946,
      "lng": 54.6286056,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:34",
      "speed": 14.850566,
      "direction": 20.796898,
      "lat": 24.462716066666665,
      "lng": 54.628651733333335,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:34",
      "speed": 14.558869,
      "direction": 20.796898,
      "lat": 24.46283753333333,
      "lng": 54.62869786666667,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:35",
      "speed": 14.267172,
      "direction": 20.796898,
      "lat": 24.462958999999998,
      "lng": 54.628744000000005,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:35",
      "speed": 13.975475,
      "direction": 20.796898,
      "lat": 24.463080466666664,
      "lng": 54.62879013333334,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:35",
      "speed": 13.683779,
      "direction": 20.796898,
      "lat": 24.46320193333333,
      "lng": 54.628836266666674,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:36",
      "speed": 13.392082,
      "direction": 20.796898,
      "lat": 24.463323399999997,
      "lng": 54.62888240000001,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:36",
      "speed": 13.100386,
      "direction": 20.796898,
      "lat": 24.463444866666663,
      "lng": 54.628928533333344,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:37",
      "speed": 12.808689,
      "direction": 20.796898,
      "lat": 24.46356633333333,
      "lng": 54.62897466666668,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:37",
      "speed": 12.516993,
      "direction": 20.796898,
      "lat": 24.463687799999995,
      "lng": 54.62902080000001,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:37",
      "speed": 12.225296,
      "direction": 20.796898,
      "lat": 24.46380926666666,
      "lng": 54.62906693333335,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:38",
      "speed": 11.9335985,
      "direction": 20.796898,
      "lat": 24.463930733333328,
      "lng": 54.62911306666668,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:38",
      "speed": 11.641902,
      "direction": 20.796898,
      "lat": 24.464052199999994,
      "lng": 54.62915920000002,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:39",
      "speed": 11.350205,
      "direction": 20.796898,
      "lat": 24.46417366666666,
      "lng": 54.62920533333335,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:39",
      "speed": 11.058509,
      "direction": 20.796898,
      "lat": 24.464295133333327,
      "lng": 54.62925146666669,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:39",
      "speed": 10.766812,
      "direction": 20.796898,
      "lat": 24.464416599999993,
      "lng": 54.62929760000002,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:40",
      "speed": 10.475116,
      "direction": 20.796898,
      "lat": 24.46453806666666,
      "lng": 54.62934373333336,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:40",
      "speed": 10.183419,
      "direction": 20.796898,
      "lat": 24.464659533333325,
      "lng": 54.62938986666669,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:41",
      "speed": 9.891723,
      "direction": 20.796898,
      "lat": 24.46478099999999,
      "lng": 54.62943600000003,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:41",
      "speed": 9.600025,
      "direction": 20.796898,
      "lat": 24.464902466666658,
      "lng": 54.62948213333336,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:41",
      "speed": 9.308329,
      "direction": 20.796898,
      "lat": 24.465023933333324,
      "lng": 54.629528266666696,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:42",
      "speed": 9.016632,
      "direction": 20.796898,
      "lat": 24.46514539999999,
      "lng": 54.62957440000003,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:42",
      "speed": 8.724936,
      "direction": 20.796898,
      "lat": 24.465266866666656,
      "lng": 54.629620533333366,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:43",
      "speed": 8.433239,
      "direction": 20.796898,
      "lat": 24.465388333333323,
      "lng": 54.6296666666667,
      "dataPoint": false,
      "rpmDivd": 0.339
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:43",
      "speed": 8.433247,
      "direction": 29.476994,
      "lat": 24.465388333333333,
      "lng": 54.629666666666665,
      "dataPoint": true,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:43",
      "speed": 9.255544,
      "direction": 29.476994,
      "lat": 24.465502266666668,
      "lng": 54.629731066666665,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:43",
      "speed": 10.07784,
      "direction": 29.476994,
      "lat": 24.465616200000003,
      "lng": 54.629795466666664,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:44",
      "speed": 10.900137,
      "direction": 29.476994,
      "lat": 24.465730133333338,
      "lng": 54.62985986666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:44",
      "speed": 11.722433,
      "direction": 29.476994,
      "lat": 24.465844066666673,
      "lng": 54.62992426666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:45",
      "speed": 12.54473,
      "direction": 29.476994,
      "lat": 24.465958000000008,
      "lng": 54.62998866666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:45",
      "speed": 13.367026,
      "direction": 29.476994,
      "lat": 24.466071933333343,
      "lng": 54.63005306666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:45",
      "speed": 14.189323,
      "direction": 29.476994,
      "lat": 24.466185866666677,
      "lng": 54.63011746666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:46",
      "speed": 15.01162,
      "direction": 29.476994,
      "lat": 24.466299800000012,
      "lng": 54.63018186666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:46",
      "speed": 15.833917,
      "direction": 29.476994,
      "lat": 24.466413733333347,
      "lng": 54.63024626666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:47",
      "speed": 16.656214,
      "direction": 29.476994,
      "lat": 24.466527666666682,
      "lng": 54.63031066666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:47",
      "speed": 17.47851,
      "direction": 29.476994,
      "lat": 24.466641600000017,
      "lng": 54.63037506666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:47",
      "speed": 18.300806,
      "direction": 29.476994,
      "lat": 24.46675553333335,
      "lng": 54.63043946666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:48",
      "speed": 19.123104,
      "direction": 29.476994,
      "lat": 24.466869466666687,
      "lng": 54.63050386666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:48",
      "speed": 19.9454,
      "direction": 29.476994,
      "lat": 24.46698340000002,
      "lng": 54.63056826666666,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:49",
      "speed": 20.767696,
      "direction": 29.476994,
      "lat": 24.467097333333356,
      "lng": 54.630632666666656,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:49",
      "speed": 21.589993,
      "direction": 29.476994,
      "lat": 24.46721126666669,
      "lng": 54.630697066666656,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:49",
      "speed": 22.41229,
      "direction": 29.476994,
      "lat": 24.467325200000026,
      "lng": 54.630761466666655,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:50",
      "speed": 23.234587,
      "direction": 29.476994,
      "lat": 24.46743913333336,
      "lng": 54.630825866666655,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:50",
      "speed": 24.056883,
      "direction": 29.476994,
      "lat": 24.467553066666696,
      "lng": 54.630890266666654,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:51",
      "speed": 24.879179,
      "direction": 29.476994,
      "lat": 24.46766700000003,
      "lng": 54.63095466666665,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:51",
      "speed": 25.701477,
      "direction": 29.476994,
      "lat": 24.467780933333366,
      "lng": 54.63101906666665,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:51",
      "speed": 26.523773,
      "direction": 29.476994,
      "lat": 24.4678948666667,
      "lng": 54.63108346666665,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:52",
      "speed": 27.34607,
      "direction": 29.476994,
      "lat": 24.468008800000035,
      "lng": 54.63114786666665,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:52",
      "speed": 28.168367,
      "direction": 29.476994,
      "lat": 24.46812273333337,
      "lng": 54.63121226666665,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:53",
      "speed": 28.990664,
      "direction": 29.476994,
      "lat": 24.468236666666705,
      "lng": 54.63127666666665,
      "dataPoint": false,
      "rpmDivd": 1.067
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:53",
      "speed": 28.990683,
      "direction": 37.30174,
      "lat": 24.468236666666666,
      "lng": 54.631276666666665,
      "dataPoint": true,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:53",
      "speed": 28.800991,
      "direction": 37.30174,
      "lat": 24.4683408,
      "lng": 54.631356,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:53",
      "speed": 28.611298,
      "direction": 37.30174,
      "lat": 24.468444933333334,
      "lng": 54.63143533333333,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:54",
      "speed": 28.421606,
      "direction": 37.30174,
      "lat": 24.468549066666668,
      "lng": 54.63151466666666,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:54",
      "speed": 28.231915,
      "direction": 37.30174,
      "lat": 24.468653200000002,
      "lng": 54.63159399999999,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:55",
      "speed": 28.042221,
      "direction": 37.30174,
      "lat": 24.468757333333336,
      "lng": 54.631673333333325,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:55",
      "speed": 27.85253,
      "direction": 37.30174,
      "lat": 24.46886146666667,
      "lng": 54.63175266666666,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:55",
      "speed": 27.662838,
      "direction": 37.30174,
      "lat": 24.468965600000004,
      "lng": 54.63183199999999,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:56",
      "speed": 27.473145,
      "direction": 37.30174,
      "lat": 24.46906973333334,
      "lng": 54.63191133333332,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:56",
      "speed": 27.283453,
      "direction": 37.30174,
      "lat": 24.469173866666672,
      "lng": 54.63199066666665,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:57",
      "speed": 27.09376,
      "direction": 37.30174,
      "lat": 24.469278000000006,
      "lng": 54.632069999999985,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:57",
      "speed": 26.904068,
      "direction": 37.30174,
      "lat": 24.46938213333334,
      "lng": 54.63214933333332,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:57",
      "speed": 26.714376,
      "direction": 37.30174,
      "lat": 24.469486266666674,
      "lng": 54.63222866666665,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:58",
      "speed": 26.524683,
      "direction": 37.30174,
      "lat": 24.46959040000001,
      "lng": 54.63230799999998,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:58",
      "speed": 26.334991,
      "direction": 37.30174,
      "lat": 24.469694533333342,
      "lng": 54.63238733333331,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:59",
      "speed": 26.1453,
      "direction": 37.30174,
      "lat": 24.469798666666676,
      "lng": 54.632466666666645,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:59",
      "speed": 25.955606,
      "direction": 37.30174,
      "lat": 24.46990280000001,
      "lng": 54.63254599999998,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:38:59",
      "speed": 25.765915,
      "direction": 37.30174,
      "lat": 24.470006933333345,
      "lng": 54.63262533333331,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:00",
      "speed": 25.576223,
      "direction": 37.30174,
      "lat": 24.47011106666668,
      "lng": 54.63270466666664,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:00",
      "speed": 25.38653,
      "direction": 37.30174,
      "lat": 24.470215200000013,
      "lng": 54.63278399999997,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:01",
      "speed": 25.196838,
      "direction": 37.30174,
      "lat": 24.470319333333347,
      "lng": 54.632863333333304,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:01",
      "speed": 25.007147,
      "direction": 37.30174,
      "lat": 24.47042346666668,
      "lng": 54.63294266666664,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:01",
      "speed": 24.817453,
      "direction": 37.30174,
      "lat": 24.470527600000015,
      "lng": 54.63302199999997,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:02",
      "speed": 24.627762,
      "direction": 37.30174,
      "lat": 24.47063173333335,
      "lng": 54.6331013333333,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:02",
      "speed": 24.438068,
      "direction": 37.30174,
      "lat": 24.470735866666683,
      "lng": 54.63318066666663,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:03",
      "speed": 24.248377,
      "direction": 37.30174,
      "lat": 24.470840000000017,
      "lng": 54.633259999999964,
      "dataPoint": false,
      "rpmDivd": 0.499
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:03",
      "speed": 24.248379,
      "direction": 37.409424,
      "lat": 24.47084,
      "lng": 54.63326,
      "dataPoint": true,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:03",
      "speed": 24.011265,
      "direction": 37.409424,
      "lat": 24.4709446,
      "lng": 54.63334,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:03",
      "speed": 23.774149,
      "direction": 37.409424,
      "lat": 24.4710492,
      "lng": 54.633419999999994,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:04",
      "speed": 23.537035,
      "direction": 37.409424,
      "lat": 24.4711538,
      "lng": 54.63349999999999,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:04",
      "speed": 23.299921,
      "direction": 37.409424,
      "lat": 24.4712584,
      "lng": 54.63357999999999,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:05",
      "speed": 23.062807,
      "direction": 37.409424,
      "lat": 24.471363,
      "lng": 54.633659999999985,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:05",
      "speed": 22.825691,
      "direction": 37.409424,
      "lat": 24.4714676,
      "lng": 54.63373999999998,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:05",
      "speed": 22.588577,
      "direction": 37.409424,
      "lat": 24.4715722,
      "lng": 54.63381999999998,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:06",
      "speed": 22.351463,
      "direction": 37.409424,
      "lat": 24.4716768,
      "lng": 54.633899999999976,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:06",
      "speed": 22.11435,
      "direction": 37.409424,
      "lat": 24.4717814,
      "lng": 54.63397999999997,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:07",
      "speed": 21.877234,
      "direction": 37.409424,
      "lat": 24.471886,
      "lng": 54.63405999999997,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:07",
      "speed": 21.64012,
      "direction": 37.409424,
      "lat": 24.4719906,
      "lng": 54.63413999999997,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:07",
      "speed": 21.403006,
      "direction": 37.409424,
      "lat": 24.472095200000002,
      "lng": 54.634219999999964,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:08",
      "speed": 21.165892,
      "direction": 37.409424,
      "lat": 24.472199800000002,
      "lng": 54.63429999999996,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:08",
      "speed": 20.928776,
      "direction": 37.409424,
      "lat": 24.472304400000002,
      "lng": 54.63437999999996,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:09",
      "speed": 20.691662,
      "direction": 37.409424,
      "lat": 24.472409000000003,
      "lng": 54.634459999999955,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:09",
      "speed": 20.454548,
      "direction": 37.409424,
      "lat": 24.472513600000003,
      "lng": 54.63453999999995,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:09",
      "speed": 20.217434,
      "direction": 37.409424,
      "lat": 24.472618200000003,
      "lng": 54.63461999999995,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:10",
      "speed": 19.980318,
      "direction": 37.409424,
      "lat": 24.472722800000003,
      "lng": 54.634699999999945,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:10",
      "speed": 19.743204,
      "direction": 37.409424,
      "lat": 24.472827400000003,
      "lng": 54.63477999999994,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:11",
      "speed": 19.50609,
      "direction": 37.409424,
      "lat": 24.472932000000004,
      "lng": 54.63485999999994,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:11",
      "speed": 19.268976,
      "direction": 37.409424,
      "lat": 24.473036600000004,
      "lng": 54.634939999999936,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:11",
      "speed": 19.03186,
      "direction": 37.409424,
      "lat": 24.473141200000004,
      "lng": 54.63501999999993,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:12",
      "speed": 18.794746,
      "direction": 37.409424,
      "lat": 24.473245800000004,
      "lng": 54.63509999999993,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:12",
      "speed": 18.557632,
      "direction": 37.409424,
      "lat": 24.473350400000005,
      "lng": 54.63517999999993,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:13",
      "speed": 18.320518,
      "direction": 37.409424,
      "lat": 24.473455000000005,
      "lng": 54.635259999999924,
      "dataPoint": false,
      "rpmDivd": 1.282
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:13",
      "speed": 18.320501,
      "direction": 37.5187,
      "lat": 24.473455,
      "lng": 54.63526,
      "dataPoint": true,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:13",
      "speed": 18.275763,
      "direction": 37.5187,
      "lat": 24.47355866666667,
      "lng": 54.6353396,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:13",
      "speed": 18.231024,
      "direction": 37.5187,
      "lat": 24.473662333333337,
      "lng": 54.6354192,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:14",
      "speed": 18.186285,
      "direction": 37.5187,
      "lat": 24.473766000000005,
      "lng": 54.6354988,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:14",
      "speed": 18.141546,
      "direction": 37.5187,
      "lat": 24.473869666666673,
      "lng": 54.6355784,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:15",
      "speed": 18.096807,
      "direction": 37.5187,
      "lat": 24.47397333333334,
      "lng": 54.635658,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:15",
      "speed": 18.052069,
      "direction": 37.5187,
      "lat": 24.47407700000001,
      "lng": 54.6357376,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:15",
      "speed": 18.00733,
      "direction": 37.5187,
      "lat": 24.474180666666676,
      "lng": 54.6358172,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:16",
      "speed": 17.96259,
      "direction": 37.5187,
      "lat": 24.474284333333344,
      "lng": 54.6358968,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:16",
      "speed": 17.91785,
      "direction": 37.5187,
      "lat": 24.474388000000012,
      "lng": 54.6359764,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:17",
      "speed": 17.873112,
      "direction": 37.5187,
      "lat": 24.47449166666668,
      "lng": 54.636055999999996,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:17",
      "speed": 17.828373,
      "direction": 37.5187,
      "lat": 24.474595333333347,
      "lng": 54.636135599999996,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:17",
      "speed": 17.783634,
      "direction": 37.5187,
      "lat": 24.474699000000015,
      "lng": 54.636215199999995,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:18",
      "speed": 17.738895,
      "direction": 37.5187,
      "lat": 24.474802666666683,
      "lng": 54.636294799999995,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:18",
      "speed": 17.694157,
      "direction": 37.5187,
      "lat": 24.47490633333335,
      "lng": 54.636374399999994,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:19",
      "speed": 17.649418,
      "direction": 37.5187,
      "lat": 24.47501000000002,
      "lng": 54.63645399999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:19",
      "speed": 17.60468,
      "direction": 37.5187,
      "lat": 24.475113666666687,
      "lng": 54.63653359999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:19",
      "speed": 17.55994,
      "direction": 37.5187,
      "lat": 24.475217333333354,
      "lng": 54.63661319999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:20",
      "speed": 17.515202,
      "direction": 37.5187,
      "lat": 24.475321000000022,
      "lng": 54.63669279999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:20",
      "speed": 17.470463,
      "direction": 37.5187,
      "lat": 24.47542466666669,
      "lng": 54.63677239999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:21",
      "speed": 17.425724,
      "direction": 37.5187,
      "lat": 24.475528333333358,
      "lng": 54.63685199999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:21",
      "speed": 17.380985,
      "direction": 37.5187,
      "lat": 24.475632000000026,
      "lng": 54.63693159999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:21",
      "speed": 17.336246,
      "direction": 37.5187,
      "lat": 24.475735666666694,
      "lng": 54.63701119999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:22",
      "speed": 17.291508,
      "direction": 37.5187,
      "lat": 24.47583933333336,
      "lng": 54.63709079999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:22",
      "speed": 17.246767,
      "direction": 37.5187,
      "lat": 24.47594300000003,
      "lng": 54.63717039999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:23",
      "speed": 17.202028,
      "direction": 37.5187,
      "lat": 24.476046666666697,
      "lng": 54.63724999999999,
      "dataPoint": false,
      "rpmDivd": 0.558
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:23",
      "speed": 17.202032,
      "direction": 37.633797,
      "lat": 24.476046666666665,
      "lng": 54.63725,
      "dataPoint": true,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:23",
      "speed": 17.097343,
      "direction": 37.633797,
      "lat": 24.476145666666664,
      "lng": 54.637326333333334,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:23",
      "speed": 16.992655,
      "direction": 37.633797,
      "lat": 24.476244666666663,
      "lng": 54.63740266666667,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:24",
      "speed": 16.887966,
      "direction": 37.633797,
      "lat": 24.47634366666666,
      "lng": 54.637479,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:24",
      "speed": 16.783276,
      "direction": 37.633797,
      "lat": 24.47644266666666,
      "lng": 54.63755533333333,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:25",
      "speed": 16.678587,
      "direction": 37.633797,
      "lat": 24.47654166666666,
      "lng": 54.637631666666664,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:25",
      "speed": 16.573898,
      "direction": 37.633797,
      "lat": 24.476640666666658,
      "lng": 54.637707999999996,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:25",
      "speed": 16.46921,
      "direction": 37.633797,
      "lat": 24.476739666666656,
      "lng": 54.63778433333333,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:26",
      "speed": 16.364521,
      "direction": 37.633797,
      "lat": 24.476838666666655,
      "lng": 54.63786066666666,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:26",
      "speed": 16.259832,
      "direction": 37.633797,
      "lat": 24.476937666666654,
      "lng": 54.637936999999994,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:27",
      "speed": 16.155144,
      "direction": 37.633797,
      "lat": 24.477036666666653,
      "lng": 54.638013333333326,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:27",
      "speed": 16.050455,
      "direction": 37.633797,
      "lat": 24.47713566666665,
      "lng": 54.63808966666666,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:27",
      "speed": 15.9457655,
      "direction": 37.633797,
      "lat": 24.47723466666665,
      "lng": 54.63816599999999,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:28",
      "speed": 15.841077,
      "direction": 37.633797,
      "lat": 24.47733366666665,
      "lng": 54.638242333333324,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:28",
      "speed": 15.736387,
      "direction": 37.633797,
      "lat": 24.477432666666648,
      "lng": 54.638318666666656,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:29",
      "speed": 15.631699,
      "direction": 37.633797,
      "lat": 24.477531666666646,
      "lng": 54.63839499999999,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:29",
      "speed": 15.52701,
      "direction": 37.633797,
      "lat": 24.477630666666645,
      "lng": 54.63847133333332,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:29",
      "speed": 15.422321,
      "direction": 37.633797,
      "lat": 24.477729666666644,
      "lng": 54.63854766666665,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:30",
      "speed": 15.317632,
      "direction": 37.633797,
      "lat": 24.477828666666642,
      "lng": 54.638623999999986,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:30",
      "speed": 15.212943,
      "direction": 37.633797,
      "lat": 24.47792766666664,
      "lng": 54.63870033333332,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:31",
      "speed": 15.108254,
      "direction": 37.633797,
      "lat": 24.47802666666664,
      "lng": 54.63877666666665,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:31",
      "speed": 15.003566,
      "direction": 37.633797,
      "lat": 24.47812566666664,
      "lng": 54.63885299999998,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:31",
      "speed": 14.898876,
      "direction": 37.633797,
      "lat": 24.478224666666637,
      "lng": 54.638929333333316,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:32",
      "speed": 14.794188,
      "direction": 37.633797,
      "lat": 24.478323666666636,
      "lng": 54.63900566666665,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:32",
      "speed": 14.689499,
      "direction": 37.633797,
      "lat": 24.478422666666635,
      "lng": 54.63908199999998,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:33",
      "speed": 14.58481,
      "direction": 37.633797,
      "lat": 24.478521666666634,
      "lng": 54.63915833333331,
      "dataPoint": false,
      "rpmDivd": 0.535
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:33",
      "speed": 14.584818,
      "direction": 37.475403,
      "lat": 24.478521666666666,
      "lng": 54.639158333333334,
      "dataPoint": true,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:33",
      "speed": 14.33965,
      "direction": 37.475403,
      "lat": 24.4786248,
      "lng": 54.6392374,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:33",
      "speed": 14.094482,
      "direction": 37.475403,
      "lat": 24.47872793333333,
      "lng": 54.63931646666666,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:34",
      "speed": 13.849315,
      "direction": 37.475403,
      "lat": 24.478831066666665,
      "lng": 54.63939553333333,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:34",
      "speed": 13.604147,
      "direction": 37.475403,
      "lat": 24.478934199999998,
      "lng": 54.63947459999999,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:35",
      "speed": 13.358979,
      "direction": 37.475403,
      "lat": 24.47903733333333,
      "lng": 54.63955366666666,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:35",
      "speed": 13.1138115,
      "direction": 37.475403,
      "lat": 24.479140466666664,
      "lng": 54.63963273333332,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:35",
      "speed": 12.868644,
      "direction": 37.475403,
      "lat": 24.479243599999997,
      "lng": 54.639711799999986,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:36",
      "speed": 12.623476,
      "direction": 37.475403,
      "lat": 24.47934673333333,
      "lng": 54.63979086666665,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:36",
      "speed": 12.378308,
      "direction": 37.475403,
      "lat": 24.479449866666663,
      "lng": 54.639869933333316,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:37",
      "speed": 12.133141,
      "direction": 37.475403,
      "lat": 24.479552999999996,
      "lng": 54.63994899999998,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:37",
      "speed": 11.887972,
      "direction": 37.475403,
      "lat": 24.47965613333333,
      "lng": 54.640028066666645,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:37",
      "speed": 11.642804,
      "direction": 37.475403,
      "lat": 24.47975926666666,
      "lng": 54.64010713333331,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:38",
      "speed": 11.397636,
      "direction": 37.475403,
      "lat": 24.479862399999995,
      "lng": 54.640186199999974,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:38",
      "speed": 11.152469,
      "direction": 37.475403,
      "lat": 24.479965533333328,
      "lng": 54.64026526666664,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:39",
      "speed": 10.907301,
      "direction": 37.475403,
      "lat": 24.48006866666666,
      "lng": 54.6403443333333,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:39",
      "speed": 10.662133,
      "direction": 37.475403,
      "lat": 24.480171799999994,
      "lng": 54.64042339999997,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:39",
      "speed": 10.4169655,
      "direction": 37.475403,
      "lat": 24.480274933333327,
      "lng": 54.64050246666663,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:40",
      "speed": 10.171798,
      "direction": 37.475403,
      "lat": 24.48037806666666,
      "lng": 54.6405815333333,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:40",
      "speed": 9.92663,
      "direction": 37.475403,
      "lat": 24.480481199999993,
      "lng": 54.64066059999996,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:41",
      "speed": 9.681462,
      "direction": 37.475403,
      "lat": 24.480584333333326,
      "lng": 54.640739666666626,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:41",
      "speed": 9.436295,
      "direction": 37.475403,
      "lat": 24.48068746666666,
      "lng": 54.64081873333329,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:41",
      "speed": 9.191127,
      "direction": 37.475403,
      "lat": 24.48079059999999,
      "lng": 54.640897799999955,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:42",
      "speed": 8.945959,
      "direction": 37.475403,
      "lat": 24.480893733333325,
      "lng": 54.64097686666662,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:42",
      "speed": 8.700791,
      "direction": 37.475403,
      "lat": 24.480996866666658,
      "lng": 54.641055933333284,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:43",
      "speed": 8.455624,
      "direction": 37.475403,
      "lat": 24.48109999999999,
      "lng": 54.64113499999995,
      "dataPoint": false,
      "rpmDivd": 0.517
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:43",
      "speed": 8.455616,
      "direction": 37.516617,
      "lat": 24.4811,
      "lng": 54.641135,
      "dataPoint": true,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:43",
      "speed": 8.9978485,
      "direction": 37.516617,
      "lat": 24.48120306666667,
      "lng": 54.641214133333335,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:43",
      "speed": 9.54008,
      "direction": 37.516617,
      "lat": 24.481306133333337,
      "lng": 54.64129326666667,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:44",
      "speed": 10.082313,
      "direction": 37.516617,
      "lat": 24.481409200000005,
      "lng": 54.64137240000001,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:44",
      "speed": 10.624545,
      "direction": 37.516617,
      "lat": 24.481512266666673,
      "lng": 54.641451533333345,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:45",
      "speed": 11.166777,
      "direction": 37.516617,
      "lat": 24.48161533333334,
      "lng": 54.64153066666668,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:45",
      "speed": 11.709009,
      "direction": 37.516617,
      "lat": 24.48171840000001,
      "lng": 54.64160980000002,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:45",
      "speed": 12.251242,
      "direction": 37.516617,
      "lat": 24.481821466666677,
      "lng": 54.641688933333356,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:46",
      "speed": 12.793474,
      "direction": 37.516617,
      "lat": 24.481924533333345,
      "lng": 54.64176806666669,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:46",
      "speed": 13.335706,
      "direction": 37.516617,
      "lat": 24.482027600000013,
      "lng": 54.64184720000003,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:47",
      "speed": 13.877938,
      "direction": 37.516617,
      "lat": 24.48213066666668,
      "lng": 54.641926333333366,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:47",
      "speed": 14.420171,
      "direction": 37.516617,
      "lat": 24.48223373333335,
      "lng": 54.6420054666667,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:47",
      "speed": 14.962402,
      "direction": 37.516617,
      "lat": 24.482336800000017,
      "lng": 54.64208460000004,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:48",
      "speed": 15.504635,
      "direction": 37.516617,
      "lat": 24.482439866666684,
      "lng": 54.642163733333376,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:48",
      "speed": 16.046867,
      "direction": 37.516617,
      "lat": 24.482542933333352,
      "lng": 54.64224286666671,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:49",
      "speed": 16.5891,
      "direction": 37.516617,
      "lat": 24.48264600000002,
      "lng": 54.64232200000005,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:49",
      "speed": 17.131332,
      "direction": 37.516617,
      "lat": 24.48274906666669,
      "lng": 54.64240113333339,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:49",
      "speed": 17.673563,
      "direction": 37.516617,
      "lat": 24.482852133333356,
      "lng": 54.64248026666672,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:50",
      "speed": 18.215796,
      "direction": 37.516617,
      "lat": 24.482955200000024,
      "lng": 54.64255940000006,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:50",
      "speed": 18.758028,
      "direction": 37.516617,
      "lat": 24.483058266666692,
      "lng": 54.6426385333334,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:51",
      "speed": 19.30026,
      "direction": 37.516617,
      "lat": 24.48316133333336,
      "lng": 54.642717666666734,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:51",
      "speed": 19.842493,
      "direction": 37.516617,
      "lat": 24.483264400000028,
      "lng": 54.64279680000007,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:51",
      "speed": 20.384726,
      "direction": 37.516617,
      "lat": 24.483367466666696,
      "lng": 54.64287593333341,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:52",
      "speed": 20.926956,
      "direction": 37.516617,
      "lat": 24.483470533333364,
      "lng": 54.642955066666744,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:52",
      "speed": 21.469189,
      "direction": 37.516617,
      "lat": 24.48357360000003,
      "lng": 54.64303420000008,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:53",
      "speed": 22.011421,
      "direction": 37.516617,
      "lat": 24.4836766666667,
      "lng": 54.64311333333342,
      "dataPoint": false,
      "rpmDivd": 0.945
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:53",
      "speed": 22.011446,
      "direction": 37.630035,
      "lat": 24.483676666666668,
      "lng": 54.64311333333333,
      "dataPoint": true,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:53",
      "speed": 21.7797,
      "direction": 37.630035,
      "lat": 24.483781733333334,
      "lng": 54.643194333333334,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:53",
      "speed": 21.547953,
      "direction": 37.630035,
      "lat": 24.4838868,
      "lng": 54.643275333333335,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:54",
      "speed": 21.316206,
      "direction": 37.630035,
      "lat": 24.483991866666667,
      "lng": 54.64335633333334,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:54",
      "speed": 21.08446,
      "direction": 37.630035,
      "lat": 24.484096933333333,
      "lng": 54.64343733333334,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:55",
      "speed": 20.852713,
      "direction": 37.630035,
      "lat": 24.484202,
      "lng": 54.64351833333334,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:55",
      "speed": 20.620966,
      "direction": 37.630035,
      "lat": 24.484307066666666,
      "lng": 54.64359933333334,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:55",
      "speed": 20.38922,
      "direction": 37.630035,
      "lat": 24.484412133333333,
      "lng": 54.64368033333334,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:56",
      "speed": 20.157473,
      "direction": 37.630035,
      "lat": 24.4845172,
      "lng": 54.643761333333345,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:56",
      "speed": 19.925726,
      "direction": 37.630035,
      "lat": 24.484622266666666,
      "lng": 54.643842333333346,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:57",
      "speed": 19.69398,
      "direction": 37.630035,
      "lat": 24.484727333333332,
      "lng": 54.64392333333335,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:57",
      "speed": 19.462233,
      "direction": 37.630035,
      "lat": 24.4848324,
      "lng": 54.64400433333335,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:57",
      "speed": 19.230486,
      "direction": 37.630035,
      "lat": 24.484937466666665,
      "lng": 54.64408533333335,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:58",
      "speed": 18.99874,
      "direction": 37.630035,
      "lat": 24.48504253333333,
      "lng": 54.64416633333335,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:58",
      "speed": 18.766993,
      "direction": 37.630035,
      "lat": 24.485147599999998,
      "lng": 54.644247333333354,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:59",
      "speed": 18.535246,
      "direction": 37.630035,
      "lat": 24.485252666666664,
      "lng": 54.644328333333355,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:59",
      "speed": 18.3035,
      "direction": 37.630035,
      "lat": 24.48535773333333,
      "lng": 54.64440933333336,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:39:59",
      "speed": 18.071753,
      "direction": 37.630035,
      "lat": 24.485462799999997,
      "lng": 54.64449033333336,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:00",
      "speed": 17.840006,
      "direction": 37.630035,
      "lat": 24.485567866666663,
      "lng": 54.64457133333336,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:00",
      "speed": 17.60826,
      "direction": 37.630035,
      "lat": 24.48567293333333,
      "lng": 54.64465233333336,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:01",
      "speed": 17.376513,
      "direction": 37.630035,
      "lat": 24.485777999999996,
      "lng": 54.64473333333336,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:01",
      "speed": 17.144766,
      "direction": 37.630035,
      "lat": 24.485883066666663,
      "lng": 54.644814333333365,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:01",
      "speed": 16.91302,
      "direction": 37.630035,
      "lat": 24.48598813333333,
      "lng": 54.644895333333366,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:02",
      "speed": 16.681273,
      "direction": 37.630035,
      "lat": 24.486093199999996,
      "lng": 54.64497633333337,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:02",
      "speed": 16.449526,
      "direction": 37.630035,
      "lat": 24.486198266666662,
      "lng": 54.64505733333337,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:03",
      "speed": 16.21778,
      "direction": 37.630035,
      "lat": 24.48630333333333,
      "lng": 54.64513833333337,
      "dataPoint": false,
      "rpmDivd": 1.454
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:03",
      "speed": 16.217781,
      "direction": 37.563263,
      "lat": 24.486303333333332,
      "lng": 54.645138333333335,
      "dataPoint": true,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:03",
      "speed": 16.244625,
      "direction": 37.563263,
      "lat": 24.486407266666664,
      "lng": 54.64521826666667,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:03",
      "speed": 16.271467,
      "direction": 37.563263,
      "lat": 24.486511199999995,
      "lng": 54.6452982,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:04",
      "speed": 16.298311,
      "direction": 37.563263,
      "lat": 24.486615133333327,
      "lng": 54.64537813333333,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:04",
      "speed": 16.325155,
      "direction": 37.563263,
      "lat": 24.48671906666666,
      "lng": 54.64545806666666,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:05",
      "speed": 16.351997,
      "direction": 37.563263,
      "lat": 24.48682299999999,
      "lng": 54.645537999999995,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:05",
      "speed": 16.378841,
      "direction": 37.563263,
      "lat": 24.486926933333322,
      "lng": 54.64561793333333,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:05",
      "speed": 16.405684,
      "direction": 37.563263,
      "lat": 24.487030866666654,
      "lng": 54.64569786666666,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:06",
      "speed": 16.432528,
      "direction": 37.563263,
      "lat": 24.487134799999986,
      "lng": 54.64577779999999,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:06",
      "speed": 16.459372,
      "direction": 37.563263,
      "lat": 24.487238733333317,
      "lng": 54.64585773333332,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:07",
      "speed": 16.486214,
      "direction": 37.563263,
      "lat": 24.48734266666665,
      "lng": 54.645937666666654,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:07",
      "speed": 16.513058,
      "direction": 37.563263,
      "lat": 24.48744659999998,
      "lng": 54.646017599999986,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:07",
      "speed": 16.539902,
      "direction": 37.563263,
      "lat": 24.487550533333312,
      "lng": 54.64609753333332,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:08",
      "speed": 16.566744,
      "direction": 37.563263,
      "lat": 24.487654466666644,
      "lng": 54.64617746666665,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:08",
      "speed": 16.593588,
      "direction": 37.563263,
      "lat": 24.487758399999976,
      "lng": 54.64625739999998,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:09",
      "speed": 16.620432,
      "direction": 37.563263,
      "lat": 24.487862333333307,
      "lng": 54.646337333333314,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:09",
      "speed": 16.647274,
      "direction": 37.563263,
      "lat": 24.48796626666664,
      "lng": 54.646417266666646,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:09",
      "speed": 16.674118,
      "direction": 37.563263,
      "lat": 24.48807019999997,
      "lng": 54.64649719999998,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:10",
      "speed": 16.70096,
      "direction": 37.563263,
      "lat": 24.488174133333303,
      "lng": 54.64657713333331,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:10",
      "speed": 16.727804,
      "direction": 37.563263,
      "lat": 24.488278066666634,
      "lng": 54.64665706666664,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:11",
      "speed": 16.754648,
      "direction": 37.563263,
      "lat": 24.488381999999966,
      "lng": 54.64673699999997,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:11",
      "speed": 16.78149,
      "direction": 37.563263,
      "lat": 24.488485933333298,
      "lng": 54.646816933333305,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:11",
      "speed": 16.808334,
      "direction": 37.563263,
      "lat": 24.48858986666663,
      "lng": 54.64689686666664,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:12",
      "speed": 16.835178,
      "direction": 37.563263,
      "lat": 24.48869379999996,
      "lng": 54.64697679999997,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:12",
      "speed": 16.86202,
      "direction": 37.563263,
      "lat": 24.488797733333293,
      "lng": 54.6470567333333,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:13",
      "speed": 16.888865,
      "direction": 37.563263,
      "lat": 24.488901666666624,
      "lng": 54.64713666666663,
      "dataPoint": false,
      "rpmDivd": 0.895
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:13",
      "speed": 16.888863,
      "direction": 37.486958,
      "lat": 24.488901666666667,
      "lng": 54.64713666666667,
      "dataPoint": true,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:13",
      "speed": 16.311733,
      "direction": 37.486958,
      "lat": 24.4890058,
      "lng": 54.647216533333335,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:13",
      "speed": 15.734605,
      "direction": 37.486958,
      "lat": 24.489109933333335,
      "lng": 54.6472964,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:14",
      "speed": 15.157475,
      "direction": 37.486958,
      "lat": 24.48921406666667,
      "lng": 54.64737626666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:14",
      "speed": 14.580347,
      "direction": 37.486958,
      "lat": 24.489318200000003,
      "lng": 54.647456133333336,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:15",
      "speed": 14.003218,
      "direction": 37.486958,
      "lat": 24.489422333333337,
      "lng": 54.647536,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:15",
      "speed": 13.426089,
      "direction": 37.486958,
      "lat": 24.48952646666667,
      "lng": 54.64761586666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:15",
      "speed": 12.84896,
      "direction": 37.486958,
      "lat": 24.489630600000005,
      "lng": 54.647695733333336,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:16",
      "speed": 12.2718315,
      "direction": 37.486958,
      "lat": 24.48973473333334,
      "lng": 54.6477756,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:16",
      "speed": 11.694702,
      "direction": 37.486958,
      "lat": 24.489838866666673,
      "lng": 54.64785546666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:17",
      "speed": 11.117574,
      "direction": 37.486958,
      "lat": 24.489943000000007,
      "lng": 54.647935333333336,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:17",
      "speed": 10.540444,
      "direction": 37.486958,
      "lat": 24.49004713333334,
      "lng": 54.6480152,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:17",
      "speed": 9.963316,
      "direction": 37.486958,
      "lat": 24.490151266666675,
      "lng": 54.64809506666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:18",
      "speed": 9.386187,
      "direction": 37.486958,
      "lat": 24.49025540000001,
      "lng": 54.64817493333334,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:18",
      "speed": 8.809058,
      "direction": 37.486958,
      "lat": 24.490359533333343,
      "lng": 54.648254800000004,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:19",
      "speed": 8.231929,
      "direction": 37.486958,
      "lat": 24.490463666666678,
      "lng": 54.64833466666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:19",
      "speed": 7.6548004,
      "direction": 37.486958,
      "lat": 24.49056780000001,
      "lng": 54.64841453333334,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:19",
      "speed": 7.0776715,
      "direction": 37.486958,
      "lat": 24.490671933333346,
      "lng": 54.648494400000004,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:20",
      "speed": 6.5005426,
      "direction": 37.486958,
      "lat": 24.49077606666668,
      "lng": 54.64857426666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:20",
      "speed": 5.9234138,
      "direction": 37.486958,
      "lat": 24.490880200000014,
      "lng": 54.64865413333334,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:21",
      "speed": 5.346285,
      "direction": 37.486958,
      "lat": 24.490984333333348,
      "lng": 54.648734000000005,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:21",
      "speed": 4.769156,
      "direction": 37.486958,
      "lat": 24.49108846666668,
      "lng": 54.64881386666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:21",
      "speed": 4.192027,
      "direction": 37.486958,
      "lat": 24.491192600000016,
      "lng": 54.64889373333334,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:22",
      "speed": 3.6148982,
      "direction": 37.486958,
      "lat": 24.49129673333335,
      "lng": 54.648973600000005,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:22",
      "speed": 3.0377693,
      "direction": 37.486958,
      "lat": 24.491400866666684,
      "lng": 54.64905346666667,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:23",
      "speed": 2.4606404,
      "direction": 37.486958,
      "lat": 24.491505000000018,
      "lng": 54.64913333333334,
      "dataPoint": false,
      "rpmDivd": 0.283
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:23",
      "speed": 2.460629,
      "direction": 37.78832,
      "lat": 24.491505,
      "lng": 54.64913333333333,
      "dataPoint": true,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:23",
      "speed": 3.3276646,
      "direction": 37.78832,
      "lat": 24.491608866666667,
      "lng": 54.64921386666666,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:23",
      "speed": 4.1947002,
      "direction": 37.78832,
      "lat": 24.491712733333333,
      "lng": 54.649294399999995,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:24",
      "speed": 5.0617356,
      "direction": 37.78832,
      "lat": 24.4918166,
      "lng": 54.64937493333333,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:24",
      "speed": 5.928771,
      "direction": 37.78832,
      "lat": 24.491920466666667,
      "lng": 54.64945546666666,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:25",
      "speed": 6.795807,
      "direction": 37.78832,
      "lat": 24.492024333333333,
      "lng": 54.64953599999999,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:25",
      "speed": 7.6628423,
      "direction": 37.78832,
      "lat": 24.4921282,
      "lng": 54.64961653333332,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:25",
      "speed": 8.529878,
      "direction": 37.78832,
      "lat": 24.492232066666666,
      "lng": 54.649697066666654,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:26",
      "speed": 9.396914,
      "direction": 37.78832,
      "lat": 24.492335933333333,
      "lng": 54.649777599999986,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:26",
      "speed": 10.263949,
      "direction": 37.78832,
      "lat": 24.4924398,
      "lng": 54.64985813333332,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:27",
      "speed": 11.130984,
      "direction": 37.78832,
      "lat": 24.492543666666666,
      "lng": 54.64993866666665,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:27",
      "speed": 11.99802,
      "direction": 37.78832,
      "lat": 24.492647533333333,
      "lng": 54.65001919999998,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:27",
      "speed": 12.865056,
      "direction": 37.78832,
      "lat": 24.4927514,
      "lng": 54.65009973333331,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:28",
      "speed": 13.732091,
      "direction": 37.78832,
      "lat": 24.492855266666666,
      "lng": 54.650180266666645,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:28",
      "speed": 14.599127,
      "direction": 37.78832,
      "lat": 24.492959133333333,
      "lng": 54.65026079999998,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:29",
      "speed": 15.466163,
      "direction": 37.78832,
      "lat": 24.493063,
      "lng": 54.65034133333331,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:29",
      "speed": 16.333199,
      "direction": 37.78832,
      "lat": 24.493166866666666,
      "lng": 54.65042186666664,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:29",
      "speed": 17.200233,
      "direction": 37.78832,
      "lat": 24.493270733333333,
      "lng": 54.65050239999997,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:30",
      "speed": 18.067268,
      "direction": 37.78832,
      "lat": 24.4933746,
      "lng": 54.650582933333304,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:30",
      "speed": 18.934305,
      "direction": 37.78832,
      "lat": 24.493478466666666,
      "lng": 54.650663466666636,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:31",
      "speed": 19.80134,
      "direction": 37.78832,
      "lat": 24.493582333333332,
      "lng": 54.65074399999997,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:31",
      "speed": 20.668375,
      "direction": 37.78832,
      "lat": 24.4936862,
      "lng": 54.6508245333333,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:31",
      "speed": 21.535412,
      "direction": 37.78832,
      "lat": 24.493790066666666,
      "lng": 54.65090506666663,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:32",
      "speed": 22.402447,
      "direction": 37.78832,
      "lat": 24.493893933333332,
      "lng": 54.65098559999996,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:32",
      "speed": 23.269482,
      "direction": 37.78832,
      "lat": 24.4939978,
      "lng": 54.651066133333295,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:33",
      "speed": 24.136518,
      "direction": 37.78832,
      "lat": 24.494101666666666,
      "lng": 54.65114666666663,
      "dataPoint": false,
      "rpmDivd": 0.205
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:33",
      "speed": 24.136532,
      "direction": 37.37878,
      "lat": 24.494101666666666,
      "lng": 54.65114666666667,
      "dataPoint": true,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:33",
      "speed": 24.043476,
      "direction": 37.37878,
      "lat": 24.494203066666664,
      "lng": 54.651224133333336,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:33",
      "speed": 23.950418,
      "direction": 37.37878,
      "lat": 24.494304466666662,
      "lng": 54.651301600000004,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:34",
      "speed": 23.857363,
      "direction": 37.37878,
      "lat": 24.49440586666666,
      "lng": 54.65137906666667,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:34",
      "speed": 23.764305,
      "direction": 37.37878,
      "lat": 24.49450726666666,
      "lng": 54.65145653333334,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:35",
      "speed": 23.67125,
      "direction": 37.37878,
      "lat": 24.494608666666657,
      "lng": 54.651534000000005,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:35",
      "speed": 23.578192,
      "direction": 37.37878,
      "lat": 24.494710066666656,
      "lng": 54.65161146666667,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:35",
      "speed": 23.485136,
      "direction": 37.37878,
      "lat": 24.494811466666654,
      "lng": 54.65168893333334,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:36",
      "speed": 23.392078,
      "direction": 37.37878,
      "lat": 24.494912866666652,
      "lng": 54.65176640000001,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:36",
      "speed": 23.299023,
      "direction": 37.37878,
      "lat": 24.49501426666665,
      "lng": 54.651843866666674,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:37",
      "speed": 23.205965,
      "direction": 37.37878,
      "lat": 24.49511566666665,
      "lng": 54.65192133333334,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:37",
      "speed": 23.11291,
      "direction": 37.37878,
      "lat": 24.495217066666648,
      "lng": 54.65199880000001,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:37",
      "speed": 23.019852,
      "direction": 37.37878,
      "lat": 24.495318466666646,
      "lng": 54.652076266666676,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:38",
      "speed": 22.926796,
      "direction": 37.37878,
      "lat": 24.495419866666644,
      "lng": 54.65215373333334,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:38",
      "speed": 22.833738,
      "direction": 37.37878,
      "lat": 24.495521266666643,
      "lng": 54.65223120000001,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:39",
      "speed": 22.740683,
      "direction": 37.37878,
      "lat": 24.49562266666664,
      "lng": 54.65230866666668,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:39",
      "speed": 22.647625,
      "direction": 37.37878,
      "lat": 24.49572406666664,
      "lng": 54.652386133333344,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:39",
      "speed": 22.55457,
      "direction": 37.37878,
      "lat": 24.495825466666638,
      "lng": 54.65246360000001,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:40",
      "speed": 22.461512,
      "direction": 37.37878,
      "lat": 24.495926866666636,
      "lng": 54.65254106666668,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:40",
      "speed": 22.368456,
      "direction": 37.37878,
      "lat": 24.496028266666634,
      "lng": 54.652618533333346,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:41",
      "speed": 22.275398,
      "direction": 37.37878,
      "lat": 24.496129666666633,
      "lng": 54.65269600000001,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:41",
      "speed": 22.182343,
      "direction": 37.37878,
      "lat": 24.49623106666663,
      "lng": 54.65277346666668,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:41",
      "speed": 22.089285,
      "direction": 37.37878,
      "lat": 24.49633246666663,
      "lng": 54.65285093333335,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:42",
      "speed": 21.99623,
      "direction": 37.37878,
      "lat": 24.496433866666628,
      "lng": 54.652928400000015,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:42",
      "speed": 21.903172,
      "direction": 37.37878,
      "lat": 24.496535266666626,
      "lng": 54.65300586666668,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:43",
      "speed": 21.810116,
      "direction": 37.37878,
      "lat": 24.496636666666625,
      "lng": 54.65308333333335,
      "dataPoint": false,
      "rpmDivd": 0.226
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:43",
      "speed": 21.81012,
      "direction": 37.360905,
      "lat": 24.496636666666667,
      "lng": 54.653083333333335,
      "dataPoint": true,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:43",
      "speed": 21.496948,
      "direction": 37.360905,
      "lat": 24.4967356,
      "lng": 54.653158866666665,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:43",
      "speed": 21.183777,
      "direction": 37.360905,
      "lat": 24.496834533333335,
      "lng": 54.653234399999995,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:44",
      "speed": 20.870605,
      "direction": 37.360905,
      "lat": 24.49693346666667,
      "lng": 54.653309933333325,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:44",
      "speed": 20.557436,
      "direction": 37.360905,
      "lat": 24.497032400000002,
      "lng": 54.653385466666656,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:45",
      "speed": 20.244265,
      "direction": 37.360905,
      "lat": 24.497131333333336,
      "lng": 54.653460999999986,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:45",
      "speed": 19.931093,
      "direction": 37.360905,
      "lat": 24.49723026666667,
      "lng": 54.653536533333316,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:45",
      "speed": 19.617922,
      "direction": 37.360905,
      "lat": 24.497329200000003,
      "lng": 54.653612066666646,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:46",
      "speed": 19.30475,
      "direction": 37.360905,
      "lat": 24.497428133333337,
      "lng": 54.65368759999998,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:46",
      "speed": 18.99158,
      "direction": 37.360905,
      "lat": 24.49752706666667,
      "lng": 54.65376313333331,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:47",
      "speed": 18.678408,
      "direction": 37.360905,
      "lat": 24.497626000000004,
      "lng": 54.65383866666664,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:47",
      "speed": 18.365238,
      "direction": 37.360905,
      "lat": 24.497724933333338,
      "lng": 54.65391419999997,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:47",
      "speed": 18.052067,
      "direction": 37.360905,
      "lat": 24.49782386666667,
      "lng": 54.6539897333333,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:48",
      "speed": 17.738895,
      "direction": 37.360905,
      "lat": 24.497922800000005,
      "lng": 54.65406526666663,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:48",
      "speed": 17.425724,
      "direction": 37.360905,
      "lat": 24.49802173333334,
      "lng": 54.65414079999996,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:49",
      "speed": 17.112553,
      "direction": 37.360905,
      "lat": 24.498120666666672,
      "lng": 54.65421633333329,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:49",
      "speed": 16.799381,
      "direction": 37.360905,
      "lat": 24.498219600000006,
      "lng": 54.65429186666662,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:49",
      "speed": 16.48621,
      "direction": 37.360905,
      "lat": 24.49831853333334,
      "lng": 54.65436739999995,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:50",
      "speed": 16.17304,
      "direction": 37.360905,
      "lat": 24.498417466666673,
      "lng": 54.65444293333328,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:50",
      "speed": 15.859868,
      "direction": 37.360905,
      "lat": 24.498516400000007,
      "lng": 54.65451846666661,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:51",
      "speed": 15.546698,
      "direction": 37.360905,
      "lat": 24.49861533333334,
      "lng": 54.65459399999994,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:51",
      "speed": 15.233526,
      "direction": 37.360905,
      "lat": 24.498714266666674,
      "lng": 54.65466953333327,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:51",
      "speed": 14.920355,
      "direction": 37.360905,
      "lat": 24.498813200000008,
      "lng": 54.6547450666666,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:52",
      "speed": 14.607184,
      "direction": 37.360905,
      "lat": 24.49891213333334,
      "lng": 54.65482059999993,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:52",
      "speed": 14.294013,
      "direction": 37.360905,
      "lat": 24.499011066666675,
      "lng": 54.65489613333326,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:53",
      "speed": 13.980842,
      "direction": 37.360905,
      "lat": 24.49911000000001,
      "lng": 54.65497166666659,
      "dataPoint": false,
      "rpmDivd": 0.927
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:53",
      "speed": 13.980845,
      "direction": 37.418167,
      "lat": 24.49911,
      "lng": 54.65497166666667,
      "dataPoint": true,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:53",
      "speed": 14.153537,
      "direction": 37.418167,
      "lat": 24.4992096,
      "lng": 54.65504786666667,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:53",
      "speed": 14.326228,
      "direction": 37.418167,
      "lat": 24.4993092,
      "lng": 54.65512406666667,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:54",
      "speed": 14.498919,
      "direction": 37.418167,
      "lat": 24.499408799999998,
      "lng": 54.655200266666675,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:54",
      "speed": 14.67161,
      "direction": 37.418167,
      "lat": 24.499508399999996,
      "lng": 54.65527646666668,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:55",
      "speed": 14.844301,
      "direction": 37.418167,
      "lat": 24.499607999999995,
      "lng": 54.65535266666668,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:55",
      "speed": 15.016993,
      "direction": 37.418167,
      "lat": 24.499707599999994,
      "lng": 54.65542886666668,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:55",
      "speed": 15.189683,
      "direction": 37.418167,
      "lat": 24.499807199999992,
      "lng": 54.655505066666684,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:56",
      "speed": 15.362374,
      "direction": 37.418167,
      "lat": 24.49990679999999,
      "lng": 54.65558126666669,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:56",
      "speed": 15.535066,
      "direction": 37.418167,
      "lat": 24.50000639999999,
      "lng": 54.65565746666669,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:57",
      "speed": 15.707757,
      "direction": 37.418167,
      "lat": 24.500105999999988,
      "lng": 54.65573366666669,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:57",
      "speed": 15.880447,
      "direction": 37.418167,
      "lat": 24.500205599999987,
      "lng": 54.655809866666694,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:57",
      "speed": 16.053139,
      "direction": 37.418167,
      "lat": 24.500305199999985,
      "lng": 54.655886066666696,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:58",
      "speed": 16.22583,
      "direction": 37.418167,
      "lat": 24.500404799999984,
      "lng": 54.6559622666667,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:58",
      "speed": 16.398521,
      "direction": 37.418167,
      "lat": 24.500504399999983,
      "lng": 54.6560384666667,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:59",
      "speed": 16.571213,
      "direction": 37.418167,
      "lat": 24.50060399999998,
      "lng": 54.6561146666667,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:59",
      "speed": 16.743904,
      "direction": 37.418167,
      "lat": 24.50070359999998,
      "lng": 54.656190866666705,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:40:59",
      "speed": 16.916594,
      "direction": 37.418167,
      "lat": 24.50080319999998,
      "lng": 54.65626706666671,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:00",
      "speed": 17.089285,
      "direction": 37.418167,
      "lat": 24.500902799999977,
      "lng": 54.65634326666671,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:00",
      "speed": 17.261976,
      "direction": 37.418167,
      "lat": 24.501002399999976,
      "lng": 54.65641946666671,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:01",
      "speed": 17.434668,
      "direction": 37.418167,
      "lat": 24.501101999999975,
      "lng": 54.656495666666714,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:01",
      "speed": 17.607359,
      "direction": 37.418167,
      "lat": 24.501201599999973,
      "lng": 54.65657186666672,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:01",
      "speed": 17.78005,
      "direction": 37.418167,
      "lat": 24.501301199999972,
      "lng": 54.65664806666672,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:02",
      "speed": 17.952742,
      "direction": 37.418167,
      "lat": 24.50140079999997,
      "lng": 54.65672426666672,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:02",
      "speed": 18.125433,
      "direction": 37.418167,
      "lat": 24.50150039999997,
      "lng": 54.65680046666672,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:03",
      "speed": 18.298122,
      "direction": 37.418167,
      "lat": 24.501599999999968,
      "lng": 54.656876666666726,
      "dataPoint": false,
      "rpmDivd": 0.601
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:03",
      "speed": 18.298132,
      "direction": 37.589027,
      "lat": 24.5016,
      "lng": 54.65687666666667,
      "dataPoint": true,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:03",
      "speed": 18.853786,
      "direction": 37.589027,
      "lat": 24.501699333333335,
      "lng": 54.65695313333334,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:03",
      "speed": 19.409441,
      "direction": 37.589027,
      "lat": 24.50179866666667,
      "lng": 54.65702960000001,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:04",
      "speed": 19.965096,
      "direction": 37.589027,
      "lat": 24.501898000000004,
      "lng": 54.65710606666668,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:04",
      "speed": 20.52075,
      "direction": 37.589027,
      "lat": 24.50199733333334,
      "lng": 54.65718253333335,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:05",
      "speed": 21.076405,
      "direction": 37.589027,
      "lat": 24.502096666666674,
      "lng": 54.65725900000002,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:05",
      "speed": 21.63206,
      "direction": 37.589027,
      "lat": 24.50219600000001,
      "lng": 54.65733546666669,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:05",
      "speed": 22.187714,
      "direction": 37.589027,
      "lat": 24.502295333333343,
      "lng": 54.65741193333336,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:06",
      "speed": 22.743368,
      "direction": 37.589027,
      "lat": 24.502394666666678,
      "lng": 54.65748840000003,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:06",
      "speed": 23.299023,
      "direction": 37.589027,
      "lat": 24.502494000000013,
      "lng": 54.657564866666696,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:07",
      "speed": 23.854677,
      "direction": 37.589027,
      "lat": 24.502593333333348,
      "lng": 54.657641333333366,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:07",
      "speed": 24.410332,
      "direction": 37.589027,
      "lat": 24.502692666666682,
      "lng": 54.657717800000036,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:07",
      "speed": 24.965984,
      "direction": 37.589027,
      "lat": 24.502792000000017,
      "lng": 54.657794266666706,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:08",
      "speed": 25.521639,
      "direction": 37.589027,
      "lat": 24.502891333333352,
      "lng": 54.657870733333375,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:08",
      "speed": 26.077293,
      "direction": 37.589027,
      "lat": 24.502990666666687,
      "lng": 54.657947200000045,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:09",
      "speed": 26.632948,
      "direction": 37.589027,
      "lat": 24.50309000000002,
      "lng": 54.658023666666715,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:09",
      "speed": 27.188602,
      "direction": 37.589027,
      "lat": 24.503189333333356,
      "lng": 54.658100133333384,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:09",
      "speed": 27.744257,
      "direction": 37.589027,
      "lat": 24.50328866666669,
      "lng": 54.658176600000054,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:10",
      "speed": 28.299911,
      "direction": 37.589027,
      "lat": 24.503388000000026,
      "lng": 54.658253066666724,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:10",
      "speed": 28.855566,
      "direction": 37.589027,
      "lat": 24.50348733333336,
      "lng": 54.658329533333394,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:11",
      "speed": 29.41122,
      "direction": 37.589027,
      "lat": 24.503586666666695,
      "lng": 54.65840600000006,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:11",
      "speed": 29.966875,
      "direction": 37.589027,
      "lat": 24.50368600000003,
      "lng": 54.65848246666673,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:11",
      "speed": 30.52253,
      "direction": 37.589027,
      "lat": 24.503785333333365,
      "lng": 54.6585589333334,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:12",
      "speed": 31.078184,
      "direction": 37.589027,
      "lat": 24.5038846666667,
      "lng": 54.65863540000007,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:12",
      "speed": 31.633839,
      "direction": 37.589027,
      "lat": 24.503984000000035,
      "lng": 54.65871186666674,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:13",
      "speed": 32.18949,
      "direction": 37.589027,
      "lat": 24.50408333333337,
      "lng": 54.65878833333341,
      "dataPoint": false,
      "rpmDivd": 0.502
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:13",
      "speed": 32.1895,
      "direction": 37.472073,
      "lat": 24.504083333333334,
      "lng": 54.658788333333334,
      "dataPoint": true,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:13",
      "speed": 31.689322,
      "direction": 37.472073,
      "lat": 24.504181,
      "lng": 54.6588632,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:13",
      "speed": 31.189144,
      "direction": 37.472073,
      "lat": 24.504278666666664,
      "lng": 54.658938066666664,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:14",
      "speed": 30.688965,
      "direction": 37.472073,
      "lat": 24.50437633333333,
      "lng": 54.65901293333333,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:14",
      "speed": 30.188787,
      "direction": 37.472073,
      "lat": 24.504473999999995,
      "lng": 54.659087799999995,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:15",
      "speed": 29.68861,
      "direction": 37.472073,
      "lat": 24.50457166666666,
      "lng": 54.65916266666666,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:15",
      "speed": 29.188433,
      "direction": 37.472073,
      "lat": 24.504669333333325,
      "lng": 54.659237533333325,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:15",
      "speed": 28.688255,
      "direction": 37.472073,
      "lat": 24.50476699999999,
      "lng": 54.65931239999999,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:16",
      "speed": 28.188076,
      "direction": 37.472073,
      "lat": 24.504864666666656,
      "lng": 54.659387266666656,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:16",
      "speed": 27.687899,
      "direction": 37.472073,
      "lat": 24.50496233333332,
      "lng": 54.65946213333332,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:17",
      "speed": 27.187721,
      "direction": 37.472073,
      "lat": 24.505059999999986,
      "lng": 54.659536999999986,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:17",
      "speed": 26.687544,
      "direction": 37.472073,
      "lat": 24.50515766666665,
      "lng": 54.65961186666665,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:17",
      "speed": 26.187366,
      "direction": 37.472073,
      "lat": 24.505255333333317,
      "lng": 54.65968673333332,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:18",
      "speed": 25.687187,
      "direction": 37.472073,
      "lat": 24.50535299999998,
      "lng": 54.65976159999998,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:18",
      "speed": 25.18701,
      "direction": 37.472073,
      "lat": 24.505450666666647,
      "lng": 54.65983646666665,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:19",
      "speed": 24.686832,
      "direction": 37.472073,
      "lat": 24.505548333333312,
      "lng": 54.65991133333331,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:19",
      "speed": 24.186655,
      "direction": 37.472073,
      "lat": 24.505645999999977,
      "lng": 54.65998619999998,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:19",
      "speed": 23.686476,
      "direction": 37.472073,
      "lat": 24.505743666666643,
      "lng": 54.66006106666664,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:20",
      "speed": 23.186298,
      "direction": 37.472073,
      "lat": 24.505841333333308,
      "lng": 54.66013593333331,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:20",
      "speed": 22.686121,
      "direction": 37.472073,
      "lat": 24.505938999999973,
      "lng": 54.66021079999997,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:21",
      "speed": 22.185944,
      "direction": 37.472073,
      "lat": 24.50603666666664,
      "lng": 54.66028566666664,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:21",
      "speed": 21.685766,
      "direction": 37.472073,
      "lat": 24.506134333333303,
      "lng": 54.660360533333304,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:21",
      "speed": 21.185587,
      "direction": 37.472073,
      "lat": 24.50623199999997,
      "lng": 54.66043539999997,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:22",
      "speed": 20.68541,
      "direction": 37.472073,
      "lat": 24.506329666666634,
      "lng": 54.660510266666634,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:22",
      "speed": 20.185232,
      "direction": 37.472073,
      "lat": 24.5064273333333,
      "lng": 54.6605851333333,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:23",
      "speed": 19.685055,
      "direction": 37.472073,
      "lat": 24.506524999999964,
      "lng": 54.660659999999964,
      "dataPoint": false,
      "rpmDivd": 1.439
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:23",
      "speed": 19.685032,
      "direction": 37.59701,
      "lat": 24.506525,
      "lng": 54.66066,
      "dataPoint": true,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:23",
      "speed": 19.4166,
      "direction": 37.59701,
      "lat": 24.5066224,
      "lng": 54.660735,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:23",
      "speed": 19.148167,
      "direction": 37.59701,
      "lat": 24.506719800000003,
      "lng": 54.660810000000005,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:24",
      "speed": 18.879736,
      "direction": 37.59701,
      "lat": 24.506817200000004,
      "lng": 54.66088500000001,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:24",
      "speed": 18.611303,
      "direction": 37.59701,
      "lat": 24.506914600000005,
      "lng": 54.66096000000001,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:25",
      "speed": 18.34287,
      "direction": 37.59701,
      "lat": 24.507012000000007,
      "lng": 54.66103500000001,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:25",
      "speed": 18.074438,
      "direction": 37.59701,
      "lat": 24.507109400000008,
      "lng": 54.661110000000015,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:25",
      "speed": 17.806005,
      "direction": 37.59701,
      "lat": 24.50720680000001,
      "lng": 54.66118500000002,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:26",
      "speed": 17.537575,
      "direction": 37.59701,
      "lat": 24.50730420000001,
      "lng": 54.66126000000002,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:26",
      "speed": 17.269142,
      "direction": 37.59701,
      "lat": 24.507401600000012,
      "lng": 54.66133500000002,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:27",
      "speed": 17.00071,
      "direction": 37.59701,
      "lat": 24.507499000000013,
      "lng": 54.661410000000025,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:27",
      "speed": 16.732277,
      "direction": 37.59701,
      "lat": 24.507596400000015,
      "lng": 54.66148500000003,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:27",
      "speed": 16.463844,
      "direction": 37.59701,
      "lat": 24.507693800000016,
      "lng": 54.66156000000003,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:28",
      "speed": 16.195414,
      "direction": 37.59701,
      "lat": 24.507791200000018,
      "lng": 54.66163500000003,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:28",
      "speed": 15.926981,
      "direction": 37.59701,
      "lat": 24.50788860000002,
      "lng": 54.661710000000035,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:29",
      "speed": 15.658548,
      "direction": 37.59701,
      "lat": 24.50798600000002,
      "lng": 54.66178500000004,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:29",
      "speed": 15.390117,
      "direction": 37.59701,
      "lat": 24.50808340000002,
      "lng": 54.66186000000004,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:29",
      "speed": 15.121684,
      "direction": 37.59701,
      "lat": 24.508180800000023,
      "lng": 54.66193500000004,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:30",
      "speed": 14.853251,
      "direction": 37.59701,
      "lat": 24.508278200000024,
      "lng": 54.662010000000045,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:30",
      "speed": 14.58482,
      "direction": 37.59701,
      "lat": 24.508375600000026,
      "lng": 54.66208500000005,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:31",
      "speed": 14.316387,
      "direction": 37.59701,
      "lat": 24.508473000000027,
      "lng": 54.66216000000005,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:31",
      "speed": 14.0479555,
      "direction": 37.59701,
      "lat": 24.50857040000003,
      "lng": 54.66223500000005,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:31",
      "speed": 13.779523,
      "direction": 37.59701,
      "lat": 24.50866780000003,
      "lng": 54.662310000000055,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:32",
      "speed": 13.511091,
      "direction": 37.59701,
      "lat": 24.50876520000003,
      "lng": 54.66238500000006,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:32",
      "speed": 13.242659,
      "direction": 37.59701,
      "lat": 24.508862600000032,
      "lng": 54.66246000000006,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:33",
      "speed": 12.974226,
      "direction": 37.59701,
      "lat": 24.508960000000034,
      "lng": 54.66253500000006,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:33",
      "speed": 12.974225,
      "direction": 38.21153,
      "lat": 24.50896,
      "lng": 54.662535,
      "dataPoint": true,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:33",
      "speed": 13.107546,
      "direction": 38.21153,
      "lat": 24.509059333333333,
      "lng": 54.662613199999996,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:33",
      "speed": 13.240868,
      "direction": 38.21153,
      "lat": 24.509158666666668,
      "lng": 54.66269139999999,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:34",
      "speed": 13.374188,
      "direction": 38.21153,
      "lat": 24.509258000000003,
      "lng": 54.66276959999999,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:34",
      "speed": 13.507509,
      "direction": 38.21153,
      "lat": 24.509357333333337,
      "lng": 54.66284779999999,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:35",
      "speed": 13.640831,
      "direction": 38.21153,
      "lat": 24.509456666666672,
      "lng": 54.662925999999985,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:35",
      "speed": 13.774152,
      "direction": 38.21153,
      "lat": 24.509556000000007,
      "lng": 54.66300419999998,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:35",
      "speed": 13.907473,
      "direction": 38.21153,
      "lat": 24.509655333333342,
      "lng": 54.66308239999998,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:36",
      "speed": 14.040794,
      "direction": 38.21153,
      "lat": 24.509754666666677,
      "lng": 54.663160599999976,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:36",
      "speed": 14.174115,
      "direction": 38.21153,
      "lat": 24.50985400000001,
      "lng": 54.663238799999974,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:37",
      "speed": 14.307436,
      "direction": 38.21153,
      "lat": 24.509953333333346,
      "lng": 54.66331699999997,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:37",
      "speed": 14.440757,
      "direction": 38.21153,
      "lat": 24.51005266666668,
      "lng": 54.66339519999997,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:37",
      "speed": 14.574079,
      "direction": 38.21153,
      "lat": 24.510152000000016,
      "lng": 54.663473399999965,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:38",
      "speed": 14.707399,
      "direction": 38.21153,
      "lat": 24.51025133333335,
      "lng": 54.66355159999996,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:38",
      "speed": 14.84072,
      "direction": 38.21153,
      "lat": 24.510350666666685,
      "lng": 54.66362979999996,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:39",
      "speed": 14.974042,
      "direction": 38.21153,
      "lat": 24.51045000000002,
      "lng": 54.66370799999996,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:39",
      "speed": 15.107363,
      "direction": 38.21153,
      "lat": 24.510549333333355,
      "lng": 54.663786199999954,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:39",
      "speed": 15.240684,
      "direction": 38.21153,
      "lat": 24.51064866666669,
      "lng": 54.66386439999995,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:40",
      "speed": 15.374005,
      "direction": 38.21153,
      "lat": 24.510748000000024,
      "lng": 54.66394259999995,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:40",
      "speed": 15.507326,
      "direction": 38.21153,
      "lat": 24.51084733333336,
      "lng": 54.664020799999946,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:41",
      "speed": 15.640647,
      "direction": 38.21153,
      "lat": 24.510946666666694,
      "lng": 54.66409899999994,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:41",
      "speed": 15.773969,
      "direction": 38.21153,
      "lat": 24.51104600000003,
      "lng": 54.66417719999994,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:41",
      "speed": 15.9072895,
      "direction": 38.21153,
      "lat": 24.511145333333364,
      "lng": 54.66425539999994,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:42",
      "speed": 16.040611,
      "direction": 38.21153,
      "lat": 24.5112446666667,
      "lng": 54.664333599999935,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:42",
      "speed": 16.173931,
      "direction": 38.21153,
      "lat": 24.511344000000033,
      "lng": 54.66441179999993,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:43",
      "speed": 16.307253,
      "direction": 38.21153,
      "lat": 24.511443333333368,
      "lng": 54.66448999999993,
      "dataPoint": false,
      "rpmDivd": 0.063
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:43",
      "speed": 16.307259,
      "direction": 37.507504,
      "lat": 24.511443333333332,
      "lng": 54.66449,
      "dataPoint": true,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:43",
      "speed": 16.367208,
      "direction": 37.507504,
      "lat": 24.511543133333333,
      "lng": 54.6645666,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:43",
      "speed": 16.427158,
      "direction": 37.507504,
      "lat": 24.511642933333334,
      "lng": 54.6646432,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:44",
      "speed": 16.487108,
      "direction": 37.507504,
      "lat": 24.511742733333335,
      "lng": 54.6647198,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:44",
      "speed": 16.547058,
      "direction": 37.507504,
      "lat": 24.511842533333336,
      "lng": 54.6647964,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:45",
      "speed": 16.607008,
      "direction": 37.507504,
      "lat": 24.511942333333337,
      "lng": 54.664873,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:45",
      "speed": 16.666958,
      "direction": 37.507504,
      "lat": 24.51204213333334,
      "lng": 54.6649496,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:45",
      "speed": 16.726908,
      "direction": 37.507504,
      "lat": 24.51214193333334,
      "lng": 54.6650262,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:46",
      "speed": 16.78686,
      "direction": 37.507504,
      "lat": 24.51224173333334,
      "lng": 54.6651028,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:46",
      "speed": 16.84681,
      "direction": 37.507504,
      "lat": 24.51234153333334,
      "lng": 54.6651794,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:47",
      "speed": 16.90676,
      "direction": 37.507504,
      "lat": 24.512441333333342,
      "lng": 54.665256,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:47",
      "speed": 16.96671,
      "direction": 37.507504,
      "lat": 24.512541133333343,
      "lng": 54.6653326,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:47",
      "speed": 17.026659,
      "direction": 37.507504,
      "lat": 24.512640933333344,
      "lng": 54.6654092,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:48",
      "speed": 17.086609,
      "direction": 37.507504,
      "lat": 24.512740733333345,
      "lng": 54.6654858,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:48",
      "speed": 17.146559,
      "direction": 37.507504,
      "lat": 24.512840533333346,
      "lng": 54.6655624,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:49",
      "speed": 17.206509,
      "direction": 37.507504,
      "lat": 24.512940333333347,
      "lng": 54.665639,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:49",
      "speed": 17.266459,
      "direction": 37.507504,
      "lat": 24.513040133333348,
      "lng": 54.6657156,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:49",
      "speed": 17.326408,
      "direction": 37.507504,
      "lat": 24.51313993333335,
      "lng": 54.6657922,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:50",
      "speed": 17.386358,
      "direction": 37.507504,
      "lat": 24.51323973333335,
      "lng": 54.6658688,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:50",
      "speed": 17.446308,
      "direction": 37.507504,
      "lat": 24.51333953333335,
      "lng": 54.6659454,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:51",
      "speed": 17.506258,
      "direction": 37.507504,
      "lat": 24.513439333333352,
      "lng": 54.666022,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:51",
      "speed": 17.566208,
      "direction": 37.507504,
      "lat": 24.513539133333353,
      "lng": 54.6660986,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:51",
      "speed": 17.626158,
      "direction": 37.507504,
      "lat": 24.513638933333354,
      "lng": 54.6661752,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:52",
      "speed": 17.68611,
      "direction": 37.507504,
      "lat": 24.513738733333355,
      "lng": 54.6662518,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:52",
      "speed": 17.74606,
      "direction": 37.507504,
      "lat": 24.513838533333356,
      "lng": 54.6663284,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:53",
      "speed": 17.80601,
      "direction": 37.507504,
      "lat": 24.513938333333357,
      "lng": 54.666405,
      "dataPoint": false,
      "rpmDivd": 0.092
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:53",
      "speed": 17.806005,
      "direction": 37.800316,
      "lat": 24.513938333333332,
      "lng": 54.666405,
      "dataPoint": true,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:53",
      "speed": 17.86506,
      "direction": 37.800316,
      "lat": 24.514037599999998,
      "lng": 54.666481999999995,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:53",
      "speed": 17.924116,
      "direction": 37.800316,
      "lat": 24.514136866666664,
      "lng": 54.66655899999999,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:54",
      "speed": 17.983171,
      "direction": 37.800316,
      "lat": 24.51423613333333,
      "lng": 54.66663599999999,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:54",
      "speed": 18.042227,
      "direction": 37.800316,
      "lat": 24.514335399999997,
      "lng": 54.66671299999999,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:55",
      "speed": 18.101284,
      "direction": 37.800316,
      "lat": 24.514434666666663,
      "lng": 54.666789999999985,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:55",
      "speed": 18.16034,
      "direction": 37.800316,
      "lat": 24.51453393333333,
      "lng": 54.66686699999998,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:55",
      "speed": 18.219395,
      "direction": 37.800316,
      "lat": 24.514633199999995,
      "lng": 54.66694399999998,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:56",
      "speed": 18.27845,
      "direction": 37.800316,
      "lat": 24.51473246666666,
      "lng": 54.66702099999998,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:56",
      "speed": 18.337505,
      "direction": 37.800316,
      "lat": 24.514831733333327,
      "lng": 54.667097999999974,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:57",
      "speed": 18.39656,
      "direction": 37.800316,
      "lat": 24.514930999999994,
      "lng": 54.66717499999997,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:57",
      "speed": 18.455616,
      "direction": 37.800316,
      "lat": 24.51503026666666,
      "lng": 54.66725199999997,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:57",
      "speed": 18.514671,
      "direction": 37.800316,
      "lat": 24.515129533333326,
      "lng": 54.66732899999997,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:58",
      "speed": 18.573729,
      "direction": 37.800316,
      "lat": 24.515228799999992,
      "lng": 54.667405999999964,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:58",
      "speed": 18.632784,
      "direction": 37.800316,
      "lat": 24.515328066666658,
      "lng": 54.66748299999996,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:59",
      "speed": 18.69184,
      "direction": 37.800316,
      "lat": 24.515427333333324,
      "lng": 54.66755999999996,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:59",
      "speed": 18.750895,
      "direction": 37.800316,
      "lat": 24.51552659999999,
      "lng": 54.66763699999996,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:41:59",
      "speed": 18.80995,
      "direction": 37.800316,
      "lat": 24.515625866666657,
      "lng": 54.667713999999954,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:00",
      "speed": 18.869005,
      "direction": 37.800316,
      "lat": 24.515725133333323,
      "lng": 54.66779099999995,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:00",
      "speed": 18.92806,
      "direction": 37.800316,
      "lat": 24.51582439999999,
      "lng": 54.66786799999995,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:01",
      "speed": 18.987116,
      "direction": 37.800316,
      "lat": 24.515923666666655,
      "lng": 54.667944999999946,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:01",
      "speed": 19.046173,
      "direction": 37.800316,
      "lat": 24.51602293333332,
      "lng": 54.668021999999944,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:01",
      "speed": 19.105228,
      "direction": 37.800316,
      "lat": 24.516122199999987,
      "lng": 54.66809899999994,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:02",
      "speed": 19.164284,
      "direction": 37.800316,
      "lat": 24.516221466666654,
      "lng": 54.66817599999994,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:02",
      "speed": 19.22334,
      "direction": 37.800316,
      "lat": 24.51632073333332,
      "lng": 54.668252999999936,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:03",
      "speed": 19.282394,
      "direction": 37.800316,
      "lat": 24.516419999999986,
      "lng": 54.66832999999993,
      "dataPoint": false,
      "rpmDivd": 1.358
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:03",
      "speed": 19.282383,
      "direction": 37.158554,
      "lat": 24.51642,
      "lng": 54.66833,
      "dataPoint": true,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:03",
      "speed": 19.173222,
      "direction": 37.158554,
      "lat": 24.516519133333333,
      "lng": 54.66840513333333,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:03",
      "speed": 19.064058,
      "direction": 37.158554,
      "lat": 24.516618266666665,
      "lng": 54.66848026666666,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:04",
      "speed": 18.954897,
      "direction": 37.158554,
      "lat": 24.516717399999997,
      "lng": 54.668555399999995,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:04",
      "speed": 18.845734,
      "direction": 37.158554,
      "lat": 24.51681653333333,
      "lng": 54.66863053333333,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:05",
      "speed": 18.736572,
      "direction": 37.158554,
      "lat": 24.516915666666662,
      "lng": 54.66870566666666,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:05",
      "speed": 18.627409,
      "direction": 37.158554,
      "lat": 24.517014799999995,
      "lng": 54.66878079999999,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:05",
      "speed": 18.518248,
      "direction": 37.158554,
      "lat": 24.517113933333327,
      "lng": 54.668855933333326,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:06",
      "speed": 18.409084,
      "direction": 37.158554,
      "lat": 24.51721306666666,
      "lng": 54.66893106666666,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:06",
      "speed": 18.299923,
      "direction": 37.158554,
      "lat": 24.517312199999992,
      "lng": 54.66900619999999,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:07",
      "speed": 18.190762,
      "direction": 37.158554,
      "lat": 24.517411333333325,
      "lng": 54.669081333333324,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:07",
      "speed": 18.081598,
      "direction": 37.158554,
      "lat": 24.517510466666657,
      "lng": 54.66915646666666,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:07",
      "speed": 17.972437,
      "direction": 37.158554,
      "lat": 24.51760959999999,
      "lng": 54.66923159999999,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:08",
      "speed": 17.863274,
      "direction": 37.158554,
      "lat": 24.517708733333322,
      "lng": 54.66930673333332,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:08",
      "speed": 17.754112,
      "direction": 37.158554,
      "lat": 24.517807866666654,
      "lng": 54.669381866666654,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:09",
      "speed": 17.644949,
      "direction": 37.158554,
      "lat": 24.517906999999987,
      "lng": 54.66945699999999,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:09",
      "speed": 17.535788,
      "direction": 37.158554,
      "lat": 24.51800613333332,
      "lng": 54.66953213333332,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:09",
      "speed": 17.426624,
      "direction": 37.158554,
      "lat": 24.51810526666665,
      "lng": 54.66960726666665,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:10",
      "speed": 17.317463,
      "direction": 37.158554,
      "lat": 24.518204399999984,
      "lng": 54.669682399999985,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:10",
      "speed": 17.208302,
      "direction": 37.158554,
      "lat": 24.518303533333317,
      "lng": 54.66975753333332,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:11",
      "speed": 17.099138,
      "direction": 37.158554,
      "lat": 24.51840266666665,
      "lng": 54.66983266666665,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:11",
      "speed": 16.989977,
      "direction": 37.158554,
      "lat": 24.51850179999998,
      "lng": 54.66990779999998,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:11",
      "speed": 16.880814,
      "direction": 37.158554,
      "lat": 24.518600933333314,
      "lng": 54.669982933333316,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:12",
      "speed": 16.771652,
      "direction": 37.158554,
      "lat": 24.518700066666646,
      "lng": 54.67005806666665,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:12",
      "speed": 16.662489,
      "direction": 37.158554,
      "lat": 24.51879919999998,
      "lng": 54.67013319999998,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:13",
      "speed": 16.553328,
      "direction": 37.158554,
      "lat": 24.51889833333331,
      "lng": 54.67020833333331,
      "dataPoint": false,
      "rpmDivd": 1.199
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:13",
      "speed": 16.553322,
      "direction": 37.348698,
      "lat": 24.518898333333333,
      "lng": 54.670208333333335,
      "dataPoint": true,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:13",
      "speed": 17.205612,
      "direction": 37.348698,
      "lat": 24.518995999999998,
      "lng": 54.67028286666667,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:13",
      "speed": 17.857903,
      "direction": 37.348698,
      "lat": 24.519093666666663,
      "lng": 54.6703574,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:14",
      "speed": 18.510191,
      "direction": 37.348698,
      "lat": 24.51919133333333,
      "lng": 54.67043193333333,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:14",
      "speed": 19.162481,
      "direction": 37.348698,
      "lat": 24.519288999999993,
      "lng": 54.670506466666666,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:15",
      "speed": 19.814772,
      "direction": 37.348698,
      "lat": 24.51938666666666,
      "lng": 54.670581,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:15",
      "speed": 20.467062,
      "direction": 37.348698,
      "lat": 24.519484333333324,
      "lng": 54.67065553333333,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:15",
      "speed": 21.119352,
      "direction": 37.348698,
      "lat": 24.51958199999999,
      "lng": 54.670730066666664,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:16",
      "speed": 21.771643,
      "direction": 37.348698,
      "lat": 24.519679666666654,
      "lng": 54.6708046,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:16",
      "speed": 22.423931,
      "direction": 37.348698,
      "lat": 24.51977733333332,
      "lng": 54.67087913333333,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:17",
      "speed": 23.076221,
      "direction": 37.348698,
      "lat": 24.519874999999985,
      "lng": 54.67095366666666,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:17",
      "speed": 23.728512,
      "direction": 37.348698,
      "lat": 24.51997266666665,
      "lng": 54.671028199999995,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:17",
      "speed": 24.380802,
      "direction": 37.348698,
      "lat": 24.520070333333315,
      "lng": 54.67110273333333,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:18",
      "speed": 25.033092,
      "direction": 37.348698,
      "lat": 24.52016799999998,
      "lng": 54.67117726666666,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:18",
      "speed": 25.68538,
      "direction": 37.348698,
      "lat": 24.520265666666646,
      "lng": 54.67125179999999,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:19",
      "speed": 26.337671,
      "direction": 37.348698,
      "lat": 24.52036333333331,
      "lng": 54.671326333333326,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:19",
      "speed": 26.989962,
      "direction": 37.348698,
      "lat": 24.520460999999976,
      "lng": 54.67140086666666,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:19",
      "speed": 27.642252,
      "direction": 37.348698,
      "lat": 24.52055866666664,
      "lng": 54.67147539999999,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:20",
      "speed": 28.294542,
      "direction": 37.348698,
      "lat": 24.520656333333307,
      "lng": 54.671549933333324,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:20",
      "speed": 28.946833,
      "direction": 37.348698,
      "lat": 24.52075399999997,
      "lng": 54.67162446666666,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:21",
      "speed": 29.599121,
      "direction": 37.348698,
      "lat": 24.520851666666637,
      "lng": 54.67169899999999,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:21",
      "speed": 30.251411,
      "direction": 37.348698,
      "lat": 24.520949333333302,
      "lng": 54.67177353333332,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:21",
      "speed": 30.903702,
      "direction": 37.348698,
      "lat": 24.521046999999967,
      "lng": 54.671848066666655,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:22",
      "speed": 31.555992,
      "direction": 37.348698,
      "lat": 24.521144666666633,
      "lng": 54.67192259999999,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:22",
      "speed": 32.208282,
      "direction": 37.348698,
      "lat": 24.521242333333298,
      "lng": 54.67199713333332,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:23",
      "speed": 32.860573,
      "direction": 37.348698,
      "lat": 24.521339999999963,
      "lng": 54.67207166666665,
      "dataPoint": false,
      "rpmDivd": 1.314
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:23",
      "speed": 32.86058,
      "direction": 37.36757,
      "lat": 24.52134,
      "lng": 54.67207166666667,
      "dataPoint": true,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:23",
      "speed": 31.672321,
      "direction": 37.36757,
      "lat": 24.5214376,
      "lng": 54.6721462,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:23",
      "speed": 30.484062,
      "direction": 37.36757,
      "lat": 24.5215352,
      "lng": 54.67222073333333,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:24",
      "speed": 29.295803,
      "direction": 37.36757,
      "lat": 24.5216328,
      "lng": 54.672295266666666,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:24",
      "speed": 28.107544,
      "direction": 37.36757,
      "lat": 24.5217304,
      "lng": 54.6723698,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:25",
      "speed": 26.919287,
      "direction": 37.36757,
      "lat": 24.521828,
      "lng": 54.67244433333333,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:25",
      "speed": 25.731028,
      "direction": 37.36757,
      "lat": 24.5219256,
      "lng": 54.672518866666664,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:25",
      "speed": 24.542768,
      "direction": 37.36757,
      "lat": 24.5220232,
      "lng": 54.6725934,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:26",
      "speed": 23.35451,
      "direction": 37.36757,
      "lat": 24.5221208,
      "lng": 54.67266793333333,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:26",
      "speed": 22.16625,
      "direction": 37.36757,
      "lat": 24.5222184,
      "lng": 54.67274246666666,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:27",
      "speed": 20.977991,
      "direction": 37.36757,
      "lat": 24.522316,
      "lng": 54.672816999999995,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:27",
      "speed": 19.789732,
      "direction": 37.36757,
      "lat": 24.5224136,
      "lng": 54.67289153333333,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:27",
      "speed": 18.601473,
      "direction": 37.36757,
      "lat": 24.5225112,
      "lng": 54.67296606666666,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:28",
      "speed": 17.413216,
      "direction": 37.36757,
      "lat": 24.5226088,
      "lng": 54.67304059999999,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:28",
      "speed": 16.224957,
      "direction": 37.36757,
      "lat": 24.5227064,
      "lng": 54.673115133333326,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:29",
      "speed": 15.036697,
      "direction": 37.36757,
      "lat": 24.522804,
      "lng": 54.67318966666666,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:29",
      "speed": 13.848438,
      "direction": 37.36757,
      "lat": 24.5229016,
      "lng": 54.67326419999999,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:29",
      "speed": 12.660179,
      "direction": 37.36757,
      "lat": 24.5229992,
      "lng": 54.673338733333324,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:30",
      "speed": 11.47192,
      "direction": 37.36757,
      "lat": 24.5230968,
      "lng": 54.67341326666666,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:30",
      "speed": 10.283662,
      "direction": 37.36757,
      "lat": 24.5231944,
      "lng": 54.67348779999999,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:31",
      "speed": 9.095403,
      "direction": 37.36757,
      "lat": 24.523292,
      "lng": 54.67356233333332,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:31",
      "speed": 7.9071436,
      "direction": 37.36757,
      "lat": 24.5233896,
      "lng": 54.673636866666655,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:31",
      "speed": 6.718885,
      "direction": 37.36757,
      "lat": 24.5234872,
      "lng": 54.67371139999999,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:32",
      "speed": 5.530626,
      "direction": 37.36757,
      "lat": 24.523584800000002,
      "lng": 54.67378593333332,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:32",
      "speed": 4.342367,
      "direction": 37.36757,
      "lat": 24.523682400000002,
      "lng": 54.67386046666665,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:33",
      "speed": 3.1541083,
      "direction": 37.36757,
      "lat": 24.523780000000002,
      "lng": 54.673934999999986,
      "dataPoint": false,
      "rpmDivd": 0.568
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:33",
      "speed": 3.154079,
      "direction": 37.797386,
      "lat": 24.52378,
      "lng": 54.673935,
      "dataPoint": true,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:33",
      "speed": 3.4055102,
      "direction": 37.797386,
      "lat": 24.523878933333332,
      "lng": 54.67401173333333,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:33",
      "speed": 3.6569412,
      "direction": 37.797386,
      "lat": 24.523977866666666,
      "lng": 54.67408846666666,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:34",
      "speed": 3.9083724,
      "direction": 37.797386,
      "lat": 24.5240768,
      "lng": 54.67416519999999,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:34",
      "speed": 4.1598034,
      "direction": 37.797386,
      "lat": 24.524175733333333,
      "lng": 54.67424193333332,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:35",
      "speed": 4.4112344,
      "direction": 37.797386,
      "lat": 24.524274666666667,
      "lng": 54.67431866666665,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:35",
      "speed": 4.662666,
      "direction": 37.797386,
      "lat": 24.5243736,
      "lng": 54.67439539999998,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:35",
      "speed": 4.914097,
      "direction": 37.797386,
      "lat": 24.524472533333334,
      "lng": 54.67447213333331,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:36",
      "speed": 5.165528,
      "direction": 37.797386,
      "lat": 24.524571466666668,
      "lng": 54.67454886666664,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:36",
      "speed": 5.416959,
      "direction": 37.797386,
      "lat": 24.5246704,
      "lng": 54.67462559999997,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:37",
      "speed": 5.6683903,
      "direction": 37.797386,
      "lat": 24.524769333333335,
      "lng": 54.6747023333333,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:37",
      "speed": 5.9198213,
      "direction": 37.797386,
      "lat": 24.52486826666667,
      "lng": 54.67477906666663,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:37",
      "speed": 6.1712523,
      "direction": 37.797386,
      "lat": 24.524967200000003,
      "lng": 54.67485579999996,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:38",
      "speed": 6.4226832,
      "direction": 37.797386,
      "lat": 24.525066133333336,
      "lng": 54.67493253333329,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:38",
      "speed": 6.6741147,
      "direction": 37.797386,
      "lat": 24.52516506666667,
      "lng": 54.67500926666662,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:39",
      "speed": 6.9255457,
      "direction": 37.797386,
      "lat": 24.525264000000004,
      "lng": 54.67508599999995,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:39",
      "speed": 7.1769767,
      "direction": 37.797386,
      "lat": 24.525362933333337,
      "lng": 54.67516273333328,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:39",
      "speed": 7.4284077,
      "direction": 37.797386,
      "lat": 24.52546186666667,
      "lng": 54.67523946666661,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:40",
      "speed": 7.679839,
      "direction": 37.797386,
      "lat": 24.525560800000004,
      "lng": 54.67531619999994,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:40",
      "speed": 7.93127,
      "direction": 37.797386,
      "lat": 24.525659733333338,
      "lng": 54.67539293333327,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:41",
      "speed": 8.182701,
      "direction": 37.797386,
      "lat": 24.525758666666672,
      "lng": 54.6754696666666,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:41",
      "speed": 8.434133,
      "direction": 37.797386,
      "lat": 24.525857600000005,
      "lng": 54.67554639999993,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:41",
      "speed": 8.685563,
      "direction": 37.797386,
      "lat": 24.52595653333334,
      "lng": 54.67562313333326,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:42",
      "speed": 8.936995,
      "direction": 37.797386,
      "lat": 24.526055466666673,
      "lng": 54.67569986666659,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:42",
      "speed": 9.188426,
      "direction": 37.797386,
      "lat": 24.526154400000006,
      "lng": 54.67577659999992,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:43",
      "speed": 9.439857,
      "direction": 37.797386,
      "lat": 24.52625333333334,
      "lng": 54.67585333333325,
      "dataPoint": false,
      "rpmDivd": 0.737
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:43",
      "speed": 9.439867,
      "direction": 37.613956,
      "lat": 24.526253333333333,
      "lng": 54.675853333333336,
      "dataPoint": true,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:43",
      "speed": 10.183424,
      "direction": 37.613956,
      "lat": 24.526355,
      "lng": 54.67593166666667,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:43",
      "speed": 10.92698,
      "direction": 37.613956,
      "lat": 24.526456666666665,
      "lng": 54.676010000000005,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:44",
      "speed": 11.670537,
      "direction": 37.613956,
      "lat": 24.52655833333333,
      "lng": 54.67608833333334,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:44",
      "speed": 12.414094,
      "direction": 37.613956,
      "lat": 24.526659999999996,
      "lng": 54.676166666666674,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:45",
      "speed": 13.15765,
      "direction": 37.613956,
      "lat": 24.526761666666662,
      "lng": 54.67624500000001,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:45",
      "speed": 13.901207,
      "direction": 37.613956,
      "lat": 24.526863333333328,
      "lng": 54.67632333333334,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:45",
      "speed": 14.644764,
      "direction": 37.613956,
      "lat": 24.526964999999993,
      "lng": 54.67640166666668,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:46",
      "speed": 15.38832,
      "direction": 37.613956,
      "lat": 24.52706666666666,
      "lng": 54.67648000000001,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:46",
      "speed": 16.131878,
      "direction": 37.613956,
      "lat": 24.527168333333325,
      "lng": 54.67655833333335,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:47",
      "speed": 16.875433,
      "direction": 37.613956,
      "lat": 24.52726999999999,
      "lng": 54.67663666666668,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:47",
      "speed": 17.61899,
      "direction": 37.613956,
      "lat": 24.527371666666657,
      "lng": 54.676715000000016,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:47",
      "speed": 18.362547,
      "direction": 37.613956,
      "lat": 24.527473333333322,
      "lng": 54.67679333333335,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:48",
      "speed": 19.106104,
      "direction": 37.613956,
      "lat": 24.527574999999988,
      "lng": 54.676871666666685,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:48",
      "speed": 19.84966,
      "direction": 37.613956,
      "lat": 24.527676666666654,
      "lng": 54.67695000000002,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:49",
      "speed": 20.593218,
      "direction": 37.613956,
      "lat": 24.52777833333332,
      "lng": 54.677028333333354,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:49",
      "speed": 21.336773,
      "direction": 37.613956,
      "lat": 24.527879999999985,
      "lng": 54.67710666666669,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:49",
      "speed": 22.08033,
      "direction": 37.613956,
      "lat": 24.52798166666665,
      "lng": 54.67718500000002,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:50",
      "speed": 22.823887,
      "direction": 37.613956,
      "lat": 24.528083333333317,
      "lng": 54.67726333333336,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:50",
      "speed": 23.567444,
      "direction": 37.613956,
      "lat": 24.528184999999983,
      "lng": 54.67734166666669,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:51",
      "speed": 24.311,
      "direction": 37.613956,
      "lat": 24.52828666666665,
      "lng": 54.677420000000026,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:51",
      "speed": 25.054558,
      "direction": 37.613956,
      "lat": 24.528388333333314,
      "lng": 54.67749833333336,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:51",
      "speed": 25.798113,
      "direction": 37.613956,
      "lat": 24.52848999999998,
      "lng": 54.677576666666695,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:52",
      "speed": 26.54167,
      "direction": 37.613956,
      "lat": 24.528591666666646,
      "lng": 54.67765500000003,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:52",
      "speed": 27.285227,
      "direction": 37.613956,
      "lat": 24.52869333333331,
      "lng": 54.677733333333364,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:53",
      "speed": 28.028784,
      "direction": 37.613956,
      "lat": 24.528794999999977,
      "lng": 54.6778116666667,
      "dataPoint": false,
      "rpmDivd": 0.792
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:53",
      "speed": 28.028801,
      "direction": 37.435646,
      "lat": 24.528795,
      "lng": 54.67781166666666,
      "dataPoint": true,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:53",
      "speed": 27.354141,
      "direction": 37.435646,
      "lat": 24.528897933333333,
      "lng": 54.67789046666666,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:53",
      "speed": 26.679483,
      "direction": 37.435646,
      "lat": 24.529000866666667,
      "lng": 54.67796926666666,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:54",
      "speed": 26.004824,
      "direction": 37.435646,
      "lat": 24.5291038,
      "lng": 54.678048066666655,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:54",
      "speed": 25.330166,
      "direction": 37.435646,
      "lat": 24.529206733333336,
      "lng": 54.67812686666665,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:55",
      "speed": 24.655506,
      "direction": 37.435646,
      "lat": 24.52930966666667,
      "lng": 54.67820566666665,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:55",
      "speed": 23.980848,
      "direction": 37.435646,
      "lat": 24.529412600000004,
      "lng": 54.678284466666646,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:55",
      "speed": 23.306189,
      "direction": 37.435646,
      "lat": 24.52951553333334,
      "lng": 54.67836326666664,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:56",
      "speed": 22.63153,
      "direction": 37.435646,
      "lat": 24.529618466666673,
      "lng": 54.67844206666664,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:56",
      "speed": 21.956871,
      "direction": 37.435646,
      "lat": 24.529721400000007,
      "lng": 54.67852086666664,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:57",
      "speed": 21.282211,
      "direction": 37.435646,
      "lat": 24.52982433333334,
      "lng": 54.678599666666635,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:57",
      "speed": 20.607553,
      "direction": 37.435646,
      "lat": 24.529927266666675,
      "lng": 54.67867846666663,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:57",
      "speed": 19.932894,
      "direction": 37.435646,
      "lat": 24.53003020000001,
      "lng": 54.67875726666663,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:58",
      "speed": 19.258236,
      "direction": 37.435646,
      "lat": 24.530133133333344,
      "lng": 54.678836066666626,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:58",
      "speed": 18.583576,
      "direction": 37.435646,
      "lat": 24.530236066666678,
      "lng": 54.678914866666624,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:59",
      "speed": 17.908918,
      "direction": 37.435646,
      "lat": 24.530339000000012,
      "lng": 54.67899366666662,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:59",
      "speed": 17.234259,
      "direction": 37.435646,
      "lat": 24.530441933333346,
      "lng": 54.67907246666662,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:42:59",
      "speed": 16.559599,
      "direction": 37.435646,
      "lat": 24.53054486666668,
      "lng": 54.679151266666615,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:00",
      "speed": 15.884941,
      "direction": 37.435646,
      "lat": 24.530647800000015,
      "lng": 54.67923006666661,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:00",
      "speed": 15.210282,
      "direction": 37.435646,
      "lat": 24.53075073333335,
      "lng": 54.67930886666661,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:01",
      "speed": 14.535624,
      "direction": 37.435646,
      "lat": 24.530853666666683,
      "lng": 54.67938766666661,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:01",
      "speed": 13.860964,
      "direction": 37.435646,
      "lat": 24.530956600000017,
      "lng": 54.679466466666604,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:01",
      "speed": 13.186305,
      "direction": 37.435646,
      "lat": 24.53105953333335,
      "lng": 54.6795452666666,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:02",
      "speed": 12.511646,
      "direction": 37.435646,
      "lat": 24.531162466666686,
      "lng": 54.6796240666666,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:02",
      "speed": 11.8369875,
      "direction": 37.435646,
      "lat": 24.53126540000002,
      "lng": 54.679702866666595,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:03",
      "speed": 11.162329,
      "direction": 37.435646,
      "lat": 24.531368333333354,
      "lng": 54.67978166666659,
      "dataPoint": false,
      "rpmDivd": 0.706
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:03",
      "speed": 11.162308,
      "direction": 37.6465,
      "lat": 24.531368333333333,
      "lng": 54.67978166666666,
      "dataPoint": true,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:03",
      "speed": 11.645486,
      "direction": 37.6465,
      "lat": 24.531673666666666,
      "lng": 54.680017199999995,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:03",
      "speed": 12.128663,
      "direction": 37.6465,
      "lat": 24.531979,
      "lng": 54.680252733333326,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:04",
      "speed": 12.611841,
      "direction": 37.6465,
      "lat": 24.532284333333333,
      "lng": 54.68048826666666,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:04",
      "speed": 13.095018,
      "direction": 37.6465,
      "lat": 24.532589666666667,
      "lng": 54.68072379999999,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:05",
      "speed": 13.578197,
      "direction": 37.6465,
      "lat": 24.532895,
      "lng": 54.68095933333332,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:05",
      "speed": 14.061375,
      "direction": 37.6465,
      "lat": 24.533200333333333,
      "lng": 54.68119486666665,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:05",
      "speed": 14.544552,
      "direction": 37.6465,
      "lat": 24.533505666666667,
      "lng": 54.68143039999998,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:06",
      "speed": 15.02773,
      "direction": 37.6465,
      "lat": 24.533811,
      "lng": 54.68166593333331,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:06",
      "speed": 15.510908,
      "direction": 37.6465,
      "lat": 24.534116333333333,
      "lng": 54.681901466666645,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:07",
      "speed": 15.994085,
      "direction": 37.6465,
      "lat": 24.534421666666667,
      "lng": 54.682136999999976,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:07",
      "speed": 16.477262,
      "direction": 37.6465,
      "lat": 24.534727,
      "lng": 54.68237253333331,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:07",
      "speed": 16.960442,
      "direction": 37.6465,
      "lat": 24.535032333333334,
      "lng": 54.68260806666664,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:08",
      "speed": 17.443619,
      "direction": 37.6465,
      "lat": 24.535337666666667,
      "lng": 54.68284359999997,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:08",
      "speed": 17.926796,
      "direction": 37.6465,
      "lat": 24.535643,
      "lng": 54.6830791333333,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:09",
      "speed": 18.409975,
      "direction": 37.6465,
      "lat": 24.535948333333334,
      "lng": 54.68331466666663,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:09",
      "speed": 18.893152,
      "direction": 37.6465,
      "lat": 24.536253666666667,
      "lng": 54.683550199999964,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:09",
      "speed": 19.37633,
      "direction": 37.6465,
      "lat": 24.536559,
      "lng": 54.683785733333295,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:10",
      "speed": 19.859509,
      "direction": 37.6465,
      "lat": 24.536864333333334,
      "lng": 54.684021266666626,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:10",
      "speed": 20.342686,
      "direction": 37.6465,
      "lat": 24.537169666666667,
      "lng": 54.68425679999996,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:11",
      "speed": 20.825863,
      "direction": 37.6465,
      "lat": 24.537475,
      "lng": 54.68449233333329,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:11",
      "speed": 21.309042,
      "direction": 37.6465,
      "lat": 24.537780333333334,
      "lng": 54.68472786666662,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:11",
      "speed": 21.79222,
      "direction": 37.6465,
      "lat": 24.538085666666667,
      "lng": 54.68496339999995,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:12",
      "speed": 22.275396,
      "direction": 37.6465,
      "lat": 24.538391,
      "lng": 54.68519893333328,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:12",
      "speed": 22.758574,
      "direction": 37.6465,
      "lat": 24.538696333333334,
      "lng": 54.685434466666614,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:13",
      "speed": 23.241753,
      "direction": 37.6465,
      "lat": 24.539001666666667,
      "lng": 54.685669999999945,
      "dataPoint": false,
      "rpmDivd": 1.418
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:13",
      "speed": 23.24176,
      "direction": 40.677364,
      "lat": 24.539001666666667,
      "lng": 54.68567,
      "dataPoint": true,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:13",
      "speed": 22.90443,
      "direction": 40.677364,
      "lat": 24.5391008,
      "lng": 54.6857552,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:13",
      "speed": 22.5671,
      "direction": 40.677364,
      "lat": 24.539199933333332,
      "lng": 54.685840400000004,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:14",
      "speed": 22.22977,
      "direction": 40.677364,
      "lat": 24.539299066666665,
      "lng": 54.685925600000004,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:14",
      "speed": 21.89244,
      "direction": 40.677364,
      "lat": 24.539398199999997,
      "lng": 54.686010800000005,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:15",
      "speed": 21.55511,
      "direction": 40.677364,
      "lat": 24.53949733333333,
      "lng": 54.686096000000006,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:15",
      "speed": 21.217781,
      "direction": 40.677364,
      "lat": 24.539596466666662,
      "lng": 54.68618120000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:15",
      "speed": 20.880451,
      "direction": 40.677364,
      "lat": 24.539695599999995,
      "lng": 54.68626640000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:16",
      "speed": 20.54312,
      "direction": 40.677364,
      "lat": 24.539794733333327,
      "lng": 54.68635160000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:16",
      "speed": 20.20579,
      "direction": 40.677364,
      "lat": 24.53989386666666,
      "lng": 54.68643680000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:17",
      "speed": 19.86846,
      "direction": 40.677364,
      "lat": 24.539992999999992,
      "lng": 54.68652200000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:17",
      "speed": 19.53113,
      "direction": 40.677364,
      "lat": 24.540092133333324,
      "lng": 54.68660720000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:17",
      "speed": 19.1938,
      "direction": 40.677364,
      "lat": 24.540191266666657,
      "lng": 54.68669240000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:18",
      "speed": 18.85647,
      "direction": 40.677364,
      "lat": 24.54029039999999,
      "lng": 54.68677760000001,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:18",
      "speed": 18.51914,
      "direction": 40.677364,
      "lat": 24.54038953333332,
      "lng": 54.686862800000014,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:19",
      "speed": 18.18181,
      "direction": 40.677364,
      "lat": 24.540488666666654,
      "lng": 54.686948000000015,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:19",
      "speed": 17.84448,
      "direction": 40.677364,
      "lat": 24.540587799999987,
      "lng": 54.687033200000016,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:19",
      "speed": 17.50715,
      "direction": 40.677364,
      "lat": 24.54068693333332,
      "lng": 54.68711840000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:20",
      "speed": 17.16982,
      "direction": 40.677364,
      "lat": 24.54078606666665,
      "lng": 54.68720360000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:20",
      "speed": 16.83249,
      "direction": 40.677364,
      "lat": 24.540885199999984,
      "lng": 54.68728880000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:21",
      "speed": 16.495161,
      "direction": 40.677364,
      "lat": 24.540984333333316,
      "lng": 54.68737400000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:21",
      "speed": 16.157831,
      "direction": 40.677364,
      "lat": 24.54108346666665,
      "lng": 54.68745920000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:21",
      "speed": 15.8205,
      "direction": 40.677364,
      "lat": 24.54118259999998,
      "lng": 54.68754440000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:22",
      "speed": 15.4831705,
      "direction": 40.677364,
      "lat": 24.541281733333314,
      "lng": 54.68762960000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:22",
      "speed": 15.145841,
      "direction": 40.677364,
      "lat": 24.541380866666646,
      "lng": 54.68771480000002,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:23",
      "speed": 14.80851,
      "direction": 40.677364,
      "lat": 24.54147999999998,
      "lng": 54.687800000000024,
      "dataPoint": false,
      "rpmDivd": 0.349
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:23",
      "speed": 14.808513,
      "direction": 50.04779,
      "lat": 24.54148,
      "lng": 54.6878,
      "dataPoint": true,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:23",
      "speed": 14.559766,
      "direction": 50.04779,
      "lat": 24.541565666666667,
      "lng": 54.68790226666667,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:23",
      "speed": 14.311019,
      "direction": 50.04779,
      "lat": 24.541651333333334,
      "lng": 54.688004533333334,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:24",
      "speed": 14.062273,
      "direction": 50.04779,
      "lat": 24.541737,
      "lng": 54.6881068,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:24",
      "speed": 13.813526,
      "direction": 50.04779,
      "lat": 24.54182266666667,
      "lng": 54.688209066666666,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:25",
      "speed": 13.564779,
      "direction": 50.04779,
      "lat": 24.541908333333335,
      "lng": 54.68831133333333,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:25",
      "speed": 13.316032,
      "direction": 50.04779,
      "lat": 24.541994000000003,
      "lng": 54.6884136,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:25",
      "speed": 13.0672865,
      "direction": 50.04779,
      "lat": 24.54207966666667,
      "lng": 54.68851586666666,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:26",
      "speed": 12.81854,
      "direction": 50.04779,
      "lat": 24.542165333333337,
      "lng": 54.68861813333333,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:26",
      "speed": 12.569793,
      "direction": 50.04779,
      "lat": 24.542251000000004,
      "lng": 54.688720399999994,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:27",
      "speed": 12.321046,
      "direction": 50.04779,
      "lat": 24.54233666666667,
      "lng": 54.68882266666666,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:27",
      "speed": 12.072299,
      "direction": 50.04779,
      "lat": 24.542422333333338,
      "lng": 54.688924933333325,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:27",
      "speed": 11.823553,
      "direction": 50.04779,
      "lat": 24.542508000000005,
      "lng": 54.68902719999999,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:28",
      "speed": 11.574806,
      "direction": 50.04779,
      "lat": 24.542593666666672,
      "lng": 54.68912946666666,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:28",
      "speed": 11.326059,
      "direction": 50.04779,
      "lat": 24.54267933333334,
      "lng": 54.68923173333332,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:29",
      "speed": 11.077312,
      "direction": 50.04779,
      "lat": 24.542765000000006,
      "lng": 54.68933399999999,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:29",
      "speed": 10.828566,
      "direction": 50.04779,
      "lat": 24.542850666666673,
      "lng": 54.689436266666654,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:29",
      "speed": 10.57982,
      "direction": 50.04779,
      "lat": 24.54293633333334,
      "lng": 54.68953853333332,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:30",
      "speed": 10.331073,
      "direction": 50.04779,
      "lat": 24.543022000000008,
      "lng": 54.689640799999985,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:30",
      "speed": 10.082326,
      "direction": 50.04779,
      "lat": 24.543107666666675,
      "lng": 54.68974306666665,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:31",
      "speed": 9.833579,
      "direction": 50.04779,
      "lat": 24.543193333333342,
      "lng": 54.68984533333332,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:31",
      "speed": 9.584833,
      "direction": 50.04779,
      "lat": 24.54327900000001,
      "lng": 54.68994759999998,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:31",
      "speed": 9.336086,
      "direction": 50.04779,
      "lat": 24.543364666666676,
      "lng": 54.69004986666665,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:32",
      "speed": 9.087339,
      "direction": 50.04779,
      "lat": 24.543450333333343,
      "lng": 54.690152133333314,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:32",
      "speed": 8.838593,
      "direction": 50.04779,
      "lat": 24.54353600000001,
      "lng": 54.69025439999998,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:33",
      "speed": 8.589846,
      "direction": 50.04779,
      "lat": 24.543621666666677,
      "lng": 54.690356666666645,
      "dataPoint": false,
      "rpmDivd": 0.803
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:33",
      "speed": 8.589832,
      "direction": 47.444942,
      "lat": 24.543621666666667,
      "lng": 54.690356666666666,
      "dataPoint": true,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:33",
      "speed": 9.09359,
      "direction": 47.444942,
      "lat": 24.5437106,
      "lng": 54.69045353333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:33",
      "speed": 9.597348,
      "direction": 47.444942,
      "lat": 24.543799533333335,
      "lng": 54.6905504,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:34",
      "speed": 10.101106,
      "direction": 47.444942,
      "lat": 24.54388846666667,
      "lng": 54.690647266666666,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:34",
      "speed": 10.604863,
      "direction": 47.444942,
      "lat": 24.543977400000003,
      "lng": 54.69074413333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:35",
      "speed": 11.108622,
      "direction": 47.444942,
      "lat": 24.544066333333337,
      "lng": 54.690841,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:35",
      "speed": 11.612379,
      "direction": 47.444942,
      "lat": 24.54415526666667,
      "lng": 54.690937866666665,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:35",
      "speed": 12.116137,
      "direction": 47.444942,
      "lat": 24.544244200000005,
      "lng": 54.69103473333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:36",
      "speed": 12.619895,
      "direction": 47.444942,
      "lat": 24.54433313333334,
      "lng": 54.6911316,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:36",
      "speed": 13.123652,
      "direction": 47.444942,
      "lat": 24.544422066666673,
      "lng": 54.691228466666665,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:37",
      "speed": 13.62741,
      "direction": 47.444942,
      "lat": 24.544511000000007,
      "lng": 54.69132533333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:37",
      "speed": 14.131167,
      "direction": 47.444942,
      "lat": 24.54459993333334,
      "lng": 54.6914222,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:37",
      "speed": 14.634926,
      "direction": 47.444942,
      "lat": 24.544688866666675,
      "lng": 54.691519066666665,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:38",
      "speed": 15.138683,
      "direction": 47.444942,
      "lat": 24.54477780000001,
      "lng": 54.69161593333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:38",
      "speed": 15.642441,
      "direction": 47.444942,
      "lat": 24.544866733333343,
      "lng": 54.6917128,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:39",
      "speed": 16.146198,
      "direction": 47.444942,
      "lat": 24.544955666666677,
      "lng": 54.691809666666664,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:39",
      "speed": 16.649958,
      "direction": 47.444942,
      "lat": 24.54504460000001,
      "lng": 54.69190653333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:39",
      "speed": 17.153715,
      "direction": 47.444942,
      "lat": 24.545133533333345,
      "lng": 54.6920034,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:40",
      "speed": 17.657473,
      "direction": 47.444942,
      "lat": 24.54522246666668,
      "lng": 54.692100266666664,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:40",
      "speed": 18.16123,
      "direction": 47.444942,
      "lat": 24.545311400000013,
      "lng": 54.69219713333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:41",
      "speed": 18.664988,
      "direction": 47.444942,
      "lat": 24.545400333333347,
      "lng": 54.692294,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:41",
      "speed": 19.168745,
      "direction": 47.444942,
      "lat": 24.54548926666668,
      "lng": 54.69239086666666,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:41",
      "speed": 19.672503,
      "direction": 47.444942,
      "lat": 24.545578200000016,
      "lng": 54.69248773333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:42",
      "speed": 20.176262,
      "direction": 47.444942,
      "lat": 24.54566713333335,
      "lng": 54.692584599999996,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:42",
      "speed": 20.68002,
      "direction": 47.444942,
      "lat": 24.545756066666684,
      "lng": 54.69268146666666,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:43",
      "speed": 21.183777,
      "direction": 47.444942,
      "lat": 24.545845000000018,
      "lng": 54.69277833333333,
      "dataPoint": false,
      "rpmDivd": 1.306
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:43",
      "speed": 21.183779,
      "direction": 27.561855,
      "lat": 24.545845,
      "lng": 54.69277833333334,
      "dataPoint": true,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:43",
      "speed": 20.994087,
      "direction": 27.561855,
      "lat": 24.545960466666667,
      "lng": 54.6928386,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:43",
      "speed": 20.804394,
      "direction": 27.561855,
      "lat": 24.546075933333334,
      "lng": 54.69289886666667,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:44",
      "speed": 20.614702,
      "direction": 27.561855,
      "lat": 24.5461914,
      "lng": 54.69295913333333,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:44",
      "speed": 20.42501,
      "direction": 27.561855,
      "lat": 24.54630686666667,
      "lng": 54.6930194,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:45",
      "speed": 20.235317,
      "direction": 27.561855,
      "lat": 24.546422333333336,
      "lng": 54.69307966666666,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:45",
      "speed": 20.045626,
      "direction": 27.561855,
      "lat": 24.546537800000003,
      "lng": 54.69313993333333,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:45",
      "speed": 19.855934,
      "direction": 27.561855,
      "lat": 24.54665326666667,
      "lng": 54.69320019999999,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:46",
      "speed": 19.66624,
      "direction": 27.561855,
      "lat": 24.546768733333337,
      "lng": 54.69326046666666,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:46",
      "speed": 19.47655,
      "direction": 27.561855,
      "lat": 24.546884200000004,
      "lng": 54.69332073333332,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:47",
      "speed": 19.286856,
      "direction": 27.561855,
      "lat": 24.54699966666667,
      "lng": 54.69338099999999,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:47",
      "speed": 19.097164,
      "direction": 27.561855,
      "lat": 24.54711513333334,
      "lng": 54.69344126666665,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:47",
      "speed": 18.907473,
      "direction": 27.561855,
      "lat": 24.547230600000006,
      "lng": 54.69350153333332,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:48",
      "speed": 18.71778,
      "direction": 27.561855,
      "lat": 24.547346066666673,
      "lng": 54.69356179999998,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:48",
      "speed": 18.528088,
      "direction": 27.561855,
      "lat": 24.54746153333334,
      "lng": 54.69362206666665,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:49",
      "speed": 18.338396,
      "direction": 27.561855,
      "lat": 24.547577000000008,
      "lng": 54.693682333333314,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:49",
      "speed": 18.148703,
      "direction": 27.561855,
      "lat": 24.547692466666675,
      "lng": 54.69374259999998,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:49",
      "speed": 17.959011,
      "direction": 27.561855,
      "lat": 24.547807933333342,
      "lng": 54.693802866666644,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:50",
      "speed": 17.76932,
      "direction": 27.561855,
      "lat": 24.54792340000001,
      "lng": 54.69386313333331,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:50",
      "speed": 17.579626,
      "direction": 27.561855,
      "lat": 24.548038866666676,
      "lng": 54.693923399999974,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:51",
      "speed": 17.389935,
      "direction": 27.561855,
      "lat": 24.548154333333343,
      "lng": 54.69398366666664,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:51",
      "speed": 17.200243,
      "direction": 27.561855,
      "lat": 24.54826980000001,
      "lng": 54.694043933333305,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:51",
      "speed": 17.01055,
      "direction": 27.561855,
      "lat": 24.548385266666678,
      "lng": 54.69410419999997,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:52",
      "speed": 16.820858,
      "direction": 27.561855,
      "lat": 24.548500733333345,
      "lng": 54.694164466666635,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:52",
      "speed": 16.631165,
      "direction": 27.561855,
      "lat": 24.548616200000012,
      "lng": 54.6942247333333,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:53",
      "speed": 16.441473,
      "direction": 27.561855,
      "lat": 24.54873166666668,
      "lng": 54.694284999999965,
      "dataPoint": false,
      "rpmDivd": 1.048
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:53",
      "speed": 16.441475,
      "direction": 8.978744,
      "lat": 24.548731666666665,
      "lng": 54.694285,
      "dataPoint": true,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:53",
      "speed": 16.678589,
      "direction": 8.978744,
      "lat": 24.548859933333333,
      "lng": 54.69430526666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:53",
      "speed": 16.915705,
      "direction": 8.978744,
      "lat": 24.5489882,
      "lng": 54.694325533333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:54",
      "speed": 17.152819,
      "direction": 8.978744,
      "lat": 24.549116466666668,
      "lng": 54.6943458,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:54",
      "speed": 17.389933,
      "direction": 8.978744,
      "lat": 24.549244733333335,
      "lng": 54.69436606666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:55",
      "speed": 17.627047,
      "direction": 8.978744,
      "lat": 24.549373000000003,
      "lng": 54.694386333333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:55",
      "speed": 17.864162,
      "direction": 8.978744,
      "lat": 24.54950126666667,
      "lng": 54.6944066,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:55",
      "speed": 18.101276,
      "direction": 8.978744,
      "lat": 24.549629533333338,
      "lng": 54.69442686666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:56",
      "speed": 18.33839,
      "direction": 8.978744,
      "lat": 24.549757800000005,
      "lng": 54.694447133333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:56",
      "speed": 18.575504,
      "direction": 8.978744,
      "lat": 24.549886066666673,
      "lng": 54.6944674,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:57",
      "speed": 18.81262,
      "direction": 8.978744,
      "lat": 24.55001433333334,
      "lng": 54.69448766666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:57",
      "speed": 19.049734,
      "direction": 8.978744,
      "lat": 24.550142600000008,
      "lng": 54.694507933333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:57",
      "speed": 19.286848,
      "direction": 8.978744,
      "lat": 24.550270866666676,
      "lng": 54.6945282,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:58",
      "speed": 19.523962,
      "direction": 8.978744,
      "lat": 24.550399133333343,
      "lng": 54.69454846666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:58",
      "speed": 19.761078,
      "direction": 8.978744,
      "lat": 24.55052740000001,
      "lng": 54.694568733333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:59",
      "speed": 19.998192,
      "direction": 8.978744,
      "lat": 24.550655666666678,
      "lng": 54.694589,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:59",
      "speed": 20.235306,
      "direction": 8.978744,
      "lat": 24.550783933333346,
      "lng": 54.69460926666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:43:59",
      "speed": 20.47242,
      "direction": 8.978744,
      "lat": 24.550912200000013,
      "lng": 54.694629533333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:00",
      "speed": 20.709536,
      "direction": 8.978744,
      "lat": 24.55104046666668,
      "lng": 54.6946498,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:00",
      "speed": 20.94665,
      "direction": 8.978744,
      "lat": 24.55116873333335,
      "lng": 54.69467006666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:01",
      "speed": 21.183764,
      "direction": 8.978744,
      "lat": 24.551297000000016,
      "lng": 54.694690333333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:01",
      "speed": 21.420877,
      "direction": 8.978744,
      "lat": 24.551425266666683,
      "lng": 54.6947106,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:01",
      "speed": 21.657993,
      "direction": 8.978744,
      "lat": 24.55155353333335,
      "lng": 54.69473086666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:02",
      "speed": 21.895107,
      "direction": 8.978744,
      "lat": 24.55168180000002,
      "lng": 54.694751133333334,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:02",
      "speed": 22.132221,
      "direction": 8.978744,
      "lat": 24.551810066666686,
      "lng": 54.6947714,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:03",
      "speed": 22.369335,
      "direction": 8.978744,
      "lat": 24.551938333333354,
      "lng": 54.69479166666667,
      "dataPoint": false,
      "rpmDivd": 0.134
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:03",
      "speed": 22.369354,
      "direction": 6.3799896,
      "lat": 24.551938333333332,
      "lng": 54.69479166666667,
      "dataPoint": true,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:03",
      "speed": 22.74516,
      "direction": 6.3799896,
      "lat": 24.55206473333333,
      "lng": 54.6948058,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:03",
      "speed": 23.120964,
      "direction": 6.3799896,
      "lat": 24.55219113333333,
      "lng": 54.69481993333333,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:04",
      "speed": 23.496767,
      "direction": 6.3799896,
      "lat": 24.55231753333333,
      "lng": 54.69483406666666,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:04",
      "speed": 23.872572,
      "direction": 6.3799896,
      "lat": 24.55244393333333,
      "lng": 54.69484819999999,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:05",
      "speed": 24.248377,
      "direction": 6.3799896,
      "lat": 24.55257033333333,
      "lng": 54.69486233333332,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:05",
      "speed": 24.624182,
      "direction": 6.3799896,
      "lat": 24.552696733333327,
      "lng": 54.69487646666665,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:05",
      "speed": 24.999985,
      "direction": 6.3799896,
      "lat": 24.552823133333327,
      "lng": 54.69489059999998,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:06",
      "speed": 25.37579,
      "direction": 6.3799896,
      "lat": 24.552949533333326,
      "lng": 54.69490473333331,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:06",
      "speed": 25.751595,
      "direction": 6.3799896,
      "lat": 24.553075933333325,
      "lng": 54.69491886666664,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:07",
      "speed": 26.1274,
      "direction": 6.3799896,
      "lat": 24.553202333333324,
      "lng": 54.69493299999997,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:07",
      "speed": 26.503202,
      "direction": 6.3799896,
      "lat": 24.553328733333323,
      "lng": 54.6949471333333,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:07",
      "speed": 26.879007,
      "direction": 6.3799896,
      "lat": 24.553455133333323,
      "lng": 54.69496126666663,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:08",
      "speed": 27.254812,
      "direction": 6.3799896,
      "lat": 24.553581533333322,
      "lng": 54.69497539999996,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:08",
      "speed": 27.630617,
      "direction": 6.3799896,
      "lat": 24.55370793333332,
      "lng": 54.69498953333329,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:09",
      "speed": 28.00642,
      "direction": 6.3799896,
      "lat": 24.55383433333332,
      "lng": 54.69500366666662,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:09",
      "speed": 28.382225,
      "direction": 6.3799896,
      "lat": 24.55396073333332,
      "lng": 54.69501779999995,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:09",
      "speed": 28.75803,
      "direction": 6.3799896,
      "lat": 24.55408713333332,
      "lng": 54.69503193333328,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:10",
      "speed": 29.133835,
      "direction": 6.3799896,
      "lat": 24.554213533333318,
      "lng": 54.69504606666661,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:10",
      "speed": 29.509638,
      "direction": 6.3799896,
      "lat": 24.554339933333317,
      "lng": 54.69506019999994,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:11",
      "speed": 29.885443,
      "direction": 6.3799896,
      "lat": 24.554466333333316,
      "lng": 54.695074333333274,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:11",
      "speed": 30.261248,
      "direction": 6.3799896,
      "lat": 24.554592733333315,
      "lng": 54.695088466666604,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:11",
      "speed": 30.637053,
      "direction": 6.3799896,
      "lat": 24.554719133333315,
      "lng": 54.695102599999935,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:12",
      "speed": 31.012856,
      "direction": 6.3799896,
      "lat": 24.554845533333314,
      "lng": 54.695116733333265,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:12",
      "speed": 31.38866,
      "direction": 6.3799896,
      "lat": 24.554971933333313,
      "lng": 54.695130866666595,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:13",
      "speed": 31.764465,
      "direction": 6.3799896,
      "lat": 24.555098333333312,
      "lng": 54.695144999999926,
      "dataPoint": false,
      "rpmDivd": 0.97
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:13",
      "speed": 31.764482,
      "direction": 6.323755,
      "lat": 24.555098333333333,
      "lng": 54.695145,
      "dataPoint": true,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:13",
      "speed": 31.323359,
      "direction": 6.323755,
      "lat": 24.555225866666667,
      "lng": 54.69515913333333,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:13",
      "speed": 30.882236,
      "direction": 6.323755,
      "lat": 24.5553534,
      "lng": 54.69517326666666,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:14",
      "speed": 30.441113,
      "direction": 6.323755,
      "lat": 24.555480933333335,
      "lng": 54.69518739999999,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:14",
      "speed": 29.999989,
      "direction": 6.323755,
      "lat": 24.55560846666667,
      "lng": 54.69520153333332,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:15",
      "speed": 29.558867,
      "direction": 6.323755,
      "lat": 24.555736000000003,
      "lng": 54.69521566666665,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:15",
      "speed": 29.117743,
      "direction": 6.323755,
      "lat": 24.555863533333337,
      "lng": 54.69522979999998,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:15",
      "speed": 28.676619,
      "direction": 6.323755,
      "lat": 24.55599106666667,
      "lng": 54.69524393333331,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:16",
      "speed": 28.235497,
      "direction": 6.323755,
      "lat": 24.556118600000005,
      "lng": 54.69525806666664,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:16",
      "speed": 27.794373,
      "direction": 6.323755,
      "lat": 24.55624613333334,
      "lng": 54.69527219999997,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:17",
      "speed": 27.353249,
      "direction": 6.323755,
      "lat": 24.556373666666673,
      "lng": 54.6952863333333,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:17",
      "speed": 26.912127,
      "direction": 6.323755,
      "lat": 24.556501200000007,
      "lng": 54.69530046666663,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:17",
      "speed": 26.471003,
      "direction": 6.323755,
      "lat": 24.55662873333334,
      "lng": 54.69531459999996,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:18",
      "speed": 26.029879,
      "direction": 6.323755,
      "lat": 24.556756266666675,
      "lng": 54.69532873333329,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:18",
      "speed": 25.588757,
      "direction": 6.323755,
      "lat": 24.55688380000001,
      "lng": 54.69534286666662,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:19",
      "speed": 25.147633,
      "direction": 6.323755,
      "lat": 24.557011333333342,
      "lng": 54.69535699999995,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:19",
      "speed": 24.706509,
      "direction": 6.323755,
      "lat": 24.557138866666676,
      "lng": 54.69537113333328,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:19",
      "speed": 24.265387,
      "direction": 6.323755,
      "lat": 24.55726640000001,
      "lng": 54.69538526666661,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:20",
      "speed": 23.824263,
      "direction": 6.323755,
      "lat": 24.557393933333344,
      "lng": 54.69539939999994,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:20",
      "speed": 23.383139,
      "direction": 6.323755,
      "lat": 24.557521466666678,
      "lng": 54.69541353333327,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:21",
      "speed": 22.942017,
      "direction": 6.323755,
      "lat": 24.557649000000012,
      "lng": 54.6954276666666,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:21",
      "speed": 22.500893,
      "direction": 6.323755,
      "lat": 24.557776533333346,
      "lng": 54.695441799999934,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:21",
      "speed": 22.059769,
      "direction": 6.323755,
      "lat": 24.55790406666668,
      "lng": 54.695455933333264,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:22",
      "speed": 21.618647,
      "direction": 6.323755,
      "lat": 24.558031600000014,
      "lng": 54.695470066666594,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:22",
      "speed": 21.177523,
      "direction": 6.323755,
      "lat": 24.558159133333348,
      "lng": 54.695484199999925,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:23",
      "speed": 20.736399,
      "direction": 6.323755,
      "lat": 24.55828666666668,
      "lng": 54.695498333333255,
      "dataPoint": false,
      "rpmDivd": 1.257
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:23",
      "speed": 20.736391,
      "direction": 6.195738,
      "lat": 24.558286666666667,
      "lng": 54.69549833333333,
      "dataPoint": true,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:23",
      "speed": 20.099312,
      "direction": 6.195738,
      "lat": 24.5584144,
      "lng": 54.6955122,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:23",
      "speed": 19.462233,
      "direction": 6.195738,
      "lat": 24.558542133333333,
      "lng": 54.69552606666667,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:24",
      "speed": 18.825155,
      "direction": 6.195738,
      "lat": 24.558669866666666,
      "lng": 54.69553993333334,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:24",
      "speed": 18.188076,
      "direction": 6.195738,
      "lat": 24.5587976,
      "lng": 54.69555380000001,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:25",
      "speed": 17.550997,
      "direction": 6.195738,
      "lat": 24.55892533333333,
      "lng": 54.69556766666668,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:25",
      "speed": 16.913918,
      "direction": 6.195738,
      "lat": 24.559053066666664,
      "lng": 54.69558153333335,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:25",
      "speed": 16.276838,
      "direction": 6.195738,
      "lat": 24.559180799999996,
      "lng": 54.69559540000002,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:26",
      "speed": 15.63976,
      "direction": 6.195738,
      "lat": 24.55930853333333,
      "lng": 54.69560926666669,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:26",
      "speed": 15.002681,
      "direction": 6.195738,
      "lat": 24.559436266666662,
      "lng": 54.69562313333336,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:27",
      "speed": 14.3656025,
      "direction": 6.195738,
      "lat": 24.559563999999995,
      "lng": 54.69563700000003,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:27",
      "speed": 13.728523,
      "direction": 6.195738,
      "lat": 24.559691733333327,
      "lng": 54.6956508666667,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:27",
      "speed": 13.091444,
      "direction": 6.195738,
      "lat": 24.55981946666666,
      "lng": 54.69566473333337,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:28",
      "speed": 12.454366,
      "direction": 6.195738,
      "lat": 24.559947199999993,
      "lng": 54.69567860000004,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:28",
      "speed": 11.8172865,
      "direction": 6.195738,
      "lat": 24.560074933333325,
      "lng": 54.69569246666671,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:29",
      "speed": 11.180208,
      "direction": 6.195738,
      "lat": 24.560202666666658,
      "lng": 54.69570633333338,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:29",
      "speed": 10.543129,
      "direction": 6.195738,
      "lat": 24.56033039999999,
      "lng": 54.69572020000005,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:29",
      "speed": 9.90605,
      "direction": 6.195738,
      "lat": 24.560458133333324,
      "lng": 54.69573406666672,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:30",
      "speed": 9.268971,
      "direction": 6.195738,
      "lat": 24.560585866666656,
      "lng": 54.695747933333394,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:30",
      "speed": 8.631892,
      "direction": 6.195738,
      "lat": 24.56071359999999,
      "lng": 54.695761800000064,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:31",
      "speed": 7.9948134,
      "direction": 6.195738,
      "lat": 24.56084133333332,
      "lng": 54.695775666666734,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:31",
      "speed": 7.357734,
      "direction": 6.195738,
      "lat": 24.560969066666654,
      "lng": 54.695789533333404,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:31",
      "speed": 6.7206554,
      "direction": 6.195738,
      "lat": 24.561096799999987,
      "lng": 54.695803400000074,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:32",
      "speed": 6.0835767,
      "direction": 6.195738,
      "lat": 24.56122453333332,
      "lng": 54.695817266666744,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:32",
      "speed": 5.446498,
      "direction": 6.195738,
      "lat": 24.561352266666653,
      "lng": 54.695831133333414,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:33",
      "speed": 4.8094187,
      "direction": 6.195738,
      "lat": 24.561479999999985,
      "lng": 54.695845000000084,
      "dataPoint": false,
      "rpmDivd": 0.076
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:33",
      "speed": 4.809411,
      "direction": 6.382723,
      "lat": 24.56148,
      "lng": 54.695845,
      "dataPoint": true,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:33",
      "speed": 5.352539,
      "direction": 6.382723,
      "lat": 24.561608133333333,
      "lng": 54.69585933333333,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:33",
      "speed": 5.8956666,
      "direction": 6.382723,
      "lat": 24.561736266666667,
      "lng": 54.695873666666664,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:34",
      "speed": 6.4387946,
      "direction": 6.382723,
      "lat": 24.5618644,
      "lng": 54.695888,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:34",
      "speed": 6.981922,
      "direction": 6.382723,
      "lat": 24.561992533333335,
      "lng": 54.69590233333333,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:35",
      "speed": 7.52505,
      "direction": 6.382723,
      "lat": 24.56212066666667,
      "lng": 54.69591666666666,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:35",
      "speed": 8.068178,
      "direction": 6.382723,
      "lat": 24.562248800000003,
      "lng": 54.695930999999995,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:35",
      "speed": 8.611305,
      "direction": 6.382723,
      "lat": 24.562376933333336,
      "lng": 54.69594533333333,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:36",
      "speed": 9.154433,
      "direction": 6.382723,
      "lat": 24.56250506666667,
      "lng": 54.69595966666666,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:36",
      "speed": 9.697561,
      "direction": 6.382723,
      "lat": 24.562633200000004,
      "lng": 54.69597399999999,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:37",
      "speed": 10.240689,
      "direction": 6.382723,
      "lat": 24.562761333333338,
      "lng": 54.695988333333325,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:37",
      "speed": 10.783816,
      "direction": 6.382723,
      "lat": 24.56288946666667,
      "lng": 54.69600266666666,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:37",
      "speed": 11.326944,
      "direction": 6.382723,
      "lat": 24.563017600000006,
      "lng": 54.69601699999999,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:38",
      "speed": 11.870072,
      "direction": 6.382723,
      "lat": 24.56314573333334,
      "lng": 54.69603133333332,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:38",
      "speed": 12.4132,
      "direction": 6.382723,
      "lat": 24.563273866666673,
      "lng": 54.696045666666656,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:39",
      "speed": 12.956327,
      "direction": 6.382723,
      "lat": 24.563402000000007,
      "lng": 54.69605999999999,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:39",
      "speed": 13.499455,
      "direction": 6.382723,
      "lat": 24.56353013333334,
      "lng": 54.69607433333332,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:39",
      "speed": 14.042583,
      "direction": 6.382723,
      "lat": 24.563658266666675,
      "lng": 54.696088666666654,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:40",
      "speed": 14.5857115,
      "direction": 6.382723,
      "lat": 24.56378640000001,
      "lng": 54.69610299999999,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:40",
      "speed": 15.128839,
      "direction": 6.382723,
      "lat": 24.563914533333342,
      "lng": 54.69611733333332,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:41",
      "speed": 15.671967,
      "direction": 6.382723,
      "lat": 24.564042666666676,
      "lng": 54.69613166666665,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:41",
      "speed": 16.215094,
      "direction": 6.382723,
      "lat": 24.56417080000001,
      "lng": 54.696145999999985,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:41",
      "speed": 16.758223,
      "direction": 6.382723,
      "lat": 24.564298933333344,
      "lng": 54.69616033333332,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:42",
      "speed": 17.30135,
      "direction": 6.382723,
      "lat": 24.564427066666678,
      "lng": 54.69617466666665,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:42",
      "speed": 17.844479,
      "direction": 6.382723,
      "lat": 24.56455520000001,
      "lng": 54.69618899999998,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:43",
      "speed": 18.387606,
      "direction": 6.382723,
      "lat": 24.564683333333345,
      "lng": 54.696203333333315,
      "dataPoint": false,
      "rpmDivd": 1.408
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:43",
      "speed": 18.38761,
      "direction": 6.3461695,
      "lat": 24.564683333333335,
      "lng": 54.69620333333334,
      "dataPoint": true,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:43",
      "speed": 18.133493,
      "direction": 6.3461695,
      "lat": 24.5648236,
      "lng": 54.696218933333334,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:43",
      "speed": 17.87938,
      "direction": 6.3461695,
      "lat": 24.564963866666666,
      "lng": 54.69623453333333,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:44",
      "speed": 17.625263,
      "direction": 6.3461695,
      "lat": 24.56510413333333,
      "lng": 54.69625013333333,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:44",
      "speed": 17.371147,
      "direction": 6.3461695,
      "lat": 24.565244399999997,
      "lng": 54.69626573333333,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:45",
      "speed": 17.117031,
      "direction": 6.3461695,
      "lat": 24.565384666666663,
      "lng": 54.696281333333324,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:45",
      "speed": 16.862917,
      "direction": 6.3461695,
      "lat": 24.56552493333333,
      "lng": 54.69629693333332,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:45",
      "speed": 16.6088,
      "direction": 6.3461695,
      "lat": 24.565665199999994,
      "lng": 54.69631253333332,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:46",
      "speed": 16.354685,
      "direction": 6.3461695,
      "lat": 24.56580546666666,
      "lng": 54.69632813333332,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:46",
      "speed": 16.100569,
      "direction": 6.3461695,
      "lat": 24.565945733333326,
      "lng": 54.696343733333315,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:47",
      "speed": 15.846454,
      "direction": 6.3461695,
      "lat": 24.56608599999999,
      "lng": 54.69635933333331,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:47",
      "speed": 15.592339,
      "direction": 6.3461695,
      "lat": 24.566226266666657,
      "lng": 54.69637493333331,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:47",
      "speed": 15.3382225,
      "direction": 6.3461695,
      "lat": 24.566366533333323,
      "lng": 54.69639053333331,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:48",
      "speed": 15.084107,
      "direction": 6.3461695,
      "lat": 24.56650679999999,
      "lng": 54.696406133333305,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:48",
      "speed": 14.829991,
      "direction": 6.3461695,
      "lat": 24.566647066666654,
      "lng": 54.6964217333333,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:49",
      "speed": 14.575876,
      "direction": 6.3461695,
      "lat": 24.56678733333332,
      "lng": 54.6964373333333,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:49",
      "speed": 14.32176,
      "direction": 6.3461695,
      "lat": 24.566927599999985,
      "lng": 54.6964529333333,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:49",
      "speed": 14.067645,
      "direction": 6.3461695,
      "lat": 24.56706786666665,
      "lng": 54.696468533333295,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:50",
      "speed": 13.813529,
      "direction": 6.3461695,
      "lat": 24.567208133333317,
      "lng": 54.69648413333329,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:50",
      "speed": 13.559414,
      "direction": 6.3461695,
      "lat": 24.567348399999982,
      "lng": 54.69649973333329,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:51",
      "speed": 13.305299,
      "direction": 6.3461695,
      "lat": 24.567488666666648,
      "lng": 54.69651533333329,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:51",
      "speed": 13.051183,
      "direction": 6.3461695,
      "lat": 24.567628933333314,
      "lng": 54.696530933333285,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:51",
      "speed": 12.797068,
      "direction": 6.3461695,
      "lat": 24.56776919999998,
      "lng": 54.69654653333328,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:52",
      "speed": 12.542952,
      "direction": 6.3461695,
      "lat": 24.567909466666645,
      "lng": 54.69656213333328,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:52",
      "speed": 12.2888365,
      "direction": 6.3461695,
      "lat": 24.56804973333331,
      "lng": 54.69657773333328,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:53",
      "speed": 12.03472,
      "direction": 6.3461695,
      "lat": 24.568189999999976,
      "lng": 54.696593333333276,
      "dataPoint": false,
      "rpmDivd": 0.093
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:53",
      "speed": 12.034713,
      "direction": 6.2868934,
      "lat": 24.56819,
      "lng": 54.69659333333333,
      "dataPoint": true,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:53",
      "speed": 12.774691,
      "direction": 6.2868934,
      "lat": 24.56831586666667,
      "lng": 54.6966072,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:53",
      "speed": 13.514668,
      "direction": 6.2868934,
      "lat": 24.568441733333337,
      "lng": 54.69662106666667,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:54",
      "speed": 14.254646,
      "direction": 6.2868934,
      "lat": 24.568567600000005,
      "lng": 54.69663493333334,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:54",
      "speed": 14.994624,
      "direction": 6.2868934,
      "lat": 24.568693466666673,
      "lng": 54.69664880000001,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:55",
      "speed": 15.734602,
      "direction": 6.2868934,
      "lat": 24.56881933333334,
      "lng": 54.69666266666668,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:55",
      "speed": 16.474579,
      "direction": 6.2868934,
      "lat": 24.56894520000001,
      "lng": 54.69667653333335,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:55",
      "speed": 17.214558,
      "direction": 6.2868934,
      "lat": 24.569071066666677,
      "lng": 54.69669040000002,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:56",
      "speed": 17.954535,
      "direction": 6.2868934,
      "lat": 24.569196933333345,
      "lng": 54.69670426666669,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:56",
      "speed": 18.694513,
      "direction": 6.2868934,
      "lat": 24.569322800000013,
      "lng": 54.69671813333336,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:57",
      "speed": 19.43449,
      "direction": 6.2868934,
      "lat": 24.56944866666668,
      "lng": 54.69673200000003,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:57",
      "speed": 20.174469,
      "direction": 6.2868934,
      "lat": 24.56957453333335,
      "lng": 54.6967458666667,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:57",
      "speed": 20.914446,
      "direction": 6.2868934,
      "lat": 24.569700400000016,
      "lng": 54.69675973333337,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:58",
      "speed": 21.654425,
      "direction": 6.2868934,
      "lat": 24.569826266666684,
      "lng": 54.69677360000004,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:58",
      "speed": 22.394402,
      "direction": 6.2868934,
      "lat": 24.569952133333352,
      "lng": 54.69678746666671,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:59",
      "speed": 23.13438,
      "direction": 6.2868934,
      "lat": 24.57007800000002,
      "lng": 54.69680133333338,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:59",
      "speed": 23.874357,
      "direction": 6.2868934,
      "lat": 24.570203866666688,
      "lng": 54.69681520000005,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:44:59",
      "speed": 24.614334,
      "direction": 6.2868934,
      "lat": 24.570329733333356,
      "lng": 54.69682906666672,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:00",
      "speed": 25.354313,
      "direction": 6.2868934,
      "lat": 24.570455600000024,
      "lng": 54.69684293333339,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:00",
      "speed": 26.09429,
      "direction": 6.2868934,
      "lat": 24.57058146666669,
      "lng": 54.69685680000006,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:01",
      "speed": 26.834269,
      "direction": 6.2868934,
      "lat": 24.57070733333336,
      "lng": 54.69687066666673,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:01",
      "speed": 27.574245,
      "direction": 6.2868934,
      "lat": 24.570833200000028,
      "lng": 54.6968845333334,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:01",
      "speed": 28.314224,
      "direction": 6.2868934,
      "lat": 24.570959066666695,
      "lng": 54.69689840000007,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:02",
      "speed": 29.054201,
      "direction": 6.2868934,
      "lat": 24.571084933333363,
      "lng": 54.69691226666674,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:02",
      "speed": 29.79418,
      "direction": 6.2868934,
      "lat": 24.57121080000003,
      "lng": 54.69692613333341,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:03",
      "speed": 30.534157,
      "direction": 6.2868934,
      "lat": 24.5713366666667,
      "lng": 54.69694000000008,
      "dataPoint": false,
      "rpmDivd": 1.028
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:03",
      "speed": 30.534168,
      "direction": 6.282771,
      "lat": 24.571336666666667,
      "lng": 54.69694,
      "dataPoint": true,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:03",
      "speed": 30.040255,
      "direction": 6.282771,
      "lat": 24.5714608,
      "lng": 54.696953666666666,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:03",
      "speed": 29.546339,
      "direction": 6.282771,
      "lat": 24.571584933333334,
      "lng": 54.69696733333333,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:04",
      "speed": 29.052425,
      "direction": 6.282771,
      "lat": 24.571709066666667,
      "lng": 54.696981,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:04",
      "speed": 28.55851,
      "direction": 6.282771,
      "lat": 24.5718332,
      "lng": 54.69699466666667,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:05",
      "speed": 28.064596,
      "direction": 6.282771,
      "lat": 24.571957333333334,
      "lng": 54.697008333333336,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:05",
      "speed": 27.570683,
      "direction": 6.282771,
      "lat": 24.572081466666667,
      "lng": 54.697022000000004,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:05",
      "speed": 27.076767,
      "direction": 6.282771,
      "lat": 24.5722056,
      "lng": 54.69703566666667,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:06",
      "speed": 26.582853,
      "direction": 6.282771,
      "lat": 24.572329733333333,
      "lng": 54.69704933333334,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:06",
      "speed": 26.088938,
      "direction": 6.282771,
      "lat": 24.572453866666667,
      "lng": 54.69706300000001,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:07",
      "speed": 25.595024,
      "direction": 6.282771,
      "lat": 24.572578,
      "lng": 54.697076666666675,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:07",
      "speed": 25.101109,
      "direction": 6.282771,
      "lat": 24.572702133333333,
      "lng": 54.69709033333334,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:07",
      "speed": 24.607195,
      "direction": 6.282771,
      "lat": 24.572826266666667,
      "lng": 54.69710400000001,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:08",
      "speed": 24.113281,
      "direction": 6.282771,
      "lat": 24.5729504,
      "lng": 54.69711766666668,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:08",
      "speed": 23.619366,
      "direction": 6.282771,
      "lat": 24.573074533333333,
      "lng": 54.697131333333346,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:09",
      "speed": 23.125452,
      "direction": 6.282771,
      "lat": 24.573198666666666,
      "lng": 54.69714500000001,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:09",
      "speed": 22.631536,
      "direction": 6.282771,
      "lat": 24.5733228,
      "lng": 54.69715866666668,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:09",
      "speed": 22.137623,
      "direction": 6.282771,
      "lat": 24.573446933333333,
      "lng": 54.69717233333335,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:10",
      "speed": 21.64371,
      "direction": 6.282771,
      "lat": 24.573571066666666,
      "lng": 54.697186000000016,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:10",
      "speed": 21.149794,
      "direction": 6.282771,
      "lat": 24.5736952,
      "lng": 54.697199666666684,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:11",
      "speed": 20.65588,
      "direction": 6.282771,
      "lat": 24.573819333333333,
      "lng": 54.69721333333335,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:11",
      "speed": 20.161964,
      "direction": 6.282771,
      "lat": 24.573943466666666,
      "lng": 54.69722700000002,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:11",
      "speed": 19.66805,
      "direction": 6.282771,
      "lat": 24.5740676,
      "lng": 54.69724066666669,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:12",
      "speed": 19.174135,
      "direction": 6.282771,
      "lat": 24.574191733333333,
      "lng": 54.697254333333355,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:12",
      "speed": 18.680222,
      "direction": 6.282771,
      "lat": 24.574315866666666,
      "lng": 54.69726800000002,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:13",
      "speed": 18.186308,
      "direction": 6.282771,
      "lat": 24.57444,
      "lng": 54.69728166666669,
      "dataPoint": false,
      "rpmDivd": 1.321
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:13",
      "speed": 18.186285,
      "direction": 10.775707,
      "lat": 24.57444,
      "lng": 54.69728166666667,
      "dataPoint": true,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:13",
      "speed": 17.627947,
      "direction": 10.775707,
      "lat": 24.5745626,
      "lng": 54.697305,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:13",
      "speed": 17.069607,
      "direction": 10.775707,
      "lat": 24.5746852,
      "lng": 54.69732833333333,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:14",
      "speed": 16.511269,
      "direction": 10.775707,
      "lat": 24.574807800000002,
      "lng": 54.69735166666666,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:14",
      "speed": 15.9529295,
      "direction": 10.775707,
      "lat": 24.574930400000003,
      "lng": 54.697374999999994,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:15",
      "speed": 15.39459,
      "direction": 10.775707,
      "lat": 24.575053000000004,
      "lng": 54.697398333333325,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:15",
      "speed": 14.836251,
      "direction": 10.775707,
      "lat": 24.575175600000005,
      "lng": 54.697421666666656,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:15",
      "speed": 14.277913,
      "direction": 10.775707,
      "lat": 24.575298200000006,
      "lng": 54.69744499999999,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:16",
      "speed": 13.719574,
      "direction": 10.775707,
      "lat": 24.575420800000007,
      "lng": 54.69746833333332,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:16",
      "speed": 13.161235,
      "direction": 10.775707,
      "lat": 24.575543400000008,
      "lng": 54.69749166666665,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:17",
      "speed": 12.602896,
      "direction": 10.775707,
      "lat": 24.57566600000001,
      "lng": 54.69751499999998,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:17",
      "speed": 12.044558,
      "direction": 10.775707,
      "lat": 24.57578860000001,
      "lng": 54.69753833333331,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:17",
      "speed": 11.486218,
      "direction": 10.775707,
      "lat": 24.57591120000001,
      "lng": 54.697561666666644,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:18",
      "speed": 10.927879,
      "direction": 10.775707,
      "lat": 24.576033800000012,
      "lng": 54.697584999999975,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:18",
      "speed": 10.36954,
      "direction": 10.775707,
      "lat": 24.576156400000013,
      "lng": 54.69760833333331,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:19",
      "speed": 9.811202,
      "direction": 10.775707,
      "lat": 24.576279000000014,
      "lng": 54.69763166666664,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:19",
      "speed": 9.252863,
      "direction": 10.775707,
      "lat": 24.576401600000015,
      "lng": 54.69765499999997,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:19",
      "speed": 8.694524,
      "direction": 10.775707,
      "lat": 24.576524200000016,
      "lng": 54.6976783333333,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:20",
      "speed": 8.136185,
      "direction": 10.775707,
      "lat": 24.576646800000017,
      "lng": 54.69770166666663,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:20",
      "speed": 7.577846,
      "direction": 10.775707,
      "lat": 24.576769400000018,
      "lng": 54.69772499999996,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:21",
      "speed": 7.0195074,
      "direction": 10.775707,
      "lat": 24.57689200000002,
      "lng": 54.697748333333294,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:21",
      "speed": 6.4611683,
      "direction": 10.775707,
      "lat": 24.57701460000002,
      "lng": 54.697771666666625,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:21",
      "speed": 5.9028296,
      "direction": 10.775707,
      "lat": 24.57713720000002,
      "lng": 54.69779499999996,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:22",
      "speed": 5.3444905,
      "direction": 10.775707,
      "lat": 24.57725980000002,
      "lng": 54.69781833333329,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:22",
      "speed": 4.786152,
      "direction": 10.775707,
      "lat": 24.577382400000022,
      "lng": 54.69784166666662,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:23",
      "speed": 4.227813,
      "direction": 10.775707,
      "lat": 24.577505000000023,
      "lng": 54.69786499999995,
      "dataPoint": false,
      "rpmDivd": 1.228
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:23",
      "speed": 4.227808,
      "direction": 21.22285,
      "lat": 24.577505,
      "lng": 54.697865,
      "dataPoint": true,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:23",
      "speed": 5.212059,
      "direction": 21.22285,
      "lat": 24.577624999999998,
      "lng": 54.6979116,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:23",
      "speed": 6.19631,
      "direction": 21.22285,
      "lat": 24.577744999999997,
      "lng": 54.697958199999995,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:24",
      "speed": 7.1805615,
      "direction": 21.22285,
      "lat": 24.577864999999996,
      "lng": 54.69800479999999,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:24",
      "speed": 8.164812,
      "direction": 21.22285,
      "lat": 24.577984999999995,
      "lng": 54.69805139999999,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:25",
      "speed": 9.149063,
      "direction": 21.22285,
      "lat": 24.578104999999994,
      "lng": 54.69809799999999,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:25",
      "speed": 10.133315,
      "direction": 21.22285,
      "lat": 24.578224999999993,
      "lng": 54.698144599999985,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:25",
      "speed": 11.117566,
      "direction": 21.22285,
      "lat": 24.57834499999999,
      "lng": 54.69819119999998,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:26",
      "speed": 12.101817,
      "direction": 21.22285,
      "lat": 24.57846499999999,
      "lng": 54.69823779999998,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:26",
      "speed": 13.086068,
      "direction": 21.22285,
      "lat": 24.57858499999999,
      "lng": 54.69828439999998,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:27",
      "speed": 14.070319,
      "direction": 21.22285,
      "lat": 24.57870499999999,
      "lng": 54.698330999999975,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:27",
      "speed": 15.05457,
      "direction": 21.22285,
      "lat": 24.578824999999988,
      "lng": 54.69837759999997,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:27",
      "speed": 16.038822,
      "direction": 21.22285,
      "lat": 24.578944999999987,
      "lng": 54.69842419999997,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:28",
      "speed": 17.023073,
      "direction": 21.22285,
      "lat": 24.579064999999986,
      "lng": 54.69847079999997,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:28",
      "speed": 18.007324,
      "direction": 21.22285,
      "lat": 24.579184999999985,
      "lng": 54.698517399999965,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:29",
      "speed": 18.991575,
      "direction": 21.22285,
      "lat": 24.579304999999984,
      "lng": 54.69856399999996,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:29",
      "speed": 19.975826,
      "direction": 21.22285,
      "lat": 24.579424999999983,
      "lng": 54.69861059999996,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:29",
      "speed": 20.960077,
      "direction": 21.22285,
      "lat": 24.57954499999998,
      "lng": 54.69865719999996,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:30",
      "speed": 21.944328,
      "direction": 21.22285,
      "lat": 24.57966499999998,
      "lng": 54.698703799999954,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:30",
      "speed": 22.92858,
      "direction": 21.22285,
      "lat": 24.57978499999998,
      "lng": 54.69875039999995,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:31",
      "speed": 23.91283,
      "direction": 21.22285,
      "lat": 24.57990499999998,
      "lng": 54.69879699999995,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:31",
      "speed": 24.897081,
      "direction": 21.22285,
      "lat": 24.580024999999978,
      "lng": 54.69884359999995,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:31",
      "speed": 25.881332,
      "direction": 21.22285,
      "lat": 24.580144999999977,
      "lng": 54.698890199999944,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:32",
      "speed": 26.865583,
      "direction": 21.22285,
      "lat": 24.580264999999976,
      "lng": 54.69893679999994,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:32",
      "speed": 27.849834,
      "direction": 21.22285,
      "lat": 24.580384999999975,
      "lng": 54.69898339999994,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:33",
      "speed": 28.834085,
      "direction": 21.22285,
      "lat": 24.580504999999974,
      "lng": 54.69902999999994,
      "dataPoint": false,
      "rpmDivd": 1.312
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:33",
      "speed": 28.834099,
      "direction": 23.980537,
      "lat": 24.580505,
      "lng": 54.69903,
      "dataPoint": true,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:33",
      "speed": 28.13528,
      "direction": 23.980537,
      "lat": 24.5806228,
      "lng": 54.6990824,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:33",
      "speed": 27.436462,
      "direction": 23.980537,
      "lat": 24.580740600000002,
      "lng": 54.6991348,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:34",
      "speed": 26.737646,
      "direction": 23.980537,
      "lat": 24.580858400000004,
      "lng": 54.699187200000004,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:34",
      "speed": 26.038828,
      "direction": 23.980537,
      "lat": 24.580976200000006,
      "lng": 54.699239600000006,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:35",
      "speed": 25.34001,
      "direction": 23.980537,
      "lat": 24.581094000000007,
      "lng": 54.69929200000001,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:35",
      "speed": 24.641191,
      "direction": 23.980537,
      "lat": 24.58121180000001,
      "lng": 54.69934440000001,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:35",
      "speed": 23.942375,
      "direction": 23.980537,
      "lat": 24.58132960000001,
      "lng": 54.69939680000001,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:36",
      "speed": 23.243557,
      "direction": 23.980537,
      "lat": 24.581447400000012,
      "lng": 54.69944920000001,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:36",
      "speed": 22.544739,
      "direction": 23.980537,
      "lat": 24.581565200000014,
      "lng": 54.69950160000001,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:37",
      "speed": 21.84592,
      "direction": 23.980537,
      "lat": 24.581683000000016,
      "lng": 54.69955400000001,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:37",
      "speed": 21.147102,
      "direction": 23.980537,
      "lat": 24.581800800000018,
      "lng": 54.699606400000015,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:37",
      "speed": 20.448286,
      "direction": 23.980537,
      "lat": 24.58191860000002,
      "lng": 54.699658800000016,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:38",
      "speed": 19.749468,
      "direction": 23.980537,
      "lat": 24.58203640000002,
      "lng": 54.69971120000002,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:38",
      "speed": 19.05065,
      "direction": 23.980537,
      "lat": 24.582154200000023,
      "lng": 54.69976360000002,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:39",
      "speed": 18.351831,
      "direction": 23.980537,
      "lat": 24.582272000000025,
      "lng": 54.69981600000002,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:39",
      "speed": 17.653015,
      "direction": 23.980537,
      "lat": 24.582389800000026,
      "lng": 54.69986840000002,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:39",
      "speed": 16.954197,
      "direction": 23.980537,
      "lat": 24.582507600000028,
      "lng": 54.69992080000002,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:40",
      "speed": 16.255379,
      "direction": 23.980537,
      "lat": 24.58262540000003,
      "lng": 54.699973200000024,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:40",
      "speed": 15.556561,
      "direction": 23.980537,
      "lat": 24.58274320000003,
      "lng": 54.700025600000025,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:41",
      "speed": 14.857743,
      "direction": 23.980537,
      "lat": 24.582861000000033,
      "lng": 54.700078000000026,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:41",
      "speed": 14.158925,
      "direction": 23.980537,
      "lat": 24.582978800000035,
      "lng": 54.70013040000003,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:41",
      "speed": 13.460108,
      "direction": 23.980537,
      "lat": 24.583096600000037,
      "lng": 54.70018280000003,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:42",
      "speed": 12.76129,
      "direction": 23.980537,
      "lat": 24.58321440000004,
      "lng": 54.70023520000003,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:42",
      "speed": 12.062472,
      "direction": 23.980537,
      "lat": 24.58333220000004,
      "lng": 54.70028760000003,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:43",
      "speed": 11.363654,
      "direction": 23.980537,
      "lat": 24.58345000000004,
      "lng": 54.70034000000003,
      "dataPoint": false,
      "rpmDivd": 0.449
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:43",
      "speed": 11.363632,
      "direction": 24.112911,
      "lat": 24.58345,
      "lng": 54.70034,
      "dataPoint": true,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:43",
      "speed": 11.9890785,
      "direction": 24.112911,
      "lat": 24.583567666666667,
      "lng": 54.700392666666666,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:43",
      "speed": 12.614526,
      "direction": 24.112911,
      "lat": 24.583685333333335,
      "lng": 54.700445333333334,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:44",
      "speed": 13.239972,
      "direction": 24.112911,
      "lat": 24.583803000000003,
      "lng": 54.700498,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:44",
      "speed": 13.865418,
      "direction": 24.112911,
      "lat": 24.58392066666667,
      "lng": 54.70055066666667,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:45",
      "speed": 14.490866,
      "direction": 24.112911,
      "lat": 24.58403833333334,
      "lng": 54.70060333333334,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:45",
      "speed": 15.116312,
      "direction": 24.112911,
      "lat": 24.584156000000007,
      "lng": 54.70065600000001,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:45",
      "speed": 15.741759,
      "direction": 24.112911,
      "lat": 24.584273666666675,
      "lng": 54.70070866666668,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:46",
      "speed": 16.367205,
      "direction": 24.112911,
      "lat": 24.584391333333343,
      "lng": 54.70076133333335,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:46",
      "speed": 16.992653,
      "direction": 24.112911,
      "lat": 24.58450900000001,
      "lng": 54.700814000000015,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:47",
      "speed": 17.6181,
      "direction": 24.112911,
      "lat": 24.58462666666668,
      "lng": 54.700866666666684,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:47",
      "speed": 18.243546,
      "direction": 24.112911,
      "lat": 24.584744333333347,
      "lng": 54.70091933333335,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:47",
      "speed": 18.868992,
      "direction": 24.112911,
      "lat": 24.584862000000015,
      "lng": 54.70097200000002,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:48",
      "speed": 19.494438,
      "direction": 24.112911,
      "lat": 24.584979666666683,
      "lng": 54.70102466666669,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:48",
      "speed": 20.119886,
      "direction": 24.112911,
      "lat": 24.58509733333335,
      "lng": 54.70107733333336,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:49",
      "speed": 20.745333,
      "direction": 24.112911,
      "lat": 24.58521500000002,
      "lng": 54.70113000000003,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:49",
      "speed": 21.370779,
      "direction": 24.112911,
      "lat": 24.585332666666687,
      "lng": 54.701182666666696,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:49",
      "speed": 21.996225,
      "direction": 24.112911,
      "lat": 24.585450333333355,
      "lng": 54.701235333333365,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:50",
      "speed": 22.621672,
      "direction": 24.112911,
      "lat": 24.585568000000023,
      "lng": 54.701288000000034,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:50",
      "speed": 23.247118,
      "direction": 24.112911,
      "lat": 24.58568566666669,
      "lng": 54.7013406666667,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:51",
      "speed": 23.872566,
      "direction": 24.112911,
      "lat": 24.58580333333336,
      "lng": 54.70139333333337,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:51",
      "speed": 24.498013,
      "direction": 24.112911,
      "lat": 24.585921000000027,
      "lng": 54.70144600000004,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:51",
      "speed": 25.123459,
      "direction": 24.112911,
      "lat": 24.586038666666695,
      "lng": 54.70149866666671,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:52",
      "speed": 25.748905,
      "direction": 24.112911,
      "lat": 24.586156333333363,
      "lng": 54.70155133333338,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:52",
      "speed": 26.374352,
      "direction": 24.112911,
      "lat": 24.58627400000003,
      "lng": 54.701604000000046,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:53",
      "speed": 26.9998,
      "direction": 24.112911,
      "lat": 24.5863916666667,
      "lng": 54.701656666666715,
      "dataPoint": false,
      "rpmDivd": 1.058
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:53",
      "speed": 26.999811,
      "direction": 24.064583,
      "lat": 24.586391666666668,
      "lng": 54.701656666666665,
      "dataPoint": true,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:53",
      "speed": 25.963663,
      "direction": 24.064583,
      "lat": 24.5865096,
      "lng": 54.70170933333333,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:53",
      "speed": 24.927515,
      "direction": 24.064583,
      "lat": 24.58662753333333,
      "lng": 54.701762,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:54",
      "speed": 23.891367,
      "direction": 24.064583,
      "lat": 24.586745466666663,
      "lng": 54.70181466666667,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:54",
      "speed": 22.85522,
      "direction": 24.064583,
      "lat": 24.586863399999995,
      "lng": 54.70186733333334,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:55",
      "speed": 21.819073,
      "direction": 24.064583,
      "lat": 24.586981333333327,
      "lng": 54.70192000000001,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:55",
      "speed": 20.782925,
      "direction": 24.064583,
      "lat": 24.58709926666666,
      "lng": 54.70197266666668,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:55",
      "speed": 19.746777,
      "direction": 24.064583,
      "lat": 24.58721719999999,
      "lng": 54.702025333333346,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:56",
      "speed": 18.710629,
      "direction": 24.064583,
      "lat": 24.587335133333323,
      "lng": 54.702078000000014,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:56",
      "speed": 17.67448,
      "direction": 24.064583,
      "lat": 24.587453066666654,
      "lng": 54.70213066666668,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:57",
      "speed": 16.638334,
      "direction": 24.064583,
      "lat": 24.587570999999986,
      "lng": 54.70218333333335,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:57",
      "speed": 15.602185,
      "direction": 24.064583,
      "lat": 24.587688933333318,
      "lng": 54.70223600000002,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:57",
      "speed": 14.566038,
      "direction": 24.064583,
      "lat": 24.58780686666665,
      "lng": 54.70228866666669,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:58",
      "speed": 13.52989,
      "direction": 24.064583,
      "lat": 24.587924799999982,
      "lng": 54.70234133333336,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:58",
      "speed": 12.493742,
      "direction": 24.064583,
      "lat": 24.588042733333314,
      "lng": 54.70239400000003,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:59",
      "speed": 11.457595,
      "direction": 24.064583,
      "lat": 24.588160666666646,
      "lng": 54.702446666666695,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:59",
      "speed": 10.421447,
      "direction": 24.064583,
      "lat": 24.588278599999978,
      "lng": 54.702499333333364,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:45:59",
      "speed": 9.385299,
      "direction": 24.064583,
      "lat": 24.58839653333331,
      "lng": 54.70255200000003,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:00",
      "speed": 8.349152,
      "direction": 24.064583,
      "lat": 24.58851446666664,
      "lng": 54.7026046666667,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:00",
      "speed": 7.3130035,
      "direction": 24.064583,
      "lat": 24.588632399999973,
      "lng": 54.70265733333337,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:01",
      "speed": 6.2768555,
      "direction": 24.064583,
      "lat": 24.588750333333305,
      "lng": 54.70271000000004,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:01",
      "speed": 5.240708,
      "direction": 24.064583,
      "lat": 24.588868266666637,
      "lng": 54.70276266666671,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:01",
      "speed": 4.2045603,
      "direction": 24.064583,
      "lat": 24.58898619999997,
      "lng": 54.702815333333376,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:02",
      "speed": 3.1684122,
      "direction": 24.064583,
      "lat": 24.5891041333333,
      "lng": 54.702868000000045,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:02",
      "speed": 2.1322646,
      "direction": 24.064583,
      "lat": 24.589222066666633,
      "lng": 54.702920666666714,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:03",
      "speed": 1.0961168,
      "direction": 24.064583,
      "lat": 24.589339999999964,
      "lng": 54.70297333333338,
      "dataPoint": false,
      "rpmDivd": 0.044
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:03",
      "speed": 1.0960983,
      "direction": 24.114199,
      "lat": 24.58934,
      "lng": 54.70297333333333,
      "dataPoint": true,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:03",
      "speed": 1.2401572,
      "direction": 24.114199,
      "lat": 24.589459,
      "lng": 54.7030266,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:03",
      "speed": 1.3842161,
      "direction": 24.114199,
      "lat": 24.589578000000003,
      "lng": 54.70307986666667,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:04",
      "speed": 1.528275,
      "direction": 24.114199,
      "lat": 24.589697000000005,
      "lng": 54.70313313333334,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:04",
      "speed": 1.6723338,
      "direction": 24.114199,
      "lat": 24.589816000000006,
      "lng": 54.70318640000001,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:05",
      "speed": 1.8163928,
      "direction": 24.114199,
      "lat": 24.589935000000008,
      "lng": 54.703239666666676,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:05",
      "speed": 1.9604516,
      "direction": 24.114199,
      "lat": 24.59005400000001,
      "lng": 54.703292933333344,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:05",
      "speed": 2.1045105,
      "direction": 24.114199,
      "lat": 24.59017300000001,
      "lng": 54.70334620000001,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:06",
      "speed": 2.2485695,
      "direction": 24.114199,
      "lat": 24.590292000000012,
      "lng": 54.70339946666668,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:06",
      "speed": 2.3926282,
      "direction": 24.114199,
      "lat": 24.590411000000014,
      "lng": 54.70345273333335,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:07",
      "speed": 2.5366871,
      "direction": 24.114199,
      "lat": 24.590530000000015,
      "lng": 54.70350600000002,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:07",
      "speed": 2.680746,
      "direction": 24.114199,
      "lat": 24.590649000000017,
      "lng": 54.70355926666669,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:07",
      "speed": 2.824805,
      "direction": 24.114199,
      "lat": 24.59076800000002,
      "lng": 54.703612533333356,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:08",
      "speed": 2.968864,
      "direction": 24.114199,
      "lat": 24.59088700000002,
      "lng": 54.703665800000024,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:08",
      "speed": 3.1129227,
      "direction": 24.114199,
      "lat": 24.59100600000002,
      "lng": 54.70371906666669,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:09",
      "speed": 3.2569816,
      "direction": 24.114199,
      "lat": 24.591125000000023,
      "lng": 54.70377233333336,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:09",
      "speed": 3.4010406,
      "direction": 24.114199,
      "lat": 24.591244000000025,
      "lng": 54.70382560000003,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:09",
      "speed": 3.5450995,
      "direction": 24.114199,
      "lat": 24.591363000000026,
      "lng": 54.7038788666667,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:10",
      "speed": 3.6891582,
      "direction": 24.114199,
      "lat": 24.591482000000028,
      "lng": 54.70393213333337,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:10",
      "speed": 3.8332171,
      "direction": 24.114199,
      "lat": 24.59160100000003,
      "lng": 54.703985400000036,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:11",
      "speed": 3.977276,
      "direction": 24.114199,
      "lat": 24.59172000000003,
      "lng": 54.704038666666705,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:11",
      "speed": 4.121335,
      "direction": 24.114199,
      "lat": 24.591839000000032,
      "lng": 54.70409193333337,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:11",
      "speed": 4.2653937,
      "direction": 24.114199,
      "lat": 24.591958000000034,
      "lng": 54.70414520000004,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:12",
      "speed": 4.409453,
      "direction": 24.114199,
      "lat": 24.592077000000035,
      "lng": 54.70419846666671,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:12",
      "speed": 4.5535116,
      "direction": 24.114199,
      "lat": 24.592196000000037,
      "lng": 54.70425173333338,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:13",
      "speed": 4.6975703,
      "direction": 24.114199,
      "lat": 24.59231500000004,
      "lng": 54.70430500000005,
      "dataPoint": false,
      "rpmDivd": 0.441
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:13",
      "speed": 4.697564,
      "direction": 24.185093,
      "lat": 24.592315,
      "lng": 54.704305,
      "dataPoint": true,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:13",
      "speed": 4.7521453,
      "direction": 24.185093,
      "lat": 24.5924342,
      "lng": 54.704358533333334,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:13",
      "speed": 4.8067265,
      "direction": 24.185093,
      "lat": 24.5925534,
      "lng": 54.70441206666667,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:14",
      "speed": 4.8613076,
      "direction": 24.185093,
      "lat": 24.5926726,
      "lng": 54.704465600000006,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:14",
      "speed": 4.915889,
      "direction": 24.185093,
      "lat": 24.5927918,
      "lng": 54.70451913333334,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:15",
      "speed": 4.9704695,
      "direction": 24.185093,
      "lat": 24.592911,
      "lng": 54.70457266666668,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:15",
      "speed": 5.0250506,
      "direction": 24.185093,
      "lat": 24.5930302,
      "lng": 54.704626200000014,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:15",
      "speed": 5.079632,
      "direction": 24.185093,
      "lat": 24.5931494,
      "lng": 54.70467973333335,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:16",
      "speed": 5.134213,
      "direction": 24.185093,
      "lat": 24.593268600000002,
      "lng": 54.704733266666686,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:16",
      "speed": 5.188794,
      "direction": 24.185093,
      "lat": 24.593387800000002,
      "lng": 54.70478680000002,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:17",
      "speed": 5.2433753,
      "direction": 24.185093,
      "lat": 24.593507000000002,
      "lng": 54.70484033333336,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:17",
      "speed": 5.2979565,
      "direction": 24.185093,
      "lat": 24.593626200000003,
      "lng": 54.704893866666694,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:17",
      "speed": 5.3525376,
      "direction": 24.185093,
      "lat": 24.593745400000003,
      "lng": 54.70494740000003,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:18",
      "speed": 5.407119,
      "direction": 24.185093,
      "lat": 24.593864600000003,
      "lng": 54.705000933333366,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:18",
      "speed": 5.4616995,
      "direction": 24.185093,
      "lat": 24.593983800000004,
      "lng": 54.7050544666667,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:19",
      "speed": 5.5162807,
      "direction": 24.185093,
      "lat": 24.594103000000004,
      "lng": 54.70510800000004,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:19",
      "speed": 5.570862,
      "direction": 24.185093,
      "lat": 24.594222200000004,
      "lng": 54.705161533333374,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:19",
      "speed": 5.625443,
      "direction": 24.185093,
      "lat": 24.594341400000005,
      "lng": 54.70521506666671,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:20",
      "speed": 5.680024,
      "direction": 24.185093,
      "lat": 24.594460600000005,
      "lng": 54.705268600000046,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:20",
      "speed": 5.7346053,
      "direction": 24.185093,
      "lat": 24.594579800000005,
      "lng": 54.70532213333338,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:21",
      "speed": 5.7891865,
      "direction": 24.185093,
      "lat": 24.594699000000006,
      "lng": 54.70537566666672,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:21",
      "speed": 5.8437676,
      "direction": 24.185093,
      "lat": 24.594818200000006,
      "lng": 54.705429200000054,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:21",
      "speed": 5.8983483,
      "direction": 24.185093,
      "lat": 24.594937400000006,
      "lng": 54.70548273333339,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:22",
      "speed": 5.9529295,
      "direction": 24.185093,
      "lat": 24.595056600000007,
      "lng": 54.705536266666726,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:22",
      "speed": 6.0075107,
      "direction": 24.185093,
      "lat": 24.595175800000007,
      "lng": 54.70558980000006,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:23",
      "speed": 6.062092,
      "direction": 24.185093,
      "lat": 24.595295000000007,
      "lng": 54.7056433333334,
      "dataPoint": false,
      "rpmDivd": 0.812
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:23",
      "speed": 6.0620947,
      "direction": 24.132265,
      "lat": 24.595295,
      "lng": 54.705643333333335,
      "dataPoint": true,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:23",
      "speed": 6.4217935,
      "direction": 24.132265,
      "lat": 24.595518066666667,
      "lng": 54.705743266666666,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:23",
      "speed": 6.781492,
      "direction": 24.132265,
      "lat": 24.595741133333334,
      "lng": 54.7058432,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:24",
      "speed": 7.1411915,
      "direction": 24.132265,
      "lat": 24.5959642,
      "lng": 54.70594313333333,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:24",
      "speed": 7.5008903,
      "direction": 24.132265,
      "lat": 24.596187266666668,
      "lng": 54.70604306666666,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:25",
      "speed": 7.860589,
      "direction": 24.132265,
      "lat": 24.596410333333335,
      "lng": 54.70614299999999,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:25",
      "speed": 8.220288,
      "direction": 24.132265,
      "lat": 24.5966334,
      "lng": 54.70624293333332,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:25",
      "speed": 8.579987,
      "direction": 24.132265,
      "lat": 24.59685646666667,
      "lng": 54.70634286666665,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:26",
      "speed": 8.939686,
      "direction": 24.132265,
      "lat": 24.597079533333336,
      "lng": 54.706442799999984,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:26",
      "speed": 9.299385,
      "direction": 24.132265,
      "lat": 24.597302600000003,
      "lng": 54.706542733333315,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:27",
      "speed": 9.659083,
      "direction": 24.132265,
      "lat": 24.59752566666667,
      "lng": 54.706642666666646,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:27",
      "speed": 10.018783,
      "direction": 24.132265,
      "lat": 24.597748733333336,
      "lng": 54.70674259999998,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:27",
      "speed": 10.378481,
      "direction": 24.132265,
      "lat": 24.597971800000003,
      "lng": 54.70684253333331,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:28",
      "speed": 10.73818,
      "direction": 24.132265,
      "lat": 24.59819486666667,
      "lng": 54.70694246666664,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:28",
      "speed": 11.097879,
      "direction": 24.132265,
      "lat": 24.598417933333337,
      "lng": 54.70704239999997,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:29",
      "speed": 11.457578,
      "direction": 24.132265,
      "lat": 24.598641000000004,
      "lng": 54.7071423333333,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:29",
      "speed": 11.817277,
      "direction": 24.132265,
      "lat": 24.59886406666667,
      "lng": 54.70724226666663,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:29",
      "speed": 12.176976,
      "direction": 24.132265,
      "lat": 24.599087133333338,
      "lng": 54.707342199999964,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:30",
      "speed": 12.5366745,
      "direction": 24.132265,
      "lat": 24.599310200000005,
      "lng": 54.707442133333295,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:30",
      "speed": 12.896374,
      "direction": 24.132265,
      "lat": 24.599533266666672,
      "lng": 54.707542066666626,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:31",
      "speed": 13.256072,
      "direction": 24.132265,
      "lat": 24.59975633333334,
      "lng": 54.70764199999996,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:31",
      "speed": 13.615771,
      "direction": 24.132265,
      "lat": 24.599979400000006,
      "lng": 54.70774193333329,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:31",
      "speed": 13.975471,
      "direction": 24.132265,
      "lat": 24.600202466666673,
      "lng": 54.70784186666662,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:32",
      "speed": 14.335169,
      "direction": 24.132265,
      "lat": 24.60042553333334,
      "lng": 54.70794179999995,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:32",
      "speed": 14.694868,
      "direction": 24.132265,
      "lat": 24.600648600000007,
      "lng": 54.70804173333328,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:33",
      "speed": 15.054567,
      "direction": 24.132265,
      "lat": 24.600871666666674,
      "lng": 54.70814166666661,
      "dataPoint": false,
      "rpmDivd": 1.05
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:33",
      "speed": 15.054575,
      "direction": 24.216866,
      "lat": 24.600871666666666,
      "lng": 54.70814166666667,
      "dataPoint": true,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:33",
      "speed": 15.3695345,
      "direction": 24.216866,
      "lat": 24.600988466666667,
      "lng": 54.7081942,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:33",
      "speed": 15.684495,
      "direction": 24.216866,
      "lat": 24.601105266666668,
      "lng": 54.70824673333333,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:34",
      "speed": 15.9994545,
      "direction": 24.216866,
      "lat": 24.60122206666667,
      "lng": 54.708299266666664,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:34",
      "speed": 16.314415,
      "direction": 24.216866,
      "lat": 24.60133886666667,
      "lng": 54.708351799999996,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:35",
      "speed": 16.629375,
      "direction": 24.216866,
      "lat": 24.60145566666667,
      "lng": 54.70840433333333,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:35",
      "speed": 16.944334,
      "direction": 24.216866,
      "lat": 24.60157246666667,
      "lng": 54.70845686666666,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:35",
      "speed": 17.259295,
      "direction": 24.216866,
      "lat": 24.60168926666667,
      "lng": 54.70850939999999,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:36",
      "speed": 17.574255,
      "direction": 24.216866,
      "lat": 24.601806066666672,
      "lng": 54.70856193333332,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:36",
      "speed": 17.889215,
      "direction": 24.216866,
      "lat": 24.601922866666673,
      "lng": 54.70861446666665,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:37",
      "speed": 18.204174,
      "direction": 24.216866,
      "lat": 24.602039666666673,
      "lng": 54.708666999999984,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:37",
      "speed": 18.519135,
      "direction": 24.216866,
      "lat": 24.602156466666674,
      "lng": 54.708719533333316,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:37",
      "speed": 18.834095,
      "direction": 24.216866,
      "lat": 24.602273266666675,
      "lng": 54.70877206666665,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:38",
      "speed": 19.149055,
      "direction": 24.216866,
      "lat": 24.602390066666675,
      "lng": 54.70882459999998,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:38",
      "speed": 19.464014,
      "direction": 24.216866,
      "lat": 24.602506866666676,
      "lng": 54.70887713333331,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:39",
      "speed": 19.778975,
      "direction": 24.216866,
      "lat": 24.602623666666677,
      "lng": 54.70892966666664,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:39",
      "speed": 20.093935,
      "direction": 24.216866,
      "lat": 24.602740466666678,
      "lng": 54.70898219999997,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:39",
      "speed": 20.408895,
      "direction": 24.216866,
      "lat": 24.60285726666668,
      "lng": 54.709034733333304,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:40",
      "speed": 20.723854,
      "direction": 24.216866,
      "lat": 24.60297406666668,
      "lng": 54.709087266666636,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:40",
      "speed": 21.038815,
      "direction": 24.216866,
      "lat": 24.60309086666668,
      "lng": 54.70913979999997,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:41",
      "speed": 21.353775,
      "direction": 24.216866,
      "lat": 24.60320766666668,
      "lng": 54.7091923333333,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:41",
      "speed": 21.668736,
      "direction": 24.216866,
      "lat": 24.60332446666668,
      "lng": 54.70924486666663,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:41",
      "speed": 21.983694,
      "direction": 24.216866,
      "lat": 24.60344126666668,
      "lng": 54.70929739999996,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:42",
      "speed": 22.298655,
      "direction": 24.216866,
      "lat": 24.603558066666682,
      "lng": 54.70934993333329,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:42",
      "speed": 22.613615,
      "direction": 24.216866,
      "lat": 24.603674866666683,
      "lng": 54.709402466666624,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:43",
      "speed": 22.928576,
      "direction": 24.216866,
      "lat": 24.603791666666684,
      "lng": 54.709454999999956,
      "dataPoint": false,
      "rpmDivd": 0.685
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:43",
      "speed": 22.928587,
      "direction": 23.496218,
      "lat": 24.603791666666666,
      "lng": 54.709455,
      "dataPoint": true,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:43",
      "speed": 22.847162,
      "direction": 23.496218,
      "lat": 24.603910666666668,
      "lng": 54.709506733333335,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:43",
      "speed": 22.765738,
      "direction": 23.496218,
      "lat": 24.60402966666667,
      "lng": 54.70955846666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:44",
      "speed": 22.684313,
      "direction": 23.496218,
      "lat": 24.60414866666667,
      "lng": 54.70961020000001,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:44",
      "speed": 22.60289,
      "direction": 23.496218,
      "lat": 24.604267666666672,
      "lng": 54.709661933333344,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:45",
      "speed": 22.521465,
      "direction": 23.496218,
      "lat": 24.604386666666674,
      "lng": 54.70971366666668,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:45",
      "speed": 22.44004,
      "direction": 23.496218,
      "lat": 24.604505666666675,
      "lng": 54.709765400000016,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:45",
      "speed": 22.358616,
      "direction": 23.496218,
      "lat": 24.604624666666677,
      "lng": 54.70981713333335,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:46",
      "speed": 22.277191,
      "direction": 23.496218,
      "lat": 24.60474366666668,
      "lng": 54.70986886666669,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:46",
      "speed": 22.195766,
      "direction": 23.496218,
      "lat": 24.60486266666668,
      "lng": 54.709920600000025,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:47",
      "speed": 22.114342,
      "direction": 23.496218,
      "lat": 24.60498166666668,
      "lng": 54.70997233333336,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:47",
      "speed": 22.032919,
      "direction": 23.496218,
      "lat": 24.605100666666683,
      "lng": 54.7100240666667,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:47",
      "speed": 21.951494,
      "direction": 23.496218,
      "lat": 24.605219666666684,
      "lng": 54.710075800000034,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:48",
      "speed": 21.87007,
      "direction": 23.496218,
      "lat": 24.605338666666686,
      "lng": 54.71012753333337,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:48",
      "speed": 21.788645,
      "direction": 23.496218,
      "lat": 24.605457666666688,
      "lng": 54.71017926666671,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:49",
      "speed": 21.70722,
      "direction": 23.496218,
      "lat": 24.60557666666669,
      "lng": 54.71023100000004,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:49",
      "speed": 21.625795,
      "direction": 23.496218,
      "lat": 24.60569566666669,
      "lng": 54.71028273333338,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:49",
      "speed": 21.54437,
      "direction": 23.496218,
      "lat": 24.605814666666692,
      "lng": 54.710334466666716,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:50",
      "speed": 21.462948,
      "direction": 23.496218,
      "lat": 24.605933666666694,
      "lng": 54.71038620000005,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:50",
      "speed": 21.381523,
      "direction": 23.496218,
      "lat": 24.606052666666695,
      "lng": 54.71043793333339,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:51",
      "speed": 21.300098,
      "direction": 23.496218,
      "lat": 24.606171666666697,
      "lng": 54.710489666666724,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:51",
      "speed": 21.218674,
      "direction": 23.496218,
      "lat": 24.6062906666667,
      "lng": 54.71054140000006,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:51",
      "speed": 21.137249,
      "direction": 23.496218,
      "lat": 24.6064096666667,
      "lng": 54.7105931333334,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:52",
      "speed": 21.055824,
      "direction": 23.496218,
      "lat": 24.6065286666667,
      "lng": 54.71064486666673,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:52",
      "speed": 20.9744,
      "direction": 23.496218,
      "lat": 24.606647666666703,
      "lng": 54.71069660000007,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:53",
      "speed": 20.892977,
      "direction": 23.496218,
      "lat": 24.606766666666704,
      "lng": 54.710748333333406,
      "dataPoint": false,
      "rpmDivd": 0.174
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:53",
      "speed": 20.892975,
      "direction": 24.234455,
      "lat": 24.606766666666665,
      "lng": 54.710748333333335,
      "dataPoint": true,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:53",
      "speed": 20.215631,
      "direction": 24.234455,
      "lat": 24.606885,
      "lng": 54.7108016,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:53",
      "speed": 19.538288,
      "direction": 24.234455,
      "lat": 24.60700333333333,
      "lng": 54.71085486666667,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:54",
      "speed": 18.860945,
      "direction": 24.234455,
      "lat": 24.607121666666664,
      "lng": 54.71090813333334,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:54",
      "speed": 18.183601,
      "direction": 24.234455,
      "lat": 24.607239999999997,
      "lng": 54.71096140000001,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:55",
      "speed": 17.506258,
      "direction": 24.234455,
      "lat": 24.60735833333333,
      "lng": 54.71101466666668,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:55",
      "speed": 16.828915,
      "direction": 24.234455,
      "lat": 24.607476666666663,
      "lng": 54.71106793333335,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:55",
      "speed": 16.151571,
      "direction": 24.234455,
      "lat": 24.607594999999996,
      "lng": 54.711121200000015,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:56",
      "speed": 15.474228,
      "direction": 24.234455,
      "lat": 24.60771333333333,
      "lng": 54.711174466666684,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:56",
      "speed": 14.796885,
      "direction": 24.234455,
      "lat": 24.607831666666662,
      "lng": 54.71122773333335,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:57",
      "speed": 14.119541,
      "direction": 24.234455,
      "lat": 24.607949999999995,
      "lng": 54.71128100000002,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:57",
      "speed": 13.442198,
      "direction": 24.234455,
      "lat": 24.60806833333333,
      "lng": 54.71133426666669,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:57",
      "speed": 12.764854,
      "direction": 24.234455,
      "lat": 24.60818666666666,
      "lng": 54.71138753333336,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:58",
      "speed": 12.087511,
      "direction": 24.234455,
      "lat": 24.608304999999994,
      "lng": 54.71144080000003,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:58",
      "speed": 11.410169,
      "direction": 24.234455,
      "lat": 24.608423333333327,
      "lng": 54.711494066666695,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:59",
      "speed": 10.732825,
      "direction": 24.234455,
      "lat": 24.60854166666666,
      "lng": 54.711547333333364,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:59",
      "speed": 10.055482,
      "direction": 24.234455,
      "lat": 24.608659999999993,
      "lng": 54.71160060000003,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:46:59",
      "speed": 9.378139,
      "direction": 24.234455,
      "lat": 24.608778333333326,
      "lng": 54.7116538666667,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:00",
      "speed": 8.700795,
      "direction": 24.234455,
      "lat": 24.60889666666666,
      "lng": 54.71170713333337,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:00",
      "speed": 8.023452,
      "direction": 24.234455,
      "lat": 24.609014999999992,
      "lng": 54.71176040000004,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:01",
      "speed": 7.346108,
      "direction": 24.234455,
      "lat": 24.609133333333325,
      "lng": 54.71181366666671,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:01",
      "speed": 6.668765,
      "direction": 24.234455,
      "lat": 24.60925166666666,
      "lng": 54.711866933333376,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:01",
      "speed": 5.9914217,
      "direction": 24.234455,
      "lat": 24.60936999999999,
      "lng": 54.711920200000044,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:02",
      "speed": 5.3140783,
      "direction": 24.234455,
      "lat": 24.609488333333324,
      "lng": 54.71197346666671,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:02",
      "speed": 4.636735,
      "direction": 24.234455,
      "lat": 24.609606666666657,
      "lng": 54.71202673333338,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:03",
      "speed": 3.9593916,
      "direction": 24.234455,
      "lat": 24.60972499999999,
      "lng": 54.71208000000005,
      "dataPoint": false,
      "rpmDivd": 1.407
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:03",
      "speed": 3.9593756,
      "direction": 24.03244,
      "lat": 24.609725,
      "lng": 54.71208,
      "dataPoint": true,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:03",
      "speed": 4.720828,
      "direction": 24.03244,
      "lat": 24.60984146666667,
      "lng": 54.71213193333333,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:03",
      "speed": 5.4822803,
      "direction": 24.03244,
      "lat": 24.609957933333337,
      "lng": 54.71218386666666,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:04",
      "speed": 6.2437325,
      "direction": 24.03244,
      "lat": 24.610074400000006,
      "lng": 54.712235799999995,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:04",
      "speed": 7.0051847,
      "direction": 24.03244,
      "lat": 24.610190866666674,
      "lng": 54.712287733333326,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:05",
      "speed": 7.766637,
      "direction": 24.03244,
      "lat": 24.610307333333342,
      "lng": 54.71233966666666,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:05",
      "speed": 8.528089,
      "direction": 24.03244,
      "lat": 24.61042380000001,
      "lng": 54.71239159999999,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:05",
      "speed": 9.289541,
      "direction": 24.03244,
      "lat": 24.61054026666668,
      "lng": 54.71244353333332,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:06",
      "speed": 10.050993,
      "direction": 24.03244,
      "lat": 24.610656733333347,
      "lng": 54.71249546666665,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:06",
      "speed": 10.812446,
      "direction": 24.03244,
      "lat": 24.610773200000015,
      "lng": 54.712547399999984,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:07",
      "speed": 11.573898,
      "direction": 24.03244,
      "lat": 24.610889666666683,
      "lng": 54.712599333333316,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:07",
      "speed": 12.33535,
      "direction": 24.03244,
      "lat": 24.61100613333335,
      "lng": 54.71265126666665,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:07",
      "speed": 13.096803,
      "direction": 24.03244,
      "lat": 24.61112260000002,
      "lng": 54.71270319999998,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:08",
      "speed": 13.858254,
      "direction": 24.03244,
      "lat": 24.611239066666688,
      "lng": 54.71275513333331,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:08",
      "speed": 14.619707,
      "direction": 24.03244,
      "lat": 24.611355533333356,
      "lng": 54.71280706666664,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:09",
      "speed": 15.381159,
      "direction": 24.03244,
      "lat": 24.611472000000024,
      "lng": 54.71285899999997,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:09",
      "speed": 16.14261,
      "direction": 24.03244,
      "lat": 24.611588466666692,
      "lng": 54.712910933333305,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:09",
      "speed": 16.904064,
      "direction": 24.03244,
      "lat": 24.61170493333336,
      "lng": 54.712962866666636,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:10",
      "speed": 17.665516,
      "direction": 24.03244,
      "lat": 24.61182140000003,
      "lng": 54.71301479999997,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:10",
      "speed": 18.426968,
      "direction": 24.03244,
      "lat": 24.611937866666697,
      "lng": 54.7130667333333,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:11",
      "speed": 19.18842,
      "direction": 24.03244,
      "lat": 24.612054333333365,
      "lng": 54.71311866666663,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:11",
      "speed": 19.949873,
      "direction": 24.03244,
      "lat": 24.612170800000033,
      "lng": 54.71317059999996,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:11",
      "speed": 20.711325,
      "direction": 24.03244,
      "lat": 24.6122872666667,
      "lng": 54.713222533333294,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:12",
      "speed": 21.472776,
      "direction": 24.03244,
      "lat": 24.61240373333337,
      "lng": 54.713274466666626,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:12",
      "speed": 22.234228,
      "direction": 24.03244,
      "lat": 24.612520200000038,
      "lng": 54.71332639999996,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:13",
      "speed": 22.995682,
      "direction": 24.03244,
      "lat": 24.612636666666706,
      "lng": 54.71337833333329,
      "dataPoint": false,
      "rpmDivd": 0.391
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:13",
      "speed": 22.995697,
      "direction": 24.20159,
      "lat": 24.612636666666667,
      "lng": 54.71337833333333,
      "dataPoint": true,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:13",
      "speed": 23.206863,
      "direction": 24.20159,
      "lat": 24.612752066666665,
      "lng": 54.7134302,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:13",
      "speed": 23.41803,
      "direction": 24.20159,
      "lat": 24.612867466666664,
      "lng": 54.713482066666664,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:14",
      "speed": 23.629196,
      "direction": 24.20159,
      "lat": 24.612982866666663,
      "lng": 54.71353393333333,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:14",
      "speed": 23.840364,
      "direction": 24.20159,
      "lat": 24.61309826666666,
      "lng": 54.7135858,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:15",
      "speed": 24.05153,
      "direction": 24.20159,
      "lat": 24.61321366666666,
      "lng": 54.71363766666666,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:15",
      "speed": 24.262697,
      "direction": 24.20159,
      "lat": 24.613329066666658,
      "lng": 54.71368953333333,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:15",
      "speed": 24.473864,
      "direction": 24.20159,
      "lat": 24.613444466666657,
      "lng": 54.713741399999996,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:16",
      "speed": 24.68503,
      "direction": 24.20159,
      "lat": 24.613559866666655,
      "lng": 54.71379326666666,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:16",
      "speed": 24.896196,
      "direction": 24.20159,
      "lat": 24.613675266666654,
      "lng": 54.71384513333333,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:17",
      "speed": 25.107363,
      "direction": 24.20159,
      "lat": 24.613790666666652,
      "lng": 54.713896999999996,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:17",
      "speed": 25.318531,
      "direction": 24.20159,
      "lat": 24.61390606666665,
      "lng": 54.71394886666666,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:17",
      "speed": 25.529697,
      "direction": 24.20159,
      "lat": 24.61402146666665,
      "lng": 54.71400073333333,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:18",
      "speed": 25.740864,
      "direction": 24.20159,
      "lat": 24.614136866666648,
      "lng": 54.714052599999995,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:18",
      "speed": 25.95203,
      "direction": 24.20159,
      "lat": 24.614252266666647,
      "lng": 54.71410446666666,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:19",
      "speed": 26.163197,
      "direction": 24.20159,
      "lat": 24.614367666666645,
      "lng": 54.71415633333333,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:19",
      "speed": 26.374363,
      "direction": 24.20159,
      "lat": 24.614483066666644,
      "lng": 54.714208199999995,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:19",
      "speed": 26.585531,
      "direction": 24.20159,
      "lat": 24.614598466666642,
      "lng": 54.71426006666666,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:20",
      "speed": 26.796698,
      "direction": 24.20159,
      "lat": 24.61471386666664,
      "lng": 54.71431193333333,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:20",
      "speed": 27.007864,
      "direction": 24.20159,
      "lat": 24.61482926666664,
      "lng": 54.714363799999994,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:21",
      "speed": 27.21903,
      "direction": 24.20159,
      "lat": 24.614944666666638,
      "lng": 54.71441566666666,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:21",
      "speed": 27.430197,
      "direction": 24.20159,
      "lat": 24.615060066666636,
      "lng": 54.71446753333333,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:21",
      "speed": 27.641363,
      "direction": 24.20159,
      "lat": 24.615175466666635,
      "lng": 54.71451939999999,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:22",
      "speed": 27.85253,
      "direction": 24.20159,
      "lat": 24.615290866666633,
      "lng": 54.71457126666666,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:22",
      "speed": 28.063698,
      "direction": 24.20159,
      "lat": 24.615406266666632,
      "lng": 54.714623133333326,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:23",
      "speed": 28.274864,
      "direction": 24.20159,
      "lat": 24.61552166666663,
      "lng": 54.71467499999999,
      "dataPoint": false,
      "rpmDivd": 1.244
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:23",
      "speed": 28.274864,
      "direction": 24.120583,
      "lat": 24.615521666666666,
      "lng": 54.714675,
      "dataPoint": true,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:23",
      "speed": 27.36488,
      "direction": 24.120583,
      "lat": 24.61564033333333,
      "lng": 54.71472813333333,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:23",
      "speed": 26.454895,
      "direction": 24.120583,
      "lat": 24.615758999999997,
      "lng": 54.71478126666666,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:24",
      "speed": 25.54491,
      "direction": 24.120583,
      "lat": 24.615877666666663,
      "lng": 54.714834399999994,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:24",
      "speed": 24.634926,
      "direction": 24.120583,
      "lat": 24.615996333333328,
      "lng": 54.714887533333325,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:25",
      "speed": 23.724941,
      "direction": 24.120583,
      "lat": 24.616114999999994,
      "lng": 54.71494066666666,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:25",
      "speed": 22.814957,
      "direction": 24.120583,
      "lat": 24.61623366666666,
      "lng": 54.71499379999999,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:25",
      "speed": 21.904974,
      "direction": 24.120583,
      "lat": 24.616352333333325,
      "lng": 54.71504693333332,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:26",
      "speed": 20.99499,
      "direction": 24.120583,
      "lat": 24.61647099999999,
      "lng": 54.71510006666665,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:26",
      "speed": 20.085005,
      "direction": 24.120583,
      "lat": 24.616589666666655,
      "lng": 54.71515319999998,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:27",
      "speed": 19.17502,
      "direction": 24.120583,
      "lat": 24.61670833333332,
      "lng": 54.71520633333331,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:27",
      "speed": 18.265036,
      "direction": 24.120583,
      "lat": 24.616826999999986,
      "lng": 54.715259466666645,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:27",
      "speed": 17.355051,
      "direction": 24.120583,
      "lat": 24.616945666666652,
      "lng": 54.715312599999976,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:28",
      "speed": 16.445066,
      "direction": 24.120583,
      "lat": 24.617064333333317,
      "lng": 54.71536573333331,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:28",
      "speed": 15.535082,
      "direction": 24.120583,
      "lat": 24.617182999999983,
      "lng": 54.71541886666664,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:29",
      "speed": 14.625097,
      "direction": 24.120583,
      "lat": 24.61730166666665,
      "lng": 54.71547199999997,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:29",
      "speed": 13.715113,
      "direction": 24.120583,
      "lat": 24.617420333333314,
      "lng": 54.7155251333333,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:29",
      "speed": 12.805129,
      "direction": 24.120583,
      "lat": 24.61753899999998,
      "lng": 54.71557826666663,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:30",
      "speed": 11.895144,
      "direction": 24.120583,
      "lat": 24.617657666666645,
      "lng": 54.715631399999964,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:30",
      "speed": 10.98516,
      "direction": 24.120583,
      "lat": 24.61777633333331,
      "lng": 54.715684533333295,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:31",
      "speed": 10.075175,
      "direction": 24.120583,
      "lat": 24.617894999999976,
      "lng": 54.71573766666663,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:31",
      "speed": 9.165191,
      "direction": 24.120583,
      "lat": 24.61801366666664,
      "lng": 54.71579079999996,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:31",
      "speed": 8.255206,
      "direction": 24.120583,
      "lat": 24.618132333333307,
      "lng": 54.71584393333329,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:32",
      "speed": 7.345222,
      "direction": 24.120583,
      "lat": 24.618250999999972,
      "lng": 54.71589706666662,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:32",
      "speed": 6.4352374,
      "direction": 24.120583,
      "lat": 24.618369666666638,
      "lng": 54.71595019999995,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:33",
      "speed": 5.5252533,
      "direction": 24.120583,
      "lat": 24.618488333333303,
      "lng": 54.71600333333328,
      "dataPoint": false,
      "rpmDivd": 0.351
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:33",
      "speed": 5.5252304,
      "direction": 26.079838,
      "lat": 24.61848833333333,
      "lng": 54.71600333333333,
      "dataPoint": true,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:33",
      "speed": 5.7498183,
      "direction": 26.079838,
      "lat": 24.61860533333333,
      "lng": 54.7160606,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:33",
      "speed": 5.974406,
      "direction": 26.079838,
      "lat": 24.61872233333333,
      "lng": 54.716117866666664,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:34",
      "speed": 6.1989937,
      "direction": 26.079838,
      "lat": 24.61883933333333,
      "lng": 54.71617513333333,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:34",
      "speed": 6.4235816,
      "direction": 26.079838,
      "lat": 24.61895633333333,
      "lng": 54.716232399999996,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:35",
      "speed": 6.6481695,
      "direction": 26.079838,
      "lat": 24.61907333333333,
      "lng": 54.71628966666666,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:35",
      "speed": 6.872757,
      "direction": 26.079838,
      "lat": 24.61919033333333,
      "lng": 54.71634693333333,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:35",
      "speed": 7.097345,
      "direction": 26.079838,
      "lat": 24.619307333333328,
      "lng": 54.71640419999999,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:36",
      "speed": 7.321933,
      "direction": 26.079838,
      "lat": 24.619424333333328,
      "lng": 54.71646146666666,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:36",
      "speed": 7.54652,
      "direction": 26.079838,
      "lat": 24.619541333333327,
      "lng": 54.716518733333324,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:37",
      "speed": 7.771108,
      "direction": 26.079838,
      "lat": 24.619658333333327,
      "lng": 54.71657599999999,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:37",
      "speed": 7.995696,
      "direction": 26.079838,
      "lat": 24.619775333333326,
      "lng": 54.716633266666655,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:37",
      "speed": 8.2202835,
      "direction": 26.079838,
      "lat": 24.619892333333325,
      "lng": 54.71669053333332,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:38",
      "speed": 8.444872,
      "direction": 26.079838,
      "lat": 24.620009333333325,
      "lng": 54.716747799999986,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:38",
      "speed": 8.669459,
      "direction": 26.079838,
      "lat": 24.620126333333324,
      "lng": 54.71680506666665,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:39",
      "speed": 8.894047,
      "direction": 26.079838,
      "lat": 24.620243333333324,
      "lng": 54.71686233333332,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:39",
      "speed": 9.118635,
      "direction": 26.079838,
      "lat": 24.620360333333323,
      "lng": 54.71691959999998,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:39",
      "speed": 9.343223,
      "direction": 26.079838,
      "lat": 24.620477333333323,
      "lng": 54.71697686666665,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:40",
      "speed": 9.56781,
      "direction": 26.079838,
      "lat": 24.620594333333322,
      "lng": 54.717034133333314,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:40",
      "speed": 9.792398,
      "direction": 26.079838,
      "lat": 24.620711333333322,
      "lng": 54.71709139999998,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:41",
      "speed": 10.016986,
      "direction": 26.079838,
      "lat": 24.62082833333332,
      "lng": 54.717148666666645,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:41",
      "speed": 10.241573,
      "direction": 26.079838,
      "lat": 24.62094533333332,
      "lng": 54.71720593333331,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:41",
      "speed": 10.466162,
      "direction": 26.079838,
      "lat": 24.62106233333332,
      "lng": 54.71726319999998,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:42",
      "speed": 10.690749,
      "direction": 26.079838,
      "lat": 24.62117933333332,
      "lng": 54.71732046666664,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:42",
      "speed": 10.915337,
      "direction": 26.079838,
      "lat": 24.62129633333332,
      "lng": 54.71737773333331,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:43",
      "speed": 11.139925,
      "direction": 26.079838,
      "lat": 24.62141333333332,
      "lng": 54.71743499999997,
      "dataPoint": false,
      "rpmDivd": 0.777
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:43",
      "speed": 11.139938,
      "direction": 34.624714,
      "lat": 24.621413333333333,
      "lng": 54.717435,
      "dataPoint": true,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:43",
      "speed": 11.752858,
      "direction": 34.624714,
      "lat": 24.6215234,
      "lng": 54.717511,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:43",
      "speed": 12.365778,
      "direction": 34.624714,
      "lat": 24.62163346666667,
      "lng": 54.717587,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:44",
      "speed": 12.978699,
      "direction": 34.624714,
      "lat": 24.621743533333337,
      "lng": 54.717663,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:44",
      "speed": 13.591619,
      "direction": 34.624714,
      "lat": 24.621853600000005,
      "lng": 54.717739,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:45",
      "speed": 14.204538,
      "direction": 34.624714,
      "lat": 24.621963666666673,
      "lng": 54.717815,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:45",
      "speed": 14.817458,
      "direction": 34.624714,
      "lat": 24.62207373333334,
      "lng": 54.717891,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:45",
      "speed": 15.430378,
      "direction": 34.624714,
      "lat": 24.62218380000001,
      "lng": 54.717967,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:46",
      "speed": 16.043299,
      "direction": 34.624714,
      "lat": 24.622293866666677,
      "lng": 54.718043,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:46",
      "speed": 16.656218,
      "direction": 34.624714,
      "lat": 24.622403933333345,
      "lng": 54.718119,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:47",
      "speed": 17.269138,
      "direction": 34.624714,
      "lat": 24.622514000000013,
      "lng": 54.718195,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:47",
      "speed": 17.88206,
      "direction": 34.624714,
      "lat": 24.62262406666668,
      "lng": 54.718271,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:47",
      "speed": 18.494978,
      "direction": 34.624714,
      "lat": 24.62273413333335,
      "lng": 54.718347,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:48",
      "speed": 19.107899,
      "direction": 34.624714,
      "lat": 24.622844200000017,
      "lng": 54.718423,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:48",
      "speed": 19.720818,
      "direction": 34.624714,
      "lat": 24.622954266666685,
      "lng": 54.718499,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:49",
      "speed": 20.333738,
      "direction": 34.624714,
      "lat": 24.623064333333353,
      "lng": 54.718575,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:49",
      "speed": 20.94666,
      "direction": 34.624714,
      "lat": 24.62317440000002,
      "lng": 54.718651,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:49",
      "speed": 21.559578,
      "direction": 34.624714,
      "lat": 24.62328446666669,
      "lng": 54.718727,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:50",
      "speed": 22.172499,
      "direction": 34.624714,
      "lat": 24.623394533333357,
      "lng": 54.718803,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:50",
      "speed": 22.785418,
      "direction": 34.624714,
      "lat": 24.623504600000025,
      "lng": 54.718879,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:51",
      "speed": 23.398338,
      "direction": 34.624714,
      "lat": 24.623614666666693,
      "lng": 54.718955,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:51",
      "speed": 24.01126,
      "direction": 34.624714,
      "lat": 24.62372473333336,
      "lng": 54.719031,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:51",
      "speed": 24.624178,
      "direction": 34.624714,
      "lat": 24.62383480000003,
      "lng": 54.719107,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:52",
      "speed": 25.237099,
      "direction": 34.624714,
      "lat": 24.623944866666697,
      "lng": 54.719183,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:52",
      "speed": 25.850018,
      "direction": 34.624714,
      "lat": 24.624054933333365,
      "lng": 54.719259,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:53",
      "speed": 26.462938,
      "direction": 34.624714,
      "lat": 24.624165000000033,
      "lng": 54.719335,
      "dataPoint": false,
      "rpmDivd": 0.248
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:53",
      "speed": 26.462946,
      "direction": 41.97869,
      "lat": 24.624165,
      "lng": 54.719335,
      "dataPoint": true,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:53",
      "speed": 25.790972,
      "direction": 41.97869,
      "lat": 24.62426406666667,
      "lng": 54.719424133333334,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:53",
      "speed": 25.118998,
      "direction": 41.97869,
      "lat": 24.624363133333336,
      "lng": 54.71951326666667,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:54",
      "speed": 24.447023,
      "direction": 41.97869,
      "lat": 24.624462200000004,
      "lng": 54.7196024,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:54",
      "speed": 23.775047,
      "direction": 41.97869,
      "lat": 24.62456126666667,
      "lng": 54.71969153333333,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:55",
      "speed": 23.103073,
      "direction": 41.97869,
      "lat": 24.62466033333334,
      "lng": 54.719780666666665,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:55",
      "speed": 22.431099,
      "direction": 41.97869,
      "lat": 24.624759400000006,
      "lng": 54.7198698,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:55",
      "speed": 21.759125,
      "direction": 41.97869,
      "lat": 24.624858466666673,
      "lng": 54.71995893333333,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:56",
      "speed": 21.08715,
      "direction": 41.97869,
      "lat": 24.62495753333334,
      "lng": 54.72004806666666,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:56",
      "speed": 20.415176,
      "direction": 41.97869,
      "lat": 24.625056600000008,
      "lng": 54.720137199999996,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:57",
      "speed": 19.743202,
      "direction": 41.97869,
      "lat": 24.625155666666675,
      "lng": 54.72022633333333,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:57",
      "speed": 19.071226,
      "direction": 41.97869,
      "lat": 24.625254733333342,
      "lng": 54.72031546666666,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:57",
      "speed": 18.399252,
      "direction": 41.97869,
      "lat": 24.62535380000001,
      "lng": 54.720404599999995,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:58",
      "speed": 17.727278,
      "direction": 41.97869,
      "lat": 24.625452866666677,
      "lng": 54.72049373333333,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:58",
      "speed": 17.055304,
      "direction": 41.97869,
      "lat": 24.625551933333345,
      "lng": 54.72058286666666,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:59",
      "speed": 16.38333,
      "direction": 41.97869,
      "lat": 24.625651000000012,
      "lng": 54.72067199999999,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:59",
      "speed": 15.711355,
      "direction": 41.97869,
      "lat": 24.62575006666668,
      "lng": 54.720761133333326,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:47:59",
      "speed": 15.03938,
      "direction": 41.97869,
      "lat": 24.625849133333347,
      "lng": 54.72085026666666,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:00",
      "speed": 14.367406,
      "direction": 41.97869,
      "lat": 24.625948200000014,
      "lng": 54.72093939999999,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:00",
      "speed": 13.695432,
      "direction": 41.97869,
      "lat": 24.62604726666668,
      "lng": 54.721028533333325,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:01",
      "speed": 13.023457,
      "direction": 41.97869,
      "lat": 24.62614633333335,
      "lng": 54.72111766666666,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:01",
      "speed": 12.351482,
      "direction": 41.97869,
      "lat": 24.626245400000016,
      "lng": 54.72120679999999,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:01",
      "speed": 11.679508,
      "direction": 41.97869,
      "lat": 24.626344466666684,
      "lng": 54.72129593333332,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:02",
      "speed": 11.007534,
      "direction": 41.97869,
      "lat": 24.62644353333335,
      "lng": 54.721385066666656,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:02",
      "speed": 10.335559,
      "direction": 41.97869,
      "lat": 24.62654260000002,
      "lng": 54.72147419999999,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:03",
      "speed": 9.663585,
      "direction": 41.97869,
      "lat": 24.626641666666686,
      "lng": 54.72156333333332,
      "dataPoint": false,
      "rpmDivd": 0.695
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:03",
      "speed": 9.663561,
      "direction": 43.64584,
      "lat": 24.626641666666668,
      "lng": 54.721563333333336,
      "dataPoint": true,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:03",
      "speed": 10.331957,
      "direction": 43.64584,
      "lat": 24.626736933333333,
      "lng": 54.7216542,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:03",
      "speed": 11.000352,
      "direction": 43.64584,
      "lat": 24.6268322,
      "lng": 54.72174506666667,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:04",
      "speed": 11.668748,
      "direction": 43.64584,
      "lat": 24.626927466666665,
      "lng": 54.72183593333334,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:04",
      "speed": 12.337143,
      "direction": 43.64584,
      "lat": 24.62702273333333,
      "lng": 54.721926800000006,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:05",
      "speed": 13.005539,
      "direction": 43.64584,
      "lat": 24.627117999999996,
      "lng": 54.72201766666667,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:05",
      "speed": 13.673934,
      "direction": 43.64584,
      "lat": 24.62721326666666,
      "lng": 54.72210853333334,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:05",
      "speed": 14.34233,
      "direction": 43.64584,
      "lat": 24.627308533333327,
      "lng": 54.72219940000001,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:06",
      "speed": 15.010725,
      "direction": 43.64584,
      "lat": 24.627403799999993,
      "lng": 54.722290266666676,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:06",
      "speed": 15.679121,
      "direction": 43.64584,
      "lat": 24.627499066666658,
      "lng": 54.72238113333334,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:07",
      "speed": 16.347517,
      "direction": 43.64584,
      "lat": 24.627594333333324,
      "lng": 54.72247200000001,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:07",
      "speed": 17.015911,
      "direction": 43.64584,
      "lat": 24.62768959999999,
      "lng": 54.72256286666668,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:07",
      "speed": 17.684307,
      "direction": 43.64584,
      "lat": 24.627784866666655,
      "lng": 54.722653733333345,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:08",
      "speed": 18.352703,
      "direction": 43.64584,
      "lat": 24.62788013333332,
      "lng": 54.72274460000001,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:08",
      "speed": 19.0211,
      "direction": 43.64584,
      "lat": 24.627975399999986,
      "lng": 54.72283546666668,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:09",
      "speed": 19.689495,
      "direction": 43.64584,
      "lat": 24.628070666666652,
      "lng": 54.72292633333335,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:09",
      "speed": 20.35789,
      "direction": 43.64584,
      "lat": 24.628165933333317,
      "lng": 54.723017200000015,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:09",
      "speed": 21.026285,
      "direction": 43.64584,
      "lat": 24.628261199999983,
      "lng": 54.72310806666668,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:10",
      "speed": 21.694681,
      "direction": 43.64584,
      "lat": 24.62835646666665,
      "lng": 54.72319893333335,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:10",
      "speed": 22.363077,
      "direction": 43.64584,
      "lat": 24.628451733333314,
      "lng": 54.72328980000002,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:11",
      "speed": 23.031471,
      "direction": 43.64584,
      "lat": 24.62854699999998,
      "lng": 54.723380666666685,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:11",
      "speed": 23.699867,
      "direction": 43.64584,
      "lat": 24.628642266666645,
      "lng": 54.72347153333335,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:11",
      "speed": 24.368263,
      "direction": 43.64584,
      "lat": 24.62873753333331,
      "lng": 54.72356240000002,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:12",
      "speed": 25.03666,
      "direction": 43.64584,
      "lat": 24.628832799999977,
      "lng": 54.72365326666669,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:12",
      "speed": 25.705053,
      "direction": 43.64584,
      "lat": 24.628928066666642,
      "lng": 54.723744133333355,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:13",
      "speed": 26.37345,
      "direction": 43.64584,
      "lat": 24.629023333333308,
      "lng": 54.72383500000002,
      "dataPoint": false,
      "rpmDivd": 0.17
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:13",
      "speed": 26.373466,
      "direction": 43.942543,
      "lat": 24.629023333333333,
      "lng": 54.723835,
      "dataPoint": true,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:13",
      "speed": 26.584633,
      "direction": 43.942543,
      "lat": 24.62911713333333,
      "lng": 54.7239254,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:13",
      "speed": 26.7958,
      "direction": 43.942543,
      "lat": 24.62921093333333,
      "lng": 54.7240158,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:14",
      "speed": 27.006966,
      "direction": 43.942543,
      "lat": 24.629304733333328,
      "lng": 54.724106199999994,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:14",
      "speed": 27.218134,
      "direction": 43.942543,
      "lat": 24.629398533333326,
      "lng": 54.72419659999999,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:15",
      "speed": 27.4293,
      "direction": 43.942543,
      "lat": 24.629492333333324,
      "lng": 54.72428699999999,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:15",
      "speed": 27.640467,
      "direction": 43.942543,
      "lat": 24.629586133333323,
      "lng": 54.72437739999999,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:15",
      "speed": 27.851633,
      "direction": 43.942543,
      "lat": 24.62967993333332,
      "lng": 54.724467799999985,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:16",
      "speed": 28.0628,
      "direction": 43.942543,
      "lat": 24.62977373333332,
      "lng": 54.72455819999998,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:16",
      "speed": 28.273966,
      "direction": 43.942543,
      "lat": 24.629867533333318,
      "lng": 54.72464859999998,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:17",
      "speed": 28.485132,
      "direction": 43.942543,
      "lat": 24.629961333333316,
      "lng": 54.72473899999998,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:17",
      "speed": 28.6963,
      "direction": 43.942543,
      "lat": 24.630055133333315,
      "lng": 54.724829399999976,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:17",
      "speed": 28.907467,
      "direction": 43.942543,
      "lat": 24.630148933333313,
      "lng": 54.724919799999974,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:18",
      "speed": 29.118633,
      "direction": 43.942543,
      "lat": 24.63024273333331,
      "lng": 54.72501019999997,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:18",
      "speed": 29.3298,
      "direction": 43.942543,
      "lat": 24.63033653333331,
      "lng": 54.72510059999997,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:19",
      "speed": 29.540966,
      "direction": 43.942543,
      "lat": 24.630430333333308,
      "lng": 54.72519099999997,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:19",
      "speed": 29.752132,
      "direction": 43.942543,
      "lat": 24.630524133333306,
      "lng": 54.725281399999965,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:19",
      "speed": 29.9633,
      "direction": 43.942543,
      "lat": 24.630617933333305,
      "lng": 54.72537179999996,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:20",
      "speed": 30.174467,
      "direction": 43.942543,
      "lat": 24.630711733333303,
      "lng": 54.72546219999996,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:20",
      "speed": 30.385633,
      "direction": 43.942543,
      "lat": 24.6308055333333,
      "lng": 54.72555259999996,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:21",
      "speed": 30.5968,
      "direction": 43.942543,
      "lat": 24.6308993333333,
      "lng": 54.725642999999955,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:21",
      "speed": 30.807966,
      "direction": 43.942543,
      "lat": 24.6309931333333,
      "lng": 54.72573339999995,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:21",
      "speed": 31.019133,
      "direction": 43.942543,
      "lat": 24.631086933333297,
      "lng": 54.72582379999995,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:22",
      "speed": 31.230299,
      "direction": 43.942543,
      "lat": 24.631180733333295,
      "lng": 54.72591419999995,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:22",
      "speed": 31.441467,
      "direction": 43.942543,
      "lat": 24.631274533333293,
      "lng": 54.726004599999946,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:23",
      "speed": 31.652634,
      "direction": 43.942543,
      "lat": 24.631368333333292,
      "lng": 54.726094999999944,
      "dataPoint": false,
      "rpmDivd": 0.16
    },
    {
      "tmTime": "",
      "tmTimeTxt": "2022-05-18 01:48:23",
      "speed": 31.652636,
      "direction": 0,
      "lat": 24.631368333333334,
      "lng": 54.726095,
      "dataPoint": true,
      "rpmDivd": 0.864
    }
  ],
  "startTimeText": "2022-05-18 01:17:43",
  "endLocation": "No Result"
}

import { limit } from '@/shared/services/base/api.service';
import moment from 'moment-timezone';

import {
  BS_DATEPICKER_FORMAT,
  DATE_API_FORMAT
} from '../../../utils/date.util';
import { BaseClass } from '../base/base.service';
import { DATE_VIEW_FULL_FORMAT } from './../../../utils/date.util';

export class DriverProvider extends BaseClass {
  constructor() {
    super('master-data/drivers');
  }

  async getDrivers(filter) {
    // limit: -1 means get ALL
    const res = await this.get('/', {
      filter,
      limit: -1
    });

    return res.data;
  }

  async createDriver(data) {
    const res = await this.post('/', data);
    return res.data;
  }

  async getDriverById(id: string) {
    const res = await this.get(`/${id}`);
    // const dateKeys = [
    //   'hireDate',
    //   'contractDate',
    //   'terminateDate',
    //   'birthday',
    //   'legalExpirationDate',
    //   'driverLicenseExpirationDate',
    //   'medicalExpirationDate',
    //   'twicExpirationDate',
    //   'insuranceExpirationDate',
    //   'mvrDate',
    //   'roadTestDate',
    //   'drugTestDate',
    //   'rehireDate'
    // ];

    // dateKeys.forEach(key => {
    //   if (res.data[key]) {
    //     res.data[key] = moment(res.data[key], DATE_API_FORMAT).format(
    //       BS_DATEPICKER_FORMAT
    //     );
    //   }
    // });

    const phones = [
      'cellPhone',
      'homePhone',
      'emergencyPhone'
    ];

    phones.forEach(phone => {
      if (res.data[phone]) {
        const phoneMas = [...res.data[phone]];
        //(123) 123-1231
        phoneMas.splice(0, 0, '(');
        phoneMas.splice(4, 0, ')');
        phoneMas.splice(5, 0, ' ');
        phoneMas.splice(9, 0, '-');
        res.data[phone] = phoneMas.join('');
      }
    });

    if (res.data.ein) {
      const einMas = [...res.data.ein];
      einMas.splice(2, 0, '-');
      res.data.ein = einMas.join('');
    }

    if (res.data.ssn) {
      const ssnMas = [...res.data.ssn];
      ssnMas.splice(3, 0, '-');
      ssnMas.splice(6, 0, '-');
      res.data.ssn = ssnMas.join('');
    }

    return res.data;
  }

  async saveDriver(id: string, data) {
    const res = await this.put(`/${id}`, data);
    return res;
  }

  async getDriverFileLink(id: string, category: string) {
    const res = await this.get(`/${id}/files/${category}`);
    return res.data;
  }

  async deleteDriverFileById(id: string, category: string) {
    const res = await this.delete(`/${id}/files/${category}`);
  }

  async changeDriverActive(id: string, data) {
    const res = await this.patch(`/${id}`, data);
    return res.data;
  }
}

export const DriverService = new DriverProvider();











































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  BS_DATEPICKER_FORMAT,
  DATE_API_FORMAT,
  DATE_VIEW_FORMAT
} from '@/utils/date.util';

import moment from 'moment-timezone';
import { isLessOrMoreTodayBy } from '@/shared/components/helpers/datePickerHandlers';

@Component({})
export default class DatePicker extends Vue {
  @Prop(String) value: string;
  @Prop({ type: [Boolean], default: null }) state: boolean;
  @Prop(Boolean) required: boolean;
  @Prop(Boolean) isDirty: boolean;
  @Prop(String) size: string;
  @Prop(Boolean) disabled: boolean;
  @Prop(String) min;
  @Prop(String) max;
  @Prop(String) handlerMessage: string;
  @Prop(Function) handler: Function;
  @Prop(String) relatedControlName: string;
  @Prop(String) currentControlName: string;
  @Prop() validation;
  @Prop(Boolean) isCorrectFortmat;

  innerHandlerMessage = null;
  innerHandler: Function = isLessOrMoreTodayBy(90, `datePicker.90DaysDiff`);

  dateInputModel: string = null;
  datePickerModel: string = null;
  needToUpdateDateValue = true;

  handlerChecked = true;
  handlerModal = false;
  incorrectDateModel = false;
  incorrectDateModelFormat = false;
  handlerTitle = 'Error message';

  dateMask = '##/##/##';

  @Watch('value')
  onDateInputChange(value) {
    if (!value?.trim() || value === 'Invalid date') {
      this.datePickerModel = '';
      this.dateInputModel = '';
      return;
    }

    if (this.isModelTheSaveAsPickerValues()) {
      return;
    }

    this.dateInputModel = moment(value, DATE_API_FORMAT).format(
      DATE_VIEW_FORMAT
    );

    this.datePickerModel = moment(value, DATE_API_FORMAT).format(
      BS_DATEPICKER_FORMAT
    );
  }

  constructor() {
    super();
  }

  get months() {
    return moment.months();
  }

  get stateStatus() {
    return;
  }

  created() {
    this.setInitialValue();
  }

  setInitialValue() {
    if (this.value) {
      this.dateInputModel = this.value;
      this.datePickerModel = this.value;
      this.datePickerChange();
    } else {
      // Update initial value to false
      // To prevent checking handler on the first render
      this.setHandlerCheckStatus(false);
    }
  }

  inputChange() {
    if (this.dateInputModel?.length !== 8) {
      return;
    }

    // Check if date is valid
    if (!moment(this.dateInputModel, DATE_VIEW_FORMAT).isValid()) {
      this.incorrectDateModel = true;
      return;
    }

    if (this.isInputAndPickerHasTheSameValue()) {
      return;
    }

    this.datePickerModel = moment(this.dateInputModel, DATE_VIEW_FORMAT).format(
      BS_DATEPICKER_FORMAT
    );
    this.needToUpdateDateValue = false;
    this.setModelValue();
  }

  datePickerChange() {
    if (this.isInputAndPickerHasTheSameValue()) {
      return;
    }

    if (!this.needToUpdateDateValue) {
      this.needToUpdateDateValue = true;
      return;
    }

    this.dateInputModel = this.datePickerModel.length
      ? moment(this.datePickerModel, BS_DATEPICKER_FORMAT).format(
          DATE_VIEW_FORMAT
        )
      : null;

    this.setModelValue();
  }

  /**
   * Update v-model with current datePickerModel value.
   * Before the update check handler status and handler function
   * If handler function exist and need to check it — call checkHandler and prevent model update
   */
  setModelValue() {
    if (this.handler && !this.handlerChecked) {
      this.setHandlerCheckStatus(true);
      this.checkHandler();
      return;
    }

    this.needToUpdateDateValue = true;
    this.incorrectDateModelFormat = false;
    this.$emit(
      'input',
      this.datePickerModel
        ? moment(this.datePickerModel, BS_DATEPICKER_FORMAT).format(
            DATE_API_FORMAT
          )
        : ''
    );

    // Update initial value to false
    // To prevent checking handler on the first render
    this.setHandlerCheckStatus(false);
  }

  /**
   * Check current handler status
   * If handler returns true prevent model update and open confirmation modal
   * If handler returns false continue with modal update
   */
  checkHandler() {
    const res = this.handler(
      moment(this.datePickerModel, BS_DATEPICKER_FORMAT)
    );

    if (res) {
      this.toggleHandlerModal(true, res);
      return;
    }

    this.setModelValue();
  }

  /**
   * Update model with current DatePiker value
   */
  handlerModalConfirm() {
    this.setModelValue();
    this.toggleHandlerModal(false);
  }

  /**
   * Reser DatePicker and Input models to previous values
   */
  handlerModalReject() {
    this.dateInputModel = this.value
      ? moment(this.value, DATE_API_FORMAT).format(DATE_VIEW_FORMAT)
      : null;

    this.datePickerModel = this.value
      ? moment(this.value, DATE_API_FORMAT).format(BS_DATEPICKER_FORMAT)
      : null;

    this.setHandlerCheckStatus(false);
    this.toggleHandlerModal(false);
  }

  onBlur() {
    if (
      !moment(this.dateInputModel, DATE_VIEW_FORMAT).isValid() ||
      this.dateInputModel?.length < 8
    ) {
      this.incorrectDateModelFormat = true;
    } else {
      this.incorrectDateModelFormat = false;
    }

    if (!this.dateInputModel?.trim()) {
      this.datePickerModel = '';
      this.dateInputModel = '';
      this.incorrectDateModelFormat = false;

      this.$emit('input', '');
    }
  }

  private setHandlerCheckStatus(status: boolean) {
    this.handlerChecked = status;
  }

  private isInputAndPickerHasTheSameValue() {
    return (
      moment(this.dateInputModel, DATE_VIEW_FORMAT).format() ===
      moment(this.datePickerModel, BS_DATEPICKER_FORMAT).format()
    );
  }

  private isModelTheSaveAsPickerValues() {
    return (
      moment(this.datePickerModel, BS_DATEPICKER_FORMAT).format() ===
      moment(this.value, DATE_API_FORMAT).format()
    );
  }

  private toggleHandlerModal(
    status = !this.handlerModal,
    message: string = null
  ) {
    this.handlerModal = status;
    this.innerHandlerMessage = message;
    if (message !== 'datePicker.90DaysDiff') {
      this.handlerTitle = 'Warning';
    }
  }
}

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DriverService } from '../../../shared/services/mater-data/driver.service';
import { ToastHelper } from '../../../utils/toast.util';
import { MasterDriver } from './master-driver.model';

@Module({
  namespaced: true,
  name: 'masterDriver'
})
export default class MasterDriverModule extends VuexModule {
  loading = false;
  errorMessage: any = null;

  filters: string = null;
  page = 1;
  empType: string;
  meta: any = null;

  drivers: MasterDriver[] | any = [];

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setDrivers({ data, meta }) {
    this.drivers = data;
    this.meta = meta;
  }

  @Mutation
  public setMetaInformation(meta: any) {
    this.meta = meta;
  }

  @Mutation
  public setFilters(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public setEmpType(empType: string) {
    this.empType = empType;
  }

  @Action
  async load() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await DriverService.getDrivers(null);
      this.context.commit('setDrivers', res);
      console.log(res);
    } catch (err) {
      console.log('driversImport Error', err);
      ToastHelper.show('Driver', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async search() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await DriverService.getDrivers(this.filters);
      this.context.commit('setDrivers', res);
    } catch (err) {
      console.log('driversImport Error', err);
      ToastHelper.show('Driver', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async createUser() {}
}
